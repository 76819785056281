import { Box, Flex } from "@alobato/flex-box";
import React, { memo, useState } from "react";
import { css } from "styled-components/macro";
import Button from "../../components/Button";
import CircularProgress from "../../components/CircularProgress";
import FormErrorBox from "../../components/FormErrorBox";
import {
  CloseIcon,
  DoctorIcon,
  EmailIcon,
  ImageIcon,
  InstitutionIcon,
  PhoneIcon,
  WhatsAppIcon,
} from "../../components/Icons";
import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import Lightbox from "../../components/Lightbox";
import Modal from "../../components/StyledModal";
import Text from "../../components/Text";
import styled from "../../utils/styled";

import formDataFromObject from "../../utils/formDataFromObject";

import { useQuery } from "../../hooks/useApollo";
import useInput from "../../hooks/useInput";
import { GET } from "./Gqls";

import AUTH_TOKEN from "../../constants/authToken";
import BASE_API from "../../constants/baseApi";

const DowloadButton = styled.div`
  box-shadow: 1px 1px 1px rgba(50, 50, 50, 0.15);
  border-radius: 100px;
  cursor: pointer;
  background-color: #5f9ec2;
  color: #fff;
  padding: 12px;
  margin-top: 12px;
`;

const Show = memo(({ institution, navigate }) => {
  // const downloadExams = () => {
  //   setTimeout(() => {

  //   }, 500)
  // }

  const nameInput = useInput("");
  const cityInput = useInput("");
  const loginInput = useInput("");
  const [birthdateInput, setBirthdateInput] = useState("");
  const passwordInput = useInput("");

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const [examData, setExamData] = useState({});
  const [examImages, setExamImages] = useState([]);
  const [examId, setExamId] = useState(null);
  const [examLink, setExamLink] = useState("");
  const [linkLoading, setLinkLoading] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [authorize, setAuthorize] = useState(false);

  const [errors, setErrors] = useState({
    login: "O login é obrigatório",
    password: "A senha é obrigatória",
    birthday: "A data de nascimento é obrigatória",
    authorize: "Você deve autorizar para continuar",
  });

  const [touched, setTouched] = useState({
    login: false,
    password: false,
    birthday: false,
    authorize: false,
  });

  const getDoctorSpecialties = (doctor) => {
    const specialties = [];
    if (doctor.specialtyPrimaryObj && doctor.specialtyPrimaryObj.name)
      specialties.push(doctor.specialtyPrimaryObj.name);
    if (doctor.specialtySecondaryObj && doctor.specialtySecondaryObj.name)
      specialties.push(doctor.specialtySecondaryObj.name);
    return specialties;
  };

  const getBirthdateInput = () => {
    const onChange = (e) => {
      setBirthdateInput(e.target.value);
    };

    return { value: birthdateInput, onChange };
  };

  const handleDownload = async (e, examId) => {
    e.preventDefault();
    const response = await fetch(`${BASE_API}/zip?exams=[${examId}]`, {
      method: "GET",
      headers: {
        Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const data = await response.json();
    window.location.href = `https://${data.link}`;
  };

  const handleDownloadExams = async () => {
    const responsePatient = await fetch(`${BASE_API}/patient/?storage=false`, {
      headers: {
        Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const patient = await responsePatient.json();
    if (institution.needLogin) {
      setBirthdateInput(patient.birth_date);

      setLoginModalOpen(true);
    } else if (
      !patient.cpf ||
      patient.cpf == "" ||
      !patient.birth_date ||
      patient.birth_date == ""
    ) {
      setAlertModal(true);
    } else {
      try {
        const response = await fetch(
          `${BASE_API}/sync?establishment=${institution.id}`,
          {
            method: "GET",
            headers: {
              Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        const data = await response.json();
        if (data && data.worked) {
          alert(
            "Quando a sincronização terminar os exames serão listados abaixo."
          );
        }
      } catch (e) {
        console.log("e", e);
      }
    }
  };

  const handleDelete = async (examId) => {
    if (window.confirm("Você tem certeza que deseja excluir esse exame?")) {
      const response = await fetch(`${BASE_API}/exam?id=${examId}`, {
        method: "DELETE",
        headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` },
      });
      const data = await response.json();
      // window.location.href = `/arquivos/${id}`
    }
  };

  const handleExamClick = (exam) => {
    setExamImages(exam.medias.map((item) => item.image));
  };

  const handleShare = async (examId) => {
    setLinkLoading(true);
    const response = await fetch(`${BASE_API}/deeplink?id=${examId}`, {
      method: "GET",
      headers: {
        Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const data = await response.json();
    setExamLink(data.deeplink);
    setLinkLoading(false);
    // console.log({data})
  };

  const handleSave = async () => {
    const value = {
      login: false,
      password: false,
      birthday: false,
      authorize: false,
    };
    let canContinue = true;
    if (!authorize) {
      value.authorize = true;
      canContinue = false;
    }

    if (!loginInput.value || loginInput.value === "") {
      value.login = true;
      canContinue = false;
    }

    if (!passwordInput.value || passwordInput.value === "") {
      value.password = true;
      canContinue = false;
    }

    if (institution.needBirthDate && birthdateInput.value === "") {
      value.birthday = true;
      canContinue = false;
    }

    console.log(value);
    setTouched(value);

    if (!canContinue) return;

    const body = formDataFromObject({
      login: loginInput.value,
      password: passwordInput.value,
      birth_date: birthdateInput.value,
      establishment: institution.id,
    });
    const data = await (
      await fetch(`${BASE_API}/thirdPartiesIntegration/`, {
        method: "POST",
        body,
        headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` },
      })
    ).json();

    if (data.worked) {
      alert("Dados salvo com sucesso!");
      // setNewInstitutionModal(false)
      // setTimeout(() => { setNewInstitutionModalSuccess(true) }, 100)
    } else {
      // message('Ocorreu um erro')
    }
  };

  return (
    <Flex
      ai={"center"}
      justifyContent={"center"}
      flexDirection={"column"}
      m={3}
    >
      <Flex
        flexWrap={["wrap", "nowrap"]}
        border={"1px solid #e1e1e1"}
        borderRadius={"6px"}
        boxShadow={"1px 1px 1px rgba(50, 50, 50, 0.15)"}
        m={2}
        justifyContent={"space-evenly"}
        p={4}
        pl={2}
        fw={"wrap"}
        width={[1]}
      >
        <Flex
          flexDirection={"column"}
          ai={"center"}
          justifyContent={"center"}
          p={2}
          width={[1, 1, 1, 1 / 3]}
        >
          {institution.thumbnail ? (
            <Box>
              <img
                alt="Logo"
                src={institution.thumbnail}
                css={css`
                  max-width: 200px;
                  max-height: 200px;
                `}
              />
            </Box>
          ) : (
            <InstitutionIcon color={"#69abc3"} height={100} />
          )}

          <Box pt={1} textAlign={"center"}>
            <Text fontSize={1}>{institution.name}</Text>
          </Box>
        </Flex>

        <Flex
          borderLeft={[
            "none",
            "none",
            "none",
            "1px solid rgba(50,50,50, 0.15)",
          ]}
          p={2}
          flexDirection={"column"}
          width={[1, 1, 1, 1 / 3]}
          ai={"center"}
        >
          <Box>
            <strong>Especialidades:</strong>
          </Box>
          <Box p={1} mb={2}>
            <Flex justifyContent="center" flexWrap="wrap">
              {institution.specialties.map((item) => (
                <Box
                  key={item.name}
                  m={2}
                  css={css`
                    border: 3px solid hsla(211, 20%, 43%, 1);
                    border-radius: 13px;
                    font-size: 13px;
                    padding: 2px 7px;
                    font-weight: 800;
                  `}
                >
                  {item.name}
                </Box>
              ))}
            </Flex>
          </Box>
          <Box ta="center">
            <Text fontWeight={700}>
              Horário de funcionamento:
              <br />
            </Text>
            <Text fontSize={1}>{institution.workingTime}</Text>
          </Box>
        </Flex>

        <Flex
          borderLeft={[
            "none",
            "none",
            "none",
            "1px solid rgba(50,50,50, 0.15)",
          ]}
          p={2}
          flexDirection={"column"}
          ai={"flex-start"}
          width={[1, 1, 1, 1 / 3]}
          ai={"center"}
        >
          <Box>
            <strong>Contato:</strong>
          </Box>
          {institution.whatsapp && (
            <Flex mt={2} fontSize={2}>
              <WhatsAppIcon height={20} />
              <Box ml={2}>{institution.whatsapp}</Box>
            </Flex>
          )}
          <Flex mt={3} fontSize={2}>
            <PhoneIcon height={20} />
            <Box ml={2}>{institution.phone}</Box>
          </Flex>
          {institution.comercial_email && (
            <Flex mt={3} ml={3} fontSize={[1, "13px", 1]}>
              <EmailIcon height={20} />
              <Box ml={1}>{institution.comercial_email}</Box>
            </Flex>
          )}
          <Box mt={3} ml={3} fontSize={[1, "13px", 1]}>
            <Text>
              <a href={institution.website} style={{ color: "#69abc3" }}>
                {institution.website}
              </a>
            </Text>
          </Box>
        </Flex>
      </Flex>

      <Box>
        <DowloadButton onClick={handleDownloadExams}>
          <Text fontWeight={700}>Baixar exames</Text>
        </DowloadButton>
      </Box>

      {/* <Flex border={'1px solid #e1e1e1'} borderRadius={'6px'} p={4} boxShadow={'1px 1px 1px rgba(50, 50, 50, 0.15)'} m={2} width={1}>
        <Box>
          <Text fontSize={2} fontWeight={300}>{institution.description}</Text>
        </Box>
      </Flex> */}

      {institution.addresses.length > 0 && (
        <Box mb={3} mt={4}>
          <Text fw={600}>Endereço(s)</Text>
        </Box>
      )}

      <Flex flexWrap={["wrap", "nowrap"]}>
        {institution.addresses.map((item) => (
          <Box
            key={item.id}
            minWidth={300}
            flex={1}
            mb={4}
            bg="white"
            p={3}
            m={3}
          >
            <Box>{item.name}</Box>
            <Box>
              {item.street} {item.number} {item.complement} {item.district}
            </Box>
            <Box>
              {item.city.name} {item.postalCode}
            </Box>
          </Box>
        ))}
      </Flex>

      <Box mb={3} mt={4}>
        <Text fw={600}>Médicos</Text>
      </Box>

      {!institution.doctors.length && <Text>Nenhum médico</Text>}

      {institution.doctors.map((item) => (
        <Flex
          w="100%"
          ai="center"
          key={item.id}
          onClick={() => navigate(`/medicos/${item.id}`)}
          cursor="pointer"
          p={3}
          style={{ borderBottom: "1px solid lightgrey" }}
        >
          <Box mr={2}>
            <DoctorIcon />
          </Box>
          <Box>{item.name}</Box>
          <Box ml={3}>
            <Text fs={12}>{getDoctorSpecialties(item).join(", ")}</Text>
          </Box>
        </Flex>
      ))}

      <Box mb={3} mt={4}>
        <Text fw={600}>Exames realizados aqui</Text>
      </Box>

      {!institution.exams.length && <Text>Nenhum exame</Text>}

      {institution.exams.map((exam) => (
        <Box key={exam.id} m={3}>
          <Flex
            alignItems="center"
            cursor="pointer"
            onClick={() => {
              setExamData(exam);
              handleExamClick(exam);
              setExamId(exam.id);
            }}
          >
            <Box color="hsla(199, 75%, 57%, 1)" mr={3} css={{ lineHeight: 0 }}>
              <ImageIcon height={30} />
            </Box>
            <Box>
              <Text fontWeight={300}>
                {exam.formatedDate} {exam.name}
              </Text>
            </Box>
          </Flex>
        </Box>
      ))}

      {/* {institution.exams.map(item => (
        <Flex ai='center' key={item.id} p={3} style={{borderBottom: '1px solid lightgrey'}}>
          <Box>
            {item.name}
          </Box>
        </Flex>
      ))} */}

      {/*institution.exams.length > 0 &&
        <Button onClick={downloadExams}>Baixar Exames</Button>
      */}

      {alertModal && (
        <Modal
          onCloseCompleted={() => {
            setAlertModal(false);
          }}
          css={css`
            & > div {
              max-height: 200px;
            }
          `}
          render={({ onRequestClose }) => (
            <Flex fd="column" h="100%">
              <Box position="relative" h={64}>
                <Box
                  position="absolute"
                  cursor="pointer"
                  lh="0"
                  top={16}
                  right={16}
                  onClick={onRequestClose}
                >
                  <CloseIcon />
                </Box>
              </Box>
              <Box p={4} overflow="auto">
                <Box ta="center">
                  Para baixar os exames nessa instituição é necessário preencher
                  o CPF e NASCIMENTO do cadastro.
                  <br />
                  <br />
                  <Button onClick={() => navigate("../../perfil")}>
                    Ir para perfil
                  </Button>
                </Box>
              </Box>
            </Flex>
          )}
        />
      )}

      {examImages.length > 0 && (
        <Modal
          onCloseCompleted={() => {
            setExamImages([]);
            setExamLink("");
            setLinkLoading(false);
            setExamId(null);
          }}
          render={({ onRequestClose }) => (
            <Flex flexDirection="column" height="100%">
              <Box position="relative" height={64}>
                <Box
                  position="absolute"
                  cursor="pointer"
                  lineHeight="0"
                  top={16}
                  right={16}
                  onClick={onRequestClose}
                >
                  <CloseIcon />
                </Box>
                <Box p={3} textAlign="center">
                  <Text secondary large>
                    Exame
                  </Text>
                </Box>
              </Box>
              <Box p={4} overflow="auto">
                <Flex mb={2}>
                  <Box mr={3}>
                    <a
                      href={examImages[0]}
                      onClick={(e) => handleDownload(e, examId)}
                      target="_blank"
                      rel="noopener noreferrer"
                      download="exame"
                    >
                      Download
                    </a>
                  </Box>
                  <Box
                    mr={3}
                    cursor="pointer"
                    onClick={() => handleDelete(examId)}
                  >
                    Excluir
                  </Box>
                  <Box mr={3}>
                    {!examLink && (
                      <Text
                        cursor="pointer"
                        onClick={() => handleShare(examId)}
                      >
                        Compartilhar
                      </Text>
                    )}
                  </Box>
                  <Box>
                    {linkLoading && <Text>Gerando link...</Text>}
                    {examLink}
                  </Box>
                </Flex>
                {/* <Box>Mover para: <Cascader defaultValue={[]} style={{marginRight: 20, width: '100%', minWidth: 400, maxWidth: 600}} options={options} onChange={tree => handleMove(tree, examId)} placeholder='Selecione a pasta' /></Box> */}
                <Box textAlign="center">Nome: {examData.name}</Box>
                <Box mb={3} textAlign="center">
                  Descrição: {examData.description}
                </Box>
                <Box>
                  {/* <img alt='' src={examUrl} style={{width: '100%'}} /> */}
                  <Lightbox color="hsla(211,20%,43%,1)" images={examImages} />
                </Box>
              </Box>
            </Flex>
          )}
        />
      )}

      {loginModalOpen && (
        <Modal
          onCloseCompleted={() => {
            setLoginModalOpen(false);
          }}
          render={({ onRequestClose }) => (
            <Flex flexDirection="column" height="100%">
              <Box position="relative" height={64}>
                <Box
                  position="absolute"
                  cursor="pointer"
                  lineHeight="0"
                  top={16}
                  right={16}
                  onClick={onRequestClose}
                >
                  <CloseIcon />
                </Box>
                <Box p={3} textAlign="center">
                  <Text secondary large>
                    {institution.name}
                  </Text>
                </Box>
              </Box>
              <Box p={4} overflow="auto">
                <Box>
                  <Box>
                    Faça login para baixar seus exames desta instituição médico
                  </Box>
                  <Box flex={1} mb={2}>
                    <Input placeholder="Login" {...loginInput} />
                  </Box>
                  <FormErrorBox
                    fieldName="login"
                    errors={errors}
                    touched={touched}
                  />
                  <Box flex={1} mb={2}>
                    <Input placeholder="Senha" {...passwordInput} />
                  </Box>
                  <FormErrorBox
                    fieldName="password"
                    errors={errors}
                    touched={touched}
                  />
                  {institution.needBirthDate && (
                      <Box flex={1} mb={2}>
                        <InputDate
                          placeholder="Data de Nascimento"
                          {...getBirthdateInput()}
                          name="birthday"
                        />
                      </Box>
                    ) && (
                      <FormErrorBox
                        fieldName="birthday"
                        errors={errors}
                        touched={touched}
                      />
                    )}
                  <Box>
                    <input
                      type="checkbox"
                      id="authorize"
                      name="authorize"
                      value={authorize}
                      onChange={() => setAuthorize(true)}
                    />{" "}
                    Eu autorizo o TRIA a utilizar minhas credenciais
                    <br />
                    para acessar os resultados de exames deste laboratório em
                    meu nome
                  </Box>
                  <FormErrorBox
                    fieldName="authorize"
                    errors={errors}
                    touched={touched}
                  />
                  <Box>
                    <Button onClick={handleSave}>Ok</Button>
                  </Box>
                </Box>
              </Box>
            </Flex>
          )}
        />
      )}
    </Flex>
  );
});

export default memo((props) => {
  const { data, error, loading } = useQuery(GET, {
    variables: { id: props.id },
  });
  if (error) return `Erro! ${error.message}`;
  if (!data || loading) return <CircularProgress />;
  return <Show {...props} institution={data.institution} />;
});
