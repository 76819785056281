import { useLayoutEffect } from 'react'
import mobileDetect from '../utils/mobileDetect'

export default () => {

  useLayoutEffect(() => {
    const { isMobile, isDesktop, isAndroid, isIos } = mobileDetect(navigator.userAgent)
    if (isMobile()) document.body.classList.add('mobile')
    if (isIos()) document.body.classList.add('ios')
    if (isAndroid()) document.body.classList.add('android')
    if (isDesktop()) document.body.classList.add('desktop')
  }, [])

}
