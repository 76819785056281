import React, { memo, useState } from 'react'

import { Box } from '@alobato/flex-box'
import Button from '../../components/Button'
import Input from '../../components/Input'
import InputMask from '../../components/InputMask'
// import Button from '@alobato/button'
import Text from '@alobato/text'
import FormErrorBox from '../../components/FormErrorBox'

// import Cards from 'react-credit-cards'
import Card from 'react-credit-cards'


// import {
//   formatCreditCardNumber,
//   formatCVC,
//   formatExpirationDate,
//   formatFormData,
// } from './utils';

import 'react-credit-cards/es/styles-compiled.css'

const cepMask = '99999-999'
// const cepMask = [
//   /\d/,
//   /\d/,
//   /\d/,
//   /\d/,
//   /\d/,
//   "-",
//   /\d/,
//   /\d/,
//   /\d/
// ]



export default memo(({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {

  const [focused, setFocused] = useState('')
  const handleInputFocus = ({ target }) => {
    let focusName = ''
    if (target.name === 'cardCvv') {
      focusName = 'cvc'
    } else if (target.name === 'cardName') {
      focusName = 'name'
    } else if (target.name === 'cardNumber') {
      focusName = 'number'
    } else if (target.name === 'cardExpiration') {
      focusName = 'expiry'
    }

    

    setFocused(focusName)
  }

  // const handleInputBlur = ({ target }) => {
  //   setFocused('cvc')
  // }

  return (
    <form onSubmit={handleSubmit}>

      {/* <Box mb={4}>
        <Box><Text fs={13} fw={600} uppercase>CPF (somente números)</Text></Box>
        <Input style={{width: 300}} name='cpf' value={values.cpf} onChange={handleChange} onBlur={handleBlur} withError={errors.cpf} />
        <FormErrorBox fieldName='cpf' errors={errors} touched={touched} />
      </Box> */}

      <Box mb={5}>

      <Box>
        <Box><Text fs={13} fw={600} uppercase>CEP</Text></Box>
        <InputMask mask={cepMask} style={{width: 180}} name='cep' value={values.cep} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='cep' errors={errors} touched={touched} />
      </Box>

      <Box>
        <Box><Text fs={13} fw={600} uppercase>Logradouro (Rua, Avenida, etc...)</Text></Box>
        <Input width={600} name='street' value={values.street} onChange={handleChange} onBlur={handleBlur} withError={errors.street} />
        <FormErrorBox fieldName='street' errors={errors} touched={touched} />
      </Box>

      <Box>
        <Box><Text fs={13} fw={600} uppercase>Número</Text></Box>
        <Input width={200} name='number' value={values.number} onChange={handleChange} onBlur={handleBlur} withError={errors.number} />
        <FormErrorBox fieldName='number' errors={errors} touched={touched} />
      </Box>

      <Box mb={4}>
        <Box><Text fs={13} fw={600} uppercase>Bairro</Text></Box>
        <Input width={600} name='district' value={values.district} onChange={handleChange} onBlur={handleBlur} withError={errors.district} />
        <FormErrorBox fieldName='district' errors={errors} touched={touched} />
      </Box>

      </Box>

      {/* <Box mb={5}>
        <Box><Text fs={13} fw={600} uppercase>Cidade</Text></Box>
        <Input name='address' value={values.address} onChange={handleChange} onBlur={handleBlur} withError={errors.address} />
        <FormErrorBox fieldName='address' errors={errors} touched={touched} />
      </Box> */}

      <Box mb={4}>
      <Card
        number={values.cardNumber}
        name={values.cardName}
        expiry={values.cardExpiration}
        cvc={values.cardCvv}
        focused={focused}
        placeholders={{name: 'Seu nome aqui'}}
        locale={{valid: 'Válido até'}}
        callback={() => {}}
      />
      </Box>
      <Box>
        <Box><Text fs={13} fw={600} uppercase>Nome (exatamente como está no cartão)</Text></Box>
        <Input width={600} name='cardName' value={values.cardName} onChange={handleChange} onBlur={handleBlur} withError={errors.cardName} onFocus={handleInputFocus} />
        <FormErrorBox fieldName='cardName' errors={errors} touched={touched} />
      </Box>

      {/* <CreditCardInput
        cardNumberInputProps={{ value: values.cardNumber, onChange: e => setFieldValue('cardNumber', e.target.value) }}
        cardExpiryInputProps={{ value: values.cardExpiration, onChange: e => setFieldValue('cardExpiration', e.target.value) }}
        cardCVCInputProps={{ value: values.cardCvv, onChange: e => setFieldValue('cardCvv', e.target.value) }}
        fieldClassName="input"
        customTextLabels={{
          invalidCardNumber: 'Número do cartão inválido',
          expiryError: {
            invalidExpiryDate: 'Data de validade inválida',
            monthOutOfRange: 'El mes de expiración debe estar entre 01 y 12',
            yearOutOfRange: 'El año de expiración no puede estar en el pasado',
            dateOutOfRange: 'La fecha de expiración no puede estar en el pasado'
          },
          invalidCvc: 'CVC inválido',
          invalidZipCode: 'El código postal es inválido',
          cardNumberPlaceholder: 'Número do cartão',
          expiryPlaceholder: 'MM/AA',
          cvcPlaceholder: 'CVV',
          zipPlaceholder: 'C.P.'
        }}
      /> */}

      <Box mt={4}>
        <Box><Text fs={13} fw={600} uppercase>Número do Cartão (sem espaços)</Text></Box>
        <InputMask width={600} mask='9999 9999 9999 9999' name='cardNumber' value={values.cardNumber} onChange={handleChange} onBlur={handleBlur} onFocus={handleInputFocus} />
        <FormErrorBox fieldName='cardNumber' errors={errors} touched={touched} />
      </Box>

      <Box>
        <Box><Text fs={13} fw={600} uppercase>Validade (MM/AA)</Text></Box>
        <InputMask mask='99/99' style={{width: 200}} name='cardExpiration' value={values.cardExpiration} onChange={handleChange} onBlur={handleBlur} onFocus={handleInputFocus} />
        <FormErrorBox fieldName='cardExpiration' errors={errors} touched={touched} />
      </Box>

      <Box mb={4}>
        <Box><Text fs={13} fw={600} uppercase>CVV (Código verificador localizado no verso do cartão)</Text></Box>
        <InputMask mask='999' style={{width: 200}} name='cardCvv' value={values.cardCvv} onChange={handleChange} onBlur={e => { handleBlur(e); setFocused('') }} onFocus={handleInputFocus} />
        <FormErrorBox fieldName='cardCvv' errors={errors} touched={touched} />
      </Box>

      <Box ta='center'>
        <Button type='submit'>Confirmar assinatura</Button>
      </Box>
    </form>
  )
})
