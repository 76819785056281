export const reduce = async (arr, fn, val, pure) => {
  for (let i = 0; i < arr.length; i++) {
    let v = await fn(val, arr[i], i, arr)
    if (pure !== false) val = v
  }
  return val
}

export const serialize = obj => {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
