import styled from "styled-components";

export const Title = styled.h1`
  font-size: 22px !important;
  font-weight: bold;
  margin: 0 25px 0 0 !important;
  color: #2f3c4a !important;
  cursor: ${(props) => props.onClick && "pointer"};
`;

export const SubTitle = styled.h2`
  margin-top: 55px;
  font-size: 18px !important;
  font-weight: bold;
  color: #2f3c4a !important;
`;

export const BtnPink = styled.button`
  background-color: #e01b4b;
  border: 3px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  color: #ffffff;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 700;
  padding: 0px 30px;
  height: 50px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

export const BtnBlue = styled(BtnPink)`
  background-color: #3eaee2;
`;

export const CardOption = styled.div`
  display: flex;
  width: 100%;
  gap: 70px;
  margin-bottom: 44px;
`;

export const CardSearch = styled.div`
  margin-left: auto;
  display: flex;
  position: relative;

  svg {
    position: absolute;
    margin-left: auto;
    right: 10px;
    top: 10px;
  }

  input {
    border-radius: 8px;
    padding: 6px 30px 6px 15px;
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
  }

  input::placeholder {
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
  }

  input:focus {
    box-shadow: 0 0 0 0;
    border: 1px solid #dbdbdb;
    outline: 0;
  }
`;

export const Option = styled.span`
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

  color: ${(props) => props.select === "true" && "#2F3C4C"};
  color: ${(props) => props.select === "false" && "#343d4b80"};

  text-decoration: ${(props) => props.select === "true" && "underline"};
  text-decoration: ${(props) => props.select === "false" && "none"};
`;

export const TableContent = styled.div`
  margin-top: 15px;

  .ant-table-thead {
    font-size: 14px !important;
  }

  .ant-table-column-title {
    color: #2f3c4c;
  }

  .ant-table-row td {
    font-size: 13px;
  }

  table {
    thead {
      tr {
        th {
          background-color: transparent;
          padding: 0;
          border-bottom: none;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-left: 0;
          padding-right: 0;
          text-align: start;
        }
        &:hover {
          td {
            text-decoration: underline;
            background-color: transparent !important;
            &:last-child {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
`;

export const Form = styled.div``;
export const FormGroup = styled.div`
  label {
    display: block;
    font-weight: 800;
    margin-bottom: 12px;
  }
  input,
  select,
  textarea {
    display: block;
    padding: 16px 27px;
    background-color: #f5f5f5;
    width: 100%;
    border: none;
    border-radius: 11px;
  }
  textarea {
    height: 100%;
  }
`;
export const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin-bottom: 30px;
`;
export const Btns = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;
export const BtnPinkAlt = styled(BtnPink)`
  background-color: transparent;
  border: 3px solid #e01b4b;
  color: #e01b4b;
`;

export const BtnsMenu = styled.div`
  display: inline-flex;
  gap: 30px;
`;

export const BoxViews = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 40px;
  grid-row-gap: 64px;
  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1150px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
export const Container = styled.div`
  height: 100%;
`;

export const ContainerBody = styled.div`
  padding: 30px 45px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: block;
  justify-content: space-between;
  align-items: center;
  color: #2f3c4c;
  h3 {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 30px;
  }
  .pic-profile {
    width: 70px;
    height: 70px;
    img {
      border-radius: 50%;
      width: 100%;
    }
  }

  & + div {
    margin-top: 30px;
  }

  div {
    span {
      display: block;
      font-weight: 500;
      & + span {
        font-weight: 400;
        color: #7e7e7e;
        margin-top: 10px;
      }
    }
  }
  form + form {
    h3 {
      margin-top: 40px;
    }
  }
`;

export const ContainerHeader = styled.div`
  margin-bottom: 50px;
  h2 {
    font-size: 16px;
    font-weight: 800;
    color: #2f3c4c;
    opacity: 50px;
  }
`;

export const FormModal = styled.form`
  input,
  select,
  textarea {
    & + input {
      margin-top: 10px;
    }
  }
  textarea {
    height: 393px;
  }
  & > div {
    & + div {
      margin-top: 20px;
    }
  }
`;

export const Toggle = styled.div`
  width: 28px;
  height: 19px;
  input {
    display: none;
  }
  label {
    display: block;
    width: 28px;
    height: 19px;
    background-color: #2f3c4c;
    position: relative;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 2px;
    &::before {
      transition: all 0.1s linear;
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      box-shadow: 0px 3px 6px #00000029;
      background-color: #ffffff;
      border-radius: 20px;
    }
  }

  input:checked + label {
    background-color: #75d16d;
    &::before {
      transform: translateX(9px);
    }
  }
`;

export const ProfilesList = styled.ul`
  height: 478px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  li {
    list-style: none;
    &+li {
      margin-top: 30px;
    }
  }
`;

export const Profile = styled.li`
  display: flex;
  gap: 17px;
  align-items: center;
`;

export const ProfileImage = styled.img`
  width: 38px;
  height: 38px;
  border-radius: 50%;
`;

export const ProfileName = styled.span``;

export const Main = styled.div`
  min-height: calc(100vh - 252px - 32px - 62px);
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    margin: 0;
    padding: 5px 10px;
    color: #e01b4b;
    border: 1px solid #e01b4b;
    &:disabled {
      cursor: auto;
      border-color: #00000040;
      color: #00000040;
    }
  }
`;