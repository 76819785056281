import React, { memo, useState } from 'react'

import Button from '../../components/Button'
import CircularProgress from '../../components/CircularProgress'
import { Box, Flex } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ReactFileBase64 from '../../components/ReactFileBase64'
import Text from '../../components/Text'

import { useMutation } from '../../hooks/useApollo'
import { UPDATE } from './Gqls'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [loading, setLoading] = useState(false)
  const [base64Image, setBase64Image] = useState('')

  const updateItem = useMutation(UPDATE)

  const handleUpdate = () => {
    setLoading(true);
    if (localStorage.getItem('type') !== "operator") {
      updateItem({ variables: { input: { image: base64Image } } })
      .then(data => {
        setLoading(false);
        onUpdated()
      })
      .catch(error => {
        console.error('error', error)
      })
    } else {
      const formdata = new FormData();
      formdata.append("thumbnail", base64Image);
      
      fetch(`${BASE_API}/update-health-operator/`, {
        method: 'PATCH',
        headers: {
          'Authorization': `Token ${localStorage.getItem(AUTH_TOKEN)}`
        },
        body: formdata,
        redirect: 'follow'
      })
        .then(res => res.json())
        .then(res => {
          setLoading(false);
          onUpdated()
        })
        .catch(err => {
          setLoading(false)
          console.log(err)
        })
    }
  }

  const handleUpload = file => {
    if (file.base64) {
      setBase64Image(file.base64)
    }
  }

  if (loading) return <CircularProgress />

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Imagem</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Box>
          <Box lh={1.2}><Text fs={12} fw={600}>UPLOAD</Text></Box>
          <Box lh={1}><ReactFileBase64 onDone={handleUpload} /></Box>
        </Box>
        <Box p={3}><img alt='' style={{width: '300px'}} src={base64Image} /></Box>

        <Box><Button onClick={handleUpdate}>Salvar</Button></Box>
      </Box>
    </Flex>
  )
})
