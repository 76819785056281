import React, { memo, useEffect, useMemo, useState } from "react";

import traverse from "traverse";

import {
  ArrowLeftIcon,
  DocIcon,
  IconPdf,
  MoreIcon,
  CloseIcon,
  DeleteIcon,
  FolderIcon,
  PdfIcon
} from "../../components/Icons";

import MenuOver from "../../components/MenuOver";
import styled, { css } from "../../utils/styled";
import Button from "../../components/Button";
import Fab from "../../components/Fab";
import { Box, Flex } from "../../components/FlexBox";
import ImageModal from "../../components/ImageModal";
import Input from "../../components/Input";
import InputDate from "../../components/InputDate";
import Lightbox from "../../components/Lightbox";
import Select from "../../components/Select";
import Modal from "../../components/StyledModal";
import Modal2 from "../../components/StyledModal2";
import Text from "../../components/Text";

// import ReactFileBase64 from '../../components/ReactFileBase64'
import CircularProgress from "../../components/CircularProgress";
import ReactUpload from "../../components/ReactUpload";

import { useMutation } from "../../hooks/useApollo";
import { CREATE, CREATE_EXAM, UPLOAD } from "./Gqls";

import AUTH_TOKEN from "../../constants/authToken";
import BASE_API from "../../constants/baseApi";

import Paginator from "../../components/Paginator";
import useMyFetch from "../../hooks/useMyFetch";
import { serialize } from "../../utils/utils";

import Switch from "react-ios-switch";

const formDataFromObject = (object) =>
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());

const asyncReduce = async (arr, fn, val, pure) => {
  for (let i = 0; i < arr.length; i++) {
    let v = await fn(val, arr[i], i, arr);
    if (pure !== false) val = v;
  }
  return val;
};

const FolderItem = styled(Flex)`
  justify-content: space-between;
  border-bottom: 1px solid hsla(0, 0%, 93%, 1);
  padding: 20px;
  font-size: 18px;
`;

const Badge = styled(Flex)`
  background: #8e8d92;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  font-size: 14px;
  ${(props) =>
    props.value &&
    css`
      background-color: #548d4b;
    `}
`;

const filter = (files, search, sortBy) => {
  let filtered = files;
  if (files.length <= 0) {
    console.log("filter inside folder");
  } else {
    filtered = filtered.filter((item) => {
      let condition = true;
      if (condition)
        condition = search
          ? item.name
            .toLowerCase()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .includes(
              search
                .toLowerCase()
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")
            )
          : condition;
      return condition;
    });
  }

  filtered = filtered.sort((a, b) => {
    if (sortBy == "id") return a.id > b.id ? 1 : b.id > a.id ? -1 : 0;
    else return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
  });
  return filtered;
};

const List = memo(({ data, id, navigate, message }) => {
  const item = data.results;
  //console.log('item', item)
  const canCreateFolder = item.folder && item.folder.can_create_folder;

  const pageSize = 10;

  const upload = useMutation(UPLOAD);
  const createExam = useMutation(CREATE_EXAM);
  const createFolder = useMutation(CREATE);

  const [patient, setPatient] = useState(null);
  const [treeData, setTreeData] = useState([]);
  const [parentArray, setParentArray] = useState([]);

  const [limitModalOpen, setLimitModalOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);


  const [base64Files, setBase64Files] = useState([]);

  const [examData, setExamData] = useState({});
  const [examImages, setExamImages] = useState([]);

  const [examId, setExamId] = useState(null);
  const [examLink, setExamLink] = useState("");
  const [linkLoading, setLinkLoading] = useState(false);

  const [sending, setSending] = useState(false);

  const [type, setType] = useState("")
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [date, setDate] = useState("");

  const [folderId, setFolderId] = useState(id);

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("id");

  const [activePage, setActivePage] = useState(1);
  const [results, setResults] = useState(item);
  const [count, setCount] = useState(data.count);

  const [modalShare, setModalShare] = useState(false);
  const [sharedExam, setSharedExam] = useState({});
  const [sharedDoctors, setSharedDoctors] = useState([]);
  const [doctors, setDoctors] = useState([]);
  const [doctorActivePage, setDoctorActivePage] = useState(1);
  const [doctorSearch, setDoctorSearch] = useState("");
  const [allFolders, setAllFolders] = useState([]);
  const [loading, setIsLoading] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const getTree = async () => {
      const data = await (
        await fetch(`${BASE_API}/folderTree/`, {
          headers: {
            Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
          },
        })
      ).json();
      const strData = JSON.stringify(data)
        .replace(/"id":/g, '"value":')
        .replace(/"name":/g, '"label":')
        .replace(/"sub_folders":/g, '"children":');
      setTreeData(JSON.parse(strData));

      const patientData = await (
        await fetch(`${BASE_API}/patient`, {
          method: "GET",
          headers: {
            Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
      ).json();
      setPatient(patientData);

      if (id) {
        const arr = await (
          await fetch(`${BASE_API}/folderArray/?folder_id=${id}`, {
            headers: {
              Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
            },
          })
        ).json();
        setParentArray(arr);
      }
    };
    getTree();
  }, [id]);

  useEffect(() => {
    const getPaginatedExams = async () => {
      setIsLoading(true)
      const params = {
        page: activePage,
        pageSize: pageSize,
      };
      if (sortBy != "") params.order_by = sortBy;
      if (search != "") params.exam_name = search;
      if (folderId) params.id = folderId;
      try {
        const data = await (
          await fetch(`${BASE_API}/folder/?${serialize(params)}`, {
            headers: {
              Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
            },
          })
        ).json();
        setResults(data.results);
        setIsLoading(false)
      } catch (error) {
        setIsLoading(false)
      }
    };
    getPaginatedExams();
  }, [activePage, sortBy, search, folderId]);

  useEffect(() => {
    setBase64Files([]);
  }, [uploadModalOpen]);

  useEffect(() => {
    const getSharedDoctors = async () => {
      const params = {
        exam: sharedExam.id,
      };
      const data = await (
        await fetch(`${BASE_API}/examDoctor/?${serialize(params)}`, {
          headers: {
            Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
          },
        })
      ).json();
      if (modalShare == true) {
        data.forEach((item) => (item.checked = true));
        setSharedDoctors(data);
        getDoctors(data);
      }
    };

    const getDoctors = async (shared) => {
      const params = {
        page: doctorActivePage,
        pageSize: 4,
      };
      if (doctorSearch != "") params.name = doctorSearch;
      const data = await (
        await fetch(`${BASE_API}/allDoctors/?${serialize(params)}`, {
          headers: {
            Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
          },
        })
      ).json();

      data.results.forEach(
        (item) =>
        (item.checked =
          shared.filter((value) => value.doctor == item.id).length > 0)
      );

      setDoctors(data.results);
    };
    getSharedDoctors();
  }, [modalShare, doctorSearch, sharedExam.id, doctorActivePage]);

  useEffect(() => {
    const getAllFolders = async () => {
      const data = await (
        await fetch(`${BASE_API}/allFolders/`, {
          headers: {
            Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
          },
        })
      ).json();
      setAllFolders(data);
    };

    getAllFolders();
  }, [examImages]);

  const filtered = useMemo(
    () => filter(item.folders, search, sortBy),
    [item.folders, search, sortBy]
  );

  const handleCreateFolder = () => {
    const folderName = prompt("Nome da pasta:");
    if (folderName != null) {
      createFolder({ variables: { input: { name: folderName, parent: id } } })
        .then((data) => {
          window.location.href = `/arquivos/${id}`;
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };

  const handleCheck = (checked, id) => {
    if (
      sharedDoctors.filter((value) => {
        return value.doctor == id;
      }).length > 0
    ) {
      const cpy = [...sharedDoctors];

      cpy.find((value) => {
        return value.doctor == id;
      }).checked = checked;

      setSharedDoctors(cpy);
    }

    if (
      doctors.filter((value) => {
        return value.id == id;
      }).length > 0
    ) {
      const cpy = [...doctors];

      cpy.find((value) => {
        return value.id == id;
      }).checked = checked;

      setDoctors(cpy);
    }

    const formData = new FormData();
    formData.append("exam", sharedExam.id);
    formData.append("doctor", id);
    formData.append("checked", checked);

    fetch(`${BASE_API}/examDoctor/`, {
      method: "POST",
      body: formData,
      headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` },
    });
  };

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const handleSend = async (variables) => {
    if (!variables.name) return message("Preencha o nome.");
    if (!variables.date) return message("Preencha a data.");

    setSending(true);

    const base64Images = base64Files.map((item) => item.base64);

    let mediasIds = await asyncReduce(
      base64Images,
      async (acc, value) => {
        const formData = new FormData();
        formData.append("image", value);
        const data = await (
          await fetch(`${BASE_API}/media/`, {
            method: "POST",
            body: formData,
            headers: {
              Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
            },
          })
        ).json();
        acc.push(data.id);
        return acc;
      },
      []
    );

    alert(variables.type)

    const exam = {
      name: variables.name,
      description: variables.description,
      exam_date: `${date}T00:00`,
      medias: JSON.stringify(mediasIds),
      folder: id,
    };

    try {
      const examFormData = formDataFromObject(exam);
      const result = await (
        await fetch(`${BASE_API}/exam/`, {
          method: "POST",
          body: examFormData,
          headers: {
            Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
          },
        })
      ).json();
      if (result.worked) window.location.href = `/arquivos/${id}`;

      if (result && !result.worked && result.message) setLimitModalOpen(true);
    } catch (e) {
      message("Ocorreu um erro ao tentar enviar.");
    } finally {
      setSending(false);
    }
  };

  const handleDownload = async (e, examId) => {
    e.preventDefault();
    await makeDownload(examId);
  };

  const makeDownload = async (examId) => {
    const response = await fetch(`${BASE_API}/zip?exams=[${examId}]`, {
      method: "GET",
      headers: {
        Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const data = await response.json();
    window.location.href = data.file;
  };

  const handleUpload = (files) => {
    if (files.length > 0) {
      files.forEach((file) => {
        setBase64Files((currentBase64Files) => [
          ...currentBase64Files,
          {
            name: file.name,
            size: file.size,
            type: file.type,
            base64: file.base64,
          },
        ]);
      });
    }
  };

  const handleShare = async (examId) => {
    setLinkLoading(true);
    const response = await fetch(`${BASE_API}/deeplink?id=${examId}`, {
      method: "GET",
      headers: {
        Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    const data = await response.json();
    setExamLink(data.deeplink);
    setLinkLoading(false);
  };

  const handleCascaderChange = (value) => {
    const [last] = value.slice(-1);
    setFolderId(last);
  };

  const handleMove = async (e) => {
    const formData = new FormData();
    formData.append("folder", e.target.value);

    if (window.confirm("Você tem certeza que deseja mover esse exame?")) {
      const response = await fetch(`${BASE_API}/exam/?id=${examId}`, {
        method: "PUT",
        body: formData,
        headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` },
      });
      const data = await response.json();
      window.location.href = `/arquivos/`;
    }
  };

  const handleDeleteFolder = async (folderId) => {
    if (window.confirm("Você tem certeza que deseja excluir esta pasta?")) {
      const folderFormData = formDataFromObject({ id: folderId });
      const result = await (
        await fetch(`${BASE_API}/folder/`, {
          method: "DELETE",
          body: folderFormData,
          headers: {
            Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
          },
        })
      ).json();
      window.location.href = `/arquivos/${id}`;
    }
  };

  const handleDelete = async (examId) => {
    if (window.confirm("Você tem certeza que deseja excluir esse exame?")) {
      const response = await fetch(`${BASE_API}/exam?id=${examId}`, {
        method: "DELETE",
        headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` },
      });
      const data = await response.json();
      window.location.href = `/arquivos/${id}`;
    }
  };

  let prefix = [];
  if (
    [2337, 2338, 2339, 2340, 2341, 2342, 2343, 2345, 2346, 2347, 2348].includes(
      Number(id)
    )
  ) {
    prefix = [2335, 2336];
  } else if ([2350, 2351, 2352].includes(Number(id))) {
    prefix = [2335, 2349];
  } else if ([2336].includes(Number(id))) {
    prefix = [2335];
  } else if ([2349].includes(Number(id))) {
    prefix = [2335];
  }

  const options = treeData;

  let node;

  traverse(options).forEach(function (x) {
    if (this.key === "value" && this.node === Number(id)) {
      node = this;
    }
  });

  if (node && node.parent && node.parent.node)
    console.log("node.parent.node", node.parent.node);
  if (
    node &&
    node.parent &&
    node.parent &&
    node.parent.parent &&
    node.parent.parent.parent &&
    node.parent.parent.parent.node
  )
    console.log("node.parent.parent.node", node.parent.parent.node);

  const handleExamClick = (exam) => {
    setExamImages(exam.medias.map((item) => item.image));
  };

  const handleRemoveThumb = (index) => {
    setBase64Files((currentBase64Files) => {
      return currentBase64Files.filter((item, i) => i !== index);
    });
  };

  const handleMenuOverClick = async (action, id) => {
    switch (action) {
      case "delete":
        await handleDelete(id);
        break;
      case "share":
        setSharedExam(results.exams.filter((item) => item.id == id)[0]);
        setModalShare(true);
        break;
      case "download":
        await makeDownload(id);
        break;
      default:
        return false;
    }
  };

  const defaultValue = id ? [...prefix, Number(id)] : [];

  return (
    <Box>
      <Flex mb={4} ai="center" jc="space-between" flexDirection={{ xs: windowWidth <= 1060 ? 'column' : 'row', sm: 'row' }}>
        <Flex width={windowWidth <= 1060 && { xs: '100%', sm: 'auto' }}>
          <Box mr={3} width={{ xs: '100%', sm: 'auto' }}>
            <Input
              placeholder="Buscar..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Box mr={3} width={{ xs: '100%', sm: 'auto' }}>
            <Select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
              <option value="">Ordenar...</option>
              <option value="name">Ordenado por Nome</option>
              <option value="exam_date">Ordenado por Data de Criação</option>
            </Select>
          </Box>
        </Flex>
        {folderId && results.folder && !results.folder.is_recycle_bin && (
          <Box mt={windowWidth <= 1060 && 3}>
            <Button
              onClick={() => {
                if (
                  patient &&
                  patient.image_size + patient.docs_size > 100 * 1024 * 1024
                ) {
                  setLimitModalOpen(true);
                } else {
                  setUploadModalOpen(true);
                }
              }}
            >
              Fazer upload de exame
            </Button>
            {/* <Box lh={1.2}><Text fs={12} fw={600}>UPLOAD</Text></Box>
            <Box lh={1}><ReactFileBase64 multiple={true} onDone={handleUpload} /></Box> */}
          </Box>
        )}
      </Flex>

      {results.folder && !!results.folder.path.length && (
        <Box ml={3}>
          <Text
            style={{ cursor: "pointer" }}
            fontWeight={600}
            onClick={() => navigate(`/arquivos`)}
          >
            Arquivos
          </Text>
          {results.folder.path.map((item, index) => {
            return (
              <span key={item.id}>
                <span> &lt; </span>
                <Text
                  style={{ cursor: "pointer" }}
                  fontWeight={600}
                  onClick={() => navigate(`/arquivos/${item.id}`)}
                >
                  {item.name}
                </Text>
              </span>
            );
          })}
        </Box>
      )}

      <Box>
        {filtered.map((item) => (
          <FolderItem
            key={item.id}
            css={{ cursor: "pointer" }}
            onClick={() => navigate(`/arquivos/${item.id}`)}
          >
            <Flex alignItems="center">
              <Box
                onClick={() => navigate(`/arquivos/${item.id}`)}
                color="hsla(199, 75%, 57%, 1)"
                mr={3}
                css={{ lineHeight: 0 }}
              >
                <FolderIcon height={30} />
              </Box>
              <Box onClick={() => navigate(`/arquivos/${item.id}`)}>
                {item.name}
              </Box>
            </Flex>
            <Flex>
              <Badge value={item.number_of_exams}>
                <Box>{item.number_of_exams}</Box>
              </Badge>
              {!item.default && item.number_of_exams === 0 && (
                <Box
                  ml={3}
                  cursor="pointer"
                  onClick={() => handleDeleteFolder(item.id)}
                >
                  <DeleteIcon />
                </Box>
              )}
            </Flex>
          </FolderItem>
        ))}
      </Box>

      {results.exams &&
        results.exams.map((exam) => (
          <Box key={exam.id} m={3}>
            <Flex jc="space-between" alignItems="center" cursor="pointer">
              <Flex
                onClick={() => {
                  setExamData(exam);
                  handleExamClick(exam);
                  setExamId(exam.id);
                }}
              >
                <Box
                  color="hsla(199, 75%, 57%, 1)"
                  mr={3}
                  css={{ lineHeight: 0 }}
                  alignItems="center"
                >
                  {exam.medias.filter((m) => m.image.includes(".pdf")).length >
                    0 && <PdfIcon height={30} />}
                  {exam.medias.filter((m) => m.image.includes(".doc")).length >
                    0 && <DocIcon height={30} />}
                  {exam.medias.filter(
                    (m) => m.image.includes(".doc") || m.image.includes(".pdf")
                  ).length == 0 &&
                    exam.medias.length > 0 && (
                      <img
                        alt=""
                        src={exam.medias[0].image}
                        height={30}
                        width={30}
                      />
                    )}
                </Box>
                <Box>
                  <Text fontWeight={300}>
                    {exam.formated_date} - {exam.name}
                  </Text>
                </Box>
              </Flex>
              <Box>
                {results.folder && results.folder.is_recycle_bin && (
                  <MenuOver
                    options={[
                      { label: "Excluir definitivamente", value: "delete" },
                    ]}
                    onSelect={(action) => handleMenuOverClick(action, exam.id)}
                  >
                    <MoreIcon style={{ cursor: "pointer" }} />
                  </MenuOver>
                )}

                {results.folder && !results.folder.is_recycle_bin && (
                  <MenuOver
                    options={[
                      { label: "Download", value: "download" },
                      { label: "Mover para lixeira", value: "delete" },
                      { label: "Compartilhar", value: "share" },
                    ]}
                    onSelect={(action) => handleMenuOverClick(action, exam.id)}
                  >
                    <MoreIcon style={{ cursor: "pointer" }} />
                  </MenuOver>
                )}
              </Box>
            </Flex>
          </Box>
        ))}

      <Flex ai={"center"}>
        <Paginator
          activePage={activePage}
          itemsCountPerPage={pageSize}
          totalItemsCount={count}
          onChange={handlePageChange}
        />
      </Flex>

      {canCreateFolder && <Fab onClick={handleCreateFolder} />}

      {uploadModalOpen && (
        <Modal
          onCloseCompleted={() => setUploadModalOpen(false)}
          render={({ onRequestClose }) => (
            <Flex fd="column" h="100%">
              <Box position="relative" h={64}>
                <Box
                  position="absolute"
                  cursor="pointer"
                  lh="0"
                  top={16}
                  right={16}
                  onClick={onRequestClose}
                >
                  <CloseIcon />
                </Box>
                <Box p={3} ta="center">
                  <Text secondary large>
                    Upload
                  </Text>
                </Box>
              </Box>
              <Box p={4} overflow="auto">
                <Box>
                  <Box p={3}>
                    <Box lh={1}>
                      <ReactUpload multiple={true} onDone={handleUpload} />
                    </Box>
                  </Box>

                  <Box maxHeight={200} overflow="auto">
                    {base64Files.map(
                      (file, index) =>
                        console.log("file.type", file.type) || (
                          <Box
                            key={index}
                            m={2}
                            style={{
                              borderBottom: "1px solid hsla(203,20%,76%,1)",
                              marginBottom: 10,
                            }}
                          >
                            <Box>
                              {[
                                "image/png",
                                "image/jpg",
                                "image/jpeg",
                              ].includes(file.type) && (
                                  <img height={100} alt="" src={file.base64} />
                                )}
                              {["application/pdf"].includes(file.type) && (
                                <IconPdf />
                              )}
                            </Box>
                            <Box
                              onClick={() => handleRemoveThumb(index)}
                              d="inline-block"
                              cursor="pointer"
                              p={1}
                            >
                              <CloseIcon height={16} />
                            </Box>
                            <Box>{file.name}</Box>
                            <Box>{file.size}</Box>
                          </Box>
                        )
                    )}
                  </Box>

                  <Box p={3}>
                    <Input
                      placeholder="Nome"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Box>
                  <Box p={3}>
                    <InputDate
                      placeholder="Data"
                      onChange={(e, v) => setDate(v)}
                    />
                  </Box>
                  <Box p={3}>
                    <Input
                      placeholder="Descrição"
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </Box>
                  <Box p={3}>
                    <Select value={type} onChange={(e) => setType(e.target.value)}>

                      {console.log({ options })}

                      {options.map(opt => (
                        <option value={opt.label}>{opt.label}</option>
                      ))}
                    </Select>

                  </Box>
                  <Box p={3}>
                    <Button
                      loading={sending}
                      onClick={() => handleSend({ name, date, description, type })}
                    >
                      Enviar
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Flex>
          )}
        />
      )}

      {examImages.length > 0 && (
        <ImageModal
          onCloseCompleted={() => {
            setExamImages([]);
            setExamLink("");
            setLinkLoading(false);
            setExamId(null);
          }}
          render={({ onRequestClose }) => (
            <Flex fd="column" h="100%" w="100%" width="100%">
              <Box position="relative" h={60}>
                <Box
                  fontSize="1.2rem"
                  position="absolute"
                  t={10}
                  l={60}
                  color="white"
                >
                  Nome: {examData.name}
                  <br />
                  Descrição: {examData.description}
                </Box>
                <Box
                  color="white"
                  fontSize="2rem"
                  position="absolute"
                  cursor="pointer"
                  lh="0"
                  top={26}
                  l={26}
                  onClick={onRequestClose}
                >
                  <ArrowLeftIcon />
                </Box>
                <Box position="absolute" r={26} t={26} color="white">
                  <MenuOver
                    options={[
                      { label: "Download", value: "download" },
                      { label: "Mover para lixeira", value: "delete" },
                      { label: "Compartilhar", value: "share" },
                    ]}
                    onSelect={(action) => handleMenuOverClick(action, examId)}
                  >
                    <MoreIcon style={{ cursor: "pointer" }} />
                  </MenuOver>
                </Box>

                <Flex position="absolute" l="40%" color="white" marginTop={10}>
                  <Flex fontSize="1rem" paddingRight="5px">
                    Mover Para:
                    <Select
                      onChange={handleMove}
                      placeholder="Pastas"
                      style={{ color: "black" }}
                    >
                      {allFolders.map((item) => (
                        <option value={item.id}>{item.name}</option>
                      ))}
                    </Select>
                  </Flex>
                </Flex>
              </Box>

              <Box p={4} overflow="auto" height="100%">
                <Lightbox color="hsla(211,20%,43%,1)" images={examImages} />
              </Box>
            </Flex>
          )}
        />
      )}

      {limitModalOpen && (
        <Modal2
          onCloseCompleted={() => {
            setLimitModalOpen(false);
          }}
          render={({ onRequestClose }) => (
            <Flex fd="column" h="100%">
              <Box position="relative" h={64}>
                <Box
                  position="absolute"
                  cursor="pointer"
                  lh="0"
                  top={16}
                  right={16}
                  onClick={onRequestClose}
                >
                  <CloseIcon />
                </Box>
                <Box p={3} ta="center">
                  <Text secondary large>
                    Aviso
                  </Text>
                </Box>
              </Box>
              <Box p={4} overflow="auto">
                <Box>
                  <Box ta="center" mb={4}>
                    Você chegou ao limite do plano gratuito! Faça upgrade para
                    ter mais espaço de armazenamento!
                  </Box>
                  <Box ta="center">
                    <Button>Ver planos</Button>
                  </Box>
                </Box>
              </Box>
            </Flex>
          )}
        />
      )}

      {modalShare && (
        <Modal
          onCloseCompleted={() => {
            setModalShare(false);
            setDoctors([]);
            setSharedDoctors([]);
          }}
          render={({ onRequestClose }) => (
            <Flex fd="column" h="100%" style={{ overflow: "auto" }}>
              <Box position="relative" h={64}>
                <Box
                  position="absolute"
                  cursor="pointer"
                  lh="0"
                  top={16}
                  right={16}
                  onClick={onRequestClose}
                >
                  <CloseIcon />
                </Box>
                <Box p={3} ta="center">
                  <Text secondary large>
                    Configurar compartilhamento
                  </Text>
                </Box>
                <Box p={3} ta="center">
                  {sharedExam.medias.filter((m) => m.image.includes(".pdf"))
                    .length > 0 && <IconPdf height={100} />}
                  {sharedExam.medias.filter((m) => m.image.includes(".doc"))
                    .length > 0 && <DocIcon height={100} />}
                  {sharedExam.medias.filter(
                    (m) => m.image.includes(".doc") || m.image.includes(".pdf")
                  ).length == 0 &&
                    sharedExam.medias.length > 0 && (
                      <img
                        alt=""
                        src={sharedExam.medias[0].image}
                        height={100}
                      />
                    )}
                </Box>
                <Flex fd="column" p={1} ta="center">
                  <Text>{sharedExam.name}</Text>
                  <Text fontSize={12}>{sharedExam.description}</Text>
                </Flex>
                <Flex fd="column">
                  {sharedDoctors.length > 0 && (
                    <Flex fd="column" backgroundColor="#F9F9F9" p={3}>
                      <Text>Compartilhado com</Text>
                      <Text fontSize={10}>
                        Esses são os medicos com quem você já compartilhou o
                        exame em questão.
                      </Text>
                    </Flex>
                  )}
                  {sharedDoctors.map((item) => (
                    <Flex
                      p={3}
                      jc="space-between"
                      alignItems="center"
                      cursor="pointer"
                    >
                      <Text ta="center">{item.doctor_name}</Text>
                      <Flex alignItems="center">
                        {item.checked ? (
                          <Text style={{ color: "green", marginRight: 10 }}>
                            Ativado
                          </Text>
                        ) : (
                          <Text style={{ color: "grey", marginRight: 10 }}>
                            Desativado
                          </Text>
                        )}
                        <Switch
                          checked={item.checked}
                          onChange={(checked) => {
                            handleCheck(checked, item.doctor);
                          }}
                        />
                      </Flex>
                    </Flex>
                  ))}
                  <Flex
                    p={3}
                    jc="space-between"
                    alignItems="center"
                    backgroundColor="#F9F9F9"
                  >
                    <Text>Compartilhar também com</Text>
                    <Box mr={3}>
                      <Input
                        placeholder="Buscar..."
                        value={doctorSearch}
                        onChange={(e) => {
                          setDoctorSearch(e.target.value);
                          setActivePage(1);
                        }}
                      />
                    </Box>
                  </Flex>
                  {doctors.map((item) => (
                    <Flex
                      p={3}
                      jc="space-between"
                      alignItems="center"
                      cursor="pointer"
                    >
                      <Text ta="center">{item.name}</Text>
                      <Flex alignItems="center">
                        {item.checked ? (
                          <Text style={{ color: "green", marginRight: 10 }}>
                            Ativado
                          </Text>
                        ) : (
                          <Text style={{ color: "grey", marginRight: 10 }}>
                            Desativado
                          </Text>
                        )}
                        <Switch
                          checked={item.checked}
                          onChange={(checked) => {
                            handleCheck(checked, item.id);
                          }}
                        />
                      </Flex>
                    </Flex>
                  ))}
                </Flex>
              </Box>
            </Flex>
          )}
        />
      )}
    </Box>
  );
});

export default memo((props) => {
  const params = { pageSize: 10 };
  if (props.id) params.id = props.id;
  const { data, error, loading } = useMyFetch(
    `${BASE_API}/folder/?${serialize(params)}`,
    { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } }
  );
  if (error) return `Erro! ${error.message}`;
  if (!data || loading) return <CircularProgress />;
  return <List {...props} data={data} />;
});
