import React, { memo, useEffect, useState } from 'react'

import Button from '../../components/Button'
import { Box, Flex } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Text from '../../components/Text'

import { useMutation } from '../../hooks/useApollo'
import { UPDATE } from './Gqls'

import BASE_API from '../../constants/baseApi'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [specialtyPrimary, setSpecialtyPrimary] = useState(initialData.specialtyPrimary || '')
  const [specialtySecundary, setSpecialtySecundary] = useState(initialData.specialtySecundary || '')
  const [crmType, setCrmType] = useState(initialData.crmType || '')
  const [crm, setCrm] = useState(initialData.crm || '')
  const [crmUf, setCrmUf] = useState(initialData.crmUf || '')

  const [specialties, setSpecialties] = useState([])

  useEffect(() => {
    const getSpecialties = async () => {
      const response = await fetch(`${BASE_API}/getAllSpecialties/`)
      const data = await response.json()
      setSpecialties(data)
    }
    getSpecialties()
  }, [])

  const updateItem = useMutation(UPDATE)

  const handleUpdate = () => {
    updateItem({ variables: { input: { specialtyPrimary, specialtySecundary, crmType, crm, crmUf } } })
      .then(data => {
        onUpdated()
      })
      .catch(error => {
        console.error('error', error)
      })
  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Profissional</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={3}>
          <Box>Especialidade Primária</Box>
          <Select placeholder='Especialidade primária' value={specialtyPrimary} onChange={e => setSpecialtyPrimary(e.target.value)}>
            {specialties.map(item => (
              <option key={item.id} value={item.name}>{item.name}</option>
            ))}
          </Select>
        </Box>
    
        <Box mb={3}>
          <Box>Especialidade Secundária</Box>
          <Select placeholder='Especialidade secundária' value={specialtySecundary} onChange={e => setSpecialtySecundary(e.target.value)}>
            {specialties.map(item => (
              <option key={item.id} value={item.name}>{item.name}</option>
            ))}
          </Select>
        </Box>

        {/* <Box mb={3}>
          <Box>Especialidade Primária</Box>
          <Box><Input value={specialtyPrimary} onChange={e => setSpecialtyPrimary(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Especialidade Secundária</Box>
          <Box><Input value={specialtySecundary} onChange={e => setSpecialtySecundary(e.target.value)} /></Box>
        </Box> */}

        <Box mb={3}>
          <Box>Inscrição no Conselho</Box>
          <Box><Input value={crmType} onChange={e => setCrmType(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Número</Box>
          <Box><Input value={crm} onChange={e => setCrm(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>UF</Box>
          <Box>
            <Select placeholder='Selecione...' value={crmUf} onChange={e => setCrmUf(e.target.value)}>
              <option>AC</option>
              <option>AL</option>
              <option>AP</option>
              <option>AM</option>
              <option>BA</option>
              <option>CE</option>
              <option>DF</option>
              <option>ES</option>
              <option>GO</option>
              <option>MA</option>
              <option>MT</option>
              <option>MS</option>
              <option>MG</option>
              <option>PA</option>
              <option>PB</option>
              <option>PR</option>
              <option>PE</option>
              <option>PI</option>
              <option>RJ</option>
              <option>RN</option>
              <option>RS</option>
              <option>RO</option>
              <option>RR</option>
              <option>SC</option>
              <option>SP</option>
              <option>SE</option>
              <option>TO</option>
            </Select>
          </Box>
        </Box>

        <Box><Button onClick={handleUpdate}>Salvar</Button></Box>
      </Box>
    </Flex>
  )
})
