import styled from "styled-components";

export const Title = styled.h1`
  font-size: 22px !important;
  font-weight: bold;
  margin: 0 25px 0 0 !important;
  color: #2f3c4a !important;
`;

export const SubTitle = styled.h2`
  margin-top: 55px;
  font-size: 18px !important;
  font-weight: bold;
  color: #2f3c4a !important;
`;

export const BtnPink = styled.button`
  background-color: #e01b4b;
  border: 3px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  color: #ffffff;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 700;
  padding: 0px 30px;
  height: 50px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

export const CardOption = styled.div`
  display: flex;
  width: 100%;
  gap: 70px;
  margin-bottom: 44px;
`;

export const CardSearch = styled.form`
  margin-left: auto;
  display: flex;
  position: relative;

  button {
    color: ${(props) => (props.active === "true" ? "#E01B4B" : "#2F3C4A")};
    margin: 0;
    border: none;
    padding: 0;
    background: transparent;
    position: absolute;
    margin-left: auto;
    right: 10px;
    top: 10px;
    cursor: pointer;
    transition: color 0.3s ease;
  }

  input {
    border-radius: 8px;
    padding: 6px 30px 6px 15px;
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
  }

  input::placeholder {
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
  }

  input:focus {
    box-shadow: 0 0 0 0;
    border: 1px solid #dbdbdb;
    outline: 0;
  }
`;

export const Option = styled.span`
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

  color: ${(props) => props.select === "true" && "#2F3C4C"};
  color: ${(props) => props.select === "false" && "#343d4b80"};

  text-decoration: ${(props) => props.select === "true" && "underline"};
  text-decoration: ${(props) => props.select === "false" && "none"};
`;

export const TableContent = styled.div`
  margin-top: 15px;

  .ant-table-thead {
    font-size: 14px !important;
  }

  .ant-table-column-title {
    color: #2f3c4c;
  }

  .ant-table-row td {
    font-size: 13px;
  }

  table {
    thead {
      tr {
        th {
          background-color: transparent;
          padding: 0;
          border-bottom: none;
        }
      }
    }
    tbody {
      tr {
        td {
          padding-left: 0;
          padding-right: 0;
          text-align: start;
        }
        &:hover {
          td {
            text-decoration: underline;
            background-color: transparent !important;
            &:last-child {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
`;

export const Form = styled.form``;
export const FormGroup = styled.div`
  label {
    display: block;
    font-weight: 800;
    margin-bottom: 12px;
  }
  input,
  select {
    display: block;
    padding: 16px 27px;
    background-color: #f5f5f5;
    width: 100%;
    border: none;
    border-radius: 11px;
  }
`;
export const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin-bottom: 30px;
`;
export const Btns = styled.div`
  display: flex;
  gap: 20px;
  justify-content: ${(props) => props.jc || "flex-end"};
`;

export const BtnPinkAlt = styled(BtnPink)`
  background-color: transparent;
  border: 3px solid #e01b4b;
  color: #e01b4b;
`;

export const Main = styled.div`
  min-height: calc(100vh - 252px - 32px - 62px);
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    margin: 0;
    padding: 5px 10px;
    color: #e01b4b;
    border: 1px solid #e01b4b;
    &:disabled {
      cursor: auto;
      border-color: #00000040;
      color: #00000040;
    }
  }
`;

export const Error = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #a314141a;
  color: #310f0f;
  border-radius: 8px;
  width: fit-content;
`;

export const Success = styled.div`
  font-size: 14px;
  margin-bottom: 20px;
  padding: 10px 15px;
  background-color: #1a9e1a1a;
  color: #0f310f;
  border-radius: 8px;
  width: fit-content;
`;

export const ModalHeader = styled.div`
  width: 100%;
  padding: 30px;
  h1 {
    color: rgba(0, 0, 0, 0.65);
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  padding: 0 30px 30px;
`;
