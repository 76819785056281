import React from "react";
import DwvComponent from "./DwvComponent";
import { Content } from "../ListExams/styles";

export default function DicomViewer() {

  const urlParams = new URLSearchParams(window.location.search);
  const fileParametro = urlParams.get('file');

  return (
    <Content>
      <h1>Visualização DICOM</h1>
      <DwvComponent fileUrl={fileParametro}/>
    </Content>
  );
}
