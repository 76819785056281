import React, { memo } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Button from '../../components/Button'
import CircularProgress from '../../components/CircularProgress'

import { useQuery, useMutation } from '../../hooks/useApollo'
import { SEARCH_DOCTORS, LINK_DOCTOR } from './Gqls'

const List = ({ me, doctors, onUpdated }) => {

  const linkDoctor = useMutation(LINK_DOCTOR)

  const establishmentId = me.id

  const handleAdd = doctorId => {
    linkDoctor({ variables: { input: { doctorId, establishmentId  } } })
      .then(data => {
        onUpdated()
      })
      .catch(error => {
        console.error('error', error)
      })
  }

  return (
    <Box mb={5}>
      {doctors.map(item => (
        <Flex jc='space-between' ai='center' key={item.id} p={3} style={{borderBottom: '1px solid lightgrey'}}>
          <Box>
            {item.name}
          </Box>
          <Box ml={3}>
            <Button small onClick={() => handleAdd(item.id)}>Adicionar</Button>
          </Box>
        </Flex>
      ))}
    </Box>
  )
}

export default props => {
  console.log('props.query', props.query)
  if (!props.query) return null
  const { data, error, loading } = useQuery(SEARCH_DOCTORS, { variables: { q: props.query }, fetchPolicy: 'network-only'})
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  return <List {...props} doctors={data.doctors} />
}
