import { AutoComplete, Modal } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import ButtonLoading from "../../components/ButtonLoading";
import { Box } from "../../components/FlexBox";
import { CloseIcon } from "../../components/Icons";
import baseApi from "../../constants/baseApi";
import "./style.css";
import {
  BtnPink,
  BtnPinkAlt,
  Btns,
  ClickableTextPink,
  Form,
  FormGroup,
  LoadingInline,
  ModalBox,
  ModalHeader
} from "./styles";

export default function CreateProtocolModal({
  setCreate,
  create,
  type,
  setType,
  data,
  setData,
  confirmDropProtocol,
}) {
  const [examsValues, setExamsValues] = useState({});
  const [examsElements, setExamsElements] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingExams, setLoadingExams] = useState(false);
  const [exams, setExams] = useState([]);
  const [inputErrors, setInputErrors] = useState(new Map());
  const [protocolData, setProtocolData] = useState({
    name: "",
    description: "",
  });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    getExams();
    if (type === "edit" || type === "view") {
      setProtocolData({
        name: data.name,
        description: data.description,
      });
    }
    if (examsElements.length === 0 && !data) {
      addNewExam();
    }
    if (data) {
      addNewExam(null, data.exams);
      return;
    }
  }, []);

  function getExams(name = "") {
    exams.length === 0 && setLoadingExams(true);
    const headers = {
      Authorization: `token ${localStorage.getItem("docpadAuthToken")}`,
    };

    fetch(`${baseApi}/list-exams-by-exam-category/?name=${name || ""}`, {
      headers,
      method: "GET",
      redirect: "follow",
    })
      .then((res) => res.json())
      .then((res) => {
        if (!res.detail) {
          exams.length === 0 && setExams(res);
          if (name !== "") {
            setOptions(res.map((exam) => ({ value: exam.name })));
          } else {
            setOptions([]);
          }
        } else {
          setExams([]);
        }
        exams.length === 0 && setLoadingExams(false);
      });
  }

  const handleCancel = () => {
    setExamsElements([{key: 1}]);
    setType("create");
    setData(null);
    setCreate(false);
  };
  const handleOk = () => {
    setType("create");
    setCreate(false);
  };

  function formSubmitHandler(e) {
    e.preventDefault();
    setLoading(true);
    const headers = {
      Authorization: `token ${localStorage.getItem("docpadAuthToken")}`,
    };

    const mapValidation = new Map();

    if (protocolData.name === "") {
      mapValidation.set("name", "Nome do protocolo é obrigatório");
    }
    if (protocolData.description === "") {
      mapValidation.set("description", "Descrição do protocolo é obrigatório");
    }
    const examsObjValues = Object.values(examsValues);
    const examsIds = examsObjValues.map((exam) => exam.id);

    if (examsIds) {
      if (examsIds.length === 0 || examsIds[0] === undefined) {
        mapValidation.set("exams", "É necessário pelo menos 1 exame");
      }
      if ([...new Set(examsIds)].length !== examsIds.length) {
        mapValidation.set("exams", "Exames não podem se repetir");
      }
    } else {
      mapValidation.set("exams", "É necessário pelo menos 1 exame");
    }

    // check examsIds for undefined
    examsIds.forEach((examId, i) => {
      if (examId === undefined) {
        examsIds.splice(i, 1);
      }
    });

    if (mapValidation.size > 0) {
      setInputErrors(mapValidation);
      setLoading(false);
      return;
    }
    const formData = new FormData();
    type === "edit" && formData.append("id", data.id);
    formData.append("name", protocolData.name);
    formData.append("description", protocolData.description);
    formData.append("exams", examsIds);

    const url =
      type === "create"
        ? `${baseApi}/create-protocol-for-doctor`
        : `${baseApi}/update-protocol-for-doctor`;

    fetch(url, {
      headers,
      method: type === "create" ? "POST" : "PATCH",
      body: formData,
      redirect: "follow",
    })
      .then((res) => res.text())
      .then((data) => {
        setLoading(false);
        setData(null);
        setCreate(false);
      });
  }

  function addNewExam(e, exams) {
    if (type === "view" && e) return;
    const nextKey = examsElements.length + 1;
    const newExamsValues = { ...examsValues };
    const newExamsElements = [...examsElements];
    if (exams) {
      const tempExamsIdSelected = [];
      for (let i = 0; i < exams.length; i++) {
        newExamsValues["exam" + (i + nextKey)] = {
          value: exams[i].name,
          id: exams[i].id,
        };

        tempExamsIdSelected.push(exams[i].id);
        newExamsElements.push({ key: i + nextKey });
      }
    } else {
      newExamsValues["exam" + nextKey] = {
        value: ""
      };
      newExamsElements.push({ key: nextKey });
    }
    setExamsValues(newExamsValues);
    setExamsElements(newExamsElements);
    setTimeout(() => {
      const inputAutoComplete = document.querySelectorAll(
        ".ant-select-selector .ant-select-selection-search-input"
      );
      inputAutoComplete.forEach((input) => {
        input.placeholder = "Exame";
      });
    }, 150);
  }

  function handleExamChange(value, name) {
    if (type === "view") return;
    inputErrors.delete("exams");

    if (value !== "") {
      getExams(value);
    } else {
      setOptions([]);
    }
    const newExamsValues = {...examsValues};
    newExamsValues[name].value = value;

    setExamsValues(newExamsValues);
  }

  function handleExamBlur(value, name) {
    if (type === "view") return;
    const isExists = exams.find((exam) => exam.name === value);

    if (isExists) {
      const newExamsValues = {...examsValues};
      newExamsValues[name] = {
        value: isExists.name,
        id: isExists.id,
      };
      setExamsValues(newExamsValues);
    } else {
      const newExamsValues = {...examsValues};
      newExamsValues[name] = {value: ""};
      setExamsValues(newExamsValues);
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    setProtocolData({
      ...protocolData,
      [name]: value,
    });
    inputErrors.delete(name);
  }

  return (
    <Modal open={create} onOk={handleOk} onCancel={handleCancel}>
      <ModalBox>
        <ModalHeader>
          <h1>
            {type === "create" && "Criar"}
            {type === "view" && "Detalhes do"}
            {type === "edit" && "Editar"} protocolo
          </h1>
          <Box cursor="pointer" onClick={handleCancel}>
            <CloseIcon />
          </Box>
        </ModalHeader>
        <Box w="100%">
          <Form onSubmit={formSubmitHandler}>
            <FormGroup>
              <label htmlFor="name">Nome</label>
              <input
                type="text"
                value={protocolData.name}
                name="name"
                id="name"
                placeholder="Nome"
                onChange={handleInputChange}
                readOnly={type === "view"}
                className={inputErrors.get("name") ? "error" : ""}
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="exam">Exame</label>
              {inputErrors.get("exams") && <span className="error">{inputErrors.get("exams")}</span>}
              {!loadingExams ? (
                examsElements.map((el) => (
                  <>
                    <AutoComplete
                      key={"exam" + el.key}
                      style={{
                        width: "100%",
                      }}
                      name={"exam" + el.key}
                      id={"exam" + el.key}
                      options={options}
                      onChange={(e, i) => handleExamChange(e, "exam" + el.key)}
                      onBlur={() =>
                        handleExamBlur(
                          examsValues["exam" + el.key].value || "",
                          "exam" + el.key
                        )
                      }
                      value={examsValues["exam" + el.key].value || ""}
                      readOnly={type === "view"}
                      className={inputErrors.get("exams") ? "error" : ""}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  </>
                ))
              ) : (
                <LoadingInline>
                  <ButtonLoading /> Carregando...
                </LoadingInline>
              )}

              {type !== 'view' && (
                <ClickableTextPink onClick={addNewExam}>
                  <FaPlus></FaPlus> Adicionar exame
                </ClickableTextPink>
              )}
            </FormGroup>
            <FormGroup>
              <label htmlFor="description">Indicação</label>
              <textarea
                id="description"
                name="description"
                value={protocolData.description}
                readOnly={type === "view"}
                onChange={handleInputChange}
                placeholder="Indicação"
                className={inputErrors.get("description") ? "error" : ""}
              ></textarea>
            </FormGroup>

            <Btns>
              {type === "create" && (
                <BtnPink width="100%" type="submit">
                  Gerar {loading && <ButtonLoading />}
                </BtnPink>
              )}
              {type === "edit" && (
                <BtnPink width="100%" type="submit">
                  Salvar {loading && <ButtonLoading />}
                </BtnPink>
              )}
              {type === "view" && (
                <BtnPinkAlt
                  width="100%"
                  type="button"
                  onClick={() => handleCancel()}
                >
                  Voltar
                </BtnPinkAlt>
              )}
            </Btns>
          </Form>
        </Box>
      </ModalBox>
    </Modal>
  );
}
