import React, { memo } from 'react'

import { Box } from '../../components/FlexBox'
import H1 from '../../components/H1'

import List from './List'

export default memo(({ id, navigate, message }) => (
  <Box p={4}>
    <Box mb={3}>
      <H1>Arquivos</H1>
    </Box>

    <List id={id} navigate={navigate} message={message} />
  </Box>
))
