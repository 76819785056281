import React, { memo } from 'react'

import { Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import H1 from '../../components/H1'

import List from './List'

export default memo(({ navigate, message }) => (
  <Box p={4}>
    <Box mb={4}>
      <H1>Instituições</H1>
    </Box>

    <List navigate={navigate} message={message} />
  </Box>
))
