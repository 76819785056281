import { useState, useEffect } from 'react'

export default targetKey => {
  const [keyPressed, setKeyPressed] = useState(false)
  let prevKey = ''

  function downHandler({ key }) {
    if (prevKey === targetKey) return
    if (key === targetKey) {
      setKeyPressed(true)
      prevKey = key
    }
  }

  const upHandler = ({ key }) => {
    if (key === targetKey) {
      setKeyPressed(false)
      prevKey = ''
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    window.addEventListener('keyup', upHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
      window.removeEventListener('keyup', upHandler)
    }
  }, [])

  return keyPressed
}
