import React, { memo, useState } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import { CloseIcon } from '../../components/Icons'
import Input from '../../components/Input'
// import Select from '../../components/Select'
import Button from '../../components/Button'

// import { useMutation } from '../../hooks/useApollo'
// import { UPDATE } from './Gqls'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [name, setName] = useState(initialData.name || '')
  const [description, setDescription] = useState(initialData.description || '')
  const [website, setWebsite] = useState(initialData.website || '')
  const [phone, setPhone] = useState(initialData.phone || '')
  const [whatsapp, setWhatsapp] = useState(initialData.whatsapp || '')
  const [workingTime, setWorkingTime] = useState(initialData.workingTime || '')

  // const updateItem = useMutation(UPDATE)

  const handleUpdate = async () => {

    const formData = new FormData()
    formData.append('name', name)
    formData.append('description', description)
    formData.append('website', website)
    formData.append('phone', phone)
    formData.append('whatsapp', whatsapp)
    formData.append('workingTime', workingTime)

    const response = await fetch(`${BASE_API}/establishment/`, { method: 'PUT', body: formData, headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})
    const data = await response.json()
    onUpdated()



    // updateItem({ variables: { input: { name, description, website, phone, whatsapp, workingtime: workingTime } } })
    //   .then(data => {
    //     onUpdated()
    //   })
    //   .catch(error => {
    //     console.error('error', error)
    //   })
  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Perfil</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Box mb={3}>
          <Box>Nome</Box>
          <Box><Input value={name} onChange={e => setName(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Descrição</Box>
          <Box><Input value={description} onChange={e => setDescription(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Site</Box>
          <Box><Input value={website} onChange={e => setWebsite(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Telefone</Box>
          <Box><Input value={phone} onChange={e => setPhone(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>WhatsApp</Box>
          <Box><Input value={whatsapp} onChange={e => setWhatsapp(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Funcionamento</Box>
          <Box><Input value={workingTime} onChange={e => setWorkingTime(e.target.value)} /></Box>
        </Box>

        <Box><Button onClick={handleUpdate}>Salvar</Button></Box>
      </Box>
    </Flex>
  )
})
