import styled from 'styled-components'

export const Content = styled.div`
  padding: 32px;
  min-height: calc(100vh - 92px - 254px);
  display: flex;
  flex-direction: column;
`
export const TableContainer = styled.div`
  margin-top: 0.7rem;

  .search-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 2rem;
  }
`;