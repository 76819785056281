import React from "react";
import styled from "styled-components/macro";

import Modal from "./Modal";

const enterAnimation = (modal, backdrop, backdropOpacity) => {
  if (!modal.current) return;
  if ("animate" in modal.current) {
    modal.current.animate(
      [
        { opacity: 0, transform: "translateY(-100px)" },
        { opacity: 1, transform: "translateY(0)" },
      ],
      { duration: 300, easing: "ease" }
    ).onfinish = () => {
      if (modal.current) {
        modal.current.style.opacity = 1;
      }
    };
  } else {
    modal.current.style.opacity = 1;
    modal.current.style.transform = "translateY(0)";
  }
};

const exitAnimation = (modal, backdrop, backdropOpacity, callback) => {
  if (!modal.current) return;
  if ("animate" in modal.current) {
    modal.current.animate(
      [
        { opacity: 1, transform: "translateY(0)" },
        { opacity: 0, transform: "translateY(-100px)" },
      ],
      { duration: 300, easing: "ease" }
    ).onfinish = () => {
      callback();
    };
  } else {
    callback();
  }
};

const StyledModal = styled(Modal)`
  align-items: stretch !important;
  & > div {
    cursor: pointer;
    margin-top: 8px;
    pointer-events: auto !important;
    height: 40px;
    border-radius: 4px;
    padding: 0 40px;
    background-color: hsla(0, 0%, 0%, 0.8);
    color: hsla(0, 0%, 90%, 1);
    min-width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const StyledModalWithAnimations = (props) => (
  <StyledModal
    enterAnimation={enterAnimation}
    exitAnimation={exitAnimation}
    {...props}
  />
);

export default StyledModalWithAnimations;
