import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Toggle } from '../Prescription/styles';

export const Signature = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 3px 0px 28px;
  gap: 17px;
  
  &>label {
    font-weight: 800;
    font-size: 12px;
  }

  input:checked + label {
    background-color: #75D16D;
  }
`;

export default function SignatureToggle() {
    const [check, setCheck] = useState(false);

    useEffect(() => {
        setCheck(window.checkSignature());
    }, []);

    function handleToggleSignature() {
        if (check) {
            localStorage.removeItem("signatureId");
            localStorage.removeItem("signatureToken");
            localStorage.removeItem("signatureExpires");
            setCheck(window.checkSignature());
        } else {
            window.showSignatureModal();
        }
    };

    window.setCheckSignature = () => {
        setCheck(window.checkSignature());
    }

    return (
        <Signature><label htmlFor="signature">Assinatura Digital</label> <Toggle><input onChange={handleToggleSignature} checked={check} id="signature" name="signature" type="checkbox" /><label htmlFor="signature"></label></Toggle></Signature>
    )
}