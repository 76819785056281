import React, { memo, useMemo, useState } from 'react'
import { css } from 'styled-components/macro'

// import uniq from 'lodash.uniq'
// import uniqWith from 'lodash.uniqwith'
// import isEqual from 'lodash.isequal'

import Button from '../../components/Button'
import CircularProgress from '../../components/CircularProgress'
import { Box, Flex } from '../../components/FlexBox'
import FormErrorBox from '../../components/FormErrorBox'
import { CloseIcon, InstitutionIcon } from '../../components/Icons'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Modal from '../../components/StyledModal'
import Text from '../../components/Text'

import formDataFromObject from '../../utils/formDataFromObject'

import useInput from '../../hooks/useInput'

import { useQuery } from '../../hooks/useApollo'
import { LIST } from './Gqls'
// import { isTerminating } from 'apollo-link/lib/linkUtils'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

const filter = (institutions, conectedInstitutions, search, specialty, location, sortBy) => {

  const conectedInstitutionsIds = conectedInstitutions.map(item => item.id)
  const filtered = institutions
    .filter(item => {
      let condition = true
      condition = !conectedInstitutionsIds.includes(item.id)
      
      if (condition) condition = search ? item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) : condition
      if (condition) condition = specialty ? item.specialties.map(item => item.name).includes(specialty) : condition
      if (condition) condition = location ? item.addresses.map(item => item.city.name).includes(location) : condition

      return condition      
    })
  const ordered = filtered.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return -1
    if (a[sortBy] > b[sortBy]) return 1
    return 0
  })
  return ordered
}

const List = memo(({ institutions, conectedInstitutions, navigate, message }) => {

  // const specialties = uniqWith(institutions.reduce((acc, item) => {
  //   const items = item.specialties.map(m => ({ id: m.id, name: m.name }))
  //   acc.push(...items)
  //   return acc
  // }, []), isEqual)

  const nameInput = useInput('')
  const cityInput = useInput('')
  const loginInput = useInput('')
  const passwordInput = useInput('')

  const [newInstitutionModal, setNewInstitutionModal] = useState(false)
  const [newInstitutionModalSuccess, setNewInstitutionModalSuccess] = useState(false)
  
  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('name') // id 

  const [specialty, setSpecialty] = useState('')
  const [location, setLocation] = useState('')
  const [authorize, setAuthorize] = useState(false)

  const [errors, setErrors] = useState({
    'name': 'O nome é obrigatório',
    'city': 'A cidade é obrigatória',
    'login': 'O login é obrigatório',
    'password': 'A senha é obrigatória',
    'authorize': 'Você deve autorizar para continuar'
  })

  const [touched, setTouched] = useState({
    'name': false,
    'city': false,
    'login': false,
    'password': false,
    'authorize': false
  })

  const filtered = useMemo(() => filter(institutions, conectedInstitutions, search, specialty, location, sortBy), [institutions, conectedInstitutions, search, specialty, location, sortBy])

  const handleSave = async () => {
    const value = {
      'name': false,
      'city': false,
      'login': false,
      'password': false,
      'authorize': false
    }
    let canContinue = true
    if(!authorize){
      value.authorize = true
      canContinue = false
    }

    if (!loginInput.value || loginInput.value === "") {
      value.login = true
      canContinue = false
    }

    if (!passwordInput.value || passwordInput.value === "") {
      value.password = true
      canContinue = false
    }

    if (!cityInput.value || cityInput.value === "") {
      value.city = true
      canContinue = false
    }

    if (!nameInput.value || nameInput.value === "") {
      value.name = true
      canContinue = false
    }
    console.log(value)
    setTouched(value)

    if(!canContinue)
      return

    const body = formDataFromObject({login: loginInput.value, password: passwordInput.value, city: cityInput.value, establishment_name: nameInput.value})
    const data = await (await fetch(`${BASE_API}/thirdPartiesIntegration/`, { method: 'POST', body, headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()

    if (data.worked) {
      setNewInstitutionModal(false)
      setTimeout(() => { setNewInstitutionModalSuccess(true) }, 100)
    } else {
      message('Ocorreu um erro')
    }
    
  }

  return (
    <Box>

      <Box mb={4}>
        <Text cursor='pointer' onClick={() => setNewInstitutionModal(true)}>Não encontrou sua instituição? <Text style={{textDecoration: 'underline'}}>Clique aqui</Text>.</Text>
      </Box>

      {!!conectedInstitutions.length &&
        <Box mb={4}>
          <Text>Minhas Instituições</Text>
          {conectedInstitutions.map(item => (
            <Flex ai='center' key={item.id} onClick={() => navigate(`/instituicoes/${item.id}`)} cursor='pointer' p={3} style={{borderBottom: '1px solid lightgrey'}}>
              <Box mr={2}>
                <InstitutionIcon />
              </Box>
              <Box>
                {item.name}
              </Box>
            </Flex>
          ))}
        </Box>
      }

      <Box mb={3}>
        <Text fw={600}>Instituições</Text>
      </Box>

      <Flex>
        <Box mr={3}>
          <Input placeholder='Buscar...' value={search} onChange={e => setSearch(e.target.value)} />
        </Box>
        <Box mr={3}>
          <Select value={sortBy} onChange={e => setSortBy(e.target.value)}>
            <option value='name'>Ordenado por Nome</option>
            {/*<option value='id'>Ordenado por Data de Criação</option>*/}
          </Select>
        </Box>
        <Box mr={3}>
          <Select value={specialty} onChange={e => setSpecialty(e.target.value)}>
            <option value=''>Todas as especialidades</option>
            <option value='Oftalmologia'>Oftalmologia</option>
            <option value='Pediatria'>Pediatria</option>
            <option value='Ginocologia e obstretícia'>Ginocologia e obstretícia</option>
            <option value='Radiologia e diagnóstico por imagem'>Radiologia e diagnóstico por imagem</option>
          </Select>
        </Box>
        <Box>
          <Select value={location} onChange={e => setLocation(e.target.value)}>
            <option value=''>Todas as localidades</option>
            <option value='Rio de Janeiro'>Rio de Janeiro</option>
            <option value='Teresópolis'>Teresópolis</option>
          </Select>
        </Box>
      </Flex>

      {filtered.map(item => (
        <Flex ai='center' key={item.id} onClick={() => navigate(`/instituicoes/${item.id}`)} cursor='pointer' p={3} style={{borderBottom: '1px solid lightgrey'}}>
          <Box mr={2}>
            <InstitutionIcon />
          </Box>
          <Box>
            {item.name}
          </Box>
        </Flex>
      ))}





      {newInstitutionModal &&
        <Modal
          onCloseCompleted={() => { setNewInstitutionModal(false) }}
          css={css`& > div { max-height: 500px }`}
          render={
            ({onRequestClose}) => (
              <Flex fd='column' h='100%'>

                <Box position='relative' h={64}>
                  <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
                  <Box p={3} ta='center'><Text secondary medium>Solicitação de Exames</Text></Box>
                </Box>

                <Box p={4} overflow='auto'>
                  <Box>
                    <Box flex={1} mb={2}><Input placeholder='Nome da instituição' name='name' {...nameInput} /></Box>
                    <FormErrorBox fieldName='name' errors={errors} touched={touched} />
                    <Box flex={1} mb={2}><Input placeholder='Cidade da instituição' name='city' {...cityInput} /></Box>
                    <FormErrorBox fieldName='city' errors={errors} touched={touched} />
                    <Box flex={1} mb={2}><Input placeholder='Login - CPF ou Protocolo (CIP)' name='login' {...loginInput} /></Box>
                    <FormErrorBox fieldName='login' errors={errors} touched={touched} />
                    <Box flex={1} mb={2}><Input placeholder='Senha da instituição' name='password' {...passwordInput} /></Box>
                    <FormErrorBox fieldName='password' errors={errors} touched={touched} />
                    <Box mb={2}>
                      <input type='checkbox' id='authorize' name='authorize' value={authorize} onChange={() => setAuthorize(true)} /><label>Eu autorizo o TRIA a utilizar minhas credenciais<br />para acessar os resultados de exames deste laboratório em meu nome</label>
                      <FormErrorBox fieldName='authorize' errors={errors} touched={touched} />
                    </Box>
                    <Box flex={1} mb={2}><Button onClick={handleSave}>Ok</Button></Box>
                  </Box>
                </Box>

              </Flex>
            )
          }
        />
      }

      {newInstitutionModalSuccess &&
        <Modal
          onCloseCompleted={() => { setNewInstitutionModalSuccess(false) }}
          css={css`& > div { max-height: 240px }`}
          render={
            ({onRequestClose}) => (
              <Flex fd='column' h='100%'>

                <Box position='relative' h={64}>
                  <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
                  <Box p={3} ta='center'><Text secondary medium>Sucesso</Text></Box>
                </Box>

                <Box p={4} overflow='auto'>
                  <Box ta='center'>
                    Solicitação enviada com sucesso.<br />Em até 48 horas seus exames estarão listados no menu Arquivos.
                  </Box>
                </Box>

              </Flex>
            )
          }
        />
      }

    </Box>
  )
})

export default memo(props => {
  const { data, error, loading } = useQuery(LIST)
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  return <List {...props} conectedInstitutions={data.me.estabelishmentPatient} institutions={data.institutions} />
})


