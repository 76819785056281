import BASE_API from "../constants/baseApi";
import AUTH_TOKEN from "../constants/authToken";
export const listUploadedExams = async (query) => {
  let url = `${BASE_API}/list-files-to-sync/?name=${query.name}&establishment_id=${query.establishmentID}&page=${query.page}&page_size=${query.pageSize}`
  if (query.dateFrom) {
    url = `${url}&date_from=${query.dateFrom}`
  }

  if (query.dateTo) {
    url = `${url}&date_to=${query.dateTo}`
  }

  const response = await fetch(
    url,
    {
      headers: {
        Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`,
      },
    }
  );
  const data = await response.json();
  return data;
};
