import React, { memo, useState } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import { CloseIcon } from '../../components/Icons'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Button from '../../components/Button'
import CircularProgress from '../../components/CircularProgress'

import useInput from '../../hooks/useInput'

import { useMutation } from '../../hooks/useApollo'
import { CREATE_ADDRESS } from './Gqls'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

export default memo(({ onUpdated, onRequestClose, initialData, message, client }) => {

  const [loading, setLoading] = useState(false)
  const [addresses, setAddresses] = useState(initialData)

  const [uf, setUf] = useState('')

  const ufInput = useInput('')
  const nameInput = useInput('')
  const cityInput = useInput('')
  const postalCodeInput = useInput('')
  const districtInput = useInput('')
  const complementInput = useInput('')
  const streetInput = useInput('')
  const numberInput = useInput('')

  const createAddress = useMutation(CREATE_ADDRESS)

  const deleteAddress = async id => {
    const response = await fetch(`${BASE_API}/address?id=${id}`, { method: 'DELETE', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})
    const data = await response.json()
    if (data.worked) setAddresses(current => current.filter(item => item.id !== id))
  }

  const handleCreate = () => {
    createAddress({
      variables: {
        input: {
          name: nameInput.value,
          city: 2,
          postalCode: postalCodeInput.value,
          district: districtInput.value,
          complement: complementInput.value,
          street: streetInput.value,
          number: numberInput.value
        }
      }
    })
      .then(data => {
        // fetchAddresses()
        // setAddresses(currentAddresses => {...currentAddresses})
      })
      .catch(error => {
        console.error('error', error)
      })
  }

  if (loading) return <CircularProgress />

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Endereços</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Box mb={3}>
          <Box flex={1} mb={2}><Input placeholder='Nome' {...nameInput} /></Box>
          <Box flex={1} mb={2}><Input placeholder='Endereço' {...streetInput} /></Box>
          <Box flex={1} mb={2}><Input placeholder='Número' {...numberInput} /></Box>
          <Box flex={1} mb={2}><Input placeholder='Complemento' {...complementInput} /></Box>
          <Box flex={1} mb={2}><Input placeholder='Bairro' {...districtInput} /></Box>
          <Box flex={1} mb={2}>
            <Select placeholder='Selecione o Estado...' {...ufInput}>
              <option value='RJ'>RJ</option>
            </Select>
          </Box>
          <Box flex={1} mb={2}><Input placeholder='Cidade' {...cityInput} /></Box>
          <Box flex={1} mb={2}><Input placeholder='CEP' {...postalCodeInput} /></Box>
          <Box><Button onClick={handleCreate}>Adicionar</Button></Box>
        </Box>
        <Box>
          {loading &&
            <CircularProgress />
          }
        </Box>
        <Box>
          {addresses.map(item => (
            <Flex key={item.id} jc='space-between'>
              <Box>{item.name}</Box>
              <Box cursor='pointer' onClick={() => deleteAddress(item.id)}>Excluir</Box>
            </Flex>
          ))}
        </Box>

      </Box>
    </Flex>
  )
})
