import React, { memo } from 'react'

import { Box } from '../../components/FlexBox'
import H1 from '../../components/H1'

import CircularProgress from '../../components/CircularProgress'

import { useQuery } from '../../hooks/useApollo'
import { ME_ESTABLISHMENT } from './Gqls'

import List from './List'

const Home = memo(({ me, message, navigate, client }) => (
  <Box p={4}>
    <Box mb={4}>
      <H1>Médicos</H1>
    </Box>
    
    <List me={me} navigate={navigate} message={message} client={client} />
  </Box>
))

export default memo(props => {
  const { data, error, loading } = useQuery(ME_ESTABLISHMENT)
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  return <Home {...props} me={data.establishment} />
})
