import React, { memo } from 'react';
import Layout from '../../layouts/Main';
import { Router } from '../../utils/router';

import Home from './Home';

export default memo(({ me, onLogout, ...rest }) => (
  <Router>
    <Layout me={me} onLogout={onLogout} path='/'>
      <Home {...rest} path='/:id' />
      <Home {...rest} path='/' />
    </Layout>
  </Router>
))
