import React from "react";
import styled from "../utils/styled";
import { Link } from "../utils/router";

import { Box } from "./FlexBox";

const ReachNavLink = (props) => (
  <Link
    {...props}
    getProps={({ isPartiallyCurrent }) => {
      return {
        className: isPartiallyCurrent
          ? `active ${props.className}`
          : props.className,
      };
    }}
  />
);

function NavLink({ className, children, to, icon }) {
  return (
    <ReachNavLink className={className} to={to}>
      <Box className="line" width={5} mr={3} />
      {icon && (
        <>
          <Box mr={2} css={{ lineHeight: 0 }}>
            {icon}
          </Box>
        </>
      )}
      <Box mr={3}>{children}</Box>
    </ReachNavLink>
  );
}

const BlankNavLinkWithouStyle = ({ className, children, to, icon }) => {
  return (
    <a
      className={className}
      href={to}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Box className="line" width={5} mr={3} />
      {icon && (
        <>
          <Box mr={2} css={{ lineHeight: 0 }}>
            {icon}
          </Box>
        </>
      )}
      <Box mr={3}>{children}</Box>
    </a>
  );
};

export const BlankNavLink = styled(BlankNavLinkWithouStyle)`
  display: flex;
  align-items: center;
  color: hsla(0, 0%, 44%, 1);
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;

  & > .line {
    height: 48px;
  }
  &:hover > .line,
  &.active .line {
    background-color: hsla(199, 75%, 57%, 1);
  }
`;

const StyledNavLink = styled(NavLink)`
  display: flex;
  align-items: center;
  color: hsla(0, 0%, 44%, 1);
  text-decoration: none;
  margin-top: 16px;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;

  & > .line {
    height: 48px;
  }
  &:hover > .line,
  &.active .line {
    background-color: hsla(199, 75%, 57%, 1);
  }
`;

export default StyledNavLink;
