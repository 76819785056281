import React, { useState } from "react";
import { AiOutlineEye } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import { Box } from "../../components/FlexBox";
import NewPresctiptionModal from "./NewPrescriptionModal";
import {
    BtnIcon,
    BtnPink,
    ClickableText,
    ClickableTextPink,
    Container,
    InputWhiteLabel,
    PrescritionBody,
    PrescritionHeader,
    PrescritionItem
} from "./styles";

export default function NewPrescription({page, prescription}) {
    const [addModal, setAddModal] = useState(false);

    const showModal = () => setAddModal(true);

    const handleOk = () => setAddModal(false);

    const handleCancel = () => setAddModal(false);

    const setPrescriptionsContent = (obj) => {
        prescription.set({prescriptions: obj, name: prescription.get.name})
    }
    return (
        <Box>
            <Container block={true}>
                <PrescritionHeader>
                    <div>
                        <label htmlFor="name">Nome</label>
                        <InputWhiteLabel value={prescription.get.name} onChange={(e) => prescription.set({...prescription.get, name: e.target.value})} required id="name" name="name" type="text" placeholder="Digite o nome do paciente" />
                    </div>
                    <BtnPink onClick={() => page.set(3)} disabled={(prescription.get.name && prescription.get.prescriptions.length > 0) ? false : true}>Enviar</BtnPink>
                </PrescritionHeader>
                {prescription.get.prescriptions.length > 0 && <>
                    <PrescritionBody>
                        {prescription.get.prescriptions.map((prescription, i) => (
                            <PrescritionItem key={i}>
                                <span>{prescription.category}</span>
                                <span>{prescription.name} <BtnIcon><AiOutlineEye /></BtnIcon></span>
                            </PrescritionItem>
                        ))}
                        <ClickableTextPink onClick={showModal}><FaPlus></FaPlus> Adicionar prescrição de exame</ClickableTextPink>
                    </PrescritionBody>
                </>}
            </Container>
            <ClickableText onClick={showModal}><FaPlus></FaPlus> Adicionar prescrição de exame</ClickableText>
            <NewPresctiptionModal addModal={addModal} handleOk={handleOk} handleCancel={handleCancel} prescription={prescription} content={{ get: prescription.get.prescriptions, set: setPrescriptionsContent }} />
        </Box>
    );
}