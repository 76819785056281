import React, { useEffect, useState } from 'react'
import Select from '../../components/Select'
import InputDate from '../../components/InputDate'
import { Box } from '../../components/FlexBox'
import Input from '../../components/Input'
import InputPhone from '../../components/InputPhone'
import Button from '../../components/Button'
import FormErrorBox from '../../components/FormErrorBox'

import BASE_API from '../../constants/baseApi'

export const SignupFormDoctorStep1 = ({ email, name, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, isValid }) => {

  useEffect(() => { setFieldValue('login', email) }, [setFieldValue, email])
  useEffect(() => { setFieldValue('name', name) }, [setFieldValue, name])

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Input type='email' autoComplete='nope' placeholder='Email' name='login' value={values.login} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='login' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <Input type='name' autoComplete='nope' placeholder='Nome' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <Input type='password' autoComplete='nope' placeholder='Senha' name='password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='password' errors={errors} touched={touched} />
      </Box>
      <Box mb={1}>
        <Input type='password' autoComplete='nope' placeholder='Confirme a Senha' name='password_confirmation' value={values.password_confirmation} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='password_confirmation' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <input type='checkbox' id='terms' name='terms' value={values.terms} onChange={handleChange} onBlur={handleBlur} /><label htmlFor='terms'>Aceito os <a target='_blank' rel='noopener noreferrer' href='https://web.tria.health/politica-de-privacidade'>Termos de Uso</a></label>
        <FormErrorBox fieldName='terms' errors={errors} touched={touched} />
      </Box>
      <Box ta='center'>
        <Button type='submit' loading={isSubmitting}>Criar conta</Button>
      </Box>
    </form>
  )
}

export const SignupFormDoctorStep2 = ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, initialValues }) => {
  const [specialties, setSpecialties] = useState([])

  useEffect(() => {
    const getSpecialties = async () => {
      const response = await fetch(`${BASE_API}/getAllSpecialties/`)
      const data = await response.json()
      setSpecialties(data)
    }
    getSpecialties()
  }, [])

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <InputDate autoComplete='nope' placeholder='Data de Nascimento' name='birth_date' value={values.birth_date} defaultValue={initialValues.birth_date} onChange={(e, value) => setFieldValue('birth_date', value)} onBlur={handleBlur} />
        <FormErrorBox fieldName='birth_date' errors={errors} touched={touched} />
      </Box>

      <Box mb={2}>
        <Input autoComplete='nope' placeholder='Tipo' name='crm_type' value={values.crm_type} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='crm_type' errors={errors} touched={touched} />
      </Box>

      <Box mb={2}>
        <Input autoComplete='nope' placeholder='CRM' name='crm' value={values.crm} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='crm' errors={errors} touched={touched} />
      </Box>

      <Box mb={2}>
        <Select placeholder='Estado do CRM' name='crm_uf' value={values.crm_uf} onChange={handleChange} onBlur={handleBlur}>
          <option>AC</option>
          <option>AL</option>
          <option>AP</option>
          <option>AM</option>
          <option>BA</option>
          <option>CE</option>
          <option>DF</option>
          <option>ES</option>
          <option>GO</option>
          <option>MA</option>
          <option>MT</option>
          <option>MS</option>
          <option>MG</option>
          <option>PA</option>
          <option>PB</option>
          <option>PR</option>
          <option>PE</option>
          <option>PI</option>
          <option>RJ</option>
          <option>RN</option>
          <option>RS</option>
          <option>RO</option>
          <option>RR</option>
          <option>SC</option>
          <option>SP</option>
          <option>SE</option>
          <option>TO</option>
        </Select>
        <FormErrorBox fieldName='crm_uf' errors={errors} touched={touched} />
      </Box>

      <Box mb={2}>
        <Select placeholder='Gênero' name='gender' value={values.gender} onChange={handleChange} onBlur={handleBlur}>
          <option>Masculino</option>
          <option>Feminino</option>
          <option>Outro</option>
        </Select>
        <FormErrorBox fieldName='gender' errors={errors} touched={touched} />
      </Box>
  
      <Box mb={2}>
        <InputPhone autoComplete='nope' placeholder='Telefone' name='phone' value={values.phone} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='phone' errors={errors} touched={touched} />
      </Box>
  
      <Box mb={2}>
        <Select placeholder='Especialidade primária' name='specialty_primary' value={values.specialty_primary} onChange={handleChange} onBlur={handleBlur}>
          {specialties.map(item => (
            <option key={item.id} value={item.name}>{item.name}</option>
          ))}
        </Select>
        <FormErrorBox fieldName='specialty_primary' errors={errors} touched={touched} />
      </Box>
  
      <Box mb={2}>
        <Select placeholder='Especialidade secundária' name='specialty_secundary' value={values.specialty_secundary} onChange={handleChange} onBlur={handleBlur}>
          {specialties.map(item => (
            <option key={item.id} value={item.name}>{item.name}</option>
          ))}
        </Select>
        <FormErrorBox fieldName='specialty_secundary' errors={errors} touched={touched} />
      </Box>

      <Box ta='center'>
        <Button type='submit' loading={isSubmitting}>Concluir</Button>
      </Box>
    </form>
  )
}
