import React, { memo, useState } from 'react'
import camelcaseKeys from 'camelcase-keys'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import { CloseIcon } from '../../components/Icons'
import RadioGroup from '../../components/MyRadioGroup'
import CircularProgress from '../../components/CircularProgress'

import { useMutation } from '../../hooks/useApollo'
import { UPDATE } from './Gqls'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'
import Input from '../../components/Input'
import Button from '../../components/Button'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(initialData)

  const fetchData = async () => {
    setLoading(true)

    const formdata = new FormData();
    formdata.append("name", data.name);
    formdata.append("cnpj", data.cnpj);

    fetch(`${BASE_API}/update-health-operator/`, {
      method: 'PATCH',
      headers: {
        'Authorization': `Token ${localStorage.getItem(AUTH_TOKEN)}`
      },
      body: formdata,
      redirect: 'follow'
    })
      .then(res => res.json())
      .then(res => {
        setLoading(false)
        onUpdated()
      })
      .catch(err => {
        setLoading(false)
        console.log(err)
      })
  }

  const handleChange = ({target}) => {
    setData({ ...data, [target.name]: target.value });
  }

  if (loading) return <CircularProgress />

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Informações</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={4}>
          <Box>Nome</Box>
          <Box>
            <Input type='text' placeholder='Nome' name='name' value={data.name} onChange={handleChange}/>
          </Box>
        </Box>

        <Box mb={4}>
          <Box>CNPJ</Box>
          <Box>
            <Input type='tel' placeholder='CNPJ' name='cnpj' value={data.cpnj} maxLength={18} onChange={handleChange}/>
          </Box>
        </Box>
        <Box><Button onClick={fetchData}>Salvar</Button></Box>
      </Box>
    </Flex>
  )
})
