import React from "react";
import { Flex } from "../../components/FlexBox";
import {
  BoxViews,
  Container,
  ContainerBody,
  ContainerHeader,
  FormGroup,
  FormModal,
} from "./styles";

export default function ViewUser({ user }) {
  const formatLocaleDate = (toParse) => {
    const date = new Date(Date.parse(toParse));
    return date.toLocaleString();
  }

  return (
    <BoxViews>
      <Container>
        <ContainerHeader>
          <h2>Informações do usuário</h2>
        </ContainerHeader>
        <ContainerBody>
          <Flex w="100%" jc="space-between" ai="center">
            <div className="pic-profile">
              <img src={user.profile} alt="Sem foto de perfil" />
            </div>
            <div className="last-access">
              {user.access ? (
                <>
                  <span>Último acesso:</span>
                  <span>{user.access}</span>
                </>
              ) : (
                <>
                  <span>Data de registro</span>
                  <span>{formatLocaleDate(user.register_date)}</span>
                </>
              )}
            </div>
          </Flex>
          <FormModal onSubmit={(e) => e.preventDefault()}>
            <FormGroup>
              <label htmlFor="name">Nome</label>
              <input value={user.name} required id="name" name="name" type="text" readOnly/>
            </FormGroup>
            <FormGroup>
              <label htmlFor="email">E-mail</label>
              <input value={user.login} required id="email" name="email" type="email" readOnly/>
            </FormGroup>
            <FormGroup>
              <label htmlFor="level">Admin</label>
              <select value={user.is_admin} id="level" name="level" readOnly>
                <option value="true">Não</option>
                <option value="2false">Sim</option>
              </select>
            </FormGroup>
          </FormModal>
        </ContainerBody>
      </Container>
    </BoxViews>
  );
}
