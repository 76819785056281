import React, { Component } from 'react'

import AUTH_TOKEN from '../constants/authToken'

class Redirect extends Component {
  componentDidMount() {
    setTimeout(() => this.props.navigate(this.props.to, { replace: true }), 0)
  }
  render() {
    return null
  }
}

const token = () => localStorage.getItem(AUTH_TOKEN)

export const OnlyAuthenticated = ({children, ...props}) => {
  if (token()) {
    return children.props.children({name: 'me'}, props)
  }
  return (props.navigate ? <Redirect navigate={props.navigate} to='/login' /> : null)
}

export const OnlyUnauthenticated = ({children, ...props}) => {
  if (token()) {
    return (props.navigate ? <Redirect navigate={props.navigate} to='/' /> : null)
  }
  return children.props.children(props)
}
