import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 800px;
  /* &>* { */
    /* width: 100%; */
  /* } */
  @media screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;
