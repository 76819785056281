import React, { memo } from 'react';

import { Box } from '../../components/FlexBox';
import {
    BtnPink,
    Container,
    Details,
    PrescritionDoctorDates
} from "./styles";

export default memo(({ content, viewDoctor }) => {
    return (
        <Box>
            {content.map((item, i) => (
                <Container key={i}>
                    <Details>
                        <div>
                            <span>{item.doctorName}</span>
                            <span>{item.exam}</span>
                        </div>
                        <PrescritionDoctorDates>
                            <div>
                                <span>Data da prescrição 1</span>
                                <span>{item.prescritionOne.date}</span>
                            </div>
                            <div>
                                <span>Data da prescrição 2</span>
                                <span>{item.prescritionTwo.date}</span>
                            </div>
                        </PrescritionDoctorDates>
                    </Details>

                    <BtnPink onClick={() => viewDoctor(item)}>Ver</BtnPink>
                </Container>
            ))}
        </Box>
    )
})