import { Formik } from 'formik'
import React, { memo } from 'react'
import * as Yup from 'yup'
import { Box } from '../../components/FlexBox'
import H1 from '../../components/H1'
import Form from './Form'


import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

export default memo(({ path }) => {

  let title = ''

  if (path === '/individual') {
    title = 'Individual'
  }

  const initialValues = {
    cep: '',
    street: '',
    number: '',
    district: '',
    cardNumber: '',
    cardName: '',
    cardExpiration: '',
    cardCvv: ''
  }

  const handleSubmit = async variables => {
    const formData = new FormData()
    formData.append('card_holder_name', variables.cardName)
    formData.append('card_expiration_date', variables.cardExpiration.replace('/', ''))
    formData.append('card_number', variables.cardNumber.replace(/\s/g, ''))
    formData.append('card_cvv', variables.cardCvv)

    const data = await (await fetch(`${BASE_API}/creditCard/`, { method: 'POST', body: formData, headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
  
    if (!data.valid) return alert('Erro ao processar o cartão.')

    const cardId = data.id

    const subscriptionFormData = new FormData()
    subscriptionFormData.append('postal_code', variables.cep)
    subscriptionFormData.append('district', variables.district)
    subscriptionFormData.append('street', variables.street)
    subscriptionFormData.append('number', variables.number)
    subscriptionFormData.append('plan', 2)

    subscriptionFormData.append('credit_card', cardId)

    const subscriptionData = await (await fetch(`${BASE_API}/subscription/`, { method: 'POST', body: subscriptionFormData, headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()


    if (subscriptionData.worked === false && subscriptionData.message === 'Paciente já tem subscription.') {
      alert('O pagamento não foi realizado. Você já tem a assinatura deste plano.')
      window.location.href = `/meu-plano`
    }

    if (subscriptionData.status === 'pending_payment') {
      alert('Seu pagamento está sendo processado. Assim que for confirmado você receberá uma notificação.')
      window.location.href = `/meu-plano`
    } else if (subscriptionData.status === 'unpaid') {
      alert('O pagamento não foi realizado. Verifique os dados e tente novamente.')
      window.location.href = `/meu-plano`
    } else if (subscriptionData.status === 'canceled') {
      alert('O pagamento não foi realizado. Verifique os dados e tente novamente.')
      window.location.href = `/meu-plano`
    } else if (subscriptionData.status === 'paid') {
      alert('O pagamento foi realizado com sucesso.')
      window.location.href = `/meu-plano`
    } else if (subscriptionData.status === 'refused') {
      alert('O pagamento foi recusado. Verifique os dados e tente novamente.')
      window.location.href = `/meu-plano`
    }


    // TODO: Verificar assim que carregar para saber se o usuário possui alguma inscrição pendente
    // GET https://api.tria.health/subscription/



    // worked: false
    // message: "Paciente já tem subscription."

  
    // const data = await (await fetch(`${BASE_API}/subscription/`, { method: 'POST', body: formData, headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()


    // const asyncReduce = async (arr, fn, val, pure) => {
    //   for (let i = 0; i < arr.length; i++) {
    //     let v = await fn(val, arr[i], i, arr)
    //     if (pure !== false) val = v
    //   }
    //   return val
    // }

    // let mediasIds = await asyncReduce(base64Images, async (acc, value) => {
    //   const formData = new FormData()
    //   formData.append('image', value)
    //   const data = await (await fetch(`https://api.tria.health/media/`, { method: 'POST', body: formData, headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
    //   acc.push(data.id)
    //   return acc
    // }, [])


  }

  const paymentValidationSchema = Yup.object().shape({
    cep: Yup.string().required('Obrigatório'),
    street: Yup.string().required('Obrigatório'),
    number: Yup.string().required('Obrigatório'),
    cardNumber: Yup.string().required('Obrigatório'),
    cardName: Yup.string().required('Obrigatório'),
    cardExpiration: Yup.string().required('Obrigatório'),
    cardCvv: Yup.string().required('Obrigatório'),
  })

  return (
    <Box p={4}>
      <Box mb={3}>
        <H1>Plano {title}</H1>
      </Box>
  
      <Box mx='auto'>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={paymentValidationSchema}
          render={props => (<Form {...props} />)}
        />
      </Box>
  
      
    </Box>
  )

})
