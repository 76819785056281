import React from "react";

export const IconPdf = (props) => (
  <svg width="100" viewBox="0 0 56 56" {...props}>
    <g>
      <path
        style={{ fill: "#E9E9E0" }}
        d="M36.985,0H7.963C7.155,0,6.5,0.655,6.5,1.926V55c0,0.345,0.655,1,1.463,1h40.074
		c0.808,0,1.463-0.655,1.463-1V12.978c0-0.696-0.093-0.92-0.257-1.085L37.607,0.257C37.442,0.093,37.218,0,36.985,0z"
      />
      <polygon
        style={{ fill: "#D9D7CA" }}
        points="37.5,0.151 37.5,12 49.349,12 	"
      />
      <path
        style={{ fill: "#CC4B4C" }}
        d="M19.514,33.324L19.514,33.324c-0.348,0-0.682-0.113-0.967-0.326
		c-1.041-0.781-1.181-1.65-1.115-2.242c0.182-1.628,2.195-3.332,5.985-5.068c1.504-3.296,2.935-7.357,3.788-10.75
		c-0.998-2.172-1.968-4.99-1.261-6.643c0.248-0.579,0.557-1.023,1.134-1.215c0.228-0.076,0.804-0.172,1.016-0.172
		c0.504,0,0.947,0.649,1.261,1.049c0.295,0.376,0.964,1.173-0.373,6.802c1.348,2.784,3.258,5.62,5.088,7.562
		c1.311-0.237,2.439-0.358,3.358-0.358c1.566,0,2.515,0.365,2.902,1.117c0.32,0.622,0.189,1.349-0.39,2.16
		c-0.557,0.779-1.325,1.191-2.22,1.191c-1.216,0-2.632-0.768-4.211-2.285c-2.837,0.593-6.15,1.651-8.828,2.822
		c-0.836,1.774-1.637,3.203-2.383,4.251C21.273,32.654,20.389,33.324,19.514,33.324z M22.176,28.198
		c-2.137,1.201-3.008,2.188-3.071,2.744c-0.01,0.092-0.037,0.334,0.431,0.692C19.685,31.587,20.555,31.19,22.176,28.198z
		 M35.813,23.756c0.815,0.627,1.014,0.944,1.547,0.944c0.234,0,0.901-0.01,1.21-0.441c0.149-0.209,0.207-0.343,0.23-0.415
		c-0.123-0.065-0.286-0.197-1.175-0.197C37.12,23.648,36.485,23.67,35.813,23.756z M28.343,17.174
		c-0.715,2.474-1.659,5.145-2.674,7.564c2.09-0.811,4.362-1.519,6.496-2.02C30.815,21.15,29.466,19.192,28.343,17.174z
		 M27.736,8.712c-0.098,0.033-1.33,1.757,0.096,3.216C28.781,9.813,27.779,8.698,27.736,8.712z"
      />
      <path
        style={{ fill: "#CC4B4C" }}
        d="M48.037,56H7.963C7.155,56,6.5,55.345,6.5,54.537V39h43v15.537C49.5,55.345,48.845,56,48.037,56z"
      />
      <g>
        <path
          style={{ fill: "#FFFFFF" }}
          d="M17.385,53h-1.641V42.924h2.898c0.428,0,0.852,0.068,1.271,0.205
			c0.419,0.137,0.795,0.342,1.128,0.615c0.333,0.273,0.602,0.604,0.807,0.991s0.308,0.822,0.308,1.306
			c0,0.511-0.087,0.973-0.26,1.388c-0.173,0.415-0.415,0.764-0.725,1.046c-0.31,0.282-0.684,0.501-1.121,0.656
			s-0.921,0.232-1.449,0.232h-1.217V53z M17.385,44.168v3.992h1.504c0.2,0,0.398-0.034,0.595-0.103
			c0.196-0.068,0.376-0.18,0.54-0.335c0.164-0.155,0.296-0.371,0.396-0.649c0.1-0.278,0.15-0.622,0.15-1.032
			c0-0.164-0.023-0.354-0.068-0.567c-0.046-0.214-0.139-0.419-0.28-0.615c-0.142-0.196-0.34-0.36-0.595-0.492
			c-0.255-0.132-0.593-0.198-1.012-0.198H17.385z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M32.219,47.682c0,0.829-0.089,1.538-0.267,2.126s-0.403,1.08-0.677,1.477s-0.581,0.709-0.923,0.937
			s-0.672,0.398-0.991,0.513c-0.319,0.114-0.611,0.187-0.875,0.219C28.222,52.984,28.026,53,27.898,53h-3.814V42.924h3.035
			c0.848,0,1.593,0.135,2.235,0.403s1.176,0.627,1.6,1.073s0.74,0.955,0.95,1.524C32.114,46.494,32.219,47.08,32.219,47.682z
			 M27.352,51.797c1.112,0,1.914-0.355,2.406-1.066s0.738-1.741,0.738-3.09c0-0.419-0.05-0.834-0.15-1.244
			c-0.101-0.41-0.294-0.781-0.581-1.114s-0.677-0.602-1.169-0.807s-1.13-0.308-1.914-0.308h-0.957v7.629H27.352z"
        />
        <path
          style={{ fill: "#FFFFFF" }}
          d="M36.266,44.168v3.172h4.211v1.121h-4.211V53h-1.668V42.924H40.9v1.244H36.266z"
        />
      </g>
    </g>
  </svg>
);

export const PdfIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M12,10.5H13V13.5H12V10.5M7,11.5H8V10.5H7V11.5M20,6V18A2,2 0 0,1 18,20H6A2,2 0 0,1 4,18V6A2,2 0 0,1 6,4H18A2,2 0 0,1 20,6M9.5,10.5A1.5,1.5 0 0,0 8,9H5.5V15H7V13H8A1.5,1.5 0 0,0 9.5,11.5V10.5M14.5,10.5A1.5,1.5 0 0,0 13,9H10.5V15H13A1.5,1.5 0 0,0 14.5,13.5V10.5M18.5,9H15.5V15H17V13H18.5V11.5H17V10.5H18.5V9Z" />
  </svg>
);

export const DocIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M14,17H7V15H14M17,13H7V11H17M17,9H7V7H17M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z" />
  </svg>
);

export const EditIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z" />
  </svg>
);

export const MenuIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
  </svg>
);

export const FacebookIcon = (props) => (
  <svg viewBox="0 0 16 16" height={24} fill="currentColor" {...props}>
    <path d="M15.117 0H.883C.395 0 0 .395 0 .883v14.234c0 .488.395.883.883.883h7.663V9.804H6.46V7.39h2.086V5.607c0-2.066 1.262-3.19 3.106-3.19.883 0 1.642.064 1.863.094v2.16h-1.28c-1 0-1.195.48-1.195 1.18v1.54h2.39l-.31 2.42h-2.08V16h4.077c.488 0 .883-.395.883-.883V.883C16 .395 15.605 0 15.117 0" />
  </svg>
);

export const GoogleIcon = (props) => (
  <svg viewBox="0 0 16 16" height={24} fill="currentColor" {...props}>
    <path d="M8.16 6.857V9.6h4.537c-.183 1.177-1.37 3.45-4.537 3.45-2.73 0-4.96-2.26-4.96-5.05s2.23-5.05 4.96-5.05c1.554 0 2.594.66 3.19 1.233l2.17-2.092C12.126.79 10.32 0 8.16 0c-4.423 0-8 3.577-8 8s3.577 8 8 8c4.617 0 7.68-3.246 7.68-7.817 0-.526-.057-.926-.126-1.326H8.16z" />
  </svg>
);

export const GenericIcon = ({ iconPath, ...rest }) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...rest}>
    <path d={iconPath} />
  </svg>
);

export const CloseIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
  </svg>
);

export const DeleteIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z" />
  </svg>
);

export const MoreIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M6 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
  </svg>
);

export const SettingsIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z" />
  </svg>
);

export const PlaylistEditIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M2,6V8H14V6H2M2,10V12H14V10H2M20.04,10.13C19.9,10.13 19.76,10.19 19.65,10.3L18.65,11.3L20.7,13.35L21.7,12.35C21.92,12.14 21.92,11.79 21.7,11.58L20.42,10.3C20.31,10.19 20.18,10.13 20.04,10.13M18.07,11.88L12,17.94V20H14.06L20.12,13.93L18.07,11.88M2,14V16H10V14H2Z" />
  </svg>
);

export const ProfileIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
  </svg>
);

export const ExpandMoreIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
  </svg>
);

export const DoctorIcon = (props) => (
  <svg viewBox="0 0 480 480" height={24} fill="currentColor" {...props}>
    <path d="m328 296h-8c-2.121094 0-4.15625.84375-5.65625 2.34375l-74.34375 74.34375-74.34375-74.34375c-1.5-1.5-3.535156-2.34375-5.65625-2.34375h-8c-83.488281 1.097656-150.902344 68.511719-152 152v24c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-24c.816406-59.375 39.457031-111.597656 96-129.734375v45.933594c-22.636719 2.09375-39.964844 21.066406-40 43.800781v40c0 4.417969 3.582031 8 8 8h16c4.417969 0 8-3.582031 8-8s-3.582031-8-8-8h-8v-32c0-15.464844 12.535156-28 28-28h8c15.464844 0 28 12.535156 28 28v32h-8c-4.417969 0-8 3.582031-8 8s3.582031 8 8 8h16c4.417969 0 8-3.582031 8-8v-40c-.035156-22.734375-17.363281-41.707031-40-43.800781v-49.855469c7.910156-1.523438 15.945312-2.304688 24-2.34375h4.6875l75.3125 75.3125v84.6875c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-84.6875l75.3125-75.3125h4.6875c8.054688.039062 16.089844.820312 24 2.34375v78.792969c-15.601562 4.027343-25.777344 19.03125-23.746094 35.015625s15.632813 27.96875 31.746094 27.96875 29.714844-11.984375 31.746094-27.96875-8.144532-30.988282-23.746094-35.015625v-74.871094c56.542969 18.136719 95.183594 70.359375 96 129.734375v24c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-24c-1.097656-83.488281-68.511719-150.902344-152-152zm48 128c0 8.835938-7.164062 16-16 16s-16-7.164062-16-16 7.164062-16 16-16 16 7.164062 16 16zm0 0" />
    <path d="m136 184h.574219c3.578125 33.601562 28.851562 61.894531 55.648437 78.878906-.101562.367188-.175781.742188-.222656 1.121094v32c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-24.503906c9.960938 5.023437 20.859375 7.921875 32 8.503906 11.140625-.582031 22.039062-3.480469 32-8.503906v24.503906c0 4.417969 3.582031 8 8 8s8-3.582031 8-8v-32c-.046875-.378906-.121094-.753906-.222656-1.121094 26.796875-16.984375 52.070312-45.320312 55.648437-78.878906h.574219c15.730469.035156 29.140625-11.402344 31.589844-26.941406 2.449218-15.539063-6.789063-30.546875-21.765625-35.355469 6.671875-26.503906 9.414062-37.742187 5.9375-51.640625-8.707031-34.824219-52.632813-70.0625-119.761719-70.0625s-111.054688 35.238281-119.761719 70.0625c-3.476562 13.898438-.734375 25.136719 5.9375 51.640625-14.976562 4.808594-24.214843 19.816406-21.765625 35.355469 2.449219 15.539062 15.859375 26.976562 31.589844 26.941406zm104 80c-28.976562 0-88-40.734375-88-88v-42.808594c45.046875-7.992187 88.015625-24.996094 126.335938-49.992187 9.878906 12.992187 30.3125 37.679687 49.664062 48v44.800781c0 47.265625-59.023438 88-88 88zm120-112c0 8.835938-7.164062 16-16 16v-32c8.835938 0 16 7.164062 16 16zm-224.238281-78.0625c7.023437-28.089844 45.941406-57.9375 104.238281-57.9375s97.214844 29.847656 104.238281 57.9375c2.539063 10.167969.1875 19.582031-6.117187 44.636719-14.785156-6.277344-37.976563-32.148438-51.617188-51.199219-1.289062-1.808594-3.277344-2.996094-5.480468-3.285156-2.203126-.285156-4.425782.355468-6.136719 1.773437-.453125.382813-45.414063 36.761719-132.957031 52.914063-6.34375-25.226563-8.714844-34.648438-6.167969-44.839844zm.238281 62.0625v32c-8.835938 0-16-7.164062-16-16s7.164062-16 16-16zm0 0" />
  </svg>
);

export const InstitutionIcon = (props) => (
  <svg viewBox="0 0 480 480" height={24} fill="currentColor" {...props}>
    <path d="M368,208v48h64v-48H368z M416,240h-32v-16h32V240z" />
    <path d="M368,272v48h64v-48H368z M416,304h-32v-16h32V304z" />
    <path d="M368,336v48h64v-48H368z M416,368h-32v-16h32V368z" />
    <path d="M368,400v48h64v-48H368z M416,432h-32v-16h32V432z" />
    <path d="M272,80V32h-64v48h-48v64h48v48h64v-48h48V80H272z M304,128h-48v48h-32v-48h-48V96h48V48h32v48h48V128z" />
    <path d="M352,176V0H128v176H16v304h448V176H352z M128,464H32V192h96V464z M232,464h-40V320h40V464z M288,464h-40V320h40V464z     M336,464h-32V304H176v160h-32V16h192V464z M448,464h-96V192h96V464z" />
    <path d="M48,208v48h64v-48H48z M96,240H64v-16h32V240z" />
    <path d="M48,272v48h64v-48H48z M96,304H64v-16h32V304z" />
    <path d="M48,336v48h64v-48H48z M96,368H64v-16h32V368z" />
    <path d="M48,400v48h64v-48H48z M96,432H64v-16h32V432z" />
  </svg>
);

export const PhoneIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M20 15.5c-1.25 0-2.45-.2-3.57-.57-.35-.11-.74-.03-1.02.24l-2.2 2.2c-2.83-1.44-5.15-3.75-6.59-6.59l2.2-2.21c.28-.26.36-.65.25-1C8.7 6.45 8.5 5.25 8.5 4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1 0 9.39 7.61 17 17 17 .55 0 1-.45 1-1v-3.5c0-.55-.45-1-1-1zM19 12h2c0-4.97-4.03-9-9-9v2c3.87 0 7 3.13 7 7zm-4 0h2c0-2.76-2.24-5-5-5v2c1.66 0 3 1.34 3 3z " />
  </svg>
);

export const EmailIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z " />
  </svg>
);

export const WhatsAppIcon = (props) => (
  <svg viewBox="0 0 16 16" height={24} fill="currentColor" {...props}>
    <path d="M11.665 9.588c-.2-.1-1.177-.578-1.36-.644-.182-.067-.315-.1-.448.1-.132.197-.514.643-.63.775-.116.13-.232.14-.43.05-.2-.1-.842-.31-1.602-.99-.592-.53-.99-1.18-1.107-1.38-.116-.2-.013-.31.087-.41.09-.09.2-.23.3-.35.098-.12.13-.2.198-.33.066-.14.033-.25-.017-.35-.05-.1-.448-1.08-.614-1.47-.16-.39-.325-.34-.448-.34-.115-.01-.248-.01-.38-.01-.134 0-.35.05-.532.24-.182.2-.696.68-.696 1.65s.713 1.91.812 2.05c.1.13 1.404 2.13 3.4 2.99.476.2.846.32 1.136.42.476.15.91.13 1.253.08.383-.06 1.178-.48 1.344-.95.17-.47.17-.86.12-.95-.05-.09-.18-.14-.38-.23M8.04 14.5h-.01c-1.18 0-2.35-.32-3.37-.92l-.24-.143-2.5.65.67-2.43-.16-.25c-.66-1.05-1.01-2.26-1.01-3.506 0-3.63 2.97-6.59 6.628-6.59 1.77 0 3.43.69 4.68 1.94 1.25 1.24 1.94 2.9 1.94 4.66-.003 3.63-2.973 6.59-6.623 6.59M13.68 2.3C12.16.83 10.16 0 8.03 0 3.642 0 .07 3.556.067 7.928c0 1.397.366 2.76 1.063 3.964L0 16l4.223-1.102c1.164.63 2.474.964 3.807.965h.004c4.39 0 7.964-3.557 7.966-7.93 0-2.117-.827-4.11-2.33-5.608 " />
  </svg>
);

export const ChatIcon = (props) => (
  <svg viewBox="0 0 455 455" height={35} fill="currentColor" {...props}>
    <path
      d="M227.5,0C101.855,0,0,101.855,0,227.5c0,47.551,14.598,91.688,39.544,128.193L9.662,445.338l89.646-29.881  C135.812,440.403,179.949,455,227.5,455C353.145,455,455,353.145,455,227.5C455,101.855,353.145,0,227.5,0z M110.238,177.5H344.8v30  H110.238V177.5z M344.801,277.5H110.2v-30h234.601V277.5z"
      data-original="#000000"
    />
  </svg>
);

export const FavoriteIcon = (props) => (
  <svg viewBox="0 0 299.995 299.995" height={35} fill="currentColor" {...props}>
    <path d="M165.45,108.207l-9.788,9.786c-1.945,1.943-4.658,2.638-7.158,2.101c-1.541-0.241-3.009-0.936-4.173-2.103l-9.783-9.783     c-6.549-6.546-15.149-9.822-23.749-9.822c-8.6,0-17.201,3.273-23.749,9.822c-12.82,12.823-12.698,33.374,0.283,47.805     c13.232,14.708,49.605,45.027,62.666,55.788c13.061-10.758,49.442-41.085,62.677-55.788c12.978-14.428,13.1-34.982,0.28-47.805     C199.859,95.115,178.55,95.112,165.45,108.207z" />
    <path d="M149.995,0C67.156,0,0,67.158,0,149.995s67.156,150,149.995,150s150-67.163,150-150S232.834,0,149.995,0z      M224.239,166.417c-16.98,18.866-67.049,59.619-69.174,61.343c-1.489,1.211-3.286,1.826-5.07,1.74     c-0.054,0.003-0.112,0.003-0.169,0.003c-1.725,0-3.46-0.571-4.902-1.743c-2.124-1.725-52.188-42.478-69.163-61.341     c-18.612-20.689-18.49-50.445,0.283-69.218c19.161-19.164,50.344-19.164,69.506,0l4.448,4.448l4.451-4.451     c19.161-19.161,50.341-19.159,69.508,0C242.726,115.975,242.848,145.731,224.239,166.417z" />
  </svg>
);

export const ShareIcon = (props) => (
  <svg viewBox="0 0 58 58" height={35} fill="currentColor" {...props}>
    <circle cx="29" cy="29" r="29" />
    <path
      fill="#FFFFFF"
      d="M32.999,44c-0.205,0-0.412-0.063-0.59-0.193l-15-11c-0.445-0.327-0.542-0.953-0.215-1.398  c0.327-0.443,0.952-0.542,1.398-0.215l15,11c0.445,0.327,0.542,0.953,0.215,1.398C33.61,43.858,33.307,44,32.999,44z"
    />
    <path
      fill="#FFFFFF"
      d="M18.001,25c-0.308,0-0.611-0.142-0.808-0.408c-0.326-0.445-0.23-1.071,0.215-1.398l15-11  c0.446-0.327,1.071-0.229,1.398,0.215c0.326,0.445,0.23,1.071-0.215,1.398l-15,11C18.413,24.938,18.206,25,18.001,25z"
    />
    <circle fill="#FFFFFF" cx="15" cy="28" r="5" />
    <circle fill="#FFFFFF" cx="38" cy="13" r="5" />
    <circle fill="#FFFFFF" cx="38" cy="43" r="5" />
  </svg>
);

export const FolderIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M10 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2h-8l-2-2z" />
  </svg>
);

export const ImageIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z" />
  </svg>
);

export const ArrowLeftIcon = (props) => (
  <svg viewBox="0 0 24 24" height={24} fill="currentColor" {...props}>
    <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z" />
  </svg>
);
