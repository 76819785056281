
import gql from 'graphql-tag'

export const LIST_SPECIALITIES = gql`
  query {
    establishment @rest(type: "[Specialty]", path: "/getAllSpecialties") {
      id
      name
      number
      deleted
      dbId
    }
  }
`

export const ME_ESTABLISHMENT = gql`
  query {
    establishment @rest(type: "Establishment", path: "/establishment") {
      id
      name
      description
      website
      phone
      workingTime
      whatsapp
      registerDate
      login
      thumbnail
      addresses
      specialties
    }
  }
`

export const UPDATE = gql`
  mutation ($input: Establishment!) {
    media(input: $input) @rest(type: "Establishment", path: "/establishment/", method: "PUT", bodySerializer: "form") {
      NoResponse
    }
  }
`

export const LINK_SPECIALITY = gql`
  mutation ($input: LinkSpecialty!) {
    linkDoctor(input: $input) @rest(type: "LinkSpecialty", path: "/establishmentSpecialty/", method: "POST", bodySerializer: "form") {
      worked
    }
  }
`
