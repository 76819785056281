import gql from 'graphql-tag'

export const LIST = gql`
  query {
    institutions @rest(type: "Institution", path: "/companies?{args}") {
      id
      name
      specialties
      addresses
    }
    me @rest(type: "Patient", path: "/patient?storage=false") {
      estabelishmentPatient
    }
  }
`

export const GET = gql`
  query ($id: Int) {
    institution(id: $id) @rest(type: "Institution", path: "/establishment?{args}") {
      id
      name
      addresses
      createdAt
      deleted
      description
      doctors
      exams
      hasExam
      icon
      login
      needLogin
      needBirthDate
      phone
      registerDate
      smallDescription
      specialties
      thumbnail
      user
      website
      whatsapp
      workingTime
      comercialEmail
      useCrawler
    }
  }
`
