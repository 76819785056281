import { useNavigate, useParams } from "@reach/router";
import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import ButtonLoading from "../../components/ButtonLoading";
import CircularProgress from "../../components/CircularProgress";
import { Box, Flex } from "../../components/FlexBox";
import { FormatDate } from "../../components/Format";
import Text from "../../components/Text";
import baseApi from "../../constants/baseApi";
import {
  BtnPink,
  BtnPinkAlt,
  Btns,
  Error,
  Main,
  ModalBody,
  ModalHeader
} from "./styles";

const typeAlimentacao = {
  1: "Não faço dieta",
  2: "Sem Glúten",
  3: "Sem Lactose",
  4: "Vegetariano",
  5: "Ovo Vegetariano",
  7: "Lacto Vegetariano",
  8: "Vegano",
};
const typeAlcool = {
  1: "Diariamente",
  2: "Mais de 2 vezes por semana",
  3: "Até 2 vezes por semana",
  4: "Não bebo",
};
const typeCigarro = {
  1: "Mais de 30 maços por mês",
  2: "Até 30 maços por mês",
  3: "Até 8 maços por mês",
  4: "Até 2 maços por mês",
  5: "Ex-usuário",
  6: "Não sou usuário",
};
const typeDrogas = {
  1: "Usuário",
  2: "Uso Social",
  3: "Ex-usuário",
  4: "Não sou usuário",
};

export default function Details({ setSuccess }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);
  const [patient, setPatient] = useState(false);
  const [dropUserModal, setDropUserModal] = useState(false);

  const { drop, id } = useParams();

  const handleOk = () => {
    dropUser();
  };
  const handleCancel = () => {
    setDropUserModal(false);
  };

  function dropUser() {
    setLoading(true);
    setError(false);

    const headers = new Headers();
    headers.append(
      "Authorization",
      `token ${localStorage.getItem("docpadAuthToken")}`
    );

    const reqOpt = {
      method: "DELETE",
      headers: headers,
      redirect: "follow",
    };

    let url;
    url = `${baseApi}/delete-doctor-health-operator/?id=${drop}`;

    fetch(url, reqOpt)
      .then((res) => res.json())
      .then((result) => {
        setLoading(false);
        if (result.detail) {
          if (result.detail === "Doctor Health Operator not found") {
            setError("Médico não encontrado.");
          } else if (result.detail === "Patient Health Operator not found") {
            setError("Paciente não encontrado.");
          } else {
            setError(result.detail);
          }
        } else {
          navigateToHome();
        }
      })
      .catch((error) => {
        setLoading(false);
        navigateToHome();
      });
  }

  function navigateToHome() {
    navigate(`/medicos`);
  }

  useEffect(() => {
    setLoadingContent(true);
    const headers = {
      Authorization: `token ${localStorage.getItem("docpadAuthToken")}`,
    };

    fetch(`${baseApi}/operator-load-doctor-by-id/?id=${id}`, {
      headers,
    }).then((response) => {
      response
        .json()
        .then((data) => {
          setPatient(data);
          setLoadingContent(false);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, [id]);

  if (loadingContent) return <CircularProgress />;

  if (!loadingContent)
    return (
      <Main>
        <Flex fd={"column"} p={[2, 3]} ai={"center"}>
          <Flex
            maxWidth="1000px"
            p={5}
            jc={"space-evenly"}
            ai={"center"}
            fw={"wrap"}
            width={[1]}
          >
            <Flex fd="column" ai="center">
              <Box
                width={100}
                height={100}
                overflow="hidden"
                borderRadius="50%"
                jc={"center"}
                border={"3px solid #eee"}
              >
                <img style={{ width: "100%" }} src={patient.thumbnail} alt="" />
              </Box>
              <Box mt={2} fontSize={3} textAlign="center" maxWidth="200px">
                {patient.name}
              </Box>
            </Flex>
          </Flex>
          <Flex jc="space-between" p={3} maxWidth="800px" width={1} fw="wrap">
            <Flex
              p={3}
              width={[1, 1, 1, "45%"]}
              fd="column"
              mt={[3, 3, 3, 0]}
              border={"1px solid #e1e1e1"}
              borderRadius={"8px"}
              boxShadow={"1px 1px 5px rgba(50, 50, 50, 0.15)"}
            >
              <Flex jc="space-between">
                <Box>
                  <Text fw={700}>Estilo de Vida</Text>
                </Box>
              </Flex>
              <Box fontSize={2}>
                <Box mt={2}>
                  <Text>Alimentação:</Text>{" "}
                  {patient.alimentation &&
                    (patient.alimentation.name ||
                      typeAlimentacao[patient.alimentation])}
                </Box>
                <Box mt={1}>
                  <Text>Alcool:</Text>{" "}
                  {patient.alcohol &&
                    (patient.alcohol.name || typeAlcool[patient.alcohol])}
                </Box>
                <Box mt={1}>
                  <Text>Cigarro:</Text>{" "}
                  {patient.smoke &&
                    (patient.smoke.name || typeCigarro[patient.smoke])}
                </Box>
                <Box mt={1}>
                  <Text>Drogas:</Text>{" "}
                  {patient.drug &&
                    (patient.drug.name || typeDrogas[patient.drug])}
                </Box>
                <Box mt={1}>
                  <Text>Atividades Físicas:</Text>{" "}
                  {patient.physicalActivity &&
                    (patient.physicalActivity.name || patient.physicalActivity)}
                </Box>
              </Box>
            </Flex>

            <Flex
              p={3}
              fontSize={2}
              width={[1, 1, 1, "45%"]}
              fd="column"
              mt={[3, 3, 3, 0]}
              height={"auto"}
              border={"1px solid #e1e1e1"}
              borderRadius={"8px"}
              boxShadow={"1px 1px 5px rgba(50, 50, 50, 0.15)"}
            >
              <Flex jc="space-between">
                <Box>
                  <Text fw={700}>Alergias</Text>
                </Box>
              </Flex>
              <Box mb={3}>
                {patient.allergies
                  ? patient.allergies.map((item) => item.name).join(", ")
                  : "Não tenho alergias"}
              </Box>

              <Flex jc="space-between">
                <Box>
                  <Text fw={700}>Histórico Familiar</Text>
                </Box>
              </Flex>
              <Box mb={3}>
                {patient.allergies
                  ? patient.familyHistory.map((item) => item.name).join(", ")
                  : "Sem histórico familiar de doenças"}
              </Box>

              <Flex jc="space-between">
                <Box>
                  <Text fw={700}>Medicamentos contínuos</Text>
                </Box>
              </Flex>
              <Box mb={3}>
                {patient.allergies
                  ? patient.continuousMedications
                      .map((item) => item.name)
                      .join(", ")
                  : "Sem histórico familiar de doenças"}
              </Box>
            </Flex>
          </Flex>

          <Flex jc="space-between" p={3} maxWidth="800px" width={1} fw="wrap">
            <Flex
              fd="column"
              p={3}
              fontSize={2}
              width={[1, 1, 1, "45%"]}
              mt={[3, 3, 3, 0]}
              border={"1px solid #e1e1e1"}
              borderRadius={"8px"}
              boxShadow={"1px 1px 5px rgba(50, 50, 50, 0.15)"}
            >
              <Flex jc="space-between">
                <Box>
                  <Text fw={700}>Informações</Text>
                </Box>
              </Flex>

              <Box mt={1}>
                <Text strong>Nome:</Text> {patient.name}
              </Box>
              <Box mt={1}>
                <Text strong>Data de Nascimento: </Text>
                <FormatDate>
                  {patient.birthDate || patient.birth_date}
                </FormatDate>
              </Box>
              <Box mt={1}>
                <Text strong>CPF: </Text>
                {patient.cpf}
              </Box>
              <Box mt={1}>
                <Text strong>Gênero: </Text>
                {patient.gender}
              </Box>
              <Box mt={1}>
                <Text strong>Número de celular: </Text>
                {patient.cellphone || patient.phone}
              </Box>
              <Box mt={1}>
                <Text strong>Email: </Text>
                {patient.login}
              </Box>
            </Flex>
            <Flex
              fd="column"
              p={3}
              fontSize={2}
              width={[1, 1, 1, "45%"]}
              mt={[3, 3, 3, 0]}
              border={"1px solid #e1e1e1"}
              borderRadius={"8px"}
              boxShadow={"1px 1px 5px rgba(50, 50, 50, 0.15)"}
            >
              <Flex jc="space-between">
                <Box>
                  <Text fw={700}>Plano de Saúde</Text>
                </Box>
              </Flex>

              <Box>
                <Text strong>Plano de Saúde: </Text>
                {patient.healthInsuranceSubPlan
                  ? patient.healthInsuranceSubPlan.name
                  : ""}
              </Box>
              <Box>
                <Text strong>Número do cartão: </Text>
                {patient.healthInsuranceNumber}
              </Box>
              {/* <Box><Text strong>Tipo: </Text>{patient.name}</Box> */}
            </Flex>
          </Flex>
          {error && <Error>{error}</Error>}
          <Btns>
            <BtnPinkAlt
              disabled={loading}
              type="button"
              onClick={navigateToHome}
            >
              Cancelar
            </BtnPinkAlt>
            <BtnPink
              disabled={loading}
              type="button"
              onClick={() => setDropUserModal(true)}
            >
              Excluir
            </BtnPink>
          </Btns>
        </Flex>
        <Modal
          open={dropUserModal}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <div className="content">
            <ModalHeader>
              <h1>Excluir associação de {patient.name}?</h1>
            </ModalHeader>
            <ModalBody>
              <Btns jc="center">
                <BtnPinkAlt
                  disabled={loading}
                  type="button"
                  onClick={handleCancel}
                >
                  Cancelar
                </BtnPinkAlt>
                <BtnPink disabled={loading} type="button" onClick={handleOk}>
                  Confirmar
                  {loading && <ButtonLoading />}
                </BtnPink>
              </Btns>
            </ModalBody>
          </div>
        </Modal>
      </Main>
    );
}
