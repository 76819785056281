import { Table } from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import CircularProgress from "../../components/CircularProgress";
import { Flex } from "../../components/FlexBox";
import BASE_API from "../../constants/baseApi";
import { BtnPink, Pagination } from "./styles";

const pgConfig = {
  hideOnSinglePage: true,
}

export const TableUsers = ({
  page,
  setViewUser,
  setViewLevel,
  searchInput,
}) => {
  const [dataUsers, setDataUsers] = useState([]);
  const [filteredDataUsers, setFilteredDataUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [prevURLPage, setPrevURLPage] = useState(null);
  const [nextURLPage, setNextURLPage] = useState(`${BASE_API}/list-users-of-operator/?page_size=10&page=1`);
  const [tablePage, setTablePage] = useState(1);

  const columnsUsers = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "login",
      key: "login",
    },
    {
      title: "Admin",
      key: "is_admin",
      render: ({ is_admin }) => (is_admin ? "Sim" : "Não"),
    },
    {
      title: "Data de registro",
      key: "register_date",
      render: ({ register_date }) => {
        const date = new Date(Date.parse(register_date));
        return date.toLocaleString();
      },
    },
    {
      title: "",
      key: "action",
      render: (user) => (
        <Flex jc="flex-end">
          <BtnPink onClick={() => setViewUser(user)}>Ver</BtnPink>
        </Flex>
      ),
    },
  ];

  function getUsers(url) {
    const config = {
      headers: {
        Authorization: `token ${localStorage.getItem("docpadAuthToken")}`,
      },
    };
    fetch(url, config).then(
      (res) => {
        res
          .json()
          .then((data) => {
            setPrevURLPage(data.previous);
            setNextURLPage(data.next);
            setTablePage(url.split("page=")[1]);
            const results = data.results.map((user) => {
              return {
                key: user.id,
                name: user.name,
                login: user.login,
                is_admin: user.is_admin,
                register_date: user.register_date,
              };
            });
            setDataUsers(results);
            setFilteredDataUsers(results);
            setLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      });
  }

  useEffect(() => {
    setLoading(true);
    getUsers(nextURLPage);
  }, []);

  useEffect(() => {
    if (searchInput) {
      const filtered = dataUsers
        .filter((user) => {
          return (
            (user.name &&
              user.name.toLowerCase().includes(searchInput.toLowerCase())) ||
            (user.login &&
              user.login.toLowerCase().includes(searchInput.toLowerCase()))
          );
        })
        .map((user) => {
          return {
            key: user.key,
            name: user.name,
            login: user.login,
            is_admin: user.is_admin,
            register_date: user.register_date,
          };
        });
      setFilteredDataUsers(filtered);
    } else {
      setFilteredDataUsers(dataUsers);
    }
  }, [dataUsers, searchInput]);

  const columnsLevel = [
    {
      title: "Nível",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Usuários",
      dataIndex: "users",
      key: "users",
    },
    {
      title: "Permissões",
      dataIndex: "premissions",
      key: "premissions",
    },
    {
      title: "",
      key: "action",
      render: (level) => (
        <Flex jc="flex-end">
          <BtnPink onClick={() => setViewLevel(level)}>Ver</BtnPink>
        </Flex>
      ),
    },
  ];
  const dataLevel = [
    {
      key: "1",
      name: "Nível 2",
      users: "10",
      premissions: "10",
      description: "Lorem Ipsum dolor sit met",
    },
    {
      key: "2",
      name: "Nível 2",
      users: "10",
      premissions: "10",
      description: "Lorem Ipsum dolor sit met",
    },
    {
      key: "3",
      name: "Nível 2",
      users: "10",
      premissions: "10",
      description: "Lorem Ipsum dolor sit met",
    },
    {
      key: "4",
      name: "Nível 2",
      users: "10",
      premissions: "10",
      description: "Lorem Ipsum dolor sit met",
    },
    {
      key: "5",
      name: "Nível 2",
      users: "10",
      premissions: "10",
      description: "Lorem Ipsum dolor sit met",
    },
    {
      key: "6",
      name: "Nível 2",
      users: "10",
      premissions: "10",
      description: "Lorem Ipsum dolor sit met",
    },
    {
      key: "7",
      name: "Nível 2",
      users: "10",
      premissions: "10",
      description: "Lorem Ipsum dolor sit met",
    },
    {
      key: "8",
      name: "Nível 2",
      users: "10",
      premissions: "10",
      description: "Lorem Ipsum dolor sit met",
    },
  ];

  if (loading) return <Flex><CircularProgress/></Flex>;

  if (!loading) return (
    <>
      {page === 1 && <Table pagination={pgConfig} columns={columnsUsers} dataSource={filteredDataUsers} />}
      {page === 2 && <Table pagination={pgConfig} columns={columnsLevel} dataSource={dataLevel} />}
      <Pagination>
        <button onClick={() => getUsers(prevURLPage)} className="back" disabled={prevURLPage === null}>&lt;</button>
        <button className="page">{tablePage}</button>
        <button onClick={() => getUsers(nextURLPage)} className="next" disabled={nextURLPage === null}>&gt;</button>
      </Pagination>
    </>
  );
};
