import { useState } from 'react'

export default (value) => {
  const [input, setInput] = useState(value)

  const onChange = e => {
    setInput(e.target.value)
  }

  return {value: input, onChange}
}
