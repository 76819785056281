import React, { useState, useEffect } from "react";
import {
  Container,
  ProtocolsList,
  Details,
  BtnIcon,
  Pagination,
  Btns,
  ModalHeader2,
  ModalBody,
  BtnPinkAlt,
  BtnPink,
} from "./styles";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { BiPencil } from "react-icons/bi";
import CreateProtocolModal from "./CreateProtocolModal";
import baseApi from "../../constants/baseApi";
import CircularProgress from "../../components/CircularProgress";
import { Modal } from "antd";
import ButtonLoading from "../../components/ButtonLoading";

export default function Protocols({section, setCreate, create, startDate, endDate }) {

  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [protocols, setProtocols] = useState([]);
  const [tablePage, setTablePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const pageSize = 10;
  const [maxPage, setMaxPage] = useState(Math.ceil(totalItems / pageSize));
  const [toView, setToView] = useState(null);
  const [typeModal, setTypeModal] = useState("create");

  const [dropProtocolModal, setDropProtocolModal] = useState(false);
  const [protocolToDrop, setProtocolToDrop] = useState(null);

  function setToEdit(e, protocol) {
    e.stopPropagation();
    setTypeModal("edit");
    setCreate(true);
    setToView(protocol);
  }

  function confirmDropProtocol(id, cb) {
    if (!id) return;
    const headers = {
      Authorization: `token ${localStorage.getItem("docpadAuthToken")}`,
    };

    fetch(`${baseApi}/delete-protocol-for-doctor/?id=${id}`, {
      headers,
      method: "DELETE",
      redirect: "follow",
    })
      .then((res) => res.text())
      .then((data) => {
        setTablePage(1);
        fetch(`${baseApi}/list-protocol-for-doctor/?page=1&page_size=${pageSize}`, {
          headers,
        })
          .then((response) => response.json())
          .then((data) => {
            setTotalItems(data.count);
            setMaxPage(Math.ceil(data.count / pageSize));
            setProtocols(data.results);
            setLoadingDelete(false);
            cb();
          });
      });
  }

  useEffect(() => {
    setLoading(true);
    setTotalItems(0);
    const headers = {
      Authorization: `token ${localStorage.getItem("docpadAuthToken")}`,
    };
    // const {initial_date, end_date} = dates;
    const date = startDate && endDate !== '' ;
    if (section === "Nova prescrição de exame") {
      return;
    }
    fetch(`${baseApi}/list-protocol-for-doctor/?page=${tablePage}&page_size=${pageSize}${date ? `&end_date=${endDate}&initial_date=${startDate}` : ''}`, {
      headers,
    })
      .then((response) => response.json())
      .then((data) => {
        setTotalItems(data.count);
        setMaxPage(Math.ceil(data.count / pageSize));
        setProtocols(data.results.reverse());
        setLoading(false);
      });
  }, [create, tablePage, startDate, endDate, section]);

  function prevPage() {
    if (tablePage > 1) {
      setTablePage(tablePage - 1);
    }
  }

  function nextPage() {
    if (tablePage < maxPage) {
      setTablePage(tablePage + 1);
    }
  }

  function handleDrop(e, protocol) {
    e.stopPropagation();
    setProtocolToDrop(protocol);
    setDropProtocolModal(true);
  }

  function handleOkDrop() {
    setLoadingDelete(true);
    confirmDropProtocol(protocolToDrop.id, () => {
      setLoadingDelete(false);
      setDropProtocolModal(false);
    });
  }

  function handleCancelDrop() {
    setDropProtocolModal(false);
  }

  if(loading) return <CircularProgress/>

  if (!loading) return (
    <>
      <ProtocolsList>
        {protocols.map((protocol, i) => (
          <Container onClick={() => {setTypeModal('view'); setToView(protocol)}} key={i * 100}>
            <Details>
              <div>
                <span>{protocol.name}</span>
                <span>{protocol.description}</span>
                <div className="exams">
                  <p>Exames:</p>
                  <ul>
                    {protocol.exams.map((exam, i) => (
                      <li key={i * 197}>{exam.name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </Details>
            <Btns>
              <BtnIcon onClick={(e) => handleDrop(e, protocol)}>
                <AiOutlineDelete></AiOutlineDelete>
              </BtnIcon>

              <BtnIcon onClick={(e) => {setToEdit(e, protocol)}}>
                <BiPencil></BiPencil>
              </BtnIcon>

              <BtnIcon onClick={(e) => {}}>
                {<AiOutlineEye></AiOutlineEye>}
              </BtnIcon>
            </Btns>
          </Container>
        ))}
      </ProtocolsList>
      <Pagination>
        <button onClick={prevPage} className="back" disabled={tablePage === 1}>
          &lt;
        </button>
        <button className="page">{tablePage}</button>
        <button
          onClick={nextPage}
          className="next"
          disabled={tablePage === maxPage || maxPage === 0}
        >
          &gt;
        </button>
      </Pagination>

      <Modal
        open={dropProtocolModal}
        onOk={handleOkDrop}
        onCancel={handleCancelDrop}
      >
        <div className="content">
          <ModalHeader2>
            <h1>Excluir {protocolToDrop && protocolToDrop.name}?</h1>
          </ModalHeader2>
          <ModalBody>
            <Btns jc="center">
              <BtnPinkAlt
                disabled={loading}
                type="button"
                onClick={handleCancelDrop}
              >
                Cancelar
              </BtnPinkAlt>
              <BtnPink disabled={loading} type="button" onClick={handleOkDrop}>
                Confirmar
                {loadingDelete && <ButtonLoading />}
              </BtnPink>
            </Btns>
          </ModalBody>
        </div>
      </Modal>

      {(create || !!toView) && <CreateProtocolModal setCreate={setCreate} create={create || !!toView} type={typeModal} setType={setTypeModal} data={toView} setData={setToView} />}
    </>
  );
}
