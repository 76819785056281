import React from "react";
import Radio from "./MyRadio";

const RadioGroup = ({ value, options, onChange, multiple = false }) => {
  const handleChange = (optionValue) => {
    if (multiple) {
      if (value.includes(optionValue)) {
        let newArr = [...value];
        const index = value.indexOf(optionValue);
        if (index > -1) newArr.splice(index, 1);
        return onChange(newArr);
      } else {
        return onChange([...value, optionValue]);
      }
    }
    if (optionValue === value) return onChange("");
    return onChange(optionValue);
  };

  if (multiple)
    return (
      <>
        {options.map((option) => (
          <Radio
            key={option.value}
            label={option.label}
            onChange={() => handleChange(option.value)}
            checked={value.includes(option.value)}
          />
        ))}
      </>
    );

  return (
    <>
      {options.map((option) => (
        <Radio
          key={option.value}
          label={option.label}
          onChange={() => handleChange(option.value)}
          checked={option.value === value}
        />
      ))}
    </>
  );
};

export default RadioGroup;
