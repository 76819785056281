import styled from "styled-components";

export const Title = styled.h1`
  font-size: 22px !important;
  font-weight: bold;
  margin: 0 25px 0 0 !important;
  color: #2f3c4a !important;
`;

export const SubTitle = styled.h2`
  margin-top: 55px;
  font-size: 18px !important;
  font-weight: bold;
  color: #2f3c4a !important;
`;

export const BtnPink = styled.button`
  border: 3px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  color: #ffffff;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 700;
  padding: 0px 30px;
  height: 50px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: #e01b4b;
  cursor: pointer;
  width: ${(props) => props.width === "100%" && "100%"};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  &:disabled {
    opacity: 0.5;
    cursor: auto;
  }
`;

export const BtnPinkAlt = styled(BtnPink)`
  background-color: transparent;
  border: 3px solid #e01b4b;
  color: #e01b4b;
`;

export const CardOption = styled.div`
  display: flex;
  width: 100%;
  gap: 70px;
`;
export const Navigation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 44px;

  @media (max-width: 1330px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: space-between;

  @media (max-width: 1530px) {
    justify-content: flex-start;
  }

  @media (max-width: 970px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CardSearch = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  svg {
    position: absolute;
    margin-left: auto;
    right: 10px;
    top: 10px;
  }

  input {
    width: 100%;
    border-radius: 8px;
    padding: 6px 30px 6px 15px;
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
  }

  input::placeholder {
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
  }

  input:focus {
    box-shadow: 0 0 0 0;
    border: 1px solid #dbdbdb;
    outline: 0;
  }
`;
export const CardDate = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  input {
    width: 100%;
    border-radius: 8px;
    padding: 6px 15px;
    color: #2f3c4c;
    background: #f5f5f5;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
  }

  input::placeholder {
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
  }

  input:focus {
    box-shadow: 0 0 0 0;
    border: 1px solid #dbdbdb;
    outline: 0;
  }
`;

export const Option = styled.span`
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

  color: ${(props) => props.select === "true" && "#2F3C4C"};
  color: ${(props) => props.select === "false" && "#343d4b80"};

  text-decoration: ${(props) => props.select === "true" && "underline"};
  text-decoration: ${(props) => props.select === "false" && "none"};
`;

export const Breadcrumb = styled.div`
  span {
    padding: 0 15px;
  }
`;

export const Container = styled.div`
  padding: 25px 30px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: ${(props) => (props.block === true ? "block" : "flex")};
  justify-content: space-between;
  align-items: center;
  color: #2f3c4c;
  width: 100%;
  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};
  div {
    span {
      display: block;
      font-weight: 500;
      & + span {
        font-weight: 400;
        color: #7e7e7e;
        margin-top: 10px;
      }
    }
  }

  & + div {
    margin-top: 30px;
  }

  h2.form {
    margin-top: 30px;
  }
`;

export const Details = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  .exams {
    margin-top: 10px;
    p {
      color: #2f3c4c;
      margin-bottom: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      margin-left: 10px;
      max-height: 100px;
      min-width: 100px;
      overflow-y: auto;
      li {
        color: #7e7e7e;
        list-style: none;
      }
      &::-webkit-scrollbar {
        width: 10px;
      }

      &::-webkit-scrollbar-track {
        background: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #7e7e7e;
        border-radius: 20px;
        border: 3px solid #f5f5f5;
      }
    }
  }
`;

export const PrescritionDoctorDates = styled.div`
  display: flex;
  gap: 86px;
  margin-left: 20px;
`;

export const PrescritionDates = styled(PrescritionDoctorDates)`
  & > div {
    display: flex;
    gap: 20px;
  }
`;

export const TableContent = styled.div`
  .ant-table-thead {
    font-size: 14px !important;
  }

  .ant-table-column-title {
    color: #2f3c4c;
  }

  .ant-table-row td {
    font-size: 13px;
  }
`;
export const Btns = styled.div`
  display: flex;
  gap: 20px;
  justify-content: ${(props) => props.jc || "flex-end"};
  margin-top: 30px;
`;

export const Form = styled.form``;

export const FormTitle = styled.h2`
  font-size: 18px !important;
  font-weight: 900 !important;
  color: #2f3c4c;
  margin-bottom: 15px;
`;

export const FormModal = styled.form`
  input,
  select,
  textarea {
    & + input {
      margin-top: 10px;
    }
  }
  & > div {
    & + div {
      margin-top: 20px;
    }
  }
`;
export const FormGroup = styled.div`
  margin-top: 20px;
  label {
    display: block;
    font-weight: 800;
    margin-bottom: 12px;
  }
  input,
  select,
  textarea {
    display: block;
    padding: 16px 27px;
    background-color: #f5f5f5;
    border: 1px solid transparent !important;
    width: 100%;
    border: none;
    border-radius: 11px;
    resize: none;
    & + input {
      margin-top: 10px;
    }
    &.error {
      border: 1px solid #ff0000 !important;
    }
  }
`;
export const Inputs = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  @media screen and (max-width: 970px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const InputWhiteLabel = styled.input`
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  display: block;
  margin-top: 10px;
`;

export const BtnIcon = styled.button`
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  display: block;
  margin-top: 10px;
  font-size: 22px;
  color: #e01b4b;
  cursor: pointer;
  display: flex;
  height: 18px;
  margin-top: 0;
  align-items: center;
  transition: all 0.5s ease;
  &:disabled {
    cursor: auto;
    animation: spin 1s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const ClickableText = styled.p`
  cursor: pointer;
  color: #3eaee2;
  margin-top: 31px;
  display: flex;
  align-items: baseline;
  gap: 5px;
  font-weight: 700;
`;

export const ClickableTextPink = styled(ClickableText)`
  color: #e01b4b;
  margin-top: 20px;
  justify-content: right;
`;

export const ModalBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 15px;
  padding: 25px;
  position: relative;
  img {
    height: 57px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 30px;
  h1 {
    color: #e01b4b;
    flex: 1;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
  }
  img {
    height: 57px;
  }
  div {
    cursor: pointer;
  }
`;

export const ProtocolsList = styled.div``;

export const PrescritionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  div {
    width: 100%;
    input {
      width: 90%;
    }
  }
`;

export const PrescritionBody = styled.div`
  margin-top: 20px;
  & > div {
    padding-top: 20px;
    padding-bottom: 20px;
    border-top: 1px solid #dbdbdb;
    span {
      & + span {
        display: flex;
        gap: 10px;
      }
    }
  }
  & > button {
    text-align: right;
  }
`;
export const PrescritionItem = styled.div``;

export const Toggle = styled.div`
  width: 28px;
  height: 19px;
  input {
    display: none;
  }
  label {
    display: block;
    width: 28px;
    height: 19px;
    background-color: #2f3c4c;
    position: relative;
    border-radius: 20px;
    display: flex;
    align-items: center;
    padding: 2px;
    &::before {
      transition: all 0.1s linear;
      content: "";
      width: 15px;
      height: 15px;
      position: absolute;
      box-shadow: 0px 3px 6px #00000029;
      background-color: #ffffff;
      border-radius: 20px;
    }
  }

  input:checked + label {
    background-color: #e01b4b;
    &::before {
      transform: translateX(9px);
    }
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
  button {
    background: transparent;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    margin: 0;
    padding: 5px 10px;
    color: #e01b4b;
    border: 1px solid #e01b4b;
    &:disabled {
      cursor: auto;
      border-color: #00000040;
      color: #00000040;
    }
  }
`;

export const ModalHeader2 = styled.div`
  width: 100%;
  padding: 30px;
  h1 {
    color: rgba(0, 0, 0, 0.65);
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
    text-align: center;
  }
`;

export const ModalBody = styled.div`
  width: 100%;
  padding: 0 30px 30px;
`;

export const LoadingInline = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: #e01b4b;
  font-weight: 500;
`;
