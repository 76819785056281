import React from "react";
import styled from "../utils/styled";

import Modal from "./Modal";

const enterAnimation = (modal, backdrop, backdropOpacity) => {
  if (!modal.current) return;
  if ("animate" in modal.current) {
    backdrop.current.animate([{ opacity: 0 }, { opacity: backdropOpacity }], {
      duration: 300,
      easing: "ease",
    });
    modal.current.animate(
      [
        { opacity: 0, transform: "translateY(-100px)" },
        { opacity: 1, transform: "translateY(0)" },
      ],
      { duration: 300, easing: "ease" }
    ).onfinish = () => {
      if (modal.current) {
        modal.current.style.opacity = 1;
      }
      if (backdrop.current) {
        backdrop.current.style.opacity = backdropOpacity;
      }
    };
  } else {
    modal.current.style.opacity = 1;
    modal.current.style.transform = "translateY(0)";
    backdrop.current.style.opacity = backdropOpacity;
  }
};

const exitAnimation = (modal, backdrop, backdropOpacity, callback) => {
  if (!modal.current) return;
  if ("animate" in modal.current) {
    backdrop.current.animate([{ opacity: backdropOpacity }, { opacity: 0 }], {
      duration: 300,
      easing: "ease",
    });
    modal.current.animate(
      [
        { opacity: 1, transform: "translateY(0)" },
        { opacity: 0, transform: "translateY(-100px)" },
      ],
      { duration: 300, easing: "ease" }
    ).onfinish = () => {
      callback();
    };
  } else {
    callback();
  }
};

const StyledModal = styled(Modal)`
  & > div {
    position: relative;
    background-color: white;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
      0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
    pointer-events: auto;
    width: 100%;
    height: calc(100vh);
    height: calc(100vh - 64px); /* test */
    margin: 0;
    margin: 32px auto; /* test */
    overflow: hidden;
    @media (min-width: 40em) {
      margin: 32px auto;
      width: auto;
      /* height: min-content; */
      height: calc(100vh - 64px);
      max-height: 800px;
      min-width: 640px;
      border-radius: 8px;
    }
  }
`;

const StyledModalWithAnimations = (props) => (
  <StyledModal
    enterAnimation={enterAnimation}
    exitAnimation={exitAnimation}
    {...props}
  />
);

export default StyledModalWithAnimations;
