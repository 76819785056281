import { DatePicker, Empty, Input, Table, message } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { FaRegFileArchive, FaRegFileImage, FaRegFilePdf } from "react-icons/fa";
import {
  ME_DOCTOR,
  ME_ESTABLISHMENT,
  ME_OPERATOR,
  ME_PATIENT,
} from "../../layouts/Gqls";
import { listUploadedExams } from "../../services/upload-exam";
import { formatCPF } from "../../utils/formatter";
import { Content, TableContainer } from "./styles";
import { Link } from "../../utils/router";

export default function ListExams() {
  const [examsList, setExamsList] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    name: "",
    dateFrom: undefined,
    dateTo: undefined,
  });
  const debounceTimeoutRef = React.useRef(null);

  const { Search } = Input;
  const { RangePicker } = DatePicker;

  const loginType = localStorage.getItem("type");
  let query = ME_PATIENT;
  if (loginType === "doctor") query = ME_DOCTOR;
  if (loginType === "operator") query = ME_OPERATOR;
  if (loginType === "establishment") query = ME_ESTABLISHMENT;
  const { data: user } = useQuery(query);

  useEffect(() => {
    const listExams = async () => {
      setLoading(true);
      try {
        const payload = {
          name: search.name,
          establishmentID: user[loginType].id,
          page,
          pageSize: pageSize,
          dateFrom: search.dateFrom,
          dateTo: search.dateTo,
        };

        const data = await listUploadedExams(payload);
        if (!data) {
          setLoading(false);
          throw new Error("Falha ao listar os exames");
        }
        setExamsList(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        message.error("Falha ao listar os exames");
      }
    };
    listExams();
  }, [page, pageSize, search]);

  const columns = [
    {
      title: "CPF paciente",
      dataIndex: "cpf_patient",
      render: (cpf) => <span>{cpf ? formatCPF(cpf) : null}</span>,
      key: "cpf_patient",
    },
    {
      title: "Exame",
      render: (value, record) => {

        let linkComponent;
        if (record.media.includes(".pdf")) {
          linkComponent = (
            <a rel="noopener noreferrer" target="_blank" href={value}>
              Abrir exame <FaRegFilePdf style={{ color: "#ff0103" }} />
            </a>
          );
        } else if (record.media.includes("dcm")) {
          linkComponent = (
            <>
              <Link to={`/dicom-view/?file=${value}`}>
                Abrir arquivo <FaRegFileArchive style={{ color: "#ff0103" }} />
              </Link>
            </>
          );
        } else if (
          record.media.includes("png") ||
          record.media.includes("jpg") ||
          record.media.includes("jpeg")
        ) {
          linkComponent = (
            <a rel="noopener noreferrer" target="_blank" href={value}>
              Abrir imagem <FaRegFileImage style={{ color: "#ff0103" }} />
            </a>
          );
        } else if (
          record.media === null &&
          record.medias_no_info_exam.length > 0
        ) {
          linkComponent = (
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={record.medias_no_info_exam[0].media}
            >
              Abrir exame <FaRegFilePdf style={{ color: "#ff0103" }} />
            </a>
          );
        } else {
          linkComponent = <span></span>;
        }

        return linkComponent;
      },
      dataIndex: "media",
      key: "media",
    },
    {
      title: "Data de upload",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <span>{date ? moment(date).format("D/MM/YYYY — HH:mm:ss") : null}</span>
      ),
    },
  ];

  const onSearch = (value) => {
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      setSearch((prev) => ({ ...prev, name: value }));
    }, 500);
  };

  const onSelectDate = (value) => {
    if (!value) return;
    setSearch((prev) => ({
      ...prev,
      dateFrom: new Date(value[0]).toISOString().slice(0, 10),
      dateTo: new Date(value[1]).toISOString().slice(0, 10),
    }));
  };

  return (
    <Content>
      <h1>Listagem de Exames</h1>
      <TableContainer>
        <div className="search-container">
          <Search
            placeholder="Procure pelo nome do paciente"
            onChange={(e) => onSearch(e.target.value)}
            style={{ width: 300 }}
          />
          <RangePicker
            locale={locale}
            onChange={(e) => onSelectDate(e)}
            format="DD/MM/YYYY"
          />
        </div>
        <h2>Total de exames: {examsList.count ? examsList.count : 0}</h2>
        <Table
          locale={{
            emptyText: <Empty description="Nenhum exame encontrado" />,
          }}
          loading={loading}
          dataSource={examsList.results}
          columns={columns}
          pagination={{
            pageSize: pageSize,
            showSizeChanger: false,
            total: examsList.count,
            onChange: (page, pageSize) => {
              setPage(page);
              setPageSize(pageSize);
            },
          }}
        />
      </TableContainer>
    </Content>
  );
}
