import React, { memo, useState, useEffect } from 'react'

import Input from './Input'

const maskValue = (value = '') => {
  value = value.toString()
  value = value.replace(/\D/g, '')

  if (!value) return ''
  if (value.length === 4 && /\d{4}/.test(value)) return `${value.substr(0, 3)}.${value.substr(3, 1)}`
  if (value.length === 5 && /\d{5}/.test(value)) return `${value.substr(0, 3)}.${value.substr(3, 2)}`
  if (value.length === 6 && /\d{6}/.test(value)) return `${value.substr(0, 3)}.${value.substr(3, 3)}`
  if (value.length === 7 && /\d{7}/.test(value)) return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 1)}`
  if (value.length === 8 && /\d{8}/.test(value)) return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 2)}`
  if (value.length === 9 && /\d{9}/.test(value)) return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}`
  if (value.length === 10 && /\d{10}/.test(value)) return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}-${value.substr(9, 1)}`
  if (value.length === 11 && /\d{11}/.test(value)) return `${value.substr(0, 3)}.${value.substr(3, 3)}.${value.substr(6, 3)}-${value.substr(9, 2)}`

  return value
}

const unmaskValue = (maskedValue = '') => {
  if (!maskedValue) return ''
  if (maskedValue.length === 10) {
    return maskedValue.replace(/\D/g, '')
  } else {
    return ''
  }

}

const initialMaskedValue = ''

const InputCpf = memo(({ onChange = () => {}, value, defaultValue, ...rest }) => {

  const [maskedValue, setMaskedValue] = useState(initialMaskedValue)

  useEffect(
    () => {
      setMaskedValue(maskValue(value || defaultValue))
    },
    [defaultValue]
  )

  const handleChange = event => {
    const { target } = event
    const { value: inputValue = 0 } = target

    const value = unmaskValue(inputValue)
    const maskedValue = maskValue(inputValue)

    setMaskedValue(maskedValue)

    if (!onChange || typeof onChange !== 'function') return false
    return onChange(event, value, maskedValue)
  }

  return (
    <Input maxLength={14} type='tel' value={maskedValue} onChange={handleChange} {...rest} />
  )

})

export default InputCpf
