import React, { memo } from 'react'

import { Box } from '../../components/FlexBox'
import Input from '../../components/Input'
import Button from '../../components/Button'
import FormErrorBox from '../../components/FormErrorBox'

import useScrollToError from '../../hooks/useScrollToError'
import InputMask from '../../components/InputMask'

export default memo(({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => {
  useScrollToError({errors, isValid, isSubmitting})

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Input 
          autoComplete='nope' 
          placeholder='Nome' 
          name='name' 
          value={values.name} 
          onChange={handleChange} 
          onBlur={handleBlur} 
        />
        <FormErrorBox 
          fieldName='name' 
          errors={errors} 
          touched={touched} 
        />
      </Box>

      <Box mb={2}>
        <InputMask 
          mask='999.999.999-99' 
          autoComplete='nope' 
          placeholder='CPF' 
          name='cpf' 
          value={values.cpf} 
          onChange={handleChange} 
          onBlur={handleBlur} 
        />
        <FormErrorBox 
          fieldName='cpf' 
          errors={errors} 
          touched={touched} 
        />
      </Box>

      <Box mb={2}>
        <InputMask 
          mask='99/99/9999' 
          autoComplete='nope' 
          placeholder='Data de nascimento' 
          name='birth_date' 
          value={values.birth_date} 
          onChange={handleChange} 
          onBlur={handleBlur} 
        />
        <FormErrorBox 
          fieldName='birth_date' 
          errors={errors} 
          touched={touched} 
        />
      </Box>

      <Box mb={2}>
        <InputMask 
          mask='99/99/9999' 
          autoComplete='nope' 
          placeholder='Data de expiração' 
          name='expiration_date' 
          value={values.expiration_date} 
          onChange={handleChange} 
          onBlur={handleBlur} 
        />
        <FormErrorBox 
          fieldName='expiration_date' 
          errors={errors} 
          touched={touched} 
        />
      </Box>

      <Box ta='center'>
        <Button 
          type='submit' 
          loading={isSubmitting} 
          disabled={!dirty || isSubmitting}
        > 
          Salvar 
        </Button>
      </Box>
    </form>
  )
})
