import camelcaseKeys from 'camelcase-keys'
import React, { memo, useState } from 'react'

import CircularProgress from '../../components/CircularProgress'
import { Box, Flex } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import RadioGroup from '../../components/MyRadioGroup'
import Text from '../../components/Text'

import { useMutation } from '../../hooks/useApollo'
import { UPDATE } from './Gqls'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(initialData)
  const [patient, setPatient] = useState({ alimentation: (data.alimentation && data.alimentation.id) || '', alcohol: (data.alcohol && data.alcohol.id) || '', smoke: (data.smoke && data.smoke.id) || '', drug: (data.drug && data.drug.id) || '', physicalActivity: (data.physicalActivity && data.physicalActivity.id) || '' })


  // const [alimentation, setAlimentation] = useState(data.alimentation.id)
  // const [birthDate, setBirthDate] = useState(data.birthDate)
  // const [email, setEmail] = useState(data.login)
  // const [cpf, setCpf] = useState(data.cpf)
  // const [gender, setGender] = useState(data.gender)
  // const [phone, setPhone] = useState(data.cellphone)

  const updateItem = useMutation(UPDATE)

  const fetchData = async () => {
    setLoading(true)
    const response = await fetch(`${BASE_API}/patient`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' }})
    const data = await response.json()
    setData(camelcaseKeys(data))
    setLoading(false)
    onUpdated()
  }

  const handleChange = (name, value) => {
    let newPatient = { ...patient, [name]: value }
    setPatient(newPatient)

    updateItem({ variables: { input: newPatient } })
      .then(data => {
        fetchData()
      })
      .catch(error => {
        console.error('error', error)
      })
  }

  if (loading) return <CircularProgress />

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Informações</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={4}>
          <Box>Alimentação</Box>
          <Box>
            <RadioGroup value={patient.alimentation} onChange={value => handleChange('alimentation', value)} options={[{label: 'Não faço dieta', value:  1}, {label: 'Sem Glúten', value:  2}, {label: 'Sem Lactose', value: 3}, {label: 'Vegetariano', value: 4}, {label: 'Ovo Vegetariano', value: 5}, {label: 'Lacto Vegetariano', value: 7}, {label: 'Vegano', value: 8}]} />
          </Box>
        </Box>

        <Box mb={4}>
          <Box>Álcool</Box>
          <Box>
            <RadioGroup value={patient.alcohol} onChange={value => handleChange('alcohol', value)} options={[{label: 'Diariamente', value:  1}, {label: 'Mais de 2 vezes por semana', value:  2}, {label: 'Até 2 vezes por semana', value: 3}, {label: 'Não bebo', value: 4}]} />
          </Box>
        </Box>

        <Box mb={4}>
          <Box>Cigarro</Box>
          <Box>
            <RadioGroup value={patient.smoke} onChange={value => handleChange('smoke', value)} options={[{label: 'Mais de 30 maços por mês', value:  1}, {label: 'Até 30 maços por mês', value:  2}, {label: 'Até 8 maços por mês', value: 3}, {label: 'Até 2 maços por mês', value: 4}, {label: 'Ex-usuário', value: 5}, {label: 'Não sou usuário', value: 6}]} />
          </Box>
        </Box>

        <Box mb={4}>
          <Box>Drogas</Box>
          <Box>
            <RadioGroup value={patient.drug} onChange={value => handleChange('drug', value)} options={[{label: 'Usuário', value:  1}, {label: 'Uso Social', value:  2}, {label: 'Ex-usuário', value: 3}, {label: 'Não sou usuário', value: 4}]} />
          </Box>
        </Box>

        <Box mb={4}>
          <Box>Atividades Físicas</Box>
          <Box>
            <RadioGroup value={patient.physicalActivity} onChange={value => handleChange('physicalActivity', value)} options={[{label: 'Sedentário', value:  1}, {label: 'Atividades Leves', value:  2}, {label: 'Atividades Moderadas', value: 3}, {label: 'Atividades Pesadas', value: 4}]} />
          </Box>
        </Box>

        {/* <Box><Button onClick={handleUpdate}>Salvar</Button></Box> */}
      </Box>
    </Flex>
  )
})
