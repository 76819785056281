import React, { memo } from 'react'
import { Router } from '../../utils/router'
import Layout from '../../layouts/Main'

import Home from './Home'

export default memo(({ me, onLogout, ...rest }) => (
  <Router>
    <Layout me={me} onLogout={onLogout} path='/'>
      <Home {...rest} path='/' />
    </Layout>
  </Router>
))
