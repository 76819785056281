import React, { memo } from 'react'

export default memo(() => {
  return (
    <div style={{padding: 32}}>
<h1>Termos de Serviço e Condições de Clientes TRIA</h1>

<h3>Visão Geral:</h3>
<p>Esse Site/App denominado TRIA é operado por <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong>. <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> oferece esse Site/App, incluindo toda a informação, ferramentas e serviços disponíveis para o usuário condicionada a sua aceitação de todos os termos, condições, políticas e avisos declarados aqui.</p>
<p>Ao visitar nosso Site/App e/ou comprar algo de nós, o usuário se envolve em nosso “Serviço” e concorda estar vinculado pelos seguintes termos e condições (“Termos de Serviço”, “Termos”), incluindo aqueles termos e condições e políticas adicionais referenciados neste documento e/ou disponíveis por hyperlink. Esses Termos de Serviço se aplicam para todos os usuários do Site/App, incluindo, sem limitação, os usuários que são navegadores, fornecedores, clientes, comerciantes, e/ou contribuidores de conteúdo.</p>
<p>Por favor, leia os Termos de Serviço cuidadosamente antes de acessar ou usar o nosso Site/App. Ao acessar ou usar qualquer parte do Site/App, você concorda estar vinculado pelos Termos de Serviço. Se você não concorda com todos os termos e condições do presente acordo, então você não pode acessar o Site/App ou usar quaisquer serviços. Se esses Termos de Serviço são considerados uma oferta, a aceitação é expressamente limitada aos Termos de Serviço.</p>
<p>Reservamos o direito de atualizar, alterar ou trocar qualquer parte descrita nesses Termos de Serviço ao publicar atualizações e/ou alterações ao nosso site. É responsabilidade do usuário verificar essa página periodicamente para alterações. Seu uso contínuo ou acesso ao site após a publicação de quaisquer alterações constitui aceitação dessas alterações.</p>
<h3>Seção 1 - Espaço online</h3>
<p>Ao concordar com esses Termos de Serviço, o usuário afirma que tem pelo menos a maioridade em seu estado ou província de residência e dá o seu consentimento para permitir que qualquer um dos seus dependentes menores de idade usem esse Site/App.</p>
<p>O usuário não deve usar nossos produtos para qualquer fim ilegal ou não autorizado nem pode, no uso do Serviço, violar quaisquer leis em sua jurisdição (incluindo, mas não limitado a leis de direitos autorais).</p>
<p>O usuário não deve transmitir quaisquer vírus ou qualquer código de natureza destrutiva.</p>
<p>Uma quebra ou violação de quaisquer itens dos Termos vai resultar em uma terminação imediata dos seus Serviços.</p>
<h3>Seção 2 - Condições gerais</h3>
<p>Reservamos o direito de recusar o serviço a qualquer um por qualquer motivo a qualquer momento.</p>
<p>O usuário concorda em não reproduzir, duplicar, copiar, vender, revender ou explorar qualquer parte do Serviço, uso do Serviço, ou acesso ao Serviço ou qualquer contato no Site/App através do qual o serviço é fornecido sem permissão expressa escrita por <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong>.</p>
<p>Os títulos usados neste acordo são incluídos para conveniência apenas e não vão limitar ou afetar estes Termos.</p>
<h3>Seção 3 - Precisão, atualidade e integridade das informações</h3>
<p>A <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> não se responsabiliza por informações disponibilizadas no Site/App que não sejam precisas, completas ou atuais. O material no Site/App é fornecido para informações gerais apenas e não deveria ser confiado ou usado como a única base para a tomada de decisões sem a consulta prévia de fontes de informações primárias, mais precisas, mais completas ou mais atuais. Qualquer confiança no material do Site/App é por sua conta e risco.</p>
<p>O Site/App pode conter certas informações históricas. Informações históricas, necessariamente, não são atuais e são fornecidas para sua referência apenas. Reservamos o direito de modificar os conteúdos do Site/App a qualquer momento, mas a <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> não tem obrigação de atualizar quaisquer informações em nosso Site/App. O usuário concorda que é sua responsabilidade monitorar alterações em nossa plataforma.</p>
<h3>Seção 4 - Contas Pagas e Reembolso</h3>
<p>A <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> oferece o aumento do espaço de armazenamento, adicionando outros recursos pagos à conta do usuário (tornando-a uma "Conta Paga"). O faturamento automático será feito a partir da data em que o usuário converter sua conta para uma Conta Paga e em cada renovação periódica até o cancelamento. Ao assinar um plano anual, será enviado, dentro de um período razoável antes da data de renovação, um e‑mail de notificação lembrando que o plano está prestes a ser renovado.</p>
<p>A Conta Paga do Cliente irá permanecer efetiva até ser cancelada ou encerrada ao abrigo destes Termos. Caso este não pague por sua Conta no prazo, nós nos reservamos o direito de suspendê-la ou de reduzir o armazenamento para níveis de espaço gratuito.</p>
<p>Não há reembolsos. O Cliente pode cancelar sua Conta Paga do TRIA a qualquer momento. Reembolsos somente são emitidos se exigidos por lei.</p>
<p>Desta forma, tem-se que:</p>
<ol>
<li>Tarifas. O Cliente pagará à TRIA todas as tarifas aplicáveis pelos Serviços, na moeda indicada no formulário do pedido. O Cliente autoriza a TRIA a cobrar todas as tarifas aplicáveis usando o método de pagamento selecionado pelo Cliente. As tarifas não são reembolsáveis, exceto se exigido por lei, ou de outra forma especificamente permitido por estes Termos.</li>
<li>Pagamento. O Cliente pagará ao TRIA faturas no intervalo de pagamento definido no formulário de pedido. A TRIA pode suspender ou encerrar os Serviços se as tarifas estiverem com atraso. O Cliente fornecerá ao TRIA informações completas e precisas de faturamento e contato.</li>
<li>Impostos. Tarifas não incluem impostos. O Cliente é responsável por todos os Impostos. A TRIA cobrará Impostos quando for obrigado a fazê-lo. Caso o Cliente forneça à TRIA certificados válidos de isenção, a TRIA não coletará impostos cobertos pela certificação.</li>
<li>Renovações automáticas e períodos de avaliação. SE A CONTA DO CLIENTE ESTIVER DEFINIDA PARA RENOVAÇÃO AUTOMÁTICA OU ESTIVER EM UM PERÍODO DE AVALIAÇÃO E O CLIENTE JÁ FORNECEU UM MÉTODO DE PAGAMENTO PELOS SERVIÇOS, A TRIA PODERÁ COBRAR AUTOMATICAMENTE NO FINAL DA AVALIAÇÃO OU PELA RENOVAÇÃO, A MENOS QUE O CLIENTE NOTIFIQUE A TRIA QUE DESEJA CANCELAR OU DESATIVAR A RENOVAÇÃO AUTOMÁTICA.</li>
</ol>
<h3>Seção 5 - Modificações ao serviço e preços</h3>
<p>Preços para os produtos da <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> são sujeitos a alteração sem notificação.</p>
<p>Reservamos o direito de, a qualquer momento, modificar ou descontinuar o Serviço (qualquer parte ou conteúdo do mesmo) sem notificação a qualquer momento.</p>
<p>Nós não seremos responsabilizados por usuários ou qualquer terceiro por qualquer modificação, alteração de preço, suspensão ou descontinuação do Serviço.</p>
<h3>Seção 6 - Ferramentas opcionais</h3>
<p>A <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> pode fornecer ao usuário acesso a ferramentas de terceiros as quais não monitoramos nem temos qualquer controle nem entrada.</p>
<p>O usuário reconhece e concorda que a <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> fornece acesso a tais ferramentas ”como elas são” e “conforme disponíveis” sem quaisquer garantias, representações ou condições de qualquer tipo. A <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> não tem nenhuma responsabilidade, qualquer que seja, decorrente ou relacionada ao seu uso de ferramentas opcionais de terceiros.</p>
<p>Qualquer uso pelo usuário de ferramentas opcionais oferecidas através do Site/App é inteiramente por sua conta e risco e o usuário deve garantir que aprova os termos em que as ferramentas são fornecidas pelos relevantes fornecedor(es) terceiro(s).</p>
<p>A <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> pode também, no futuro, oferecer novos serviços e/ou recursos através do Site/App (incluindo o lançamento de novas ferramentas e recursos). Tais recursos novos e/ou serviços devem também estar sujeitos a esses Termos de Serviço.</p>
<h3>Seção 7 - Links de terceiros</h3>
<p>Certos produtos, conteúdos e serviços disponíveis via nosso Serviço podem incluir materiais de terceiros.</p>
<p>Links de terceiros no Site/App podem direcionar você para sites de terceiros que não são afiliados conosco. A <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> não é responsável por examinar, avaliar o conteúdo ou a precisão dos mesmos, e não garantimos, nem teremos obrigação ou responsabilidade por quaisquer materiais ou sites de terceiros, ou por quaisquer outros materiais, produtos, ou serviços de terceiros.</p>
<p>A <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> não é responsável por quaisquer danos ou prejuízos relacionados com a compra ou uso de mercadorias, serviços, recursos, conteúdo, ou quaisquer outras transações feitas em conexão com quaisquer sites de terceiros. Por favor, revise com cuidado as políticas e práticas dos terceiros e certifique-se que você as entenda antes de interagir em qualquer transação. Queixas, reclamações, preocupações ou questões relativas a produtos de terceiros devem ser direcionadas ao terceiro.</p>
<h3>Seção 8 - Informações pessoais</h3>
<p>A submissão de suas informações pessoais através da loja é regida pela nossa Política de Privacidade.</p>
<h3>Seção 9 - Erros, imprecisões e omissões</h3>
<p>Ocasionalmente, pode haver informações no nosso Site/App ou no Serviço que contém erros tipográficos, imprecisões ou omissões que possam relacionar-se a descrições de produtos, preços, promoções, ofertas, taxas de envio do produto, os tempos de transporte e disponibilidade. Reservamos o direito de corrigir quaisquer erros, imprecisões ou omissões, e de alterar ou atualizar informações ou cancelar encomendas se qualquer informação no Serviço ou em qualquer site relacionado é imprecisa, a qualquer momento e sem aviso prévio.</p>
<p>Não assumimos nenhuma obrigação de atualizar, alterar ou esclarecer informações no Serviço ou em qualquer site relacionado, incluindo, sem limitação, a informações sobre preços, exceto conforme exigido por lei. Nenhuma atualização especificada ou data de atualização aplicada no Serviço ou em qualquer site relacionado devem ser tomadas para indicar que todas as informações no Serviço ou em qualquer site relacionado tenha sido modificada ou atualizada.</p>
<h3>Seção 10 - Usos proibidos</h3>
<p>Além de outras proibições, conforme estabelecido nos Termos de Serviço, o usuário está proibido de usar o site ou o seu conteúdo: (a) para qualquer finalidade ilegal; (b) para solicitar outros a realizar ou participar de quaisquer atos ilícitos; (c) violar quaisquer regulamentos internacionais, provinciais, estaduais ou federais, regras, leis ou regulamentos locais; (d) infringir ou violar os nossos direitos de propriedade intelectual ou os direitos de propriedade intelectual de terceiros; (e) para assediar, abusar, insultar, danificar, difamar, caluniar, depreciar, intimidar ou discriminar com base em gênero, orientação sexual, religião, etnia, raça, idade, origem nacional ou deficiência; (f) apresentar informações falsas ou enganosas; (g) para fazer o envio ou transmitir vírus ou qualquer outro tipo de código malicioso que será ou poderá ser utilizado em qualquer forma que afete a funcionalidade ou operação do Serviço ou de qualquer site relacionado, outros sites, ou da Internet; (h) coletar ou rastrear as informações pessoais de outras pessoas; (i) para fazer spam, phishing, pharm, pretexto, spider, crawl, ou scrape; (j) para qualquer finalidade obscena ou imoral; ou (k) para interferir ou contornar os recursos de segurança do Serviço ou de qualquer site relacionado, outros sites, ou a Internet. Reservamos o direito de terminar o seu uso do Serviço ou de qualquer site relacionado por violar qualquer dos usos proibidos.</p>
<h3>Seção 11 - Isenção de responsabilidade e garantias</h3>
<p>A <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> não garante ou justifica que o uso do nosso serviço não será interrompido, pontual, seguro ou livre de erros.</p>
<p>O usuário concorda que de tempos em tempos, a <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> pode remover o serviço por períodos indefinidos de tempo ou cancelar a qualquer momento, sem notificação para o usuário.</p>
<p>O usuário expressamente concorda que o seu uso de, ou falta de habilidade de usar, o serviço é por sua conta e risco. O serviço e todos os produtos e serviços entregues para o usuário através do serviço são (exceto como expressamente declarado por nós) fornecidos 'como são' e 'conforme disponível' para seu uso, sem qualquer representação, garantias ou condições de qualquer tipo, expressa ou implícita, incluindo todas as garantias implícitas ou condições de comercialidade, quantidade negociável, adequação a uma finalidade específica, durabilidade, título, e não violação.</p>
<p>Em nenhum caso devem a <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong>, nossos diretores, oficiais, funcionários, afiliados, agentes, contratantes, estagiários, fornecedores, fornecedores de serviço ou licenciadores ser responsáveis por qualquer prejuízo, perda, reclamação ou danos diretos, indiretos, incidentais, punitivos, especiais ou consequentes de qualquer tipo, incluindo, sem limitação, lucros cessantes, perda de receitas, poupanças perdidas, perda de dados, custos de reposição, ou quaisquer danos semelhantes, seja com base em contrato, ato ilícito (incluindo negligência), responsabilidade objetiva ou de outra forma, decorrentes do seu uso de qualquer um dos serviços ou quaisquer produtos adquiridos usando o serviço, ou para qualquer outra reclamação relacionada de alguma forma para o seu uso do serviço ou qualquer produto, incluindo, mas não limitado a, quaisquer erros ou omissões em qualquer conteúdo, ou qualquer perda ou dano de qualquer tipo incorridos como resultado do uso do serviço ou qualquer conteúdo (ou produto) publicado, transmitido ou de outra forma disponível através do serviço, mesmo se avisados ​​de sua possibilidade. Como alguns estados ou jurisdições não permitem a exclusão ou a limitação de responsabilidade por danos consequentes ou incidentais, em tais estados ou jurisdições, a nossa responsabilidade será limitada à extensão máxima permitida por lei.</p>
<h3>Seção 12 - Indenização</h3>
<p>O usuário concorda em indenizar, defender e isentar <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> e nossas subsidiárias, afiliadas, parceiros, funcionários, diretores, agentes, contratados, licenciadores, fornecedores de serviços, subcontratados, fornecedores, estagiários e funcionários, de qualquer reclamação ou demanda, incluindo honorários razoáveis de advogados, feitos por quaisquer terceiros devido a resultantes de sua violação destes Termos de Serviço ou os documentos que incorporam por referência, ou a violação de qualquer lei ou os direitos de um terceiro.</p>
<h3>Seção 13 - Independência</h3>
<p>No caso de qualquer disposição destes Termos de Serviço for considerada ilegal, nula ou ineficaz, tal disposição deve, contudo, ser aplicável até ao limite máximo permitido pela lei aplicável, e a porção inexequível será considerada separada desses Termos de Serviço, tal determinação não prejudica a validade e aplicabilidade de quaisquer outras disposições restantes.</p>
<h3>Seção 14 - Rescisão</h3>
<p>As obrigações e responsabilidades das partes incorridas antes da data de rescisão devem sobreviver a rescisão deste acordo para todos os fins.</p>
<p>Esses Termos de Serviço são efetivos, a menos e até que seja rescindido pelo usuário ou pela <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong>. O usuário pode rescindir estes Termos de Serviço a qualquer momento, notificando-nos que já não deseja utilizar os nossos serviços, ou quando você deixar de usar o nosso site.</p>
<p>Se em nosso critério exclusivo o usuário falhar, ou nós suspeitarmos que o usuário falhou, em cumprir com qualquer termo ou disposição destes Termos de Serviço, a <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> também pode rescindir este contrato a qualquer momento sem aviso prévio e o usuário permanecerá responsável por todas as quantias devidas até e incluindo a data da rescisão; e/ou em conformidade, podemos lhe negar acesso a nossos Serviços (ou qualquer parte dele).</p>
<h3>Seção 15 - Acordo integral</h3>
<p>A falha da <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> em exercer ou executar qualquer direito ou disposição destes Termos de Serviço não constituirá uma renúncia de tal direito ou disposição.</p>
<p>Esses Termos de Serviço e quaisquer políticas ou normas operacionais postadas pela <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> neste Site/App ou no que diz respeito ao serviço constitui a totalidade do acordo e entendimento entre o usuário e <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> e regem o uso do Serviço, substituindo quaisquer acordos anteriores ou contemporâneos, comunicações e propostas, seja oral ou escrita, entre você e nós (incluindo, mas não limitado a quaisquer versões anteriores dos Termos de Serviço).</p>
<p>Quaisquer ambiguidades na interpretação destes Termos de Serviço não devem ser interpretadas contra a parte que a elaborou.</p>
<h3>Seção 16 - Legislação aplicável</h3>
<p>Esses Termos de Serviço e quaisquer acordos separados em que a <strong>DOCPAD SERVIÇOS DE INFORMÁTICA LTDA</strong> forneça os Serviços devem ser regidos por e interpretados de acordo com as leis em vigor no estado do Rio de Janeiro, Brasil.</p>
<h3>Seção 17 - Alterações aos Termos de Serviço</h3>
<p>O usuário pode revisar a versão mais atual dos Termos de Serviço a qualquer momento nessa página.</p>
<p>Reservamos o direito, a nosso critério, de atualizar, modificar ou substituir qualquer parte destes Termos de Serviço ao publicar atualizações e alterações no nosso site. É responsabilidade do usuário verificar nosso Site/App periodicamente. Seu uso continuado ou acesso ao nosso site ou ao Serviço após a publicação de quaisquer alterações a estes Termos de Serviço constitui aceitação dessas alterações.</p>
<h3>Seção 18 - Informação de contato</h3>
<p>Perguntas sobre os Termos de Serviço devem ser enviadas para nós em suporte@tria.health.</p>

    </div>
  )
})
