import gql from 'graphql-tag'

export const ME_ESTABLISHMENT = gql`
  query {
    establishment @rest(type: "Establishment", path: "/establishment") {
      id
      name
      description
      website
      phone
      workingTime
      whatsapp
      registerDate
      login
      thumbnail
      addresses
    }
  }
`

export const LIST = gql`
  query {
    doctors @rest(type: "[Doctor]", path: "/doctorEstablishment") {
      id
      createdAt
      name
      crm
    }
    invites @rest(type: "[Invite]", path: "/invite") {
      id
      email
      status
      establishment
    }
  }
`

// export const SEARCH_DOCTORS = gql`
//   query {
//     doctors @rest(type: "[SearchDoctor]", path: "/searchDoctor?{args}") {
//       id
//       createdAt
//       name
//     }
//   }
// `

export const SEARCH_DOCTORS = gql`
  query ($q: Search!) {
    doctors(q: $q) @rest(type: "[SearchDoctor]", path: "/searchDoctor?{args}") {
      id
      name
    }
  }
`

export const UPDATE = gql`
  mutation ($input: Establishment!) {
    media(input: $input) @rest(type: "Establishment", path: "/establishment/", method: "PUT", bodySerializer: "form") {
      NoResponse
    }
  }
`

export const INVITE = gql`
  mutation ($input: Invite!) {
    invite(input: $input) @rest(type: "Invite", path: "/invite/", method: "POST", bodySerializer: "form") {
      worked
    }
  }
`

export const LINK_DOCTOR = gql`
  mutation ($input: LinkDoctor!) {
    linkDoctor(input: $input) @rest(type: "LinkDoctor", path: "/doctorEstablishment/", method: "POST", bodySerializer: "form") {
      worked
    }
  }
`
