import React, { memo, useEffect, useState } from 'react'

import CircularProgress from '../../components/CircularProgress'
import { Box, Flex } from '../../components/FlexBox'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Text from '../../components/Text'

import { useQuery } from '../../hooks/useApollo'
import { LIST } from './Gqls'

import Paginator from "../../components/Paginator"
import { serialize } from '../../utils/utils'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

const List = memo(({ patients, navigate }) => {

  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('name') // id 
  
  const [activePage, setActivePage] = useState(1)

  const [results, setResults] = useState(patients.results)
  const [count, setCount] = useState(patients.count)

  const pageSize = 10

  useEffect(() => {
    async function fetchData() {
      const params = {
        'page': activePage,
        'pageSize': pageSize,
        'order_by': sortBy,
      }
      if(search != '')
        params.name = search
      const data = await (await fetch(`${BASE_API}/getPatientByEstablishment/?${serialize(params)}`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
      setResults(data.results)
      setCount(data.count)
    }
    fetchData()
  }, [activePage, search, sortBy])

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber)
  }

  const handleSearch = async (query) => {
    setActivePage(1)
    setSearch(query)
  }

  const handleSort = async (value) => {
    setActivePage(1)
    setSortBy(value)
  }

  return (
    <Box>

      <Box mb={3}>
        <Text fw={600}>Pacientes</Text>
      </Box>

      <Flex>
        <Box mr={3}>
          <Input placeholder='Buscar...' value={search} onChange={e => handleSearch(e.target.value)} />
        </Box>
        <Box>
          <Select value={sortBy} onChange={e => handleSort(e.target.value)}>
            <option value='name'>Ordenado por Nome</option>
            <option value='id'>Ordenado por Data de Criação</option>
          </Select>
        </Box>
      </Flex>

      {results.map(item => (
        <Flex jc='space-between' ai='center' key={item.id} p={3} style={{borderBottom: '1px solid lightgrey'}}>
          <Box>
            {item.name}
          </Box>
          <Box ml={3}>
            <Text fs={12}>{item.gender}</Text>
            {/* <ButtonRaw onClick={() => { window.confirm('Você tem certeza que deseja excluir este paciente?') }}><DeleteIcon /></ButtonRaw> */}
          </Box>
        </Flex>
      ))}
      <Flex ai={'center'}>
        <Paginator 
          activePage={activePage}
          itemsCountPerPage={pageSize}
          totalItemsCount={count}
          onChange={handlePageChange}/> 
      </Flex>
    </Box>
  )
})

export default memo(props => {
  const { data, error, loading } = useQuery(LIST)
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  return <List {...props} patients={data.patients} />
})
