import { Modal } from 'antd';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Flex } from '../components/FlexBox';
import { CloseIcon } from '../components/Icons';
import { BtnPink, Btns, FormGroup, FormModal, ModalBox, ModalHeader } from '../containers/Prescription/styles';
import './style.css';

import BirdId from "./signaturesImages/birdId.png";

const Signatures = styled.div`
    h2 {
        font-size: 16px;
        font-weight: 500;
    }
    ul.list {
        margin: 0;
        margin-top: 10px;
        padding: 0;
        
        li {
            list-style: none;
            padding: 11px 21px;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;
            cursor: pointer;
            opacity: 0.5;
            transition: all 0.3s ease-in-out;
            &:hover {
                opacity: 1;
            }
            &+li {
                margin-top: 10px;
            }
            img {
                height: 30px;
            }
        }
    }
`

export default function SignatureModal() {
    const [modal, setModal] = useState(false);
    const [page, setPage] = useState(1);
    const [signatures, setSignatures] = useState(false);
    const [sigSelected, setSigSelected] = useState(false);
    const [formValues, setFormValues] = useState({
        expireIn: "12",
        signatureId: "",
        token: ""
    });

    const showSignatureModal = () => setModal(true);
    const handleOk = () => {
        localStorage.setItem("showSignature", false);
        setPage(1);
        setModal(false);
    };
    const handleCancel = () => {
        localStorage.setItem("showSignature", false);
        setPage(1);
        setModal(false);
    };

    window.showSignatureModal = showSignatureModal;

    window.checkSignature = () => {
        const signature = localStorage.getItem("signatureId");
        if (signature) {
            const signatureExpires = localStorage.getItem("signatureExpires");
            const now = new Date();
            const signatureExpiresDate = new Date(parseInt(signatureExpires));
            const diff = signatureExpiresDate - now;
            if(diff < 0) {
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    function selectSignature(i) {
        const sig = signatures[i];
        setSigSelected(sig);
        setFormValues({
            ...formValues,
            signatureId: sig.id
        });
        setPage(2);
    }

    function handleSubmit(e) {
        e.preventDefault();

        const hours = parseInt(formValues.expireIn);
        const now = new Date();
        now.setHours(now.getHours() + hours);

        localStorage.setItem("showSignature", false);
        localStorage.setItem("signatureId", formValues.signatureId);
        localStorage.setItem("signatureToken", formValues.token);
        localStorage.setItem("signatureExpires", now.getTime());
        window.setCheckSignature && window.setCheckSignature();
        setPage(3);
    }
    function handleChangeForm(e) {
        const value = e.target.value;
        const name = e.target.name;
        setFormValues({
            ...formValues,
            [name]: value
        });
    }

    function getExpiredTokenHours(hours) {
        hours = parseInt(hours);
        const now = new Date();
        now.setHours(now.getHours() + hours);
        return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
    }


    useEffect(() => {
        setSignatures([
            {
                imageUrl: BirdId,
                id: "647.7983.617",
            },
            {
                imageUrl: BirdId,
                id: "345.5983.485",
            },
            {
                imageUrl: BirdId,
                id: "498.3465.362",
            },
            {
                imageUrl: BirdId,
                id: "798.5433.238",
            }
        ]);

        const show = localStorage.getItem("showSignature");

        if (show === null || show === true) {
            window.showSignatureModal()
        }
    }, []);

    return (
        <>
            <Modal open={modal} onOk={handleOk} onCancel={handleCancel}>
                <ModalBox>
                    {page === 1 && <>
                        <ModalHeader>
                            <h1>Assinatura Digital</h1>
                            <Box cursor='pointer' onClick={handleCancel}><CloseIcon /></Box>
                        </ModalHeader>
                        <Box w="100%">
                            <p className="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirm</p>
                            <Signatures>
                                <h2>Certificados Integrados</h2>
                                <ul className='list'>
                                    {signatures && signatures.map((signature, i) => (
                                        <li onClick={() => selectSignature(i)} key={signature.id}>
                                            <img src={signature.imageUrl} alt={`Assinatura ${i}`} />
                                        </li>
                                    ))}
                                </ul>
                            </Signatures>
                        </Box>
                    </>}
                    {page === 2 && <>
                        <Flex w="100%" mb={"30px"} jc="space-between">
                            <img src={sigSelected.imageUrl} alt="assinatura selecionada" />
                            <Box cursor='pointer' onClick={handleCancel}><CloseIcon /></Box>
                        </Flex>
                        <ModalHeader>
                            <h1>Assinatura Digital</h1>
                        </ModalHeader>
                        <Box w="100%">
                            <p className="description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirm</p>
                            <Signatures>
                                <h2>Certificados Integrados</h2>
                                <FormModal onSubmit={handleSubmit}>
                                    <FormGroup>
                                        <select id="expireIn" value={formValues.expireIn} onChange={handleChangeForm} name="expireIn">
                                            <option value="12">Expirar Login em 12 horas</option>
                                            <option value="6">Expirar Login em 6 horas</option>
                                            <option value="2">Expirar Login em 2 horas</option>
                                        </select>
                                    </FormGroup>
                                    <FormGroup>
                                        <input value={formValues.signatureId} onChange={handleChangeForm} readOnly={true} required id="signatureId" name="signatureId" type="tel" />
                                    </FormGroup>
                                    <FormGroup>
                                        <input value={formValues.token} onChange={handleChangeForm} required id="token" name="token" type="text" placeholder="Token" />
                                    </FormGroup>
                                    <Btns>
                                        <BtnPink width="100%" type="submit">Gerar</BtnPink>
                                    </Btns>
                                </FormModal>
                            </Signatures>
                        </Box>
                    </>}
                    {page === 3 && <Box text-align="center">
                        <ModalHeader>
                            <h1 text="center">Assinatura Digital</h1>
                        </ModalHeader>
                        <Box w="100%">
                            <p className="description" text="center">Seu token expira em {getExpiredTokenHours(formValues.expireIn)}</p>
                            <Btns>
                                <BtnPink width="100%" onClick={handleOk}>Ok</BtnPink>
                            </Btns>
                        </Box>
                    </Box>}
                </ModalBox>
            </Modal>
        </>
    )
}