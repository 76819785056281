import { Button, Select } from 'antd';
import styled from 'styled-components'

export const Content = styled.div`
  padding: 32px;
  min-height: calc(100vh - 92px - 254px);
  display: flex;
  flex-direction: column;
`

export const SubmitBtn = styled(Button)`
  width: 100%;
  max-width: 130px;
  margin-top: 1rem;
`;

export const SelectStyled = styled(Select)`
  max-width: 320px;
  width: 100%;
`;

export const Container = styled.div`
display: flex;
align-items: center;
justify-content: center;
margin: 5rem 0;

.upload-container {
  cursor: not-allowed;
}

 
  input[type="file"] {
    display: none;
}
.custom-file-upload {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 3px dotted #ccc;
    padding: 50px 50px;
    cursor: pointer;
    font-size: 1.5rem;
    border-radius: 15px;
    transition: 0.2s opacity;
    opacity: 0.7;

    &:hover {
      opacity: 0.5;
    }

    svg {
      margin-bottom: 1rem;
    }
}
`;
