import moment from "moment";

export const FormatDate = (props) => {
  if (!props.children || props.children === "") return "";
  return moment(props.children, "YYYY-MM-DD").format("DD/MM/YY");
};

export const FormatDateTime = (props) => {
  if (!props.children || props.children === "") return "";
  return moment(props.children, "YYYY-MM-DDTHH:mm:ss.000Z").format(
    "DD/MM/YY HH:mm"
  );
};
