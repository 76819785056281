import React, { memo, useState } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import { CloseIcon } from '../../components/Icons'
import Input from '../../components/Input'
import InputDate from '../../components/InputDate'
import InputCpf from '../../components/InputCpf'
import Select from '../../components/Select'
// import InputCpf from '../../components/InputCpf'
import InputPhone from '../../components/InputPhone'
import Button from '../../components/Button'
import CircularProgress from '../../components/CircularProgress'

import { useMutation } from '../../hooks/useApollo'
import { UPDATE } from './Gqls'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(initialData)

  const [name, setName] = useState(data.name)
  const [birthDate, setBirthDate] = useState(data.birthDate)
  const [cpf, setCpf] = useState(data.cpf)
  // const [email, setEmail] = useState(data.login)
  // const [cpf, setCpf] = useState(data.cpf)
  const [gender, setGender] = useState(data.gender)
  const [phone, setPhone] = useState(data.cellphone)

  const updateItem = useMutation(UPDATE)

  const fetchData = async () => {
    setLoading(true)
    const response = await fetch(`${BASE_API}/patient/?storage=false`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' }})
    const data = await response.json()
    setData(data)
    setLoading(false)
    onUpdated()
  }

  const handleUpdate = () => {
    setLoading(true)
    updateItem({ variables: { input: { name, gender, cpf, cellphone: phone, birthDate } } })
      .then(data => {
         fetchData()
      })
      .catch(error => {
        setLoading(false)
        if(error.networkError && error.networkError.result.detail)
          alert(error.networkError.result.detail)
      })
  }

  if (loading) return <CircularProgress />

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Informações</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Box mb={3}>
          <Box>Nome</Box>
          <Box><Input value={name} onChange={e => setName(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Data de Nascimento</Box>
          <Box><InputDate value={birthDate} onChange={(e, value) => setBirthDate(value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>CPF</Box>
          <Box><InputCpf value={cpf} onChange={(e, value, maskedValue) => {setCpf(maskedValue); console.log(cpf)}} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Gênero</Box>
          <Select placeholder='Selecione...' name='gender' value={gender} onChange={e => setGender(e.target.value)}>
            <option value='Masculino'>Masculino</option><option value='Feminino'>Feminino</option>
          </Select>
        </Box>

        <Box mb={3}>
          <Box>Número de celular</Box>
          <Box><InputPhone value={phone} onChange={e => setPhone(e.target.value)} /></Box>
        </Box>

        <Box><Button onClick={handleUpdate}>Salvar</Button></Box>
      </Box>
    </Flex>
  )
})
