import React, { useState } from 'react'

import Button from '../../components/Button'
import { Box, Flex } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import Input from '../../components/Input'
import Text from '../../components/Text'


import ListDoctor from './ListDoctor'

export default ({ me, onUpdated, onRequestClose, initialData }) => {

  const [query, setQuery] = useState('')
  const [q, setQ] = useState('')

  
  // const updateItem = useMutation(UPDATE)

  const handleSearch = e => {
    e.preventDefault()
    console.log('query', query)
    setQ(query)


    // updateItem({ variables: { input: { name, gender, phone, price, description } } })
    //   .then(data => {
    //     onUpdated()
    //   })
    //   .catch(error => {
    //     console.error('error', error)
    //   })
  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Adicionar Médico</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <form onSubmit={handleSearch}>
        <Box mb={2}>
          <Box>Pesquisar médico por nome ou CRM</Box>
          <Box><Input value={query} onChange={e => setQuery(e.target.value)} /></Box>
        </Box>
        <Box><Button type='submit' small>Pesquisar</Button></Box>
        </form>
        <ListDoctor onUpdated={onUpdated} me={me} query={q} />
      </Box>
    </Flex>
  )
}
