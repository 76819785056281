import React, { memo, useState } from 'react'
import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import useFetch from 'use-http'
import CircularProgress from '../../components/CircularProgress'
import { FormatDate } from '../../components/Format'
import H1 from '../../components/H1'
import Modal from '../../components/StyledModal'
import { CloseIcon } from '../../components/Icons'

import { Formik } from 'formik'
import { css } from 'styled-components/macro'
import Form from './Form'
import * as Yup from 'yup'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const Home = memo(({ data, creditCardData, subscriptionData }) => {

  const patientSubscription = data.subscription
  const subscription = subscriptionData[0]


  const [changeCardModalOpen, setChangeCardModalOpen] = useState(false)

  const initialValues = {
    cardNumber: '',
    cardName: '',
    cardExpiration: '',
    cardCvv: ''
  }

  const paymentValidationSchema = Yup.object().shape({
    cardNumber: Yup.string().required('Obrigatório'),
    cardName: Yup.string().required('Obrigatório'),
    cardExpiration: Yup.string().required('Obrigatório'),
    cardCvv: Yup.string().required('Obrigatório'),
  })

  let creditCard = null
  if (creditCardData.length > 0) creditCard = creditCardData[0]

  const handleSubmit = async variables => {

    const formData = new FormData()
    formData.append('card_holder_name', variables.cardName)
    formData.append('card_expiration_date', variables.cardExpiration.replace('/', ''))
    formData.append('card_number', variables.cardNumber.replace(/\s/g, ''))
    formData.append('card_cvv', variables.cardCvv)

    if (creditCard) {
      await (await fetch(`${BASE_API}/creditCard/${creditCard.id}/`, { method: 'DELETE', headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
    }

    const creditCardData = await (await fetch(`${BASE_API}/creditCard/`, { method: 'POST', body: formData, headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
    if (!creditCardData.valid) return alert('Erro ao processar o cartão.')

    const cardId = creditCardData.id

    const subscriptionFormData = new FormData()

    subscriptionFormData.append('plan', 2)
    subscriptionFormData.append('credit_card', cardId)

    const subscriptionData = await (await fetch(`${BASE_API}/subscription/${subscription.id}/`, { method: 'PUT', body: subscriptionFormData, headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
    alert('Plano cancelado com sucesso.')
    window.location.href = `/meu-plano`

  }

  const handleCancelSubscription = async () => {
    await fetch(`${BASE_API}/subscription/${subscription.id}/`, { method: 'DELETE', headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
    if (creditCard && creditCard.id) {
      await fetch(`${BASE_API}/creditCard/${creditCard.id}/`, { method: 'DELETE', headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
    }
    alert('Renovação automática cancelada com sucesso.')
    window.location.href = `/meu-plano`
  }

  if (!subscription) {
    return (<Box p={4}>
      <H1>Meu plano</H1>
      <Box ta='center' mt={4} style={{border: '1px solid hsla(0,0%,93%,1)'}} bg='hsla(211, 20%, 92%, 1)' p={4}>
        <Box mb={3}><Text fw={700} medium>Você não está inscrito em nunhum plano</Text></Box>
      </Box>
    </Box>)
  }


  return (
    <Box p={4}>
      <H1>Meu plano</H1>


      <Box ta='center' mt={4} style={{border: '1px solid hsla(0,0%,93%,1)'}} bg='hsla(211, 20%, 92%, 1)' p={4}>
        <Box mb={3}><Text fw={700} medium>{patientSubscription.plan.name}</Text></Box>
        <Box><Text fw={400} medium>{patientSubscription.plan.size}{patientSubscription.plan.size_type}</Text></Box>
        <Box>
        Guarde exames, laudos, prescrições, entre outros<br />
        Assinatura anual<br />
        R$ 9,15/mês
        </Box>
      </Box>

      <Box style={{border: '1px solid hsla(0,0%,93%,1)'}} bg='white' p={4} pb={5}>
        <Box mb={5} mt={2}><Text fw={700} medium>Pagamento</Text></Box>

        <Flex jc='space-between' maxWidth={800}>
          <Box>
            <Box><Text fw={400}>Cobrança realizada no dia <Text fw={700}><FormatDate>{subscription.current_period_start}</FormatDate></Text></Text></Box>

            {subscription.status === 'canceled' ? (
              <Box><Text fw={400}>Seu plano atual tem validade até <Text fw={700}><FormatDate>{subscription.current_period_end}</FormatDate></Text></Text></Box>
            ) : (
              <Box><Text fw={400}>Sua próxima cobrança será no dia <Text fw={700}><FormatDate>{subscription.current_period_end}</FormatDate></Text></Text></Box>
            )}
            
          </Box>

          {creditCard &&
            <Box ml={6} pl={5} style={{borderLeft: '1px solid hsla(211,20%,43%,1)'}}>
              <Box><Text fw={700}>Dados do cartão:</Text></Box>
              <Box><Text fw={400}>Bandeira <Text fw={700}>{creditCard.brand.toUpperCase()}</Text></Text></Box>
              <Box><Text fw={400}>Nome <Text fw={700}>{creditCard.holder_name.toUpperCase()}</Text></Text></Box>
              <Box><Text fw={400}>Número <Text fw={700}>**** **** **** {creditCard.last_digits}</Text></Text></Box>
              <Box><Text fw={400}>Venc. <Text fw={700}>{creditCard.expiration_date}</Text></Text></Box>
            </Box>
          }

        </Flex>

        <Box pt={3}>

          {creditCard &&
            <Box>
              <Text pointer underline onClick={() => setChangeCardModalOpen(true)}>Alterar cartão</Text>
            </Box>          
          }

          {subscription.status === 'canceled' ? (
            <Box mt={2}>
              <Box css={css`border: 1px solid red; display: inline-block; padding: 4px 12px; border-radius: 17px; font-size: 13px; color: red; font-weight: 600;`}>Renovação automática cancelada</Box>
            </Box>
          ) : (
            <Box>
              <Text pointer underline onClick={() => handleCancelSubscription()}>Cancelar próxima renovação de assinatura</Text>
            </Box>
          )}

        </Box>
        
      </Box>


      {changeCardModalOpen &&
        <Modal
          onCloseCompleted={() => { setChangeCardModalOpen(false) }}
          render={
            ({onRequestClose}) => (
              <Flex fd='column' h='100%'>
                <Box position='relative' h={64}>
                  <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
                  <Box p={3} ta='center'><Text secondary large>Alterar cartão</Text></Box>
                </Box>
                <Box p={4} overflow='auto'>
                  <Box>
                    {/* <img src={examImageUrl} style={{width: '100%'}} /> */}

                    {/* <Flex mb={4} jc='center'>
                      <Box mx={2} css={css`background-color: hsla(199,75%,57%,1); color: white; font-size: 10px; text-transform: uppercase; padding: 5px 8px; display: inline-block; line-height: 13px; font-weight: 700; border-radius: 10px;`}>Recebido</Box>
                      {exam.exams[0].visualized && exam.exams[0].visualized > 0 &&
                        <Box mx={2} css={css`background-color: hsla(199,75%,57%,1); color: white; font-size: 10px; text-transform: uppercase; padding: 5px 8px; display: inline-block; line-height: 13px; font-weight: 700; border-radius: 10px;`}>Visualizado</Box>
                      }
                    </Flex> */}

                    <Box mx='auto'>
                      <Formik
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        validationSchema={paymentValidationSchema}
                        render={props => (<Form {...props} />)}
                      />
                    </Box>

                  </Box>
                </Box>
              </Flex>
            )
          }
        />
      }


    </Box>
  )

})

export default memo(props => {
  const { data, error, loading } = useFetch(`${BASE_API}/patient`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } }, [])
  const { data: creditCardData, error: creditCardError, loading: creditCardLoading } = useFetch(`${BASE_API}/creditCard/`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } }, [])
  const { data: subscriptionData, error: subscriptionError, loading: subscriptionLoading } = useFetch(`${BASE_API}/subscription/`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } }, [])
  if (error || creditCardError || subscriptionError) return `Erro! ${error.message}`
  if (!data || !creditCardData || loading || creditCardLoading || subscriptionLoading) return <CircularProgress />
  return <Home data={data} creditCardData={creditCardData} subscriptionData={subscriptionData} />
})
