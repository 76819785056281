import React, { memo, useState, useMemo } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Button from '../../components/Button'
import Modal from '../../components/StyledModal'
import ButtonRaw from '../../components/ButtonRaw'
import { DeleteIcon } from '../../components/Icons'
import CircularProgress from '../../components/CircularProgress'

import LinkDoctor from './LinkDoctor'
import InviteDoctor from './InviteDoctor'

import { useQuery } from '../../hooks/useApollo'
import { LIST } from './Gqls'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const filter = (doctors, search, sortBy) => {
  const filtered = doctors
    .filter(item => {
      let condition = true
      if (condition) condition = search ? item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) : condition
      return condition      
    })
  const ordered = filtered.sort((a, b) => {
    if (a[sortBy] < b[sortBy]) return -1
    if (a[sortBy] > b[sortBy]) return 1
    return 0
  })
  return ordered
}

const List = memo(({ me, doctors, invites, message, client }) => {

  // const [doctors, setDoctors] = useState(defaultDoctors)

  // const fetchData = async () => {
  //   const response = await fetch(`${BASE_API}/doctorEstablishment`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' }})
  //   const data = await response.json()
  //   setDoctors(data)
  // }

  const refetch = async () => await client.query({ query: LIST, fetchPolicy: 'network-only' })

  const removeLinkDoctor = async doctorId => {
    const response = await fetch(`${BASE_API}/doctorEstablishment?doctor_id=${doctorId}`, { method: 'DELETE', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})
    const data = await response.json()
    if (data && data.worked) message('Médico excluído com sucesso!')
    refetch()
    // fetchData()
    // if (data.worked) setAddresses(current => current.filter(item => item.id !== id))
    // onUpdated()
  }
  
  const removeInvite = async id => {
    const response = await fetch(`${BASE_API}/invite?id=${id}`, { method: 'DELETE', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})
    const data = await response.json()
    if (data && data.worked) message('Solicitação excluída com sucesso!')
    refetch()
  }

  const [linkDoctorModalOpen, setLinkDoctorModalOpen] = useState(false)
  const [inviteDoctorModalOpen, setInviteDoctorModalOpen] = useState(false)

  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('name') // id 

  const filtered = useMemo(() => filter(doctors, search, sortBy), [doctors, search, sortBy])

  const handleRemoveLink = doctorId => {
    removeLinkDoctor(doctorId)
  }

  const handleRemoveInvite = id => {
    removeInvite(id)
  }

  return (
    <>
      <Box>

        <Box mb={3}>
          <Text fw={600}>Médicos</Text>
        </Box>

        <Flex>
          <Box mr={3}>
            <Input placeholder='Buscar...' value={search} onChange={e => setSearch(e.target.value)} />
          </Box>
          <Box mr={2}>
            <Select value={sortBy} onChange={e => setSortBy(e.target.value)}>
              <option value='name'>Ordenado por Nome</option>
              <option value='id'>Ordenado por Data de Criação</option>
            </Select>
          </Box>
          <Box mr={2}>
            <Button small onClick={() => setLinkDoctorModalOpen(true)}>Adicionar Médico</Button>
          </Box>
          <Box>
            <Button small onClick={() => setInviteDoctorModalOpen(true)}>Convidar Médico</Button>
          </Box>
        </Flex>

        <Box mb={5}>
          {filtered.map(item => (
            <Flex jc='space-between' ai='center' key={item.id} p={3} style={{borderBottom: '1px solid lightgrey'}}>
              <Box>
                <Box display='inline-block'>{item.name}</Box>
                <Box ml={3} display='inline-block'><Text fs={12} fw={700}>CRM:</Text>: <Text fs={12}>{item.crm}</Text></Box>
              </Box>
              <Box ml={3}>
                <ButtonRaw onClick={() => { if (window.confirm('Você tem certeza que deseja excluir este médico?')) handleRemoveLink(item.id) }}><DeleteIcon /></ButtonRaw>
              </Box>
            </Flex>
          ))}
        </Box>
      

        <Box mb={3}>
          <Text fw={600}>Solicitações Pendentes</Text>
        </Box>

        <Box mb={5}>
          {invites.map(item => (
            <Flex jc='space-between' ai='center' key={item.id} p={3} style={{borderBottom: '1px solid lightgrey'}}>
              <Box>
                {item.email}
              </Box>
              <Box ml={3}>
                <ButtonRaw onClick={() => { if (window.confirm('Você tem certeza que deseja remover a solicitação?')) handleRemoveInvite(item.id) }}><DeleteIcon /></ButtonRaw>
              </Box>
            </Flex>
          ))}
        </Box>

      </Box>

      {linkDoctorModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setLinkDoctorModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <LinkDoctor
                me={me}
                onRequestClose={onRequestClose}
                initialData={{}}
                onUpdated={() => { message('Salvo com sucesso!'); setLinkDoctorModalOpen(false) }}
              />
            )
          }
        />
      }

      {inviteDoctorModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setInviteDoctorModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <InviteDoctor
                onRequestClose={onRequestClose}
                initialData={{}}
                onUpdated={() => { refetch(); message('Convite enviado com sucesso!'); setInviteDoctorModalOpen(false) }}
              />
            )
          }
        />
      }
    </>
  )
})

export default memo(props => {
  const { data, error, loading } = useQuery(LIST)
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  return <List {...props} doctors={data.doctors} invites={data.invites} />
})
