import gql from 'graphql-tag'

export const LIST = gql`
  query {
    patients @rest(type: "Patient", path: "/hcg/?pageSize=10") {
      count
      next
      previous
      results @type(name: "ResultsPatient"){
        id
        created_at
        hcg
        exam @type(name: "ExamHCG"){
          created_at
          created_by_patient
          deleted
          description
          establishment
          exam_date
          folder
          id
          id_sync
          name
          visualized
          visualized_date
          patient @type(name: "PatientHCG"){
            alcohol
            alimentation
            birth_date
            blood_type
            cellphone
            cpf
            created_at
            deleted
            deleted_date
            drug
            ethnicity
            gender
            health_insurance
            health_insurance_card_photo
            id
            name
            notification_enable
            orientation
            other_health_insurance
            physical_activity
            rh_factor
            smoke
            thumbnail
            token_notification
            verified_phone
          }
        }
      }
    }
  }
`
