import React, { memo, useState, useEffect } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import { Table, TableContainer, TableRow, TableCell, TableHeaderRow, TableHeaderCell } from '@alobato/table'
import Text from '../../components/Text'
import Input from '../../components/Input'
import Select from '../../components/Select'
import CircularProgress from '../../components/CircularProgress'
import MyDatePicker from '../../components/DatePicker'

import { useQuery } from '../../hooks/useApollo'
import { LIST } from './Gqls'

import { serialize } from '../../utils/utils'
import Paginator from "../../components/Paginator"

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const formatDateTime = text => {
  if (!text) return ''
  const year = text.slice(0, 4)
  const month = text.slice(5, 7)
  const day = text.slice(8, 10)
  return `${day}/${month}/${year}`
}

const formatHCGColor = value => {
  if (!value) return 'inherit'
  else if(value < 5) return 'red'
  else if(value >= 5 && value < 25) return '#fc0'
  else return 'green'
}

const List = memo(({ patients, navigate }) => {

  const [search, setSearch] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [activePage, setActivePage] = useState(1)
  const [results, setResults] = useState(patients.results)
  const [count, setCount] = useState(patients.count)
  const pageSize = 10

  useEffect(() => {
    async function fetchData() {
      const params = {
        'page': activePage,
        'pageSize': pageSize,
        'order': sortBy,
      }

      if(startDate != null && endDate != null){
        params.date_from = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        params.date_to = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
      }
        
      if(search != '')
        params.q = search

      const data = await (await fetch(`${BASE_API}/hcg/?${serialize(params)}`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
      setResults(data.results)
      setCount(data.count)
    }

    fetchData()
  }, [activePage, search, sortBy, startDate, endDate])

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber)
  }

  const handleSearch = async (query) => {
    setActivePage(1)
    setSearch(query)
  }

  const handleSort = async (value) => {
    setActivePage(1)
    setSortBy(value)
  }

  const handleStartDate = async (date) => {
    setStartDate(date)
  }

  const handleEndDate = async (date) => {
    setActivePage(1)
    setEndDate(date)
  }

  return (
    <Box>
      <Box mb={3}>
        <Text fw={600}>Beta HCG</Text>
      </Box>

      <Flex>
        <Box mr={3}>
          <Input placeholder='Buscar...' value={search} onChange={e => handleSearch(e.target.value)} />
        </Box>
        <Box mr={3}>
          <Select value={sortBy} onChange={e => handleSort(e.target.value)}>
            <option value=''>Ordenar por...</option>
            <option value='exam__patient__name'>A a Z</option>
            <option value='-exam__patient__name'>Z a A</option>
            <option value='hcg'>Menor para o maior</option>
            <option value='-hcg'>Maior para o menor</option>
          </Select>
        </Box>
        <Box mr={2}>
          <MyDatePicker
            selected={startDate}
            onChange={handleStartDate}
            maxDate={new Date()}
            placeholderText="Data de início..."
            isClearable
          />
        </Box>
        <Box>
          <MyDatePicker
            selected={endDate}
            onChange={handleEndDate}
            minDate={startDate == null ? new Date() : startDate}
            maxDate={new Date()}
            placeholderText="Data de fim..."
            isClearable
          />
        </Box>
      </Flex>

      <TableContainer style={{border: 'none', marginTop: '40px'}}>
        <Table>
          <TableHeaderRow style={{boxShadow: 'inset 0 -1px 0 0 lightgrey'}}>
            <TableHeaderCell style={{padding: '16px'}}> 
              Nome 
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Data de Nasc. 
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Data do exame
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Email
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Tel. 
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              HCG 
            </TableHeaderCell>
          </TableHeaderRow>
          {results.map(item => (
            <TableRow key={item.id} style={{boxShadow: 'inset 0 -1px 0 0 lightgrey'}}>
              <TableCell style={{padding: '16px'}} data-title='Nome'> 
                {item.exam.patient.name} 
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Data de Nasc.'> 
                {formatDateTime(item.exam.patient.birth_date)} 
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Data do exame'> 
                {formatDateTime(item.exam.exam_date)} 
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Nome'> 
                {item.exam.patient.email}  
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Tel.'> 
                {item.exam.patient.cellphone} 
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='HCG'> 
                <Text style={{color: formatHCGColor(item.hcg)}}> {item.hcg} </Text>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>

      <Flex ai={'center'}>
        <Paginator 
          activePage={activePage}
          itemsCountPerPage={pageSize}
          totalItemsCount={count}
          onChange={handlePageChange}/> 
      </Flex>
    </Box>
  )
})

export default memo(props => {
  const { data, error, loading } = useQuery(LIST)
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />

  return <List {...props} patients={data.patients} />
})
