import React, { memo, useEffect } from 'react'
import mobileDetect from '../../utils/mobileDetect'
import AUTH_TOKEN from '../../constants/authToken'

export default memo(() => {

  const { isAndroid, isIos } = mobileDetect(navigator.userAgent)

  useEffect(() => {
    localStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem('has_study_hcg')
    localStorage.removeItem('has_polypus')
    localStorage.removeItem('has_loyalty_card')
    
    if (isIos()) return setTimeout(() => { window.location = 'https://apps.apple.com/br/app/tria-med/id1402850423' }, 100)
    if (isAndroid()) return setTimeout(() => { window.location = 'https://play.google.com/store/apps/details?id=br.com.docpad.www.docpadmedico&hl=pt_BR' }, 100)
    return setTimeout(() => { window.location = 'https://web.tria.health/cadastro?profile=doctor' }, 100)
  }, [isIos, isAndroid])

  return (
    <></>
  )
})
