import styled from "styled-components";

export const Title = styled.h1`
  font-size: 22px !important;
  font-weight: bold;
  margin: 0 25px 0 0 !important;
  color: #2f3c4a !important;
  cursor: ${(props) => (props.onClick ? "pointer" : "auto")};
`;

export const BtnPink = styled.button`
  background-color: #e01b4b;
  border: 3px solid transparent;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.05);
  color: #ffffff;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 700;
  padding: 0px 30px;
  height: 50px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  cursor: pointer;
`;

export const CardOption = styled.div`
  display: flex;
  width: 100%;
  gap: 70px;
`;
export const Navigation = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 44px;

  @media (max-width: 1330px) {
    flex-direction: column;
    gap: 20px;
  }
`;

export const Filters = styled.div`
  width: 100%;
  display: flex;
  gap: 30px;
  justify-content: space-between;

  @media (max-width: 1530px) {
    justify-content: flex-start;
  }

  @media (max-width: 970px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const CardSearch = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  svg {
    position: absolute;
    margin-left: auto;
    right: 10px;
    top: 10px;
  }

  input {
    width: 100%;
    border-radius: 8px;
    padding: 6px 30px 6px 15px;
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
  }

  input::placeholder {
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
  }

  input:focus {
    box-shadow: 0 0 0 0;
    border: 1px solid #dbdbdb;
    outline: 0;
  }
`;
export const CardDate = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  input {
    width: 100%;
    border-radius: 8px;
    padding: 6px 15px;
    color: #2f3c4c;
    background: #f5f5f5;
    font-size: 13px;
    font-weight: 500;
    border: 1px solid #dbdbdb;
  }

  input::placeholder {
    color: #2f3c4c;
    font-size: 13px;
    font-weight: 500;
  }

  input:focus {
    box-shadow: 0 0 0 0;
    border: 1px solid #dbdbdb;
    outline: 0;
  }
`;

export const Option = styled.span`
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;

  color: ${(props) => props.select === "true" && "#2F3C4C"};
  color: ${(props) => props.select === "false" && "#343d4b80"};

  text-decoration: ${(props) => props.select === "true" && "underline"};
  text-decoration: ${(props) => props.select === "false" && "none"};
`;
export const Container = styled.div`
  padding: 25px 30px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #2f3c4c;

  & + div {
    margin-top: 30px;
  }

  div {
    span {
      display: block;
      font-weight: 500;
      & + span {
        font-weight: 400;
        color: #7e7e7e;
        margin-top: 10px;
      }
    }
  }

  @media (max-width: 1240px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    & > div {
      justify-content: space-between;
      width: 100%;
    }
  }

  @media (max-width: 1080px) {
    & > div {
      flex-direction: column;
      gap: 30px;
      div {
        margin-left: 0;
      }
    }
  }
`;
export const Details = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
`;

export const PrescritionDoctorDates = styled.div`
  display: flex;
  gap: 86px;
  margin-left: 20px;
`;

export const PrescritionDates = styled(PrescritionDoctorDates)`
  & > div {
    display: flex;
    gap: 20px;
  }
`;

export const TableContent = styled.div`
  margin-top: 15px;

  .ant-table-thead {
    font-size: 14px !important;
  }

  .ant-table-column-title {
    color: #2f3c4c;
  }

  .ant-table-row td {
    font-size: 13px;
  }
`;

export const BoxViews = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  &.doctor {
    grid-template-columns: 1fr 2fr;
  }
  grid-column-gap: 40px;
  grid-row-gap: 64px;
  @media screen and (max-width: 1600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 1150px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ContainerBody = styled.div`
  padding: 30px 45px;
  background: #ffffff;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  display: block;
  justify-content: space-between;
  align-items: center;
  color: #2f3c4c;
  h3 {
    font-size: 16px;
    font-weight: 800;
    margin-bottom: 30px;
  }
  .pic-profile {
    width: 100px;
    height: 100px;
    margin-bottom: 30px;
    img {
      border-radius: 50%;
      width: 100%;
    }
  }

  & + div {
    margin-top: 30px;
  }

  div.infors {
    div {
      & + div {
        margin-top: 30px;
      }
      span {
        display: block;
        font-weight: 500;
        & + span {
          font-weight: 400;
          color: #7e7e7e;
          margin-top: 5px;
        }
      }
    }
    &.flex {
      margin-top: 30px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
      div + div {
        margin-top: 0;
      }
    }
  }
  &.prescription {
    .grid {
      & + .grid {
        margin-top: 30px;
      }
    }
    div.infors {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 20px;
      @media screen and (max-width: 1240px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
`;

export const ContainerHeader = styled.div`
  margin-bottom: 50px;
  h2 {
    font-size: 16px;
    font-weight: 800;
    color: #2f3c4c;
    opacity: 50px;
  }
`;

export const Main = styled.div`
  min-height: calc(100vh - 252px - 32px - 62px);
`;