
import React, { memo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Box, Flex } from "../../components/FlexBox";
import { TableAssociate } from "./TableAssociate";
import {
  BtnPink,
  CardOption,
  CardSearch,
  Main,
  SubTitle,
  Success,
  TableContent,
  Title
} from "./styles";

import SignatureToggle from "../SignatureToggle";
import { AddForm } from "./AddForm";

export default memo(({ id, navigate, message }) => {
  const type = localStorage.getItem("type")
  const [page, setPage] = useState(0);
  const [adding, setAdding] = useState(false);
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [success, setSuccess] = useState(false);

  function handleSearchSubmit(e) {
    e.preventDefault();
    setSearch(searchInput);
  }
  

  return (
    <Main>
      <Box p={4}>
        {adding && <>
          <Box mb={3} jc={"flex-start"} ai={"center"}>
            <Title>Vincular nova operadora</Title>
            <SubTitle>Dados da operadora</SubTitle>
          </Box>
          <AddForm setAdding={setAdding} setSuccess={setSuccess}/>
        </>}

        {!adding && <>
          <Flex mb={3} jc={"flex-start"} ai={"center"}>
            <Title>Operadora</Title>
            <BtnPink onClick={() => setAdding(true)}>Vincular</BtnPink>
          </Flex>
          {type === "doctor" && <SignatureToggle />}
          <CardOption>
            <CardSearch active={(!!searchInput).toString()} onSubmit={handleSearchSubmit}>
              <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)} type="text" placeholder="Buscar nome" />
              <button title={'buscar'} type='submit'><FaSearch></FaSearch></button>
            </CardSearch>
          </CardOption>
          {success && <Success>{success}</Success>}
          <TableContent>
            <TableAssociate search={search} />
          </TableContent>
        </>}

      </Box>
    </Main>
  )
});
