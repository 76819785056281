import React, { Component } from "react";
import { createPortal } from "react-dom";
import { Manager, Reference, Popper } from "react-popper";

const Portal = ({ children }) =>
  createPortal(children, document.getElementById("portal-root"));

class ClickOutside extends Component {
  constructor(props) {
    super(props);
    this.getContainer = this.getContainer.bind(this);
    this.isTouch = false;
  }

  getContainer(ref) {
    this.container = ref;
  }

  render() {
    const { children, onClickOutside, ...props } = this.props;
    return (
      <div {...props} ref={this.getContainer}>
        {children}
      </div>
    );
  }

  componentDidMount() {
    document.addEventListener("touchend", this.handle, true);
    document.addEventListener("click", this.handle, true);
  }

  componentWillUnmount() {
    document.removeEventListener("touchend", this.handle, true);
    document.removeEventListener("click", this.handle, true);
  }

  handle = (e) => {
    if (e.type === "touchend") this.isTouch = true;
    if (e.type === "click" && this.isTouch) return;
    const { onClickOutside } = this.props;
    const el = this.container;
    if (!el.contains(e.target)) onClickOutside(e);
  };
}

export default class MenuOver extends Component {
  state = {
    open: false,
  };

  static defaultProps = {
    placement: "right",
  };

  render() {
    const { children, options, placement, id } = this.props;

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <div ref={ref} onClick={() => this.setState({ open: true })}>
              {children}
            </div>
          )}
        </Reference>
        <Portal>
          {this.state.open && (
            <Popper
              placement={placement}
              modifiers={{
                computeStyle: { gpuAcceleration: false },
                preventOverflow: { enabled: false },
                hide: { enabled: false },
              }}
            >
              {({ ref, style, placement }) => (
                <div
                  ref={ref}
                  data-placement={placement}
                  style={{ zIndex: "1002", ...style }}
                >
                  <ClickOutside
                    onClickOutside={() => this.setState({ open: false })}
                  >
                    <>
                      <div
                        style={{
                          borderRadius: 4,
                          background: "white",
                          fontSize: 14,
                          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                          padding: "4px 0",
                        }}
                      >
                        {options.map((option, index) => (
                          <div
                            key={`${option}${index}`}
                            className="option"
                            role="option"
                            aria-selected="false"
                            style={{
                              fontSize: 14,
                              padding: "6px 16px",
                              cursor: "pointer",
                              userSelect: "none",
                            }}
                            onTouchStart={this.handleTouch}
                            onTouchEnd={this.handleTouch}
                            onClick={() =>
                              this.setState({ open: false }, () =>
                                this.props.onSelect(option.value, id)
                              )
                            }
                          >
                            {option.label}
                          </div>
                        ))}
                      </div>
                    </>
                  </ClickOutside>
                </div>
              )}
            </Popper>
          )}
        </Portal>
      </Manager>
    );
  }
}
