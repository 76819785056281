import React, { memo } from 'react'

import { useQuery } from '../../hooks/useApollo'
import CircularProgress from '../../components/CircularProgress'
import { Flex, Box } from '../../components/FlexBox';
import './style.css';
import { Link } from "../../utils/router";
import icons from "./icons"

const Footer = memo(({ me, onLogout, ...rest }) => (
  <Box id="footer">
      <Box className="image">
        <img src={process.env.PUBLIC_URL + "/logo.png"} alt="Logotipo da Tria" />
      </Box>
      <Box className="links">
        <Box className="pages">
          {localStorage.getItem('type') === 'doctor' ? <ul>
            <li><Link to="/associados">Associados</Link></li>
            <li><Link to="/associados">Prescrições</Link></li>
          </ul> : <ul>
            <li><Link to="/associados">Associados</Link></li>
            <li><Link to="/relatorios">Relatórios</Link></li>
            <li><Link to="/alertas">Alertas</Link></li>
            <li><Link to="/usuarios">Usuários</Link></li>
          </ul>}
        </Box>
        <Box className='pages-profile'>
        {localStorage.getItem('type') === 'doctor' ? <ul>
            <li><Link to="/meuperfil">Perfil</Link></li>
            <li><Link to="/meuperfil">Alterar Senha</Link></li>
          </ul> : <ul>
            <li><Link to="/perfil">Perfil</Link></li>
            <li><Link to="/perfil">Alterar Senha</Link></li>
          </ul>}
        </Box>
        <Box className="pages-terms">
          <ul>
            <li><Link to="/termos">Termos de Uso</Link></li>
            <li><Link to="/politica-de-privacidade">Políticas de Privacidade</Link></li>
            <li><Link to="/">Informar um Problema</Link></li>
          </ul>
        </Box>
        <Box className="social-links">
          <ul>
            <li><a href="/">{icons.facebook}</a></li>
            <li><a href="/">{icons.email}</a></li>
            <li><a href="/">{icons.telefone}</a></li>
          </ul>
        </Box>
      </Box>
  </Box>
))


export default memo(props => {
  return <Footer {...props} />
})
