import { Upload, message } from "antd";
import React, { useState } from "react";
import { useQuery } from "react-apollo-hooks";
import { BsBoxArrowUp } from "react-icons/bs";
import {
  ME_DOCTOR,
  ME_ESTABLISHMENT,
  ME_OPERATOR,
  ME_PATIENT,
} from "../../layouts/Gqls";
import { Container, Content, SubmitBtn } from "./styles";

export default function UploadExams() {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [countSuccess, setCountSuccess] = useState(0);
  const [isSendingToTheAPI, setIsSendingToTheAPI] = useState(false);

  const loginType = localStorage.getItem("type");
  let query = ME_PATIENT;
  if (loginType === "doctor") query = ME_DOCTOR;
  if (loginType === "operator") query = ME_OPERATOR;
  if (loginType === "establishment") query = ME_ESTABLISHMENT;
  const { data: user } = useQuery(query);

  const customRequest = ({ file, onSuccess, onError }) => {
    setTimeout(() => {
      onSuccess(file);
      setUploading(false);
    }, 1000);
  };

  const handleUpload = async () => {
    setIsSendingToTheAPI(true);
    if (fileList.length === 0) {
      setIsSendingToTheAPI(false);
      return;
    }

    try {
      setIsSending(true);
      let successCount = 0;

      for (let index = 0; index < fileList.length; index++) {
        const fileObject = fileList[index];
        const file = fileObject.originFileObj;
        const reader = new FileReader();

        reader.readAsDataURL(file);

        const base64Data = await new Promise((resolve) => {
          reader.onload = () => {
            resolve(reader.result);
          };
        });

        const headers = {
          Authorization: `token ${localStorage.getItem("docpadAuthToken")}`,
          "Content-Type": "application/json",
        };

        const payload = {
          media: base64Data,
          establishment_id: user[loginType].id,
        };

        try {
          const response = await fetch(
            `https://uploadocr.tria.health/upload-file-to-sync/`,
            {
              method: "POST",
              headers,
              body: JSON.stringify(payload),
            }
          );

          message.success("Arquivos enviados com sucesso!");
          await response.json();

          if (response.status === 201) {
            successCount++; // Increment successCount here
            setCountSuccess(successCount); // Update successCount in the state
          } else {
            message.error(`Falha ao enviar o arquivo: ${response.status}`);
            setIsSendingToTheAPI(false);
          }
        } catch (error) {
          message.error("Falha ao enviar o arquivo.");
          setIsSendingToTheAPI(false);
        }
      }

      setUploading(false);
      setIsSending(false);
      setFileList([]);
      setCountSuccess(0);
      setIsSendingToTheAPI(false);

    } catch (error) {
      setIsSending(false);
      message.error("Falha ao enviar o arquivo.");
    }
  };

  const acceptedTypes = ['application/pdf', 'application/dicom', 'image/jpeg', 'image/png', 'image/jpg']
  const handleChange = (info) => {
    if (info.file.status === "error") {
      message.error(`${info.file.name} Falha ao adicionar o arquivo.`);
    }

    setFileList(
      info.fileList.filter((file) => {
        return acceptedTypes.includes(file.type);
      })
    );
  };

  const props = {
    name: "file",
    listType: "picture",
    customRequest,
    fileList,
    accept: ".pdf, .dcm",
    beforeUpload: (file) => {
      if (!acceptedTypes.includes(file.type)) {
        message.error("O arquivo não é suportado");
        return false;
      }
      return true;
    },
    headers: {
      authorization: "authorization-text",
    },
    onChange: handleChange,
  };

  return (
    <Content>
      <h1>Upload de Exames</h1>

      <Upload {...props}>
        <Container>
          <label
            className={`${
              fileList.length === 1 ? "upload-container" : ""
            } custom-file-upload`}
          >
            <BsBoxArrowUp fontSize={40} />
            <i className="fa fa-cloud-upload"></i> Arraste o arquivo aqui ou
            clique para selecionar
          </label>

          <input id="file-upload custom-file-upload" type="file" />
        </Container>
      </Upload>
      <SubmitBtn
        type="primary"
        loading={isSending}
        onClick={handleUpload}
        disabled={uploading || isSending}
      >
        {isSending ? "Enviando..." : "Enviar"}
      </SubmitBtn>
      {isSendingToTheAPI ? (
        <span>
          Enviando {countSuccess} / {fileList.length}...
        </span>
      ) : null}
    </Content>
  );
}
