import React, { useState } from "react";
import ButtonLoading from "../../components/ButtonLoading";
import {
  BtnPink,
  BtnPinkAlt,
  Btns,
  Error,
  Form,
  FormGroup,
  Inputs,
} from "./styles";

export const AddForm = ({ setAdding, setSuccess }) => {
  const type = localStorage.getItem("type");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  function formSubmitHandler(e) {
    e.preventDefault();
    setLoading(true);
    setError(false);
    const data = {
      email: e.target.email.value
    };

    const headers = {
      Authorization: `token ${localStorage.getItem("docpadAuthToken")}`,
    };

    let url;
    let formdata = new FormData();
      // url = `${baseApi}/create-doctor-health-operator`;
      // formdata.append("doctor", data.email);
    setError("Ainda não é possível adicionar operadoras.");
    setLoading(false);
    if (!url) return;

    const requestOptions = {
      method: "POST",
      headers: headers,
      body: formdata,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.detail) {
          switch (result.detail) {
            case "Doctor not found":
              setError("Médico não encontrado");
              break;
            case "Patient not found":
              setError("Paciente não encontrado");
              break;
            case "User is not health operator":
              setError("Usuário não é operador de saúde");
              break;
            default:
              setError(error.detail);
          }
          setLoading(false);
        } else {
          setSuccess(
            <>
              <strong>{data.email}</strong> vinculado com sucesso.
            </>
          );
          setLoading(false);
          setAdding(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setError("Não foi possível adicionar o usuário, talvez já esteja vinculado.");
        setLoading(false);
      });
  }
  return (
    <>
      <Form onSubmit={formSubmitHandler}>
        <Inputs>
          <FormGroup>
            <label htmlFor="email">E-mail</label>
            <input
              required
              id="email"
              name="email"
              type="email"
              placeholder="E-mail"
            />
          </FormGroup>
        </Inputs>
        {error && <Error> {error} </Error>}
        <Btns>
          <BtnPinkAlt
            disabled={loading}
            type="button"
            onClick={() => setAdding(false)}
          >
            Cancelar
          </BtnPinkAlt>
          <BtnPink disabled={loading} type="submit">
            Vincular
            {loading && <ButtonLoading />}
          </BtnPink>
        </Btns>
      </Form>
    </>
  );
};
