import React, { memo } from "react";
import { Box, Flex } from "../../components/FlexBox";
import {
  BoxViews,
  BtnPink,
  ContainerBody,
  ContainerHeader
} from "./styles";

export default memo(({ viewPrescription }) => {
  console.log(viewPrescription);
  return (
    <BoxViews>
      <Box>
        <ContainerHeader>
          <h2>Informações do médico 1</h2>
        </ContainerHeader>
        <ContainerBody>
          <Flex w="100%" jc="space-between" ai="center">
            <div className="pic-profile">
              <img
                src={viewPrescription.doctorOne.picProfile}
                alt="Sem foto de perfil"
              />
            </div>
          </Flex>
          <div className="infors">
            <div>
              <span>Nome</span>
              <span>{viewPrescription.doctorOne.name}</span>
            </div>
            <div>
              <span>Especialização</span>
              <span>{viewPrescription.doctorOne.specialization}</span>
            </div>
            <div>
              <span>CRM</span>
              <span>{viewPrescription.doctorOne.crm}</span>
            </div>
            <div>
              <span>E-mail</span>
              <span>{viewPrescription.doctorOne.email}</span>
            </div>
            <div>
              <span>Telefone</span>
              <span>{viewPrescription.doctorOne.phone}</span>
            </div>
          </div>
        </ContainerBody>
      </Box>
      <Box>
        <ContainerHeader>
          <h2>Informações do médico 2</h2>
        </ContainerHeader>
        <ContainerBody>
          <Flex w="100%" jc="space-between" ai="center">
            <div className="pic-profile">
              <img
                src={viewPrescription.doctorTwo.picProfile}
                alt="Sem foto de perfil"
              />
            </div>
          </Flex>
          <div className="infors">
            <div>
              <span>Nome</span>
              <span>{viewPrescription.doctorTwo.name}</span>
            </div>
            <div>
              <span>Especialização</span>
              <span>{viewPrescription.doctorTwo.specialization}</span>
            </div>
            <div>
              <span>CRM</span>
              <span>{viewPrescription.doctorTwo.crm}</span>
            </div>
            <div>
              <span>E-mail</span>
              <span>{viewPrescription.doctorTwo.email}</span>
            </div>
            <div>
              <span>Telefone</span>
              <span>{viewPrescription.doctorTwo.phone}</span>
            </div>
          </div>
        </ContainerBody>
      </Box>
      <Box>
        <ContainerHeader>
          <h2>Prescrições</h2>
        </ContainerHeader>
        <ContainerBody>
          <div className="infors">
            <div>
              <span>Prescrição</span>
              <span>{viewPrescription.exam}</span>
            </div>
            <div>
              <span>Tipo de exame</span>
              <span>{viewPrescription.examType}</span>
            </div>
            <div>
              <span>Paciente</span>
              <span>{viewPrescription.patient}</span>
            </div>
          </div>
          <Flex className="infors flex" fd={"row"} w="100%" jc="start" ai="center">
              <div>
                <span>Data 1</span>
                <span>{viewPrescription.prescritionDateOne}</span>
              </div>
              <div>
                <span>Status</span>
                <span>
                  {viewPrescription.statusOne ? "Atual" : "Antigo"}
                </span>
              </div>
              <BtnPink>Ver</BtnPink>
          </Flex>
          <Flex className="infors flex" fd={"row"} w="100%" jc="start" ai="center">
              <div>
                <span>Data 2</span>
                <span>{viewPrescription.prescritionDateTwo}</span>
              </div>
              <div>
                <span>Status</span>
                <span>
                  {viewPrescription.statusTwo ? "Atual" : "Antigo"}
                </span>
              </div>
              <BtnPink>Ver</BtnPink>
          </Flex>
        </ContainerBody>
      </Box>
    </BoxViews>
  );
});
