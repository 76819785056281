import React, { memo, useEffect, useState } from 'react'
// import { Bar } from 'react-chartjs-2'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'


export default memo(( { chartData } ) => {

  return (
    <BarChart
      width={900}
      height={300}
      data={chartData}
      margin={{top: 5, right: 30, left: 20, bottom: 5}}
      style={{margin: '0 auto', marginTop: 50}}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey='Usuários conectados' fill="#8884d8" />
      <Bar dataKey='Exames distribuídos' fill="#82ca9d" />
      <Bar dataKey='Usuários sincronizados automaticamente' fill="#ebc182" />
    </BarChart>
  )
})

//<Bar dataKey='Exames visualizados' fill="#95bde0" />