import camelcaseKeys from 'camelcase-keys'
import React, { memo, useEffect, useState } from 'react'

import Button from '../../components/Button'
import { Box, Flex } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import ReactUpload from '../../components/ReactUpload'
import Select from '../../components/Select'
import Text from '../../components/Text'

// import { useMutation } from '../../hooks/useApollo'
// import { UPDATE } from './Gqls'
import formDataFromObject from '../../utils/formDataFromObject'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [file, setFile] = useState(null)

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(camelcaseKeys(initialData))

  const [plan, setPlan] = useState((initialData.healthInsurance && initialData.healthInsurance.id ? initialData.healthInsurance.id : ''))
  const [planNumber, setPlanNumber] = useState(initialData.healthInsuranceNumber ? initialData.healthInsuranceNumber : '')

  // const updateItem = useMutation(UPDATE)

  const [healthInsurances, setHealthInsurances] = useState([])

  useEffect(() => {
    const getHealthInsurances = async () => {
      const response = await fetch(`${BASE_API}/getAllHealthInsurance/`)
      const data = await response.json()
      setHealthInsurances(data)
    }
    getHealthInsurances()
  }, [])

  // const fetchData = async () => {
  //   setLoading(true)
  //   const response = await fetch(`${BASE_API}/patient`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' }})
  //   const data = await response.json()
  //   setData(camelcaseKeys(data))
  //   setLoading(false)
  //   onUpdated()
  // }

  const handleUpdate = async () => {
    setLoading(true)
    let variables = {
      health_insurance: plan,
      // health_insurance_card_photo: planNumber
    }
    if (file) variables.health_insurance_card_photo = file.base64
    try {
      const putData = await (await fetch(`${BASE_API}/patient/`, { method: 'PUT', body: formDataFromObject(variables), headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
      setLoading(false)
      onUpdated()
      onRequestClose()
    } catch(err) {
      setLoading(false)
      console.error(err)
    }

    // updateItem({ variables: { input: { healthInsurance: plan, healthInsuranceNumber: planNumber } } })
    //   .then(data => {
    //     fetchData()
    //   })
    //   .catch(error => {
    //     console.error('error', error)
    //   })
  }

  const handleUpload = file => {
    setFile(file)
    // file.name
    // file.size
    // file.type
  }

  // if (loading) return <CircularProgress />

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Plano de Saúde</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={3}>
          <Box>Plano</Box>
          <Box mb={2}>
            <Select placeholder='Selecione...' name='health_insurance' value={plan} onChange={e => setPlan(e.target.value)}>
              {healthInsurances.map(item => (
                <option key={item.id} value={item.id}>{item.name}</option>
              ))}
            </Select>
          </Box>
        </Box>
        <Box mb={3}>
          <Box>Foto da Carteira do Plano</Box>
          <ReactUpload multiple={false} onDone={handleUpload} text='Selecione o arquivo' />
          {file &&
            <Box>{file.name}</Box>
          }
        </Box>

        {/* <Box mb={3}>
          <Box>Número do cartão</Box>
          <Box><Input value={planNumber} onChange={e => setPlanNumber(e.target.value)} /></Box>
        </Box> */}

        <Box><Button loading={loading} onClick={handleUpdate}>Salvar</Button></Box>
      </Box>
    </Flex>
  )
})
