import React from "react";
import styled from "styled-components";
import Pagination from "react-js-pagination";

const Container = styled.div`
  display: inline-block;
  margin: 30px auto;
  ul {
    list-style: none;
  }
  li {
    position: relative;
    text-align: -webkit-match-parent;
    float: left;
    padding: 6px 12px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #337ab7;
    background-color: #fff;
    border: 1px solid #ddd;
    margin-left: -1px;
    text-align: -webkit-match-parent;
  }
  a {
    color: #337ab7;
    text-decoration: none;
  }
  .active {
    z-index: 3;
    color: #fff;
    background-color: #337ab7;
    border-color: #337ab7;
    cursor: default;
  }

  .active a {
    color: #fff;
  }
`;

const Paginator = ({ ...rest }) => (
  <Container>
    <Pagination hideDisabled {...rest} />
  </Container>
);

export default Paginator;
