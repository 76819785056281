import "antd/dist/antd.css";
import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { BtnPink, Pagination } from "./styles";
import { Flex } from "../../components/FlexBox";
import { useNavigate } from "@reach/router";
import baseApi from "../../constants/baseApi"
import CircularProgress from "../../components/CircularProgress";

const pgConfig = {
  hideOnSinglePage: true,
}

export const TableAssociate = ({ search }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(2);
  const [tablePage, setTablePage] = useState(1);
  
  const page_size = 10;
  const maxPage = Math.ceil(totalItems / page_size);

  const navigate = useNavigate();
  function viewAssociate(id, drop) {
    navigate(`/operadoras/${id}/${drop}`);
  }

  useEffect(() => {
    setLoading(true);
    setData([]);
    const headers = {
      "Authorization": `token ${localStorage.getItem("docpadAuthToken")}`,
    }

    fetch(`${baseApi}/list-operators-of-doctor/?page_size=${page_size}&page=${tablePage}&name=${search}`, {headers}).then((response) => {
      response.json().then((data) => {
        setTotalItems(data.count);
        const results = data.results.map((item) => {
          return {
            key: item.id,
            id: item.health_operator_obj.id,
            name: item.health_operator_obj.name || '-',
            email: item.health_operator_obj.email || '-',
            phone: item.health_operator_obj.phone || '-',
            access: item.updated_at || '-',
          };
        });
        setData(results);
        setLoading(false);
      }).catch((err) => {
        console.log(err);
        setLoading(false);
      });
    });

  }, [tablePage, page_size, search]);

  const columns = [
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Telefone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Último acesso",
      key: "access",
      render: (item) => {
        if (item.access === '-') {
          return item.access;
        } else {
          const date = new Date(item.access);
          return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
        }
      },
    },
    {
      title: "",
      key: "action",
      render: (item) => (
        <Flex jc="flex-end">
          <BtnPink onClick={() => viewAssociate(item.key, item.id)}>Ver</BtnPink>
        </Flex>
      ),
    },
  ];

  function prevPage() {
    if (tablePage > 1) {
      setTablePage(tablePage - 1);
    }
  }

  function nextPage() {
    if (tablePage < maxPage) {
      setTablePage(tablePage + 1);
    }
  }

  if (loading) return <CircularProgress/>;

  if (!loading) return (
    <>
      <Table pagination={pgConfig} columns={columns} dataSource={data} />

      <Pagination>
        <button onClick={prevPage} className="back" disabled={tablePage === 1}>&lt;</button>
        <button className="page">{tablePage}</button>
        <button onClick={nextPage} className="next" disabled={tablePage === maxPage || maxPage === 0}>&gt;</button>
      </Pagination>
    </>
  );
};
