import React, { memo, useState, useEffect } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import { CloseIcon } from '../../components/Icons'
import RadioGroup from '../../components/MyRadioGroup'
import CircularProgress from '../../components/CircularProgress'

import formDataFromObject from '../../utils/formDataFromObject'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

export default memo(({ me, onUpdated, onRequestClose }) => {

  const [healthInsurances, setHealthInsurances] = useState([])
  const [myHealthInsurances, setMyHealthInsurances] = useState(me.healthInsurances)

  const [loading, setLoading] = useState(false)


  useEffect(() => {
    const getHealthInsurances = async () => {
      const response = await fetch(`${BASE_API}/getAllHealthInsurance/`)
      const data = await response.json()
      setHealthInsurances(data)
    }
    getHealthInsurances()
  }, [])

  const updatePlan = async value => {
    setLoading(true)
    const body = formDataFromObject({ health_insurances: `[${value.toString()}]` })
    const putData = await (await fetch(`${BASE_API}/doctorHealthInsurance/`, { method: 'POST', body, headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
    setMyHealthInsurances(putData.health_insurances)
    setLoading(false)
    onUpdated()
  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Planos Atendidos</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={4}>
          <Box mx='auto' w={250}>
            <RadioGroup multiple={true} value={myHealthInsurances.map(item => item.id)} onChange={updatePlan} options={healthInsurances.map(item => ({ label: item.name, value: item.id }))} />
          </Box>
          <Box mx='auto' w={250}>
            {loading &&
              <CircularProgress />
            }
          </Box>
        </Box>

      </Box>
    </Flex>
  )
})
