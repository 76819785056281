import React, { memo, useState } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import { CloseIcon } from '../../components/Icons'
import Input from '../../components/Input'
import Button from '../../components/Button'
import CircularProgress from '../../components/CircularProgress'

import { useMutation } from '../../hooks/useApollo'
import { CREATE_ALLERGY } from './Gqls'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [loading, setLoading] = useState(false)
  const [allergies, setAllergies] = useState(initialData)
  const [allergy, setAllergy] = useState('')

  const createAllergy = useMutation(CREATE_ALLERGY)

  const fetchAllergies = async () => {
    setLoading(true)
    const response = await fetch(`${BASE_API}/patient`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' }})
    const data = await response.json()
    setAllergies(data.allergies)
    setLoading(false)
    onUpdated()
  }

  const deleteAllergy = async (id) => {
    const response = await fetch(`${BASE_API}/allergyPatient?id=${id}`, { method: 'DELETE', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})
    const data = await response.json()
    if (data.worked) setAllergies(current => current.filter(item => item.id !== id))
    onUpdated()
  }

  const handleCreateAllergy = () => {
    setAllergy('')
    createAllergy({ variables: { input: { name: allergy } } })
      .then(data => {
        fetchAllergies()
      })
      .catch(error => {
        console.error('error', error)
      })
  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Alergias</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Flex mb={3}>
          <Box flex={1} mr={2}><Input value={allergy} onChange={e => setAllergy(e.target.value)} /></Box>
          <Box><Button onClick={handleCreateAllergy}>Adicionar</Button></Box>
        </Flex>
        <Box>
          {loading &&
            <CircularProgress />
          }
        </Box>
        <Box>
          {allergies.map(item => (
            <Flex key={item.id} jc='space-between'><Box>{item.name}</Box><Box cursor='pointer' onClick={() => deleteAllergy(item.id)}>Excluir</Box></Flex>
          ))}
        </Box>

      </Box>
    </Flex>
  )
})
