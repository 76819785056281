import React, { useEffect, useState } from 'react'
import Select from '../../components/Select'
import { Box } from '../../components/FlexBox'
import Input from '../../components/Input'
import InputCpf from '../../components/InputCpf'
import InputPhone from '../../components/InputPhone'
import InputDate from '../../components/InputDate'
import Button from '../../components/Button'
import FormErrorBox from '../../components/FormErrorBox'

import BASE_API from '../../constants/baseApi'

export const SignupFormPatientStep1 = ({ email, name, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => {

  useEffect(() => { setFieldValue('login', email) }, [setFieldValue, email])
  useEffect(() => { setFieldValue('name', name) }, [setFieldValue, name])

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <InputCpf autoComplete='off' placeholder='Cpf' name='cpf' value={values.cpf} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='cpf' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <Input type='email' autoComplete='off' placeholder='Email' name='login' value={values.login} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='login' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <Input autoComplete='off' placeholder='Nome' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <Input type='password' autoComplete='off' placeholder='Senha' name='password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='password' errors={errors} touched={touched} />
      </Box>
      <Box mb={1}>
        <Input type='password' autoComplete='off' placeholder='Confirme a Senha' name='password_confirmation' value={values.password_confirmation} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='password_confirmation' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <input type='checkbox' id='terms' name='terms' value={values.terms} onChange={handleChange} onBlur={handleBlur} /><label htmlFor='terms'>Aceito os <a target='_blank' rel='noopener noreferrer' href='https://web.tria.health/politica-de-privacidade'>Termos de Uso</a></label>
        <FormErrorBox fieldName='terms' errors={errors} touched={touched} />
      </Box>
      <Box ta='center'>
        <Button type='submit' loading={isSubmitting}>Criar conta</Button>
      </Box>
    </form>
  )
}

export const SignupFormPatientStep2 = ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue, initialValues }) => {

  const [healthInsurances, setHealthInsurances] = useState([])

  useEffect(() => {
    const getHealthInsurances = async () => {
      const response = await fetch(`${BASE_API}/getAllHealthInsurance/`)
      const data = await response.json()
      setHealthInsurances(data)
    }
    getHealthInsurances()
  }, [])





  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <InputDate autoComplete='off' placeholder='Data de Nascimento' name='birth_date' value={values.birth_date} defaultValue={initialValues.birth_date} onChange={(e, value) => setFieldValue('birth_date', value)} onBlur={handleBlur} />
        <FormErrorBox fieldName='birth_date' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <InputCpf autoComplete='off' placeholder='CPF' name='cpf' value={values.cpf} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='cpf' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <Select placeholder='Gênero' name='gender' value={values.gender} onChange={handleChange} onBlur={handleBlur}>
          <option>Masculino</option>
          <option>Feminino</option>
          <option>Outro</option>
        </Select>
        <FormErrorBox fieldName='gender' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <InputPhone autoComplete='off' placeholder='Telefone' name='cellphone' value={values.cellphone} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='cellphone' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <Select placeholder='Plano de Saúde' name='health_insurance' value={values.health_insurance} onChange={handleChange} onBlur={handleBlur}>
          {healthInsurances.map(item => (
            <option key={item.id} value={item.id}>{item.name}</option>
          ))}
        </Select>
        <FormErrorBox fieldName='health_insurance' errors={errors} touched={touched} />
      </Box>
      {values.health_insurance && values.health_insurance === '7' &&
        <Box mb={2}>
          <Input autoComplete='off' placeholder='Digite o nome do plano' name='other_health_insurance' value={values.other_health_insurance} onChange={handleChange} onBlur={handleBlur} />
          <FormErrorBox fieldName='other_health_insurance' errors={errors} touched={touched} />
        </Box>
      }
      <Box ta='center'>
        <Button type='submit' loading={isSubmitting}>Concluir</Button>
      </Box>
    </form>
  )
}
