import 'antd/dist/antd.css';
import Table from 'antd/lib/table';
import React, { memo } from 'react';

import "./style.css";


import styled, { css } from '../../utils/styled';

import { Box, Flex } from '../../components/FlexBox';
import Select from '../../components/Select';

// import ReactFileBase64 from '../../components/ReactFileBase64'




import ReactApexChart from 'react-apexcharts';

const formDataFromObject = object => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())

const asyncReduce = async (arr, fn, val, pure) => {
  for (let i = 0; i < arr.length; i++) {
    let v = await fn(val, arr[i], i, arr)
    if (pure !== false) val = v
  }
  return val
}

const FolderItem = styled(Flex)`
  justify-content: space-between;
  border-bottom: 1px solid hsla(0, 0%, 93%, 1);
  padding: 20px;
  font-size: 18px;
`

const Badge = styled(Flex)`
  background: #8E8D92;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  font-size: 14px;
  ${props => props.value && css`background-color: #548D4B;`}
`

const filter = (files, search, sortBy) => {
  let filtered = files
  if (files.length <= 0) {
    console.log('filter inside folder')
  }
  else {
    filtered = filtered.filter(item => {
      let condition = true
      if (condition) condition = search ? item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) : condition
      return condition
    })
  }

  filtered = filtered.sort((a, b) => {
    if (sortBy == 'id')
      return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
    else
      return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
  });
  return filtered
}

const ListsCharts = memo(({ data, id, navigate, message }) => {
  const dataList = [
    {
      key: '1',
      id: 1,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 2000
    },
    {
      key: '2',
      id: 2,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 1990
    },
    {
      key: '3',
      id: 3,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 1800
    },
    {
      key: '4',
      id: 4,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 1760
    },
    {
      key: '5',
      id: 5,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 1670
    },
    {
      key: '6',
      id: 6,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 1000
    },
    {
      key: '7',
      id: 7,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 700
    },
    {
      key: '8',
      id: 8,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 658
    },
    {
      key: '9',
      id: 9,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 321
    },
    {
      key: '10',
      id: 10,
      doctorName: "Dr. Carlos Eduardo Silveira",
      requestsNumber: 145
    },
  ]
  const dataColumns = [
    {
      title: 'Nome do médico',
      dataIndex: 'doctorName',
      key: 'doctorName',
    },
    {
      title: 'Número de solicitações',
      dataIndex: 'requestsNumber',
      key: 'requestsNumber',
    }
  ];

  const chartDonutsOptions = {
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      formatter: function (seriesName, opts) {
        return [`${opts.w.globals.series[opts.seriesIndex]}%`, " - ", seriesName]
      },
      markers: {
        radius: 0,
      },
    },
  }

  const chartLocationOptions = {
    series: [40, 30, 30],
    options: {
      labels: ['Sudeste', 'Nordeste', 'Sul'],
      ...chartDonutsOptions,
    },
  };
  
  const chartTUSSOptions = {
    series: [40, 30, 30],
    options: {
      labels: ['Radiografia de mão direita', 'Radiografia de mão esquerda', 'Radiografia de tórax'],
      ...chartDonutsOptions,
    },
  };

  const chartLabOptions = {
    series: [40, 30, 30],
    options: {
      labels: ['Laboratório 1', 'Laboratório 2', 'Laboratório 3'],
      ...chartDonutsOptions,
    },
  };

  const chartLoremOptions = {
    series: [40, 30, 30],
    options: {
      labels: ['Lorem Ipsum', 'Lorem Ipsum', 'Lorem Ipsum'],
      ...chartDonutsOptions,
    },
  };


  return (
    <Box id="lists-charts" className="lists-charts" mb={4}>
      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} ai="center"><h2>Quem solicita mais</h2> <Select id='Filter'><option>Filter</option></Select></Flex>
        <Box className='main-section list'>
          <Table dataSource={dataList} columns={dataColumns}></Table>
        </Box>
      </Box>

      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} ai="center"><h2>Quem solicita mais em duplicidade</h2> <Select id='FilterDuplicity'><option>Filter</option></Select></Flex>
        <Box className='main-section list'>
          <Table dataSource={dataList} columns={dataColumns}></Table>
        </Box>
      </Box>

      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} ai="center"><h2>Onde é mais solicitado</h2></Flex>
        <Box className='main-section'>
          <ReactApexChart options={chartLocationOptions.options} series={chartLocationOptions.series} height="150px" type="donut" />
        </Box>
      </Box>

      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} ai="center"><h2>TUSS mais solicitado</h2> <Select id='FilterTUSS'><option>Filter</option></Select></Flex>
        <Box className='main-section'>
          <ReactApexChart options={chartTUSSOptions.options} series={chartTUSSOptions.series} height="150px" type="donut" />
        </Box>
      </Box>

      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} ai="center"><h2>Onde é mais realizado (fase 2)</h2></Flex>
        <Box className='main-section'>
        <ReactApexChart options={chartLabOptions.options} series={chartLabOptions.series} height="150px" type="donut" />
        </Box>
      </Box>

      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} ai="center"><h2>Lorem Ipsum</h2> <Select id='FilterLorem'><option>Filter</option></Select></Flex>
        <Box className='main-section'>
          <ReactApexChart options={chartLoremOptions.options} series={chartLoremOptions.series} height="150px" type="donut" />
        </Box>
      </Box>

    </Box>
  );


  // return (
  //   <Box>
  //     <Flex mb={4} ai='center' jc='space-between'>
  //       <Flex>
  //         <Box mr={3}>
  //           <Input placeholder='Buscar...' value={search} onChange={e => setSearch(e.target.value)} />
  //         </Box>
  //         <Box mr={3}>
  //           <Select value={sortBy} onChange={e => setSortBy(e.target.value)}>
  //             <option value=''>Ordenar...</option>
  //             <option value='name'>Ordenado por Nome</option>
  //             <option value='exam_date'>Ordenado por Data de Criação</option>
  //           </Select>
  //         </Box>
  //       </Flex>
  //       {folderId && results.folder && !results.folder.is_recycle_bin &&
  //         <Box>
  //           <Button onClick={() => {
  //             if (patient && (patient.image_size + patient.docs_size) > (100 * 1024 * 1024)) {
  //               setLimitModalOpen(true)
  //             } else {
  //               setUploadModalOpen(true)
  //             }
  //           }}>Fazer upload de exame</Button>
  //           {/* <Box lh={1.2}><Text fs={12} fw={600}>UPLOAD</Text></Box>
  //           <Box lh={1}><ReactFileBase64 multiple={true} onDone={handleUpload} /></Box> */}
  //         </Box>
  //       }
  //     </Flex>

  //       {results.folder && !!results.folder.path.length &&
  //         <Box ml={3}>
  //           <Text style={{cursor: 'pointer'}} fontWeight={600} onClick={() => navigate(`/arquivos`)}>Arquivos</Text>
  //           {results.folder.path.map((item, index) => {
  //             return (
  //               <span key={item.id}>
  //                 <span> &lt; </span>
  //                 <Text style={{cursor: 'pointer'}} fontWeight={600} onClick={() => navigate(`/arquivos/${item.id}`)}>{item.name}</Text>
  //               </span>
  //             )
  //           })}
  //         </Box>
  //       }

  //     <Box>
  //       {filtered.map(item => (
  //         <FolderItem key={item.id} css={{cursor: 'pointer'}} onClick={() => navigate(`/arquivos/${item.id}`)}>
  //           <Flex alignItems='center' >
  //             <Box onClick={() => navigate(`/arquivos/${item.id}`)} color='hsla(199, 75%, 57%, 1)' mr={3} css={{lineHeight: 0}}>
  //               <FolderIcon height={30} />
  //             </Box>
  //             <Box onClick={() => navigate(`/arquivos/${item.id}`)}>{item.name}</Box>
  //           </Flex>
  //           <Flex>
  //             <Badge value={item.number_of_exams}><Box>{item.number_of_exams}</Box></Badge>
  //             {!item.default && item.number_of_exams === 0 &&
  //               <Box ml={3} cursor='pointer' onClick={() => handleDeleteFolder(item.id)}><DeleteIcon /></Box>
  //             }
  //           </Flex>
  //         </FolderItem>
  //       ))}
  //     </Box>

  //     {results.exams && results.exams.map(exam => (
  //       <Box key={exam.id} m={3}>

  //         <Flex jc='space-between' alignItems='center' cursor='pointer' >
  //           <Flex onClick={() => { setExamData(exam); handleExamClick(exam); setExamId(exam.id) }}>
  //             <Box color='hsla(199, 75%, 57%, 1)' mr={3} css={{lineHeight: 0}} alignItems='center'>
  //               {(exam.medias.filter(m => m.image.includes('.pdf')).length > 0) && 
  //                 <PdfIcon height={30} />
  //               } 
  //               {(exam.medias.filter(m => m.image.includes('.doc')).length > 0) && 
  //                 <DocIcon height={30} />
  //               }
  //               {(exam.medias.filter(m => m.image.includes('.doc') || m.image.includes('.pdf')).length == 0 && exam.medias.length > 0) && (
  //                 <img alt='' src={exam.medias[0].image} height={30} width={30}/>
  //                 // <ImageIcon height={30} />
  //               )}
  //             </Box>
  //             <Box><Text fontWeight={300}>{exam.formated_date} - {exam.name}</Text></Box>
  //           </Flex>
  //           <Box>
  //             {results.folder && results.folder.is_recycle_bin &&
  //               <MenuOver
  //                 options={[{label: 'Excluir definitivamente', value: 'delete'}]}
  //                 onSelect={action => handleMenuOverClick(action, exam.id)}
  //               >
  //                 <MoreIcon style={{cursor: 'pointer'}} />
  //               </MenuOver>
  //             }

  //             {results.folder && !results.folder.is_recycle_bin &&
  //               <MenuOver
  //                 options={[
  //                   {label: 'Download', value: 'download'},
  //                   {label: 'Mover para lixeira', value: 'delete'},
  //                   {label: 'Compartilhar', value: 'share'}
  //                 ]}
  //                 onSelect={action => handleMenuOverClick(action, exam.id)}
  //               >
  //                 <MoreIcon style={{cursor: 'pointer'}} />
  //               </MenuOver>
  //             }
  //           </Box>
  //         </Flex>

  //       </Box>
  //     ))}

  //     <Flex ai={'center'}>
  //       <Paginator 
  //         activePage={activePage}
  //         itemsCountPerPage={pageSize}
  //         totalItemsCount={count}
  //         onChange={handlePageChange}/> 
  //     </Flex>

  //     {canCreateFolder &&
  //       <Fab onClick={handleCreateFolder} />
  //     }

  //     {uploadModalOpen &&
  //       <Modal
  //         onCloseCompleted={() => setUploadModalOpen(false)}
  //         render={
  //           ({onRequestClose}) => (
  //             <Flex fd='column' h='100%'>
  //               <Box position='relative' h={64}>
  //                 <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
  //                 <Box p={3} ta='center'><Text secondary large>Upload</Text></Box>
  //               </Box>
  //               <Box p={4} overflow='auto'>
  //                 <Box>
  //                   <Box p={3}>
  //                     <Box lh={1}><ReactUpload multiple={true} onDone={handleUpload} /></Box>
  //                   </Box>


  //                   <Box maxHeight={200} overflow='auto'>
  //                     {base64Files.map((file, index) => console.log('file.type', file.type) || (
  //                       <Box key={index} m={2} style={{borderBottom: '1px solid hsla(203,20%,76%,1)', marginBottom: 10}}>
  //                         <Box>
  //                           {['image/png', 'image/jpg', 'image/jpeg'].includes(file.type) &&
  //                             <img height={100} alt='' src={file.base64} />
  //                           }
  //                           {['application/pdf'].includes(file.type) &&
  //                             <IconPdf />
  //                           }
  //                         </Box>
  //                         <Box onClick={() => handleRemoveThumb(index)} d='inline-block' cursor='pointer' p={1}><CloseIcon height={16} /></Box>
  //                         <Box>{file.name}</Box>
  //                         <Box>{file.size}</Box>
  //                       </Box>
  //                     ))}
  //                   </Box>

  //                   <Box p={3}><Input placeholder='Nome' onChange={e => setName(e.target.value)} /></Box>
  //                   <Box p={3}><InputDate placeholder='Data' onChange={(e, v) => setDate(v)} /></Box>
  //                   <Box p={3}><Input placeholder='Descrição' onChange={e => setDescription(e.target.value)} /></Box>

  //                   <Box p={3}>
  //                     <Cascader changeOnSelect={true} defaultValue={parentArray} style={{marginRight: 20, width: '100%', minWidth: 400, maxWidth: 600}} options={options} onChange={handleCascaderChange} placeholder='Selecione a pasta' />
  //                   </Box>

  //                   <Box p={3}><Button loading={sending} onClick={() => handleSend({name, date, description})}>Enviar</Button></Box>
  //                 </Box>
  //               </Box>
  //             </Flex>
  //           )
  //         }
  //       />
  //     }

  //     {(examImages.length > 0) &&
  //       <ImageModal 
  //         onCloseCompleted={() => { setExamImages([]); setExamLink(''); setLinkLoading(false); setExamId(null) }}
  //         render={
  //           ({onRequestClose}) => (
  //             <Flex fd='column' h='100%' w='100%' width='100%'>
  //               <Box position='relative' h={60}>
  // 			          <Box fontSize='1.2rem' position='absolute' t={10} l={60} color='white'>Nome: {examData.name}<br />Descrição: {examData.description}</Box>
  //                 <Box color='white' fontSize='2rem' position='absolute' cursor='pointer' lh='0' top={26} l={26} onClick={onRequestClose}><ArrowLeftIcon/></Box>
  // 			          <Box position='absolute' r={26} t={26} color='white'>
  //                   <MenuOver 
  //                     options={[
  //                     {label: 'Download', value: 'download'},
  //                     {label: 'Mover para lixeira', value: 'delete'},
  //                     {label: 'Compartilhar', value: 'share'}
  //                     ]}
  //                     onSelect={action => handleMenuOverClick(action, examId)}
  //                   >
  //                     <MoreIcon style={{cursor: 'pointer'}} />
  //                   </MenuOver>
  //                 </Box>

  // 			          <Flex position='absolute' l='40%' color='white' marginTop={10}>
  // 				          <Flex fontSize='1rem' paddingRight='5px'>
  //                     {/* Mover para: <Cascader defaultValue={[]} style={{marginRight: 20, width: '100%', minWidth: 400, maxWidth: 600}} options={options} onChange={tree => handleMove(tree, examId)} placeholder='Selecione a pasta' /> */}
  //                     Mover Para:
  //                     <Select onChange={handleMove} placeholder="Pastas" style={{color: 'black'}}>
  //                       {allFolders.map(item => 
  //                         <option value={item.id}>{item.name}</option>  
  //                       )}
  //                     </Select>

  //                   </Flex>
  //                 </Flex>

  //               </Box>

  //               <Box p={4} overflow='auto' height="100%">
  //                 {/* <Box> */}
  //                   {/* <img alt='' src={examUrl} style={{width: '100%'}} /> */}
  //                   <Lightbox color='hsla(211,20%,43%,1)' images={examImages} />
  //                 {/* </Box> */}
  //               </Box>
  //             </Flex>
  //           )
  //         }
  //       />
  //     }

  //     {limitModalOpen &&
  //       <Modal2
  //         onCloseCompleted={() => { setLimitModalOpen(false) }}
  //         render={
  //           ({onRequestClose}) => (
  //             <Flex fd='column' h='100%'>
  //               <Box position='relative' h={64}>
  //                 <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
  //                 <Box p={3} ta='center'><Text secondary large>Aviso</Text></Box>
  //               </Box>
  //               <Box p={4} overflow='auto'>
  //                 <Box>
  //                   <Box ta='center' mb={4}>Você chegou ao limite do plano gratuito! Faça upgrade para ter mais espaço de armazenamento!</Box>
  //                   <Box ta='center'><Button>Ver planos</Button></Box>
  //                 </Box>
  //               </Box>
  //             </Flex>
  //           )
  //         }
  //       />
  //     }

  //     {modalShare &&
  //       <Modal 
  //         onCloseCompleted={() => { setModalShare(false) ; setDoctors([]) ; setSharedDoctors([]) }}
  //         render={
  //           ({onRequestClose}) => (
  //             <Flex fd='column' h='100%' style={{overflow: 'auto'}}>
  //               <Box position='relative' h={64}>
  //                 <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
  //                 <Box p={3} ta='center'><Text secondary large>Configurar compartilhamento</Text></Box>
  //                 <Box p={3} ta='center'>
  //                   {(sharedExam.medias.filter(m => m.image.includes('.pdf')).length > 0) && 
  //                     <IconPdf height={100} />
  //                   } 
  //                   {(sharedExam.medias.filter(m => m.image.includes('.doc')).length > 0) && 
  //                     <DocIcon height={100} />
  //                   }
  //                   {(sharedExam.medias.filter(m => m.image.includes('.doc') || m.image.includes('.pdf')).length == 0 && sharedExam.medias.length > 0) && (
  //                     <img alt='' src={sharedExam.medias[0].image} height={100} />
  //                     // <ImageIcon height={30} />
  //                   )}
  //                 </Box>
  //                 <Flex fd='column' p={1} ta='center'>
  //                   <Text>{sharedExam.name}</Text>
  //                   <Text fontSize={12}>{sharedExam.description}</Text>
  //                 </Flex>
  //                 <Flex fd='column'>
  //                   {sharedDoctors.length > 0 &&
  //                     <Flex fd='column' backgroundColor='#F9F9F9' p={3}>
  //                       <Text>Compartilhado com</Text>
  //                       <Text fontSize={10}>Esses são os medicos com quem você já compartilhou o exame em questão.</Text>
  //                     </Flex>
  //                   }
  //                   {sharedDoctors.map(item => 
  //                     <Flex p={3} jc='space-between' alignItems='center' cursor='pointer'>
  //                       <Text ta='center'>{item.doctor_name}</Text>
  //                       <Flex alignItems='center'>
  //                         { item.checked ? 
  //                           <Text style={{color: 'green', marginRight: 10}}>Ativado</Text> : 
  //                           <Text style={{color: 'grey', marginRight: 10}}>Desativado</Text>
  //                         }
  //                         <Switch
  //                           checked={item.checked}
  //                           onChange={checked => {handleCheck(checked, item.doctor)}}
  //                         />
  //                       </Flex>
  //                     </Flex>
  //                   )}
  //                   <Flex p={3} jc='space-between' alignItems='center' backgroundColor='#F9F9F9' >
  //                     <Text>Compartilhar também com</Text>
  //                     <Box mr={3}>
  //                     <Input placeholder='Buscar...' value={doctorSearch} onChange={e => { setDoctorSearch(e.target.value) ; setActivePage(1) }} /></Box>
  //                   </Flex>
  //                   {doctors.map(item => 
  //                     <Flex p={3} jc='space-between' alignItems='center' cursor='pointer'>
  //                       <Text ta='center'>{item.name}</Text>
  //                       <Flex alignItems='center'>
  //                         { item.checked ? 
  //                           <Text style={{color: 'green', marginRight: 10}}>Ativado</Text> : 
  //                           <Text style={{color: 'grey', marginRight: 10}}>Desativado</Text>
  //                         }
  //                         <Switch
  //                           checked={item.checked}
  //                           onChange={checked => {handleCheck(checked, item.id)}}
  //                         />
  //                       </Flex>
  //                     </Flex>
  //                   )}
  //                 </Flex>
  //               </Box>
  //             </Flex>
  //           )
  //         }
  //       />
  //     }
  //   </Box>
  // )
})

export default memo(props => {
  // const params = { pageSize: 10 }
  // if (props.id)
    // params.id = props.id
  // const { data, error, loading } = useMyFetch(`${BASE_API}/folder/?${serialize(params)}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
  // const { data, error, loading } = useQuery(LIST, { variables: { id: props.id } })
  // if (error) return `Erro! ${error.message}`
  // if (!data || loading) return <CircularProgress />
  // return <ListsCharts {...props} data={data} />
  return <ListsCharts {...props} />
})
