import React, { memo } from 'react'
import { Box } from '../../components/FlexBox'
import Input from '../../components/Input'
import Button from '../../components/Button'
import FormErrorBox from '../../components/FormErrorBox'

export default memo(({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid }) => {

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Input type='password' autoComplete='off' placeholder='Senha atual' name='currentPassword' value={values.currentPassword} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='currentPassword' errors={errors} touched={touched} />
      </Box>
      <Box mb={2}>
        <Input type='password' autoComplete='off' placeholder='Senha' name='password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='password' errors={errors} touched={touched} />
      </Box>
      <Box mb={1}>
        <Input type='password' autoComplete='off' placeholder='Confirme a Senha' name='passwordConfirmation' value={values.passwordConfirmation} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='passwordConfirmation' errors={errors} touched={touched} />
      </Box>
      <Box ta='center'>
        <Button type='submit' loading={isSubmitting}>Alterar senha</Button>
      </Box>
    </form>
  )
})
