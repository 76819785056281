import gql from 'graphql-tag'

export const LIST = gql`
  query ($id: Int) {
    folder(id: $id, pageSize: $pageSize, page: $page) @rest(type: "Folder", path: "/folder?{args}") {
      count
      next
      previous
      results @type(name: "ResultsFolder") {


        folder @type(name: "MetaFolder") {
          can_create_exam_without_chronology
          can_create_folder
          default
          deleted
          id
          name
          number_of_exams
          parent
          parent_id
          is_recycler_bin
          parent_name
          path
          user
        }
        folders @type(name: "SubFolder") {
          id
          number_of_exams
          name
          default
        }
        exams @type(name: "Exam") {
          id
          name
          created_at
          created_by_patient
          deleted
          description
          establishment
          exam_date
          folder
          formated_date
          id_sync
          medias
          name
          patient
        }


      }


    }
  }
`

export const UPLOAD = gql`
  mutation ($input: Media!) {
    media(input: $input) @rest(type: "Media", path: "/media/", method: "POST", bodySerializer: "form") {
      id
      image
      exam
    }
  }
`

export const CREATE_EXAM = gql`
  mutation ($input: Exam!) {
    media(input: $input) @rest(type: "Exam", path: "/exam/", method: "POST", bodySerializer: "form") {
      worked
      # NoResponse
    }
  }
`

export const CREATE = gql`
  mutation ($input: Folder!) {
    media(input: $input) @rest(type: "Folder", path: "/folder/", method: "POST", bodySerializer: "form") {
      NoResponse
    }
  }
`