import React, { memo } from 'react'

export default memo(() => {
  return (
    <div style={{padding: 32}}>
<h1>TERMOS DE USO - TRIA</h1>

<p>O presente instrumento (“Termos de uso e Política de Privacidade do aplicativo” ou “Termos”) rege as condições de utilização do Sistema denominado TRIA, disponibilizado por DOCPAD TECNOLOGIA EM SAÚDE LTDA, inscrita no CNPJ com o no 24.029.240/0001-87 com endereço à Rua Aquarela do Brasil, 333, Bloco 2 / 1801, São Conrado, Rio de Janeiro, Brasil (“Titular do Sistema”).</p>
<p>A DOCPAD TECNOLOGIA EM SAÚDE LTDA está disponibilizando no aplicativo TRIA acesso on-line as suas informações médicas e de seus dependentes que estejam armazenados no aplicativo tais como, resultados de exames, passagens em pronto atendimento, internações, prontuários, entre outras. O acesso a esses conteúdos multimídia será realizado via página web ​tria.health em uma área logada no aplicativo TRIA , de forma que o paciente acompanhe as suas informações médicas.</p>
<p>1 – ACEITAÇÃO</p>
<p>A utilização do Sistema atribui a condição de Usuário, e implica a aceitação plena, e sem reservas, de todos os itens destes Termos, na versão publicada no momento da utilização do Sistema. Deste modo, o Usuário deve sempre ler atentamente estes Termos, em cada ocasião em que utilizar o Sistema, pois eles podem sofrer modificações.</p>
<p>A utilização do Sistema também está submetida às regras estabelecidas na Política de Privacidade do aplicativo (disponível em: web.tria.health/politica-de-privacidade). Qualquer dúvida, reclamação, sugestão ou comentário que o Usuário possua com relação a estes Termos ou ao Sistema poderá ser direcionado ao e-mail suporte@tria.health.</p>
<p>2 – CONDIÇÕES DE ACESSO E UTILIZAÇÃO DO SISTEMA</p>
<p>2.1 Acesso</p>
<p>A utilização do Sistema, e de suas ferramentas, exige a prévia inscrição e o registro do Usuário. Por isso, é obrigação do Usuário informar todos os dados solicitados no momento do cadastro no Sistema, com precisão e veracidade, sendo vedada a inserção de dados falsos.</p>
<p>O Usuário somente poderá criar perfil em nome próprio, sendo absolutamente vedada a utilização de informações de terceiros para criação de perfil do Sistema.</p>
<p>O Titular do Sistema não se responsabiliza por informações incorretas ou imprecisas fornecidas pelos Usuários no momento de cadastro no Sistema, bem como por eventuais danos e prejuízos decorrentes de tais informações, sendo os Usuários os únicos responsáveis pela veracidade das informações apresentadas.</p>
<p>2.2 Incapacidade</p>
<p>O Sistema somente poderá ser utilizado por Usuários incapazes para fins civis (tanto relativa quanto absolutamente) mediante autorização de seus responsáveis legais, que deverão aceitar esses Termos. Assim, tais Usuários declaram, sob as penas da Lei que a aceitação destes Termos ocorreu mediante assistência e/ou representação de seus responsáveis legais.</p>
<p>No caso de usuários menores de 18 anos ou de outras pessoas que necessitem de representação na forma da lei, o cadastramento deverá ser realizado com a assistência dos pais ou dos representantes legais.</p>
<p>2.3 Utilização de senha segura</p>
<p>É de responsabilidade exclusiva do Usuário a escolha de senha segura para verificação de seu acesso ao Sistema, entendendo-se como tal aquela que contenha caracteres maiúsculos e minúsculos, números e símbolos (@, #, * etc).</p>
<p>O Usuário é o único responsável por manter em segredo a sua senha de acesso, além de utilizar as tecnologias adequadas para proteger a sua privacidade perante terceiros. Desse modo, o Usuário não deve fornecer sua senha a terceiros, nem mesmo a profissionais de saúde que eventualmente a solicitem, para quaisquer fins.</p>
<p>Não nos responsabilizamos por acessos indevidos a informações decorrentes de culpa exclusiva do Usuário como, por exemplo, em razão da utilização de senhas fracas ou da existência de programas maliciosos nos dispositivos de acesso dos Usuários.</p>
<p>2.4 Obrigação de utilizar o Sistema corretamente</p>
<p>O Usuário se compromete a utilizar o Sistema apenas para fins pessoais, de caráter não comercial, em conformidade com a legislação aplicável, com o disposto nestes Termos e na Política de Privacidade, bem como com a ordem pública, a moral e os bons costumes geralmente aceitos, e todas as normas aplicáveis.</p>
<p>Toda e qualquer ação executada ou conteúdo publicado pelo usuário durante o uso do aplicativo TRIA será de sua exclusiva e integral responsabilidade, devendo isentar e indenizar a DOCPAD de quaisquer reclamações, prejuízos, perdas e danos causados à DOCPAD, em decorrência de tais ações ou manifestações.</p>
<p>A DOCPAD se reserva o direito de incluir, excluir ou alterar os conteúdos e funcionalidades do aplicativo TRIA, bem como suspendê-lo temporariamente ou cancelá-lo, a qualquer momento, independentemente de aviso-prévio ao usuário. Da mesma forma, poderá modificar o presente “Termos de Uso e Política de Privacidade”, cuja versão mais recente estará sempre disponível para consulta através do próprio aplicativo TRIA.</p>
<p>2.5 Obrigação de utilizar o Conteúdo corretamente</p>
<p>O Usuário está ciente de que os direitos sobre todos os componentes do Sistema, tais como Arquivos de dados, textos, programas, arquivo de áudio, fotografias, vídeos, imagens, e outros, a estes não se limitando (coletivamente denominados “Conteúdos”), são de propriedade do Titular do Sistema, ou de terceiros, reproduzidos sob autorização, quando necessária.</p>
<p>O Usuário se compromete a não alimentar o Sistema com qualquer tipo de informação ou conteúdo contrário às normas aplicáveis, bem como se compromete a não utilizar o Sistema para qualquer fim ilícito.</p>
<p>O Usuário deverá se abster de obter, ou tentar obter, os Conteúdos por meios distintos daqueles que, em cada caso, tenham sido colocados à disposição para tais propósitos ou que, em geral, sejam empregados habitualmente na Internet para tais finalidades, mas que possuam o risco de dano ou inutilização do Sistema ou Conteúdos.</p>
<p>Caso o Usuário acesse quaisquer informações de terceiros, disponibilizados ou indicados no Sistema, cabe única e exclusivamente ao Usuário checar os eventuais Termos de Uso e Políticas de Privacidade aplicáveis ao acesso dessas informações, não podendo o Titular do Sistema ser responsabilizado, sob hipótese alguma, em razão desse acesso a sites, Sistemas, entre outros, de terceiros.</p>
<p>2.6 Propriedades Intelectual</p>
<p>O Usuário se declara ciente de que todo o Conteúdo a que terá acesso, inclusive os resultados de exames, está protegido por direitos de propriedade intelectual (estando esses direitos registrados ou não), que são de propriedade da Titular do Sistema ou de terceiros. Portanto, dentre outras ações incompatíveis, o Usuário não poderá copiar, modificar, licenciar, vender, ceder ou de qualquer outra forma distribuir o Conteúdo ou criar obras derivadas com base nele.</p>
<p>Todas as marcas, nomes comerciais, logotipos, nomes de domínio, nome do Sistema, sinais distintivos ou outra propriedade intelectual de qualquer espécie que estejam contidos no Sistema são de propriedade do Titular do Sistema ou de terceiros, sem que o uso ou acesso ao sistema, ou aos Conteúdos, possa ser entendido como atribuição de direitos ou autorização para que o usuário possa citar as tais marcas, nomes, logotipos, sinais e propriedade intelectual.</p>
<p>O Usuário concorda que não utilizará qualquer componente ou informação disponibilizado no Sistema de nenhuma forma, exceto para o uso normal e autorizado do Sistema, em conformidade com sua finalidade, com estes Termos de Uso, a Política de Privacidade e com a legislação a aplicável e vigente. A utilização do Sistema em qualquer modalidade e/ou finalidade diversa da permitida é expressamente proibida, e sujeitará o Usuário às sanções legais cabíveis.</p>
<p>Caso o Usuário crie obras derivadas de qualquer um dos Conteúdos disponibilizados no Sistema, ao Titular do Sistema se reserva o direito de requerer esses Conteúdos derivados, sem a necessidade de qualquer pagamento, concordando o Usuário em transferi-los ao Titular do Sistema.</p>
<p>2.7 Informações obtidas junto a Parceiros de Informação</p>
<p>Nos termos da autorização constante da Política de Privacidade, o Usuário, após o fornecimento de todos os dados necessários para tanto, autoriza o Sistema a buscar, de modo automatizado ou não, informações e dados junto a laboratórios, clínicas, hospitais, entre outros (“Parceiros da Informação”), incluindo, mas não se limitando a resultados de exames e dados médicos de maneira geral.</p>
<p>O Usuário manifesta ciência de que os dados exibidos no Sistema não substituem as informações oficiais dos Parceiros de Informações, no que não se responsabilizam, nem o Parceiro e nem o Titular do Sistema, por eventuais inconsistências nesse procedimento de coleta de informações, cabendo ao Usuário ratificar a validade de todas os dados coletados.</p>
<p>3. DO ACESSO AO SISTEMA</p>
<p>O Sistema pode ser fornecido de forma gratuita ou onerosa. Para ambas as modalidades não oferecemos qualquer garantia, implícita ou explícita, de qualquer natureza, seja em relação ao seu funcionamento, seja em relação à disponibilidade das informações.</p>
<p>Não obstante o disposto no item antecedente, o Titular do Sistema se reserva o direito de, a qualquer tempo, passar a cobrar pelo download e/ou uso do Sistema, e/ou de quaisquer funcionalidades adicionais, conforme condições que estiverem disponíveis na página de download do Sistema, sem que a eventual cobrança signifique a concessão de qualquer garantia adicional ao Usuário, ou qualquer extensão da responsabilidade do Titular do Sistema em relação ao previsto nestes Termos de Uso.</p>
<p>4. EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADE</p>
<p>A DOCPAD não se responsabiliza por qualquer dano, prejuízo ou perda no equipamento do usuário causado por falhas no aplicativo TRIA na Internet. A DOCPAD também não será responsável por qualquer vírus que possa atacar o equipamento do usuário em decorrência do acesso, utilização ou navegação no aplicativo e na Internet; ou como consequência da transferência de dados, arquivos, imagens, textos ou áudio. O usuário não poderá atribuir à DOCPAD nenhuma responsabilidade nem exigir o pagamento por lucro cessante em virtude de prejuízos resultantes de dificuldades técnicas, falhas no aplicativo ou na Internet e perda de dados ou mídias.</p>
<p>As dicas de saúde fornecidas pelo Sistema têm caráter meramente informativo. Não recomendamos, e não nos responsabilizamos pela adoção de tais informações pelo Usuário, sem o devido acompanhamento de profissional de saúde, apto a realizar tal aconselhamento.</p>
<p>O Titular do Sistema não será, em hipótese alguma, responsável por quaisquer danos decorrentes da interrupção de acesso ao Sistema, independentemente do motivo que cause essa suspensão, quer ocorra por culpa de terceiros ou não.</p>
<p>O Titular do Sistema não garante a ausência de quaisquer elementos nocivos no Sistema, quem possam produzir alterações no sistema informativo do Usuário (software e hardware) ou nos documentos eletrônicos armazenados no sistema informático, eximindo-se de qualquer responsabilidade pelos danos e prejuízos que possam decorrer da presença de elementos nocivos no Sistema.</p>
<p>Caso o Usuário identifique qualquer material ofensivo, ilegal, ou atentatório à moral e aos bons costumes no Sistema, deverá, imediatamente, comunicar ao Titular do Sistema, por meio do endereço de e-mail suporte@tria.health, para que possa ser apurada a denúncia. Ficará ao critério exclusivo do Titular do Sistema a apuração de tais denúncias.</p>
<p>O Usuário, igualmente, isenta o Titular do Sistema e os Parceiros de Informação de qualquer responsabilidade decorrente do mau uso do Sistema e pelos danos e prejuízos de toda natureza que possam decorrem da transmissão, difusão, disponibilização, recepção, obtenção ou acesso aos Conteúdos e que decorram, entre outros: (i) do descumprimento da lei, da moral, dos bons costumes geralmente aceitos ou da ordem pública; (ii) da infração aos direitos de propriedade intelectual e industrial, dos segredos empresariais, dos compromissos contratuais de qualquer tipo, dos direitos à honra, à intimidade pessoal e familiar, à imagem das pessoas, dos direitos de propriedade e de toda e qualquer natureza pertencentes a um terceiro; (iii) da realização de atos de concorrência desleal e publicidade ilícita; (iv) da falta de veracidade, precisão, exatidão, pertinência e/ou atualidade dos conteúdos; (v) da inadequação para qualquer tipo de propósito, ou da frustração, das expectativas geradas pelos Conteúdos.</p>
<p>Na máxima extensão permitida pela legislação aplicável, o máximo valor em relação ao qual o Titular do Sistema se responsabiliza não ultrapassará, em hipótese alguma, o montante eventualmente pago pelo Usuário para aquisição do Sistema.</p>
<p>5. DURAÇÃO E FINALIZAÇÃO</p>
<p>O acesso ao Sistema estará disponível por tempo indeterminado, podendo o Titular do Sistema, a seu exclusivo critério, a qualquer momento e independentemente de aviso prévio, suspender ou descontinuar, temporária ou definitivamente, o acesso ao Sistema, sem que seja devida qualquer indenização ou compensação ao Usuário.</p>
<p>A critério exclusivo do Titular do Sistema, o acesso ao Sistema por determinado(s) Usuário(s) também poderá ser negado ou suspenso, o que pode ensejar a exclusão de todas as informações disponibilizadas pelos Usuários, sem que seja devida qualquer indenização ou compensação ao(s) Usuário(s) que teve(tiveram) acesso negado ou suspenso.</p>
<p>Igualmente, o Usuário poderá solicitar sua exclusão do Sistema, pelo e-mail suporte@tria.health, o qual será respondido no mais curto espaço de tempo possível. A exclusão dos dados será processada nos termos descritos na Política de Privacidade.</p>
<p>6. LEGISLAÇÃO E FORO</p>
<p>Estes Termos de Uso serão regidos, interpretados e executados de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com leis de outros estados ou países, sendo competente o Foro da Comarca do Rio de Janeiro, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento.</p>
<p>O Usuário consente, expressamente, com a competência desse juízo, e renuncia, neste ato, à competência de qualquer outro for, por mais privilegiado que seja ou venha a ser.</p>
<p>7. CONTATO</p>
<p>Caso seja necessário contatarmos você, tal será realizado por meio do endereço de e-mail informado quando de seu cadastro no Sistema, ou por meio de mensagens disponibilizadas no próprio Sistema.</p>
<p>Em caso de dúvidas, sugestões ou reclamações, o Usuário pode contatar o Titular do Sistema por meio de uma das seguintes modalidades:</p>
<p>E-mail: suporte@tria.health Última atualização: 13/6/2019</p>
<p>POLÍTICA DE PRIVACIDADE</p>
<p>O presente instrumento (“Política de Privacidade”) regula o tratamento das informações inseridas espontaneamente por você (doravante “Usuário”), ou coletadas automaticamente, no Sistema TRIA (doravante “Sistema”) disponibilizado por DOCPAD TECNOLOGIA EM SAÚDE LTDA inscrita no CNPJ com o no 24.029.240/0001-87, com endereço à Rua Aquarela do Brasil, 333, Bloco 2 / 1801, São Conrado, Rio de Janeiro, Brasil (“Titular do Sistema”).</p>
<p>O Sistema permite que o Usuário registre o controle o uso de medicamentos, datas de consultas, vacinas, médicos, receituários, exames laboratoriais, linha do tempo da saúde, entre outros. Assim, o Sistema objetiva facilitar os cuidados com sua saúde, permitindo fácil acesso a diversas informações.</p>
<p>Ao realizar seu cadastro no Sistema, o Usuário manifesta ampla e irrestrita ciência e concordância com esta Política de Privacidade (“Política”), correspondendo tal aceitação em consentimento livre, expresso e informado. Ou seja, a partir do momento em que o Usuário iniciar a utilização do Sistema, o mesmo declara sua ciência e concordância irrestrita, com a presente Política.</p>
<p>Este documento deve ser interpretado em consonância com o disposto nos Termos de Uso do Sistema (“Termos de Uso”), disponíveis do endereço: web.tria.health/politica-de-privacidade.</p>
<p>Esta Política visa preservar nosso bom relacionamento, garantido que o Usuário tenha ciência de quais informações são coletadas quando da utilização do Sistema e como elas são tratadas. Desse modo, esta Política aborda, entre outros, os seguintes assuntos:</p>
<p>1. Coleta das informações;</p>
<p>2. Tratamento das informações;</p>
<p>3. Uso de informações;</p>
<p>4. Como protegemos as informações;</p>
<p>5. Atualizações da Política;</p>
<p>6. Legislação e foro aplicáveis; e</p>
<p>7. Informações de Contato.</p>
<p>1. COLETA DE INFORMAÇÕES</p>
<p>Coletamos informações dos Usuários de 02 formas principais: (i) dados fornecidos pelo Usuário; e (ii) dados coletados automaticamente pelo Sistema.</p>
<p>Dados fornecidos pelo Usuário​: Coletamos todos os dados inseridos espontaneamente pelo Usuário no Sistema, como as informações cadastrais fornecidas para criação da conta de acesso ao Sistema, bem como os dados inseridos durante a utilização do Sistema, pelo preenchimento dos formulários e inclusão de informações sobre sua saúde, tais como: resultado de exames, histórico familiar, médicos, medicamentos, consultas, cirurgias, vacinas, contatos de emergência, dependentes, a estas não se limitando.</p>
<p>Dados coletados automaticamente pelo Sistema​: São informações coletadas pelo Sistema, independentemente do fornecimento pelo Usuário. A cada acesso ao Sistema obteremos alguns dados automaticamente, tais como, mas não se limitando a, características do dispositivo de acesso, número IP com informação de data e hora, origem do IP, funcionalidades acessadas, informações sobre cliques, entre outros. Podemos utilizar na coleta de tais dados algumas tecnologias padrões, como cookies, pixel tags, beacons e local shared objects, que servem para identificar o usuário e tornar seu acesso mais rápido, bem como para estudarmos os seus hábitos e assim lhe proporcionar uma melhor experiência na utilização do Sistema. Alguns desses recursos podem ser bloqueados pelo Usuário quando de sua utilização do Sistema. Neste caso, no entanto, o Usuário deve estar ciente de que algumas funções do Sistema poderão não funcionar corretamente.</p>
<p>Ademais, o Usuário autoriza que, por meio do Sistema, sejam obtidas informações e dados, de modo automatizado ou não, junto a laboratórios, clínicas, hospitais, entre outros (“Parceiros de Informação”), incluindo, mas não se limitando a resultados de exames e dados médicos de maneira geral, o que será possível mediante a inserção de todas as credenciais de acesso necessárias, no que o Usuário, desde logo, expressa o pleno consentimento e autorização para tanto.</p>
<p>O Usuário compromete-se a inserir apenas informações sobre si mesmo ou sobre seus “Dependentes”, assim considerados indivíduos legalmente incapazes, que estejam sob responsabilidade legal do Usuário, conforme expressamente referido nos Termos de Uso. Ao inserir informações sobre Dependentes, o Usuário declara ser responsável legal do Dependente, possuindo todas as autorizações necessárias para o fornecimento das informações.</p>
<p>Menores de idade não deverão utilizar o Sistema diretamente, a menos que devidamente autorizados por seus pais responsáveis. Caso você tenha ciência de que menor de idade sob sua responsabilidade está utilizando o Sistema sem a devida autorização, favor nos informe por meio do e-mail: suporte@tria.health, para que possamos excluir o perfil e as informações de nossos servidores.</p>
<p>O presente Sistema, por suas características e utilização, não constitui Prontuário Eletrônico do Paciente (PEP).</p>
<p>Não nos responsabilizamos por informações coletadas em razão do uso indevido do Sistema, ou seja, em desrespeito às regras estipuladas na presente Política de Privacidade, nos Termos de Uso e demais leis e políticas pertinentes (“Normas”). Assim, o Usuário isenta, completamente, o Titular do Sistema e os Parceiros de Informação em relação a quaisquer danos que possam se originar em razão do uso dos dados e informações constantes do Sistema, por motivos atribuíveis exclusivamente ao Usuário.</p>
<p>Reservamo-nos o direito de excluir quaisquer perfis de Usuários que insiram informações de terceiros no Sistema, sem expressa autorização destas, ou em desrespeito ao disposto nas Normas, no que todos os dados serão perdidos de forma definitiva, cabendo ao usuário manter cópia de segurança das informações, conforme expressamente aludido nos Termos de Uso.</p>
<p>O TRIA pode, eventualmente, incluir anúncios no Sistema, que podem coletar algumas informações da utilização do Sistema pelo Usuário. Não nos responsabilizamos por informações coletadas por anunciantes, bem como pela utilização de tais informações por terceiros.</p>
<p>2. TRATAMENTO DAS INFORMAÇÕES</p>
<p>Trataremos as informações coletadas no modo descrito nesta Política de Privacidade, mediante autorização do Usuário. As informações coletadas dividem-se em “informações sensíveis” e “informações Não sensíveis”.</p>
<p>São consideradas como “sensíveis” todas as informações cujo tratamento possa, de alguma forma, representar discriminação do titular, incluindo origem racial ou étnica, dados referentes à saúde e à vida sexual, bem como os dados genéticos e biométricos. Todas as informações que não sejam sensíveis, nos termos acima conceituados, serão consideradas como Não sensíveis, mas ainda assim tratadas como confidenciais.</p>
<p>As informações coletadas automaticamente serão utilizadas para operar, manter e aprimorar o Sistema, para fins comerciais, bem como para melhorar a experiência do Usuário, personalizar o serviço e auxiliar na oferta de produtos e serviço.</p>
<p>Sob hipótese alguma, uma informação sensível está obtida automaticamente, sendo mandatória a voluntária inclusão delas, pelo Usuário, salvo se expressamente autorizado pelo Usuário, especialmente no que concerne à obtenção de dados junto aos Parceiros de Informação. As informações sensíveis poderão ser tratadas para fins de pesquisa científica ou estatística, sempre de forma a respeitar os direitos dos Usuários.</p>
<p>Qualquer informação coletada poderá ser excluída, quando deixar de ser necessária ou pertinente para a finalidade que justificou a sua coleta e tratamento, sendo certo que nenhum dado pessoal, sensível ou não, irá trafegar pelo sistema ou será armazenado em qualquer local, físico ou remoto, após a sua exclusão.</p>
<p>O Usuário poderá excluir e/ou solicitar a exclusão de suas informações sensíveis, quando excluídas pelo Usuário, serão apagadas dos servidores do TRIA, com a maior brevidade possível. A remoção de certas informações, como dos dados cadastrais, poderá impedir a utilização do Sistema pelo Usuário.</p>
<p>3. USO DAS INFORMAÇÕES</p>
<p>O TRIA se esforçará para seguir todas as obrigações legais, especialmente em relação às informações existentes no Sistema.</p>
<p>As informações constantes no Sistema poderão ser repassadas a terceiros, de forma gratuita, ou não, no que o Usuário, desde logo, dá o consentimento livre, expresso e informado para tanto, nos termos exigidos pela Lei No 12.965/2014, Marco Civil da Internet.</p>
<p>Importante que você esteja ciente também de que, caso o TRIA seja unificada com outra empresa, seja vendida (no todo ou em parte), ou deixe de operar, as informações dos Usuários comporão os ativos transferidos ou adquiridos por terceiros. Neste caso, mesmo as informações sensíveis serão repassadas a terceiros, de modo a permitir a continuidade da utilização do Sistema.</p>
<p>Por fim, mediante ordem judicial, poderemos fornecer os dados dos Usuários solicitados, inclusive informações sensíveis. Outras situações em que tal se faça necessário para proteger direitos superiores à privacidade, também atuaremos em sentido semelhante, independentemente de decisão do Poder Judiciário, sempre com o objetivo de resguardar os Usuários.</p>
<p>4. CONFIABILIDADE DA GUARDA DAS INFORMAÇÕES</p>
<p>As informações do Usuário, sensíveis ou não, serão guardadas de forma sigilosa, e qualquer funcionário ou prestador de serviços do TRIA que entre em contato com elas se comprometerão a não desvirtuar a sua utilização, bem como em não as usar de modo destoante do ora previsto nesta Política de Privacidade e nos Termos de Uso. Nós empregamos todos os esforços razoáveis de mercado para garantir a segurança de seus sistemas na guarda de referidos dados.</p>
<p>Para que tais medidas se tornem viáveis, nós adotamos as seguintes precauções na guarda e tratamento das informações dos Usuários:</p>
<p>(a) Utilizamos os métodos mais confiáveis de segurança;</p>
<p>(b) Possuímos proteção contra acesso não autorizado em nossos sistemas;</p>
<p>(c) Somente autorizamos o acesso de pessoas previamente estabelecidas aos locais onde armazenamos as informações;</p>
<p>(d) Aqueles que entrarem em contato com as informações deverão se comprometer a manter sigilo absoluto. A quebra do sigilo acarretará responsabilidade civil e o responsável será processado nos moldes da legislação brasileira.</p>
<p>Tais precauções, no entanto, não garantem integralmente que todas as informações que trafegam no Sistema não sejam acessadas por terceiros mal-intencionados, por meio de métodos desenvolvidos para obter informações de forma indevida. Em razão disso, não dos responsabilizados por tais acessos ilícitos.</p>
<p>Não necessariamente, as informações ficarão armazenadas em servidores localizados no Brasil, podendo ser encaminhadas para qualquer país em que o TRIA tiver servidores. Favor atentar para o fato de que, mesmo que seu país trate a coleta e o envio de forma diferente, reservamo-nos ao direito de tratá-la consoante ora estipulado.</p>
<p>A DOCPAD também não se responsabiliza por atos de terceiros que logrem êxito em coletar ou utilizar, por quaisquer meios, dados cadastrais e informações disponibilizadas no Sistema pelo Usuário.</p>
<p>5. ATUALIZAÇÕES</p>
<p>Reservamo-nos o direito de alterar a nossa Política de Privacidade quantas vezes forem necessárias, de modo a fornecer-lhe maior segurança e privacidade. Por isso é importante que Usuário acesse nossa Política de Privacidade sempre que for utilizar o Sistema. Para facilitar a visualização, ao final desse documento iremos lhe informar a data da última modificação do mesmo.</p>
<p>Em caso de qualquer alteração com relação ao tratamento que damos às informações sensíveis, os Usuários serão comunicados previamente, por meio de e-mail cadastrado no Sistema, sobre tais alterações, devendo deixar de utilizar o Sistema caso não concordem com quaisquer modificações nesta Política de Privacidade.</p>
<p>6 LEGISLAÇÃO E FORO</p>
<p>Essa Política de Privacidade será redigida, interpretada e executada de acordo com as Leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com as leis de outros estados ou países, sendo competente o Foro da Comarca do Rio de Janeiro, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento. O Usuário consente, expressamente, com a competência desse juízo, e renuncia, neste ato, à competência de qualquer outro foro, por mais privilegiado que seja ou venha a ser.</p>
<p>7 CONTATO</p>
<p>Caso seja necessário contatarmos você, tal será realizado por meio do endereço de e-mail informado quando de seu cadastro no Sistema, ou por meio de mensagens disponibilizadas no próprio Sistema.</p>
<p>Em caso de dúvidas, sugestões ou reclamações, o Usuário pode contatar o DOCPAD por meio de uma das seguintes modalidades:</p>
<p>E-mail: suporte@tria.health Última atualização: 13/06/2019</p>
    </div>
  )
})
