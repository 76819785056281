import React, { memo } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import { CloseIcon } from '../../components/Icons'
import Input from '../../components/Input'
import Button from '../../components/Button'

import useInput from '../../hooks/useInput'

import { useMutation } from '../../hooks/useApollo'
import { UPDATE } from './Gqls'

export default memo(({ onUpdated, onRequestClose, message }) => {

  const currentPasswordInput = useInput('')
  const newPasswordInput = useInput('')
  const newPasswordConfirmationInput = useInput('')

  const updateItem = useMutation(UPDATE)

  const handleUpdate = () => {
    if (newPasswordInput.value !== newPasswordConfirmationInput.value) return message('Confirmação de senha incorreta')

    updateItem({ variables: { input: { password: newPasswordInput.value } } })
      .then(data => {
        onUpdated()
      })
      .catch(error => {
        console.error('error', error)
      })
  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Alterar Senha</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Box mb={3}>
          <Box>Senha atual</Box>
          <Box><Input {...currentPasswordInput} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Nova senha</Box>
          <Box><Input {...newPasswordInput} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Repita a nova senha</Box>
          <Box><Input {...newPasswordConfirmationInput} /></Box>
        </Box>

        <Box><Button onClick={handleUpdate}>Salvar</Button></Box>
      </Box>
    </Flex>
  )
})
