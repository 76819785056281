import React, { memo, useState } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import Modal from '../../components/StyledModal'
import CircularProgress from '../../components/CircularProgress'
import { FormatDate } from '../../components/Format'
import FormatPhone from '../../components/FormatPhone'
import { useQuery } from '../../hooks/useApollo'
import { GET } from './Gqls'
import Button from '../../components/Button'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

import Avatar from './Avatar'
import Info from './Info'
import Addresses from './Addresses'
import Plan from './Plan'
import Professional from './Professional'
import Password from './Password'
import { Grid } from './styles'

const Home = memo(({ me, message, client }) => {

  const [avatarModalOpen, setAvatarModalOpen] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [professionalModalOpen, setProfessionalModalOpen] = useState(false)
  const [addressesModalOpen, setAddressesModalOpen] = useState(false)
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)

  const [doctor, setDoctor] = useState(me)

  const fetchDoctor = async () => {
    const result = await client.query({ query: GET, fetchPolicy: 'network-only' })
    setDoctor(result.data.doctor)
  }

  const unlinkEstablishment = id => {
    fetch(`${BASE_API}/doctorEstablishment/?doctor_id=${doctor.id}&establishment_id=${id}`, { method: 'DELETE', headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
    window.location.href = `/meuperfil`
  }

  return (
    <>
      <Box mx="2">
        <Flex mx='auto' w={100} h={100} overflow='hidden' borderRadius='50%' border='3px solid #eee'>
          <img style={{ width: '100%' }} src={doctor.thumbnail} alt='' />
        </Flex>
        <Box mx='auto' ta='center' mb={2} onClick={() => setAvatarModalOpen(true)}><Text cursor='pointer' fs={12}>Alterar imagem</Text></Box>

        <Box mx='auto' mb={4} ta='center' fs={3}>
          {doctor.name}
        </Box>

        <Box mx='auto' mb={4} ta='center' fs={3}>
          Descrição: {doctor.description}
        </Box>

        {/* <Flex jc='space-between' maxWidth='810px' mb="4" fw='wrap' mx='auto'>

          <Box p={3} width={[1, 1/2]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Informações</Text></Box>
              <Box><Text pointer onClick={() => setInfoModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box fontSize={2}>
              <Box mt={2}><Text>Email:</Text> {doctor.login}</Box>
              <Box mt={2}><Text strong>Data de Nascimento: </Text><FormatDate>{doctor.birthDate}</FormatDate></Box>
              <Box mt={2}><Text>Gênero:</Text> {doctor.gender}</Box>              
              <Box mt={2}><Text strong>Telefone: </Text><FormatPhone>{doctor.phone.replace(/\D/g, '')}</FormatPhone></Box>
              <Box mt={2}><Text>Valor da consulta:</Text> {doctor.price && `R$ ${doctor.price}`}</Box>
              <Box mt={2} ta='right' cursor='pointer' onClick={() => setPasswordModalOpen(true)}><Text>Alterar senha</Text></Box>
            </Box>
          </Box>

          <Box p={3} width={[1, 1/2]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Profissional</Text></Box>
              <Box><Text pointer onClick={() => setProfessionalModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box fontSize={2}>
              <Box mt={2}><Text>Especialidade Primária:</Text> {doctor.specialtyPrimary != null ? doctor.specialtyPrimaryObj.name : ''}</Box>
              <Box mt={2}><Text>Especialidade Secundária:</Text> {doctor.specialtySecundary != null ? doctor.specialtySecundaryObj.name : ''}</Box>
              <Box mt={2}><Text>Inscrição no Conselho:</Text> {doctor.crmType}</Box>
              <Box mt={2}><Text>Número:</Text> {doctor.crm}</Box>
              <Box mt={2}><Text>UF:</Text> {doctor.crmUf}</Box>
            </Box>
          </Box>

          <Box p={3} width={[1, 1/2]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Planos Atendidos</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setPlanModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box fontSize={2}>
              <Box mt={2}>{doctor.healthInsurances.map(item => item.name).join(', ')}</Box>
            </Box>
          </Box>

          <Box p={3} width={[1, 1/2]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Endereços</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setAddressesModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box fontSize={2}>
              <Box mt={1}>
                {doctor.addresses.map(item => (
                  <Box key={item.id} mt={3}>
                    {item.name}<br />
                    {item.street}<br />
                    {item.complement}<br />
                    {item.district}<br />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box fontSize={2}>
              <Box mt={1}>
                {doctor.establishments.map(establishment => (
                  <Box mt={1} key={establishment.id}>
                    {establishment.addresses.map(item => (
                      <Box mt={3} key={item.id}>
                        {item.name}<br />
                        <Box><Button small onClick={() => unlinkEstablishment(establishment.id)}>Desvincular estabelecimento</Button></Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

        </Flex> */}
        <Grid>
          <Box p={3} width={'100%'} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Informações</Text></Box>
              <Box><Text pointer onClick={() => setInfoModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box fontSize={2}>
              <Box mt={2}><Text>Email:</Text> {doctor.login}</Box>
              <Box mt={2}><Text strong>Data de Nascimento: </Text><FormatDate>{doctor.birthDate}</FormatDate></Box>
              <Box mt={2}><Text>Gênero:</Text> {doctor.gender}</Box>
              <Box mt={2}><Text strong>Telefone: </Text><FormatPhone>{doctor.phone ? doctor.phone.replace(/\D/g, '') : ''}</FormatPhone></Box>
              <Box mt={2}><Text>Valor da consulta:</Text> {doctor.price && `R$ ${doctor.price}`}</Box>
              <Box mt={2} ta='right' cursor='pointer' onClick={() => setPasswordModalOpen(true)}><Text>Alterar senha</Text></Box>
            </Box>
          </Box>

          <Box p={3} width={'100%'} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Profissional</Text></Box>
              <Box><Text pointer onClick={() => setProfessionalModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box fontSize={2}>
              <Box mt={2}><Text>Especialidade Primária:</Text> {doctor.specialtyPrimary != null ? doctor.specialtyPrimaryObj.name : ''}</Box>
              <Box mt={2}><Text>Especialidade Secundária:</Text> {doctor.specialtySecundary != null ? doctor.specialtySecundaryObj.name : ''}</Box>
              <Box mt={2}><Text>Inscrição no Conselho:</Text> {doctor.crmType}</Box>
              <Box mt={2}><Text>Número:</Text> {doctor.crm}</Box>
              <Box mt={2}><Text>UF:</Text> {doctor.crmUf}</Box>
            </Box>
          </Box>

          <Box p={3} width={'100%'} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Planos Atendidos</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setPlanModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box fontSize={2}>
              <Box mt={2}>{doctor.healthInsurances.map(item => item.name).join(', ')}</Box>
            </Box>
          </Box>

          <Box p={3} width={'100%'} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Endereços</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setAddressesModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box fontSize={2}>
              <Box mt={1}>
                {doctor.addresses.map(item => (
                  <Box key={item.id} mt={3}>
                    {item.name}<br />
                    {item.street}<br />
                    {item.complement}<br />
                    {item.district}<br />
                  </Box>
                ))}
              </Box>
            </Box>
            <Box fontSize={2}>
              <Box mt={1}>
                {doctor.establishments.map(establishment => (
                  <Box mt={1} key={establishment.id}>
                    {establishment.addresses.map(item => (
                      <Box mt={3} key={item.id}>
                        {item.name}<br />
                        <Box><Button small onClick={() => unlinkEstablishment(establishment.id)}>Desvincular estabelecimento</Button></Box>
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>

        </Grid>

      </Box>

      {avatarModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setAvatarModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Avatar
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={doctor}
                onUpdated={() => { fetchDoctor(); onRequestClose() }}
              />
            )
          }
        />
      }

      {infoModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setInfoModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Info
                onRequestClose={onRequestClose}
                initialData={doctor}
                onUpdated={() => { fetchDoctor(); message('Salvo com sucesso!'); setInfoModalOpen(false) }}
              />
            )
          }
        />
      }

      {professionalModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setProfessionalModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Professional
                onRequestClose={onRequestClose}
                initialData={doctor}
                onUpdated={() => { fetchDoctor(); message('Salvo com sucesso!'); setProfessionalModalOpen(false) }}
              />
            )
          }
        />
      }

      {passwordModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setPasswordModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Password
                message={message}
                onRequestClose={onRequestClose}
                initialData={doctor}
                onUpdated={() => { message('Salvo com sucesso!'); setPasswordModalOpen(false) }}
              />
            )
          }
        />
      }

      {planModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setPlanModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Plan
                me={doctor}
                message={message}
                onRequestClose={onRequestClose}
                initialData={doctor.addresses}
                onUpdated={() => { fetchDoctor(); message('Salvo com sucesso!'); }}
              />
            )
          }
        />
      }

      {addressesModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setAddressesModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Addresses
                message={message}
                onRequestClose={onRequestClose}
                initialData={doctor.addresses}
                onUpdated={() => { message('Salvo com sucesso!'); setAddressesModalOpen(false) }}
              />
            )
          }
        />
      }

    </>
  )
})


export default memo(props => {
  const { data, error, loading } = useQuery(GET)
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  return <Home {...props} me={data.doctor} />
})
