import React, { memo } from 'react'

import { Box } from '../../components/FlexBox'
import H1 from '../../components/H1'
import Charts from './Charts'
import ListsCharts from './ListsCharts'
import { Main } from './styles'

export default memo(({ id, navigate, message }) => (
  <Main>
    <Box p={4} w={"100%"} >
      <Box mt={3} w={"100%"}>
        <ListsCharts id={id} navigate={navigate} message={message} />
        <Charts id={id} navigate={navigate} message={message} />
      </Box>
    </Box>
  </Main>
))
