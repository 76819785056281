import React, { memo } from "react";
import { Box, Flex } from "../../components/FlexBox";
import {
  BoxViews,
  ContainerBody,
  ContainerHeader
} from "./styles";

export default memo(({ viewDoctor }) => {
  return (
    <BoxViews className="doctor">
      <Box>
        <ContainerHeader>
          <h2>Informações do médico</h2>
        </ContainerHeader>
        <ContainerBody>
          <Flex w="100%" jc="space-between" ai="center">
            <div className="pic-profile">
              <img src={viewDoctor.doctorPicProfile} alt="Sem foto de perfil" />
            </div>
          </Flex>
          <div className="infors">
            <div>
              <span>Nome</span>
              <span>{viewDoctor.doctorName}</span>
            </div>
            <div>
              <span>Especialização</span>
              <span>{viewDoctor.specialization}</span>
            </div>
            <div>
              <span>CRM</span>
              <span>{viewDoctor.crm}</span>
            </div>
            <div>
              <span>E-mail</span>
              <span>{viewDoctor.email}</span>
            </div>
            <div>
              <span>Telefone</span>
              <span>{viewDoctor.phone}</span>
            </div>
          </div>
        </ContainerBody>
      </Box>
      <Box>
        <ContainerHeader>
          <h2>Prescrições</h2>
        </ContainerHeader>
        <ContainerBody className="prescription">
          <Flex w="100%" jc="space-between" ai="center">
            <h3>Prescrição 1</h3>
            {/* <BtnPink>Ver</BtnPink> */}
          </Flex>
          <div className="grid">
            <div className="infors">
              <div>
                <span>Prescrição</span>
                <span>{viewDoctor.prescritionOne.exam}</span>
              </div>
              <div>
                <span>Paciente</span>
                <span>{viewDoctor.prescritionOne.patient}</span>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="infors">
              <div>
                <span>Tipo de exame</span>
                <span>{viewDoctor.prescritionOne.examType}</span>
              </div>
              <div>
                <span>Data</span>
                <span>{viewDoctor.prescritionOne.date}</span>
              </div>
              <div>
                <span>Status</span>
                <span>{viewDoctor.prescritionOne.status ? "Atual" : "Antigo"}</span>
              </div>
            </div>
          </div>
        </ContainerBody>
        <ContainerBody className="prescription">
          <Flex w="100%" jc="space-between" ai="center">
            <h3>Prescrição 2</h3>
            {/* <BtnPink>Ver</BtnPink> */}
          </Flex>
          <div className="grid">
            <div className="infors">
              <div>
                <span>Prescrição</span>
                <span>{viewDoctor.prescritionTwo.exam}</span>
              </div>
              <div>
                <span>Paciente</span>
                <span>{viewDoctor.prescritionTwo.patient}</span>
              </div>
            </div>
          </div>
          <div className="grid">
            <div className="infors">
              <div>
                <span>Tipo de exame</span>
                <span>{viewDoctor.prescritionTwo.examType}</span>
              </div>
              <div>
                <span>Data</span>
                <span>{viewDoctor.prescritionTwo.date}</span>
              </div>
              <div>
                <span>Status</span>
                <span>{viewDoctor.prescritionTwo.status ? "Atual" : "Antigo"}</span>
              </div>
            </div>
          </div>
        </ContainerBody>
      </Box>
    </BoxViews>
  );
});
