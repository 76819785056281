import gql from "graphql-tag";

export const ME_DOCTOR = gql`
  query {
    doctor @rest(type: "Doctor", path: "/doctor") {
      id
      name
      login
      thumbnail
    }
  }
`;

export const ME_PATIENT = gql`
  query {
    patient @rest(type: "Patient", path: "/patient") {
      id
      name
      login
      thumbnail
    }
  }
`;

export const ME_OPERATOR = gql`
  query {
    operator @rest(type: "Operator", path: "/get-health-operator/") {
      id
      name
      login
      thumbnail
    }
  }
`;

export const ME_ESTABLISHMENT = gql`
  query {
    establishment @rest(type: "Establishment", path: "/establishment") {
      id
      name
      login
      thumbnail
      addresses
    }
  }
`;
