import React, { memo } from "react";

import { Box } from "../../components/FlexBox";
import {
  BtnPink,
  Container,
  Details,
  PrescritionDates
} from "./styles";

export default memo(({ content, viewPrescription }) => {
  return (
    <Box>
      {content.map((item, i) => (
        <Container key={i}>
          <Details>
            <div>
              <span>Prescrição</span>
              <span>{item.exam}</span>
            </div>
            <PrescritionDates>
              <div>
                <span>01.</span>
                <div>
                  <span>Data da prescrição 1</span>
                  <span>{item.prescritionDateOne}</span>
                </div>
              </div>
              <div>
                <span>01.</span>
                <div>
                  <span>Data da prescrição 2</span>
                  <span>{item.prescritionDateTwo}</span>
                </div>
              </div>
            </PrescritionDates>
          </Details>

          <BtnPink onClick={() => viewPrescription(item)}>Ver</BtnPink>
        </Container>
      ))}
    </Box>
  );
});
