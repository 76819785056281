import React, { memo, useState, useEffect } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import H1 from '../../components/H1'
import Text from '../../components/Text'
import ButtonRaw from '../../components/ButtonRaw'
import { EditIcon } from '../../components/Icons'
import Modal from '../../components/StyledModal'
import Select from '../../components/Select'
import MyDatePicker from '../../components/DatePicker'

import ChartYear from './ChartYear'
import ChartMonth from './ChartMonth'
import ChartDay from './ChartDay'

import GoogleMapReact from 'google-map-react'

import CircularProgress from '../../components/CircularProgress'

import { useQuery } from '../../hooks/useApollo'
import { ME_ESTABLISHMENT } from './Gqls'

// import BASE_API from '../../constants/baseApi'
// import AUTH_TOKEN from '../../constants/authToken'

import Avatar from './Avatar'
import Info from './Info'
import Specialties from './Specialties'
import Addresses from './Addresses'
import { date } from 'yup'
import Item from 'antd/lib/list/Item'
import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

import * as moment from 'moment'

const formatDate = text => {
  const array = text.split('T')[0].split('-')
  return `${array[2]}/${array[1]}/${array[0]}`
}

const monthMap = { 0: 'Jan', 1: 'Fev', 2: 'Mar', 3: 'Abr', 4: 'Mai', 5: 'Jun', 6: 'Jul', 7: 'Ago', 8: 'Set', 9: 'Out', 10: 'Nov', 11: 'Dez' }
const monthMapReverse = { 'Jan': 0, 'Fev': 1, 'Mar': 2, 'Abr': 3, 'Mai': 4, 'Jun': 5, 'Jul': 6, 'Ago': 7, 'Set': 8, 'Out': 9, 'Nov': 10, 'Dez': 11 }
const monthNames = ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"];

const getYears = () => {
  const today = new Date();

  const dateNames = []

  for(var i = 2; i >= 0; i -= 1) {
    const d = new Date(today.getFullYear() - i, 1);
    const year = d.getFullYear();
    dateNames.push(year)
  }

  return dateNames
}

const getDates = () => {
  const today = new Date();

  const dateNames = []

  for(var i = 5; i >= 0; i -= 1) {
    const d = new Date(today.getFullYear(), today.getMonth() - i, 1);
    const month = monthNames[d.getMonth()];
    dateNames.push([month, d.getFullYear()])
  }

  return dateNames
}

const Home = memo(({ me, message, client }) => {

  const [avatarModalOpen, setAvatarModalOpen] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [specialtiesModalOpen, setSpecialtiesModalOpen] = useState(false)
  const [addressesModalOpen, setAddressesModalOpen] = useState(false)
  const [chartYear, setChartYear] = useState(getYears()[2])
  const [chartData, setChartData] = useState([])
  const [chartMonth, setChartMonth] = useState(getDates().slice(-1)[0])
  const [chartMonthData, setChartMonthData] = useState([])
  const [chartDayData, setChartDayData] = useState([])
  const [date, setDate] = useState(new Date())

  const loadChartYearInfo = async (year) => {
    const arrayData = []
    const month = 12
    const response = await fetch(`${BASE_API}/examCountByYear/?year=${year}`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' }})
    const jsonData = await response.json()
  
    for(let i = 0; i < month; i++) {
  
      let linked = 0
      let establishmentPatients = 0
      let visualized = 0
      let auto = 0
  
      jsonData['linked_exams'].months.forEach(item => {
        if (item.month === (i + 1)) linked = item.count
      })
  
      jsonData['establishment_patients'].months.forEach(item => {
        if (item.month === (i + 1)) establishmentPatients = item.count
      })
  
      jsonData['visualized_exams'].months.forEach(item => {
        if (item.month === (i + 1)) visualized = item.count
      })

      jsonData['establishment_new_patient'].months.forEach(item => {
        if (item.month === (i + 1)) auto = item.count
      })
  
      arrayData.push({ name: monthMap[i], 'Usuários conectados': establishmentPatients, 'Exames distribuídos': linked, 'Exames visualizados': visualized, 'Usuários sincronizados automaticamente': auto },)
    }

    setChartData(arrayData)
    setChartYear(year)
  }

  const loadChartMonthInfo = async (month, year) => {
    const arrayData = []
    const monthNumber = monthMapReverse[month] + 1
    var d = new Date(year, monthNumber, 0);
    const response = await fetch(`${BASE_API}/examCountByEstablishment?start=1/${monthNumber}/${year}&end=${d.getDate()}/${monthNumber}/${year}`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' }})
    const jsonData = await response.json()

    for(let i = 1; i <= d.getDate(); i++) {
  
      let linked = 0
      let establishmentPatients = 0
      let visualized = 0
      let auto = 0

      const date = `${("0" + i).slice(-2)}/${("0" + monthNumber).slice(-2)}/${year}`
  
      jsonData['linked_exams'].daily.forEach(item => {
        if (item.day === date) linked = item.count
      })
  
      jsonData['establishment_patients'].daily.forEach(item => {
        if (item.day === date) establishmentPatients = item.count
      })
  
      jsonData['visualized_exams'].daily.forEach(item => {
        if (item.day === date) visualized = item.count
      })

      jsonData['establishment_new_patient'].daily.forEach(item => {
        if (item.day === date) auto = item.count
      })
  
      arrayData.push({ name: i.toString(), 'Usuários conectados': establishmentPatients, 'Exames distribuídos': linked, 'Exames visualizados': visualized, 'Usuários sincronizados automaticamente': auto },)
    }

    setChartMonthData(arrayData)
    setChartMonth([monthNames[d.getMonth()], d.getFullYear()])
  }

  const loadChartDayInfo = async (date) => {
    const response = await fetch(`${BASE_API}/examCountByEstablishment?start=${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}&end=${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' }})
    const jsonData = await response.json()

    const dayData = [{ name: moment(date, 'DD/MM/YYYY'), 'Usuários conectados': jsonData['establishment_patients'].total, 'Exames distribuídos': jsonData['linked_exams'].total, 'Exames visualizados': jsonData['visualized_exams'].total, 'Usuários sincronizados automaticamente': jsonData['establishment_new_patient'].total }]
    setChartDayData(dayData)
    setDate(date)
  }

  useEffect(() => {
    loadChartYearInfo(chartYear)
    loadChartMonthInfo(chartMonth[0], chartMonth[1])
    loadChartDayInfo(date)
  }, [])

  const handleChartYear = async (e) => {
    const year = e.target.value
    loadChartYearInfo(year)
  }

  const handleChartMonth = async (e) => {
    const value = e.target.value.split(',')
    loadChartMonthInfo(value[0], value[1])
  }

  let latitude = 0
  let longitude = 0
  if (me.addresses && me.addresses.length > 0) {
    latitude = me.addresses[0].latitude
    longitude = me.addresses[0].longitude
  }

  latitude = -23.0031071
  longitude = -43.3206413

  return (
    <>
      <Box p={4}>
        <Box mb={4}>
          <H1>Dashboard</H1>
        </Box>


        {/* <Box mx='auto' w={100} h={100} overflow='hidden' borderRadius='50%' border='3px solid #eee'>
          <img style={{width: '100%'}} src={`${BASE_API}${me.thumbnail}?token=token%20${localStorage.getItem(AUTH_TOKEN)}`} alt='' />
        </Box> */}


        <Flex height={150}>
          {/* <img alt='Avatar' src={`${BASE_API}/${me.thumbnail}?token=token%20${localStorage.getItem(AUTH_TOKEN)}`} style={{ height: '100%', margin: '0 auto' }} /> */}
          <img alt='Avatar' src={me.thumbnail} style={{ height: '100%', margin: '0 auto' }} />
        </Flex>
        <Box mx='auto' ta='center' mb={2}  onClick={() => setAvatarModalOpen(true)}><Text cursor='pointer' fs={12}>Alterar imagem</Text></Box>

        <Box mx='auto' mb={5} ta='center' fs={3}>
          {me.name}
        </Box>

        {/* <Box mx='auto' mb={4} ta='center' fs={3}>
          Descrição: {me.description}
        </Box> */}

        {/* <Flex fd='column' p={2} ai='center'>
          <Flex height={150}>
            <img alt='Avatar' src={`${BASE_API}/${me.thumbnail}?token=token%20${localStorage.getItem(AUTH_TOKEN)}`} style={{ height: '100%' }} />
          </Flex>
          <Flex>
            <Text>{me.name}</Text>
          </Flex>
        </Flex> */}

        <Box>
          <Box bg='hsla(210, 20%, 98%, 1)' mb={5} p={3} width={[1]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex ai='center' jc='space-between'>
              <Box><Text fw={600}>Ano</Text></Box>
              <Box>
                <Select placeholder='Ano' value={chartYear} onChange={handleChartYear}>
                  {getYears().map(item =>
                    <option value={item} key={item}>{item}</option>
                  )}
                  {/* <option value='2018'>2018</option>
                  <option value='2019'>2019</option> */}
                </Select>
              </Box>
            </Flex>
            <Box>
              <ChartYear chartData={chartData} id={me.id} />
            </Box>
          </Box>
        </Box>

        <Box>
          <Box bg='hsla(210, 20%, 98%, 1)' mb={5} p={3} width={[1]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex ai='center' jc='space-between'>
              <Box><Text fw={600}>Mês</Text></Box>
              <Box>
                <Select placeholder='Mês' value={chartMonth} onChange={handleChartMonth}>
                  {getDates().map(item =>
                    <option value={item} key={item}>{item.join(' ')}</option>
                  )}
                </Select>
              </Box>
            </Flex>
            <ChartMonth chartData={chartMonthData} />
          </Box>
        </Box>

        <Box>
          <Box bg='hsla(210, 20%, 98%, 1)' mb={5} p={3} width={[1]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex ai='center' jc='space-between'>
              <Box><Text fw={600}>Dia</Text></Box>
              <Box>
                <MyDatePicker
                  selected={date}
                  onChange={loadChartDayInfo}
                  maxDate={new Date()}
                />
              </Box>
            </Flex>
            <ChartDay chartData={chartDayData}/>
          </Box>
        </Box>


        {/* <Box>
          <Box bg='hsla(210, 20%, 98%, 1)' mb={5} p={3} width={[1]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
            <Flex ai='center' jc='space-between'>
              <Box><Text fw={600}>Total de exames distribuídos</Text></Box>
              <Box>
                <Select placeholder='Ano' value={chartYear} onChange={e => setChartYear(e.target.value)}>
                  <option value='2018'>2018</option>
                  <option value='2019'>2019</option>
                </Select>
              </Box>
            </Flex>
            <ChartYear year={chartYear} />
          </Box>
        </Box> */}


        {/* <Flex mb={3} jc='space-around'>
          <Box width={3/7}>
            <Box bg='hsla(210, 20%, 98%, 1)' mb={5} p={3} width={[1]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
              <Box><Text fw={600}>Total de Exames em 2019</Text></Box>
              <ChartYear />
            </Box>
          </Box>
          <Box width={3/7}>
            <Box bg='hsla(210, 20%, 98%, 1)' mb={5} p={3} width={[1]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
              <Box><Text fw={600}>Total de Exames em Junho/2019</Text></Box>
              <ChartMonth />
            </Box>
          </Box>
        </Flex> */}

        {/* <Box width={1/2} mb={3}>
          <ChartDay />
        </Box> */}

        <Box bg='hsla(210, 20%, 98%, 1)' mb={5} p={3} width={[1]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
          <Flex mb={3} jc='space-between'>
            <Box><Text small fw={700}>Perfil</Text></Box>
            <Box><ButtonRaw onClick={() => setInfoModalOpen(true)}><EditIcon /></ButtonRaw></Box>
          </Flex>
          <Box fontSize={2}>
            <Box mb={2}><Text fw={700}>Nome:</Text> {me.name}</Box>
            <Box mb={2}><Text fw={700}>Descrição:</Text> {me.description}</Box>
            <Box mb={2}><Text fw={700}>Site:</Text> {me.website}</Box>
            <Box mb={2}><Text fw={700}>Telefone:</Text> {me.phone}</Box>
            <Box mb={2}><Text fw={700}>Funcionamento:</Text> {me.workingTime}</Box>
            <Box mb={2}><Text fw={700}>WhatsApp:</Text> {me.whatsapp}</Box>
            <Box mb={2}><Text fw={700}>Membro desde:</Text> {formatDate(me.registerDate)}</Box>
            <Box mb={2}><Text fw={700}>Email:</Text> {me.login}</Box>
            <Box>
              {me.addresses && me.addresses.length > 0 &&
                <>
                  <Text fw={700}>Endereço:</Text> {me.addresses[0].name},&nbsp; 
                  {me.addresses[0].street} &nbsp; número: {me.addresses[0].number} <br/>
                  CEP: &nbsp; {me.addresses[0].postalCode}, &nbsp; {me.addresses[0].district} &nbsp;
                  Cidade: &nbsp; {me.addresses[0].city.name}, &nbsp; UF: &nbsp; {me.addresses[0].city.uf.name} <br/>
                </>
              }
            </Box>
          </Box>
        </Box>

        <Box bg='hsla(210, 20%, 98%, 1)' mb={4} p={3} width={[1]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
          <Flex mb={3} jc='space-between'>
            <Box><Text small fw={700}>Especialidades</Text></Box>
            <Box><ButtonRaw onClick={() => setSpecialtiesModalOpen(true)}><EditIcon /></ButtonRaw></Box>
          </Flex>
          <Box>
            {me.specialties.map(item => item.name).join(', ')}
          </Box>
        </Box>

        <Box bg='hsla(210, 20%, 98%, 1)' mb={4} p={3} width={[1]} border='1px solid #e1e1e1' borderRadius='8px' boxShadow='1px 1px 5px rgba(50, 50, 50, 0.15)'>
          <Flex mb={3} jc='space-between'>
            <Box><Text small fw={700}>Endereços</Text></Box>
            <Box><ButtonRaw onClick={() => setAddressesModalOpen(true)}><EditIcon /></ButtonRaw></Box>
          </Flex>
          <Box>
            {me.addresses.map(item =>
              <Box key={item.id} py={3} style={{borderTop: '1px solid lightgrey'}}>
                <Box>{item.street} {item.number} {item.complement}</Box>
                <Box>{item.district}</Box>
                <Box>{item.postal_code}</Box>
                <Box>{item.city.name} - {item.city.uf.abbreviation}</Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box mb={3}>
          <Text fw={600} small>Localização</Text>
        </Box>

        <Box h='50vh' w='100%'>
        <iframe title='maps' width='100%' height='500' id='gmap_canvas' src={`https://maps.google.com/maps?q=${latitude},${longitude}&t=&z=15&ie=UTF8&iwloc=&output=embed`} frameBorder='0' scrolling='no' marginHeight='0' marginWidth='0' style={{border: 0, width: '100%', height: '100%'}} />
          {/* <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBLb2rHvw_gvI6ybhKSblYt8T3AIvYPVQI' }}
            defaultCenter={{lat: latitude, lng: longitude}}
            defaultZoom={14}
          >
            <Box w={30} display='inline-block' lat={latitude} lng={longitude}>
              <img style={{transform: 'translate(-15px, -46px)', width: 30, height: 46}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAACaBAMAAACtVPAOAAAAMFBMVEUAAAAAv/8AresArO4ArvAAru8Are8Are8Are8ArO8ArfAAre8Are8Are4Ar/IAsvZrw0x+AAAADXRSTlMABBkuRV6AnrvS5/r9ewq5wwAABNFJREFUWMOVWEGPFEUUrmJZkVvNclFOkxAWXTyMXvSgZvwDQEIiJiZAYjx4EQ0H44kxooaLi9EE9iCEvRIwYY9Lb8d4nOmZAze6Cg4Yg8vu7EVYwjIzdk9vz3S9elX1qg57mM3Xr9573/vqq2IMWbxWE+Vf0uKiCiYCDn38zaWlpas/ff6O/gELgrHD394ajYb9YX80enLlJGOeQDU280U8VO3VTrZWV5OtwZWP3Jgam/99mN5Vk9WOttY/y0PbEe/GmxXAGNQdXLTH4ey9h2miwJLR8CITNsSRh6lCVmfrewtGzMYoIsMMvkbT4XsWLQilohdNjm3r053EBlFr/yBbE/sGkRWhZO87Iwznix3lWO0XdRhGvLmjnGtthcPcr0duSPqkoYcRr3uCIGF8QbIw61o2fJ83SBbml2oYcS7yQ9LnFQjfu6EIKz473Rk/SILIR9Mw4kJEgvxb2ddLpQJ3tn+DBlF3eEi9xjXbLiH8dkKM0t0lDX9lh4hQcWs3mYPUVJT6q9iZOBNREZNkriXkKGl9nMoMfV8qPiGsLJadDha7e9mWvWw/6Pc328iXxvmLY+bnotH9q5dujky1TZ9xvPfJ4Ie3GZs7ZaqnfMzRgqWb5znngrP5PxK0ZHNGkPhXVhyv7MgAYuKmYHwWMr+zXZ4nnJ2WCP9NhvXOToavtleBMN2MZfzVlzaG57U5DSE3kLbErapaGXu4x5l436iJJqSgnvIpNzpZNGu6M+TfkPrJsq7vINU8U9j8+CttX1B9ZCaZUMO6Tf1MAMKQa5lY1H9aB4cV+GT+f/FzAgPrELDx+3VYRa2RhTIkkJccQJ5BCKiyrMP0TMiHgDINP+SYF+LLxYR4K5ZBQMXMviwaFYM/+bqft/KCm2NgzseEgSJOYDJslX9e2AJUt4ZzKv/mWeQNqNTcMfvdZeRHt8L0clEyzEhc0bHZzQQ5+o3zWFbU8kuolkmeqelG4t9KTX4DanLhSQzeqbR/vogyH0fo+cIWpOm+f8wuOwc+Qc6XsVnCzsrsCrK0dGuUWs5K1Ce1VXZW3rV6JdzC4CeyKqhBNkqTo5KJD+jm4mkB4a+R7cWD5XKK6OaqnL9asIWj519mH5B/mb053/Z93Qj077qDJ/q+wvOFuEttxIke9h53eAh09VoVudpD2pgm16RmThs5hhyl9P4/Hnp92zW8JWRG+TtT2OqQq1XlWkVl5pST5GT0VMbJBKZCSAamQkgGpkJIBqZC6IyRijcZMxUvzcxUvMmYqXhnBlob/8wYnqtY5gGomwrkUcklAHELfe1yXsgbKMShZobjJDwu3MEhjieMqhg7nafdo1aMdwBbPJzpXrZF4XOWkvWa1rdbC2csbHG9LVXfkgwI7gCA2wYLPQHTugOCvTCYVxpg13dcRzcKmaFpi6fM8rHnOX1hI6TEljK7S4y/l9Q9EKPM7hKjbEY1Dzz5RmQWW8qcPvftyxSNFT8EiIZVKHTRiChCoUHeIgmFVZstWmxgqgRokCDieEJvvTFn7ulCCeCZLoQApNYDAqxQIRNDT2q95jQwR2GFHJX2Y9ipAP6phwogvVOvGzpJpaRGzW4rZF/5JVASKVmhJpWSFW2iUrJCzbWmCMOIM9th+8qH5k8Wug6cEKGQYIAL8z9X+2IrjcKgvQAAAABJRU5ErkJggg==' />
            </Box>
          </GoogleMapReact> */}
        </Box>

      </Box>

      {avatarModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setAvatarModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <Avatar
                onRequestClose={onRequestClose}
                initialData={me}
                onUpdated={() => { client.query({query: ME_ESTABLISHMENT, fetchPolicy: 'network-only'}); onRequestClose() } }
              />
            )
          }
        />
      }

      {infoModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setInfoModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <Info
                onRequestClose={onRequestClose}
                initialData={me}
                onUpdated={() => { client.query({query: ME_ESTABLISHMENT, fetchPolicy: 'network-only'}); message('Salvo com sucesso!'); setInfoModalOpen(false) }}
              />
            )
          }
        />
      }

      {specialtiesModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setSpecialtiesModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <Specialties
                onRequestClose={onRequestClose}
                initialData={me}
                onDeleted={() => { client.query({query: ME_ESTABLISHMENT, fetchPolicy: 'network-only'}); message('Especialidade excluída com sucesso!') } }
                onAdd={() => { client.query({query: ME_ESTABLISHMENT, fetchPolicy: 'network-only'}); message('Especialidade adicionada com sucesso!') }}
              />
            )
          }
        />
      }

      {addressesModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setAddressesModalOpen(false)}
          render={
            ({onRequestClose}) => (
              <Addresses
                onRequestClose={onRequestClose}
                initialData={me}
                onUpdated={() => { client.query({query: ME_ESTABLISHMENT, fetchPolicy: 'network-only'}); message('Salvo com sucesso!'); setAddressesModalOpen(false) }}
              />
            )
          }
        />
      }

    </>
  )
})

export default memo(props => {
  const { data, error, loading } = useQuery(ME_ESTABLISHMENT)
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  return <Home {...props} me={data.establishment} />
})
