import gql from 'graphql-tag'

export const LIST = gql`
  query {
    patients @rest(type: "Patient", path: "/getPatientByEstablishment/?pageSize=10") {
      count
      next
      previous
      results @type(name: "ResultsPatient"){
        id
        name
        cpf
        birthDate
        gender
        thumbnail
        cellphone
        orientation
        bloodType
        rhFactor
        alimentation
        smoke
        drug
        alcohol
        physicalActivity
        ethnicity
        allergies
        familyHistory
        continuousMedications
      }
    }
  }
`
