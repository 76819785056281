import React from "react";
import { Flex } from "../../components/FlexBox";
import {
  BoxViews,
  Container,
  ContainerBody,
  ContainerHeader,
  FormGroup,
  FormModal,
  Profile,
  ProfileImage,
  ProfileName,
  ProfilesList,
  Toggle
} from "./styles";

export default function ViewLevel({ level }) {
  const usersActions = [
    {
      name: "Juliana Castro Gomes",
      profile: "https://s3-sa-east-1.amazonaws.com/api.tria.health/media/public/a.png"
    },
    {
      name: "Juliana Castro Gomes",
      profile: "https://s3-sa-east-1.amazonaws.com/api.tria.health/media/public/a.png"
    },
    {
      name: "Juliana Castro Gomes",
      profile: "https://s3-sa-east-1.amazonaws.com/api.tria.health/media/public/a.png"
    },
    {
      name: "Juliana Castro Gomes",
      profile: "https://s3-sa-east-1.amazonaws.com/api.tria.health/media/public/a.png"
    },
    {
      name: "Juliana Castro Gomes",
      profile: "https://s3-sa-east-1.amazonaws.com/api.tria.health/media/public/a.png"
    },
    {
      name: "Juliana Castro Gomes",
      profile: "https://s3-sa-east-1.amazonaws.com/api.tria.health/media/public/a.png"
    },
    {
      name: "Juliana Castro Gomes",
      profile: "https://s3-sa-east-1.amazonaws.com/api.tria.health/media/public/a.png"
    },
    {
      name: "Juliana Castro Gomes",
      profile: "https://s3-sa-east-1.amazonaws.com/api.tria.health/media/public/a.png"
    }
  ]
  const levelsIds = {
    "Nível 1": 1,
    "Nível 2": 2,
  };

  return (
    <BoxViews>
      <Container>
        <ContainerHeader>
          <h2>Informações do nível</h2>
        </ContainerHeader>
        <ContainerBody>
          <FormModal onSubmit={(e) => e.preventDefault()}>
            <FormGroup>
              <label htmlFor="name">Nome</label>
              <input
                value={level.name}
                required
                id="name"
                name="name"
                type="text"
              />
            </FormGroup>
            <FormGroup>
              <label htmlFor="description">Descrição</label>
              <textarea required id="description" name="description">
                {level.description}
              </textarea>
            </FormGroup>
          </FormModal>
        </ContainerBody>
      </Container>
      <Container>
        <ContainerHeader>
          <h2>Permissões</h2>
        </ContainerHeader>
        <ContainerBody>
          <FormModal onSubmit={(e) => e.preventDefault()}>
            <h3>Ações</h3>
            <FormGroup>
              <Flex gap="27px">
                <Toggle>
                  <input
                    id="permissionOne"
                    name="permissionOne"
                    type="checkbox"
                  />
                  <label htmlFor="permissionOne"></label>
                </Toggle>
                <label htmlFor="permissionOne">Lorem ipsum dolor sit met</label>
              </Flex>
            </FormGroup>
            <FormGroup>
              <Flex gap="27px">
                <Toggle>
                  <input
                    id="permissionTwo"
                    name="permissionTwo"
                    type="checkbox"
                  />
                  <label htmlFor="permissionTwo"></label>
                </Toggle>
                <label htmlFor="permissionTwo">Lorem ipsum dolor sit met</label>
              </Flex>
            </FormGroup>
            <FormGroup>
              <Flex gap="27px">
                <Toggle>
                  <input
                    id="permissionTree"
                    name="permissionTree"
                    type="checkbox"
                  />
                  <label htmlFor="permissionTree"></label>
                </Toggle>
                <label htmlFor="permissionTree">Lorem ipsum dolor sit met</label>
              </Flex>
            </FormGroup>
            <FormGroup>
              <Flex gap="27px">
                <Toggle>
                  <input
                    id="permissionFour"
                    name="permissionFour"
                    type="checkbox"
                  />
                  <label htmlFor="permissionFour"></label>
                </Toggle>
                <label htmlFor="permissionFour">Lorem ipsum dolor sit met</label>
              </Flex>
            </FormGroup>
          </FormModal>
          
          <FormModal onSubmit={(e) => e.preventDefault()}>
            <h3>Visualização</h3>
            <FormGroup>
              <Flex gap="27px">
                <Toggle>
                  <input
                    id="permissionFive"
                    name="permissionFive"
                    type="checkbox"
                  />
                  <label htmlFor="permissionFive"></label>
                </Toggle>
                <label htmlFor="permissionFive">Lorem ipsum dolor sit met</label>
              </Flex>
            </FormGroup>
            <FormGroup>
              <Flex gap="27px">
                <Toggle>
                  <input
                    id="permissionSix"
                    name="permissionSix"
                    type="checkbox"
                  />
                  <label htmlFor="permissionSix"></label>
                </Toggle>
                <label htmlFor="permissionSix">Lorem ipsum dolor sit met</label>
              </Flex>
            </FormGroup>
            <FormGroup>
              <Flex gap="27px">
                <Toggle>
                  <input
                    id="permissionSeven"
                    name="permissionSeven"
                    type="checkbox"
                  />
                  <label htmlFor="permissionSeven"></label>
                </Toggle>
                <label htmlFor="permissionSeven">Lorem ipsum dolor sit met</label>
              </Flex>
            </FormGroup>
            <FormGroup>
              <Flex gap="27px">
                <Toggle>
                  <input
                    id="permissionEight"
                    name="permissionEight"
                    type="checkbox"
                  />
                  <label htmlFor="permissionEight"></label>
                </Toggle>
                <label htmlFor="permissionEight">Lorem ipsum dolor sit met</label>
              </Flex>
            </FormGroup>
          </FormModal>
        </ContainerBody>
      </Container>

      <Container>
        <ContainerHeader>
          <h2>Usuários</h2>
        </ContainerHeader>
        <ContainerBody>
          <h3>Ações</h3>
          <ProfilesList>
            {usersActions.map((profile, index) => (
              <Profile key={index}>
                <ProfileImage src={profile.profile} />
                <ProfileName>{profile.name}</ProfileName>
              </Profile>
            ))}
          </ProfilesList>
        </ContainerBody>
      </Container>
    </BoxViews>
  );
}
