import { Formik } from 'formik'
import md5 from 'md5'
import React, { memo, useEffect, useState } from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { GoogleLogin } from 'react-google-login'
import { css } from 'styled-components/macro'
import { StringParam, useQueryParam } from 'use-query-params'
// https://medium.com/reactbrasil/usando-facebook-login-no-react-com-styled-components-c5c680acdb5c

import Button from '../../components/Button'
import { CloseIcon } from '../../components/Icons'
import Modal from '../../components/StyledModal'

import ButtonRaw from '../../components/ButtonRaw'
import { Box, Flex } from '../../components/FlexBox'
import { FacebookIcon, GoogleIcon } from '../../components/Icons'
import Text from '../../components/Text'

import LoginForm from './LoginForm'
import validationSchema from './validationSchema'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

import formDataFromObject from '../../utils/formDataFromObject'

export default memo(({ navigate, message }) => {

  const [loginType, setLoginType] = useState('')
  const [alertValid, setAlertValid] = useState(false)
  const [alertModal, setAlertModal] = useState(false)
  const [lastEmail, setLastEmail] = useState('')
  const [lastAuthToken, setLastAuthToken] = useState('')
  const [actionSubmit, setActionSubmit] = useState();
  const [selectTypeModal, setSelectTypeModal] = useState(false);
  const [types, setTypes] = useState([]);

  const initialValues = { type: 'patient', username: '', password: '' }

  const validado = useQueryParam('validado', StringParam)[0]
  const [successSignup, setSuccessSignup] = useQueryParam('successSignup', StringParam)

  useEffect(() => {
    if (validado && validado === 'true') setAlertValid('Sua conta foi validada com sucesso! Faça login para continuar.')
    if (validado && validado === 'false') setAlertValid('Erro ao validar sua conta.')
  }, [validado])

  useEffect(() => {
    if (successSignup && successSignup === '1') {
      setAlertModal(true)
      setSuccessSignup('')
    }
  }, [successSignup, setAlertModal, setSuccessSignup])

  // useEffect(() => {
  //   if (successSignup && successSignup === '1') setSuccessSignup('')  //setAlertValid('Sua conta foi criada com sucesso! Agora faça login.')
  // }, [alertModal, successSignup, setSuccessSignup])

  const handleSubmit = async (variables, actions) => {
    setTypes([]);
    setLastEmail(variables.username)
    try {
      const body = formDataFromObject({ username: variables.username, password: md5(variables.password).toUpperCase() })
      const data = await (await fetch(`${BASE_API}/auth/`, { method: 'POST', body })).json()
      const { token } = data
      // const token = '936ce879b48d4a37f722388942bd104485a981af'

      if (!token) {
        actions.setSubmitting(false)
        return message('Login inválido')
      }

      setLastAuthToken(token)


      const { type_doctor, type_establishment, type_operator, type_patient } = data;

      const types = [];

      if (type_doctor) types.push({ value: 'doctor', label: 'Médico' });
      if (type_establishment) types.push({ value: 'establishment', label: 'Estabelecimento' });
      if (type_operator) types.push({ value: 'operator', label: 'Operador' });
      if (type_patient) types.push({ value: 'patient', label: 'Paciente' });



      if (types.length === 0) {
        setAlertModal(true)
        actions.setSubmitting(false)
        return message('Login inválido')

      } else if (types.length === 1) {
        localStorage.setItem(AUTH_TOKEN, token);
        await setLogType(types[0].value);
        actions.setSubmitting(false)

      } else if (types.length > 1) {
        localStorage.setItem('tempToken', token)
        setTypes(types);
        setSelectTypeModal(true);
        actions.setSubmitting(false);
      }




    } catch (error) {
      console.error(error)
      actions.setSubmitting(false)
      message('Login inválido')
    }
  }

  async function setLogType(type) {
    const token = localStorage.getItem(AUTH_TOKEN)

    const tempToken = localStorage.getItem('tempToken')

    var has_study_hcg = false
    var has_polypus = false
    var has_loyalty_card = false


    if (!token) {
      localStorage.setItem(AUTH_TOKEN, tempToken)
    }

    if (type === 'establishment') {
      const establishmentData = await (await fetch(`${BASE_API}/establishment`, { method: 'GET', headers: { Authorization: `token ${token}` } })).json()
      if (establishmentData.has_study_hcg === true) has_study_hcg = true
      if (establishmentData.id === 15) has_loyalty_card = true
      if (establishmentData.id === 7) has_polypus = true
    }

    localStorage.setItem('has_study_hcg', has_study_hcg)
    localStorage.setItem('has_polypus', has_polypus)
    localStorage.setItem('has_loyalty_card', has_loyalty_card)

    localStorage.setItem('type', type)

    setSelectTypeModal(false);

    if (type === 'doctor') navigate('/prescricoes')
    if (type === 'operator') navigate('/relatorios')
    if (type === 'patient') navigate('/arquivos')
    if (type === 'establishment') navigate('/estabelecimento/dashboard')

  }

  const responseFacebook = async response => {
    const { accessToken } = response


    try {
      const loginFacebookData = await (await fetch(`${BASE_API}/loginFacebook/`, { method: 'POST', body: formDataFromObject({ token: accessToken, mode: loginType }) })).json()
      const { token } = loginFacebookData

      const data = await (await fetch(`${BASE_API}/${loginType}/`, { method: 'GET', headers: { Authorization: `token ${token}` } })).json()

      var has_study_hcg = false
      var has_polypus = false
      var has_loyalty_card = false

      if (loginType === 'establishment') {
        if (data.has_study_hcg === true) has_study_hcg = true
        if (data.id === 7) has_polypus = true
        if (data.id === 15) has_loyalty_card = true
      }

      localStorage.setItem(AUTH_TOKEN, token)
      localStorage.setItem('type', loginType)
      localStorage.setItem('has_study_hcg', has_study_hcg)
      localStorage.setItem('has_polypus', has_polypus)
      localStorage.setItem('has_loyalty_card', has_loyalty_card)

      navigate('/')
    } catch (error) {
      console.error(error)
    }
  }

  const responseGoogle = async response => {
    const { tokenId } = response

    try {
      const loginGoogleData = await (await fetch(`${BASE_API}/loginGoogle/`, { method: 'POST', body: formDataFromObject({ token: tokenId, mode: loginType }) })).json()
      const { token } = loginGoogleData

      const data = await (await fetch(`${BASE_API}/${loginType}/`, { method: 'GET', headers: { Authorization: `token ${token}` } })).json()

      var has_study_hcg = false
      var has_polypus = false
      var has_loyalty_card = false

      if (loginType === 'establishment') {
        if (data.has_study_hcg === true) has_study_hcg = true
        if (data.id === 7) has_polypus = true
        if (data.id === 15) has_loyalty_card = true
      }

      localStorage.setItem(AUTH_TOKEN, token)
      localStorage.setItem('type', loginType)
      localStorage.setItem('has_study_hcg', has_study_hcg)
      localStorage.setItem('has_polypus', has_polypus)
      localStorage.setItem('has_loyalty_card', has_loyalty_card)

      navigate('/')
    } catch (error) {
      console.error(error)
    }
  }

  const sendEmailVerification = async () => {

    const tempToken = localStorage.getItem('tempToken')


    const data = await (await fetch(`${BASE_API}/sendVerificationEmail/`, {
      method: 'POST',
      headers: { Authorization: `token ${tempToken}` }
    })).json()

    if (data.worked) {
      message('Email enviado com sucesso!')
      setAlertModal(false)
    }
  }

  return (
    <Flex jc='center' maxWidth={420} mx='auto'>

      <Box width={1}>

        <Box ta='center' mb={2}>
          <Text extralarge primary fw={600}>Login</Text>
        </Box>

        <Box p={[3, 4]} mx='auto'>

          <Flex css={css`align-items:center;margin:20px 0;`}>
            <Box css={css`flex-grow:1;border-top:1px solid #A5A5A5;`} />
            <Box css={css`font-size:13px;padding:0 10px;color:#A5A5A5;`}>Entrar com</Box>
            <Box css={css`flex-grow:1;border-top:1px solid #A5A5A5;`} />
          </Flex>

          {/* <Box mb={4}>
            <Select placeholder='Selecione...' name='type' value={loginType} onChange={e => setLoginType(e.target.value)}>
              <option value='patient'>Paciente</option>
              <option value='doctor'>Médico</option>
              <option value='establishment'>Instituição</option>
              <option value='operator'>Operadora</option>
            </Select>
          </Box> */}

          {/* {['patient', 'doctor'].includes(loginType) && */}
          <Box mb={4}>
            <Box mb={3} ta='center'>
              <FacebookLogin
                appId='187089541949496'
                callback={responseFacebook}
                render={renderProps => (
                  <ButtonRaw
                    style={{ width: '100%', maxWidth: 240, padding: 8, background: '#4267b2', color: 'white', borderRadius: 20 }}
                    onClick={renderProps.onClick}
                  >
                    <Flex ai='center' jc='center'>
                      <Box mr={2}><FacebookIcon height={16} /></Box>
                      <Box css={css`font-size: 14px;`}>Entrar com Facebook</Box>
                    </Flex>
                  </ButtonRaw>
                )}
              />
            </Box>

            <Box ta='center'>
              <GoogleLogin
                clientId='1080521404778-dc1i91l4n34kgrncvp8si6ji2og980rh.apps.googleusercontent.com'
                buttonText="Login"
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                cookiePolicy={'single_host_origin'}
                render={renderProps => (
                  <ButtonRaw
                    style={{ width: '100%', maxWidth: 240, padding: 8, background: '#d93025', color: 'white', borderRadius: 20 }}
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    <Flex ai='center' jc='center'>
                      <Box mr={2}><GoogleIcon height={16} /></Box>
                      <Box css={css`font-size: 14px;`}>Entrar com Google</Box>
                    </Flex>
                  </ButtonRaw>
                )}
              />
            </Box>

            <Box ta='center' mt={4}>ou</Box>
          </Box>
          {/* } */}

          <Box mb={4}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              render={props => (<LoginForm {...props} />)}
            />
          </Box>

          <Box mb={3} ta='center'>
            <Text pointer underline onClick={() => navigate('/recuperar-senha')}>
              Esqueceu sua senha?
            </Text>
          </Box>

          <Box ta='center'>
            <Text pointer underline onClick={() => navigate('/cadastro')}>
              Criar conta
            </Text>
          </Box>

        </Box>

      </Box>

      {alertModal &&
        <Modal
          onCloseCompleted={() => { setAlertModal(false) }}
          css={css`& > div { max-height: 300px }`}
          render={
            ({ onRequestClose }) => (
              <Flex fd='column' h='100%'>
                <Box position='relative' h={64}>
                  <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}>
                    <CloseIcon />
                  </Box>
                </Box>
                <Box p={4} overflow='auto'>
                  <Box ta='center'>
                    Conta criada com sucesso! Verifique seu email para validar sua conta.
                    <br />
                    <br />
                    <Button onClick={sendEmailVerification}>Enviar email novamente</Button>
                  </Box>
                </Box>
              </Flex>
            )
          }
        />
      }

      {alertValid &&
        <Modal
          onCloseCompleted={() => { setAlertValid(false); setSuccessSignup('') }}
          css={css`& > div { max-height: 240px }`}
          render={
            ({ onRequestClose }) => (
              <Flex fd='column' h='100%'>
                <Box position='relative' h={64}>
                  <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}>
                    <CloseIcon />
                  </Box>
                </Box>
                <Box p={4} overflow='auto'>
                  <Box ta='center'>
                    <Text fs={20} fw={600}>{alertValid}</Text>
                  </Box>
                </Box>
              </Flex>
            )
          }
        />
      }

      {(selectTypeModal && types.length > 0) &&
        <Modal
          onCloseCompleted={() => { setSelectTypeModal(false) }}
          css={css`& > div { max-height: 300px; max-width: 500px; min-width: 300px; width: 100%; }`}
          render={
            ({ onRequestClose }) => (
              <Box h='100%'>
                <Box position='relative'>
                  <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}>
                    <CloseIcon />
                  </Box>
                </Box>
                <Flex fd='column' jc="center" ai="center" h="100%" overflow='auto'>
                  <Box ta='center'>
                    <Text fs={20} fw={600}>Selecione o tipo da conta</Text>

                    <Flex fd='column' gap="10px" mt={4}>
                      {types.map((type, i) => (
                        <Button key={i * 35} onClick={() => { setLogType(type.value) }}>{type.label}</Button>
                      ))}
                    </Flex>
                  </Box>
                </Flex>
              </Box>
            )
          }
        />
      }

    </Flex>
  )
})
