import React, { memo, useEffect, useState } from 'react'

import { Table, TableCell, TableContainer, TableHeaderCell, TableHeaderRow, TableRow } from '@alobato/table'
import CircularProgress from '../../components/CircularProgress'
import MyDatePicker from '../../components/DatePicker'
import { Box, Flex } from '../../components/FlexBox'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Text from '../../components/Text'
import useMyFetch from '../../hooks/useMyFetch'

import Paginator from "../../components/Paginator"
import { serialize } from '../../utils/utils'

import BASE_API from '../../constants/baseApi'

const formatDateTime = text => {
  if (!text) return ''
  const year = text.slice(0, 4)
  const month = text.slice(5, 7)
  const day = text.slice(8, 10)
  return `${day}/${month}/${year}`
}

const formatPolypusColor = value => {
  if (!value) return 'inherit'

  if(value <= 0.4) return 'green'
  else if(value >= 0.5 && value < 1) return 'orange'
  else if(value >= 1) return 'red'
  else return 'inherit'
}

const List = memo(({ patients, navigate }) => {

  const [search, setSearch] = useState('')
  const [biggestPolypus, setBiggestPolypus] = useState('')
  const [sortBy, setSortBy] = useState('')
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [activePage, setActivePage] = useState(1)
  const [results, setResults] = useState(patients.results)
  const [count, setCount] = useState(patients.count)
  const pageSize = 10

  useEffect(() => {
    async function fetchData() {
      const params = {
        'page': activePage,
        'pageSize': pageSize,
        'order': sortBy,
      }

      if(startDate != null && endDate != null){
        params.date_from = `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`
        params.date_to = `${endDate.getFullYear()}-${endDate.getMonth() + 1}-${endDate.getDate()}`
      }
        
      if(search != '')
        params.q = search

      if(biggestPolypus != '')
        params.biggest_polypus__gte = biggestPolypus

      const data = await (await fetch(`${BASE_API}/polypus/?${serialize(params)}`, { method: 'GET', headers: { Authorization:  `token e9dc673713c2b1fdf8ba66375581fa808d191e99` }})).json()
      setResults(data.results)
      setCount(data.count)
    }

    fetchData()
  }, [activePage, search, sortBy, startDate, endDate, biggestPolypus])

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber)
  }

  const handleSearch = async (query) => {
    setActivePage(1)
    setSearch(query)
  }

  const handleBiggestPolypus = async (query) => {
    setActivePage(1)
    setBiggestPolypus(query)
  }

  const handleSort = async (value) => {
    setActivePage(1)
    setSortBy(value)
  }

  const handleStartDate = async (date) => {
    setStartDate(date)
  }

  const handleEndDate = async (date) => {
    setActivePage(1)
    setEndDate(date)
  }

  return (
    <Box>
      <Box mb={3}>
        <Text fw={600}>Pólipos</Text>
      </Box>

      <Flex>
        <Box mr={3}>
          <Input placeholder='Buscar...' value={search} onChange={e => handleSearch(e.target.value)} />
        </Box>

        <Box mr={3}>
          <Select value={sortBy} onChange={e => handleSort(e.target.value)}>
            <option value=''>Ordenar por...</option>
            <option value='exam__patient__name'>A a Z</option>
            <option value='-exam__patient__name'>Z a A</option>
          </Select>
        </Box>

        <Box mr={2}>
          <MyDatePicker
            selected={startDate}
            onChange={handleStartDate}
            maxDate={new Date()}
            placeholderText="Data de início..."
            isClearable
          />
        </Box>

        <Box mr={3}>
          <MyDatePicker
            selected={endDate}
            onChange={handleEndDate}
            minDate={startDate == null ? new Date() : startDate}
            maxDate={new Date()}
            placeholderText="Data de fim..."
            isClearable
          />
        </Box>

        <Box mr={3}>
          <Select value={biggestPolypus} onChange={e => handleBiggestPolypus(e.target.value)}>
            <option value=''>Filtrar por valor</option>
            <option value='0.5'>Maior que 0.5</option>
          </Select>
        </Box>
      </Flex>

      <TableContainer style={{border: 'none', marginTop: '40px'}}>
        <Table>
          <TableHeaderRow style={{boxShadow: 'inset 0 -1px 0 0 lightgrey'}}>
            <TableHeaderCell style={{padding: '16px'}}> 
              Nome 
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Data de Nasc. 
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Data do exame
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Email
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Tel. 
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Pólipo 
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Mucosectomia 
            </TableHeaderCell>
            <TableHeaderCell style={{padding: '16px'}}> 
              Maior Pólipo
            </TableHeaderCell>
          </TableHeaderRow>
          
          {results.map(item => (
            <TableRow key={item.id} style={{boxShadow: 'inset 0 -1px 0 0 lightgrey'}}>
              <TableCell style={{padding: '16px'}} data-title='Nome'> 
                {item.exam && item.exam.patient ? item.exam.patient.name : item.name} 
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Data de Nasc.'> 
                {formatDateTime(item.exam && item.exam.patient ? item.exam.patient.birth_date : item.birth_date)} 
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Data do exame'> 
                {item.exam && item.exam.patient ? formatDateTime(item.exam.exam_date) : '-'} 
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Email'> 
                {item.exam && item.exam.patient ? item.exam.patient.email : '-'}  
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Tel.'> 
                {item.exam && item.exam.patient ? item.exam.patient.cellphone : '-'} 
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Pólipo'> 
                {item.mention_polyps ? 'Sim' : 'Não'}
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Mucosectomia'> 
                {item.mention_mucosectomy ? 'Sim' : 'Não'}
              </TableCell>
              <TableCell style={{padding: '16px'}} data-title='Maior Pólipo'> 
                <Text style={{color: formatPolypusColor(item.biggest_polypus)}}> {item.biggest_polypus} </Text>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>

      <Flex ai={'center'}>
        <Paginator 
          activePage={activePage}
          itemsCountPerPage={pageSize}
          totalItemsCount={count}
          onChange={handlePageChange}/> 
      </Flex>
    </Box>
  )
})

export default memo(props => {
  const {data, error, loading} = useMyFetch(`${BASE_API}/polypus/`, { method: 'GET', headers: { Authorization:  `token e9dc673713c2b1fdf8ba66375581fa808d191e99` }})
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  return <List {...props} patients={data} />
})
