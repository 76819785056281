import React, { memo } from 'react'
// import { Bar } from 'react-chartjs-2'
import { Bar, BarChart, CartesianGrid, Legend, Tooltip, XAxis, YAxis } from 'recharts'


const monthMap = { 0: 'Jan', 1: 'Fev', 2: 'Mar', 3: 'Abr', 4: 'Mai', 5: 'Jun', 6: 'Jul', 7: 'Ago', 8: 'Set', 9: 'Out', 10: 'Nov', 11: 'Dez' }

export default memo(({ id, chartData }) => {

  // const [chartData, setChartData] = useState([])

  // useEffect(() => {
  //   let arrayData = []
  //   const fetchToday = async () => {
  //     const month = 12
  //     const response = await fetch(`${BASE_API}/examCountByYear/?year=${year}`, { method: 'GET', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' }})
  //     const jsonData = await response.json()
  
  //     for(let i = 0; i < month; i++) {
  
  //       let linked = 0
  //       let notLinked = 0
  //       let visualized = 0
  
  //       jsonData['linked_exams'].months.forEach(item => {
  //         if (item.month === (i + 1)) linked = item.count
  //       })
  
  //       jsonData['not_linked_exams'].months.forEach(item => {
  //         if (item.month === (i + 1)) notLinked = item.count
  //       })
  
  //       jsonData['visualized_exams'].months.forEach(item => {
  //         if (item.month === (i + 1)) visualized = item.count
  //       })
  
  //       arrayData.push({ name: monthMap[i], 'Usuários conectados': linked, 'Exames distribuídos': notLinked, 'Exames visualizados': visualized },)
  //     }
  //     setChartData(arrayData)
  //   }
  //   fetchToday()
  // }, [])

  const blankYear = [
    { name: 'Jan', 'Usuários conectados': 0, 'Exames distribuídos': 0, 'Exames visualizados': 0 },
    { name: 'Fev', 'Usuários conectados': 0, 'Exames distribuídos': 0, 'Exames visualizados': 0 },
    { name: 'Mar', 'Usuários conectados': 0, 'Exames distribuídos': 0, 'Exames visualizados': 0 },
    { name: 'Abr', 'Usuários conectados': 0, 'Exames distribuídos': 0, 'Exames visualizados': 0 },
    { name: 'Mai', 'Usuários conectados': 0, 'Exames distribuídos': 0, 'Exames visualizados': 0 },
    { name: 'Jun', 'Usuários conectados': 0, 'Exames distribuídos': 0, 'Exames visualizados': 0 },
    { name: 'Jul', 'Usuários conectados': 0, 'Exames distribuídos': 0, 'Exames visualizados': 0 }
  ]

  let cData = []

  return (
    <BarChart
      width={900}
      height={300}
      data={chartData}
      margin={{top: 5, right: 30, left: 20, bottom: 5}}
      style={{margin: '0 auto', marginTop: 50}}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey='Usuários conectados' fill="#8884d8" />
      <Bar dataKey='Exames distribuídos' fill="#82ca9d" />
      <Bar dataKey='Usuários sincronizados automaticamente' fill="#ebc182" />
    </BarChart>
  )
})

//<Bar dataKey='Exames visualizados' fill="#95bde0" />