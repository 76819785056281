import React, { useState } from "react";
import { Box, Flex } from "../../components/FlexBox";

import { AiOutlineEye } from "react-icons/ai";
import ButtonLoading from "../../components/ButtonLoading";
import {
  BtnIcon,
  BtnPink,
  BtnPinkAlt,
  Btns,
  Container,
  Form,
  FormGroup,
  FormTitle,
  Inputs,
  PrescritionBody,
  PrescritionHeader,
  PrescritionItem,
  Toggle
} from "./styles";

export default function ConfirmPrescription({ page, page3, prescription }) {
  const [withCPF, setWithCPF] = useState(false);
  const [loading, setLoading] = useState(false);

  const [sendToPatientToggles, setSendToPatientToggles] = useState({
    checkSendwpp: true,
    checkSendSMS: true,
    checkPrescription: true,
    checkExamsRoute: true,
  });

  const previousStep = () => {
    if (page3.get === 1) {
      page.set(1);
    } else {
      page3.set(page3.get - 1);
    }
  };
  const nextStep = (e) => {
    e && e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      if (page3.get !== 3) {
        page3.set(page3.get + 1);
      } else {
        page3.set(1);
        prescription.set({name: "", prescriptions: []});
        page.set(1);
      }
    }, 500);
  };

  function togglesSelectHandler(e) {
    const el = e.target;
    const checked = el.checked;
    const id = el.id;

    setSendToPatientToggles({
      ...sendToPatientToggles,
      [id]: checked,
    });
  }

  return (
    <Box>
      <Container block={true}>
        {page3.get === 1 && (
          <>
            <PrescritionHeader>
              <div>
                <span>Nome</span>
                <span>{prescription.get.name}</span>
              </div>
            </PrescritionHeader>
            <PrescritionBody>
              {prescription.get.prescriptions.map((prescription, i) => (
                <PrescritionItem key={i}>
                  <span style={{ fontWeight: "800" }}>
                    {prescription.category}
                  </span>
                  <span>
                    {prescription.name}{" "}
                    <BtnIcon>
                      <AiOutlineEye />
                    </BtnIcon>
                  </span>
                </PrescritionItem>
              ))}
            </PrescritionBody>
          </>
        )}

        {page3.get === 2 && (
          <>
            <FormTitle>Dados do paciente</FormTitle>
            <Form onSubmit={nextStep}>
              <Inputs>
                <FormGroup>
                  <label htmlFor="name">Name</label>
                  <input
                    required
                    id="name"
                    name="name"
                    type="text"
                    placeholder="Lorem Ipsum de Souza"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="email">E-mail</label>
                  <input
                    required
                    id="email"
                    name="email"
                    type="email"
                    placeholder="loremipsum@gmail.com"
                  />
                </FormGroup>
                <FormGroup>
                  <Flex jc="space-between">
                    <label htmlFor="cpf">CPF</label>
                    <Flex ai="baseline" gap="8px">
                      <input
                        id="withCPF"
                        checked={withCPF}
                        onChange={(e) => setWithCPF(e.target.checked)}
                        type="checkbox"
                      />
                      <label htmlFor="withCPF">
                        Paciente não possui <strong>CPF</strong>
                      </label>
                    </Flex>
                  </Flex>
                  <input
                    disabled={withCPF ? true : false}
                    required
                    id="cpf"
                    name="cpf"
                    type="tel"
                    placeholder="172.580.598-89"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="birthdate">Data de nascimento</label>
                  <input
                    required
                    id="birthdate"
                    name="birthdate"
                    type="date"
                    placeholder="21 988954578"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="address">Endereço</label>
                  <input
                    required
                    id="address"
                    name="address"
                    type="text"
                    placeholder="Rua Porto Adolfo, 924, apt 301"
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="city">Endereço</label>
                  <input
                    required
                    id="city"
                    name="city"
                    type="text"
                    placeholder="Rio de Janeiro"
                  />
                </FormGroup>
              </Inputs>
            </Form>
          </>
        )}

        {page3.get === 3 && (
          <>
            <Box>
              <FormTitle>Minha assinatura digital</FormTitle>
              <Form onSubmit={nextStep}>
                <Inputs>
                  <FormGroup>
                    <label htmlFor="signature">
                      Assinar documento digitalmente
                    </label>
                    <select
                      required
                      id="signature"
                      name="signature"
                      type="text"
                    >
                      <option value={1}>Sim</option>
                      <option value={0}>Não</option>
                    </select>
                  </FormGroup>
                </Inputs>
              </Form>
            </Box>
            <Box>
              <FormTitle className="form">Enviar para paciente</FormTitle>
              <Form onSubmit={nextStep}>
                <Inputs>
                  <FormGroup>
                    <Flex jc="space-between" ai="baseline">
                      <label htmlFor="sendwpp">
                        Enviar via WhatsApp Business
                      </label>{" "}
                      <Toggle>
                        <input
                          onChange={togglesSelectHandler}
                          checked={sendToPatientToggles.checkSendwpp}
                          id="checkSendwpp"
                          name="checkSendwpp"
                          type="checkbox"
                        />
                        <label htmlFor="checkSendwpp"></label>
                      </Toggle>
                    </Flex>
                    <input
                      disabled={!sendToPatientToggles.checkSendwpp}
                      required
                      id="sendwpp"
                      name="sendwpp"
                      type="tel"
                      placeholder="Número de telefone"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Flex jc="space-between" ai="baseline">
                      <label htmlFor="sendsms">Enviar SMS para paciente</label>{" "}
                      <Toggle>
                        <input
                          onChange={togglesSelectHandler}
                          checked={sendToPatientToggles.checkSendSMS}
                          id="checkSendSMS"
                          name="checkSendSMS"
                          type="checkbox"
                        />
                        <label htmlFor="checkSendSMS"></label>
                      </Toggle>
                    </Flex>
                    <input
                      disabled={!sendToPatientToggles.checkSendSMS}
                      required
                      id="sendsms"
                      name="sendsms"
                      type="tel"
                      placeholder="Número de telefone"
                    />
                  </FormGroup>
                </Inputs>
              </Form>
            </Box>
            <Box>
              <FormTitle className="form">Imprimir meus receituários</FormTitle>
              <Form onSubmit={nextStep}>
                <Inputs>
                  <FormGroup>
                    <Flex jc="space-between" ai="baseline">
                      <label htmlFor="prescription">Receiturário simples</label>{" "}
                      <Toggle>
                        <input
                          onChange={togglesSelectHandler}
                          checked={sendToPatientToggles.checkPrescription}
                          id="checkPrescription"
                          name="checkPrescription"
                          type="checkbox"
                        />
                        <label htmlFor="checkPrescription"></label>
                      </Toggle>
                    </Flex>
                    <select
                      disabled={!sendToPatientToggles.checkPrescription}
                      required
                      id="prescription"
                      name="prescription"
                    >
                      <option value="1">1 via</option>
                    </select>
                  </FormGroup>
                  <FormGroup>
                    <Flex jc="space-between" ai="baseline">
                      <label htmlFor="examsRoute">Via de exames</label>{" "}
                      <Toggle>
                        <input
                          onChange={togglesSelectHandler}
                          checked={sendToPatientToggles.checkExamsRoute}
                          id="checkExamsRoute"
                          name="checkExamsRoute"
                          type="checkbox"
                        />
                        <label htmlFor="checkExamsRoute"></label>
                      </Toggle>
                    </Flex>
                    <select
                      disabled={!sendToPatientToggles.checkExamsRoute}
                      required
                      id="examsRoute"
                      name="examsRoute"
                    >
                      <option value="1">1 via</option>
                    </select>
                  </FormGroup>
                </Inputs>
              </Form>
            </Box>
          </>
        )}
      </Container>
      <Btns>
        <BtnPinkAlt disabled={loading} onClick={previousStep}>
          Voltar
        </BtnPinkAlt>
        <BtnPink disabled={loading} onClick={nextStep}>
          {page3.get === 3 ? "Concluir" : "Próximo"} {loading && <ButtonLoading />}
        </BtnPink>
      </Btns>
    </Box>
  );
}
