import gql from 'graphql-tag'

const path = () => {
  const type = localStorage.getItem("type");
  return type === "operator" ? "/get-health-operator" : "/patient";
}

export const GET = gql`
  query {
    patient @rest(type: "Patient", path: "${path()}") {
      id
      login
      registerDate
      createdAt
      name
      cpf
      birthDate
      gender
      thumbnail
      cellphone
      tokenNotification
      verifiedPhone
      notificationEnable
      user
      orientation
      bloodType
      rhFactor
      alimentation
      smoke
      drug
      alcohol
      physicalActivity
      ethnicity
      favoriteDoctors
      estabelishmentPatient
      allergies
      familyHistory
      continuousMedications
      totalSize
      imageSize
      docsSize
      videoSize
      othersSize
      healthInsuranceSubPlan
      healthInsurance
      healthInsuranceNumber
      healthInsuranceCardPhoto
      subscription
    }
  }
`

export const UPDATE = gql`
  mutation ($input: Patient!) {
    media(input: $input) @rest(type: "Patient", path: "${path()}/", method: "PUT", bodySerializer: "form") {
      NoResponse
    }
  }
`

export const CREATE_ALLERGY = gql`
  mutation ($input: AllergyPatient!) {
    media(input: $input) @rest(type: "AllergyPatient", path: "/allergyPatient/", method: "POST", bodySerializer: "form") {
      NoResponse
    }
  }
`

export const CREATE_HISTORY = gql`
  mutation ($input: HistoryPatient!) {
    media(input: $input) @rest(type: "HistoryPatient", path: "/familyHistoryPatient/", method: "POST", bodySerializer: "form") {
      NoResponse
    }
  }
`

export const CREATE_MEDICATIONS = gql`
  mutation ($input: MedicationsPatient!) {
    media(input: $input) @rest(type: "MedicationsPatient", path: "/continuousMedicationsPatient/", method: "POST", bodySerializer: "form") {
      NoResponse
    }
  }
`

export const DELETE_ALLERGY = gql`
  mutation ($input: AllergyPatient!) {
    media(input: $input) @rest(type: "Folder", path: "/folder/", method: "POST", bodySerializer: "form") {
      NoResponse
    }
  }
`