import React from "react";
import styled from "styled-components/macro";

import Modal from "./Modal";

const enterAnimation = (modal, backdrop, backdropOpacity) => {
  if (!modal.current) return;
  if ("animate" in modal.current) {
    modal.current.animate(
      [
        { opacity: 0, transform: "translateX(+400px)" },
        { opacity: 1, transform: "translateX(0)" },
      ],
      { duration: 300, easing: "ease" }
    ).onfinish = () => {
      if (modal.current) {
        modal.current.style.opacity = 1;
      }
    };
  } else {
    modal.current.style.opacity = 1;
    modal.current.style.transform = "translateX(0)";
  }
};

const exitAnimation = (modal, backdrop, backdropOpacity, callback) => {
  if (!modal.current) return;
  if ("animate" in modal.current) {
    modal.current.animate(
      [
        { opacity: 1, transform: "translateX(0)" },
        { opacity: 0, transform: "translateX(+400px)" },
      ],
      { duration: 300, easing: "ease" }
    ).onfinish = () => {
      callback();
    };
  } else {
    callback();
  }
};

const StyledModal = styled(Modal)`
  & > div {
    position: absolute;
    right: 0;
    background-color: white;
    box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
      0 13px 19px 2px rgba(0, 0, 0, 0.14), 0 5px 24px 4px rgba(0, 0, 0, 0.12);
    pointer-events: auto;
    height: 100vh;
    overflow: scroll;
    width: 240px;
  }
`;

const StyledModalWithAnimations = (props) => (
  <StyledModal
    enterAnimation={enterAnimation}
    exitAnimation={exitAnimation}
    {...props}
  />
);

export default StyledModalWithAnimations;
