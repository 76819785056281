
import React, { memo, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Box, Flex } from "../../components/FlexBox";
import {
  BtnPink,
  CardDate,
  CardOption,
  CardSearch,
  Filters,
  Navigation,
  Option,
  TableContent,
  Title
} from "./styles";

import SignatureToggle from "../SignatureToggle";
import ConfirmPrescription from "./ConfirmPrescription";
import NewPrescription from "./NewPrescription";
import Protocols from "./Protocols";



export default memo(({ id, navigate, message }) => {
  const [page, setPage] = useState(1);
  const [page3Breadcrumbs, setPage3Breadcrumbs] = useState(1);
  const [creatingProtocol, setCreatingProtocol] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('')
  const [section, setSection] = useState('Nova prescrição de exame');
  const [addPrescription, setAddPrescription] = useState({ name: "", prescriptions: [] });



  return (
    <div style={{ minHeight: "calc(100vh - 92px - 254px)" }}>
      <Box p={4}>
        <Flex mb={3} jc={"flex-start"} ai={"center"}>
          {section === "Nova prescrição de exame" && <Title>Prescrições</Title>}
          {section === 'Protocolos' && <><Title>Protocolos</Title><BtnPink onClick={() => setCreatingProtocol(!creatingProtocol)}>Criar</BtnPink></>}
          {page === 3 && <Title>{addPrescription.name}</Title>}
        </Flex>
        {localStorage.getItem("type") === "doctor" && <SignatureToggle />}
        <Navigation>
          <CardOption>


            <Option onClick={() => {
              setSection("Nova prescrição de exame");
              setStartDate('')
              setEndDate('')
            }} select={section === "Nova prescrição de exame" ? "true" : "false"} >Nova prescrição de exame</Option>
            <Option onClick={() => {
              setSection("Protocolos")
              setStartDate('')
              setEndDate('')
            }} select={section === "Protocolos" ? "true" : "false"} >Protocolos</Option>
            {/* <Breadcrumb>
              <Option select={page3Breadcrumbs === 1 ? "true" : "false"} >Resumo</Option>&gt;
              <Option select={page3Breadcrumbs === 2 ? "true" : "false"} >Indentificação</Option>&gt;
              <Option select={page3Breadcrumbs === 3 ? "true" : "false"} >Enviar</Option>
            </Breadcrumb> */}

          </CardOption>

          <Filters>
            <CardDate>
              <input
                type="date"
                onChange={(e) => {
                  setStartDate(e.target.value)
                }}
                value={startDate}
                placeholder="De 12/08/2021"
              />
            </CardDate>
            <CardDate>
              <input
                type="date"
                onChange={(e) =>
                  setEndDate(e.target.value)

                }
                value={endDate}
                placeholder="Até 12/08/2021"
              />
            </CardDate>
            <CardSearch>
              <input type="text" placeholder="Buscar" />
              <FaSearch></FaSearch>
            </CardSearch>
          </Filters>
        </Navigation>

        <TableContent>
          {section === "Nova prescrição de exame" && <NewPrescription page={{ get: page, set: setPage }} prescription={{ get: addPrescription, set: setAddPrescription }} />}
          {section === "Protocolos" && <Protocols section={section} setCreate={setCreatingProtocol} create={creatingProtocol} startDate={startDate} endDate={endDate} />}
          {page === 3 && <ConfirmPrescription page={{ get: page, set: setPage }} page3={{ get: page3Breadcrumbs, set: setPage3Breadcrumbs }} prescription={{ get: addPrescription, set: setAddPrescription }} />}
        </TableContent>
      </Box >
    </div >
  )
});
