import React, { memo } from 'react'

import { Box } from '../../components/FlexBox'
import Input from '../../components/Input'
import Button from '../../components/Button'
import FormErrorBox from '../../components/FormErrorBox'

import useScrollToError from '../../hooks/useScrollToError'

export default memo(({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => {
  useScrollToError({errors, isValid, isSubmitting})

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Input autoComplete='nope' placeholder='Email' name='email' value={values.email} onChange={handleChange} onBlur={handleBlur} withError={touched.email && errors.email} />
        <FormErrorBox fieldName='email' errors={errors} touched={touched} />
      </Box>
      <Box ta='center'>
        <Button type='submit' loading={isSubmitting} disabled={isSubmitting}>Enviar</Button>
      </Box>
    </form>
  )
})
