import React, { memo } from 'react'
import { Box } from '../../components/FlexBox'
import H1 from '../../components/H1'
import { css } from 'styled-components/macro'

const sliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  arrows: true,
}

export default memo(({ id, navigate, message }) => (
  <Box p={4}>
    <Box mb={3}>
      <H1>Planos</H1>
    </Box>

    <Box>
    <div style={{margin: '30px auto 0 auto'}}>


<div css={css`display: none; @media (min-width: 1096px) { display: block !important; }`}>

    <div>

      <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

        <div style={{margin: '10px auto 40px auto', boxShadow: '0 0px 1px 0 rgba(0,0,0,0.1), 0 2px 1px -1px rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1)', color: '#A5A5A5', position: 'relative', backgroundColor: 'white', padding: 20, minWidth: 240, maxWidth: 320, height: 280, fontWeight: 300}}>
          <div style={{fontSize: 20, marginBottom: 50, fontWeight: 500}}>Plano Grátis</div>
          <div style={{display: 'block'}}>
            <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
            <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Guarde exames, laudos, prescrições, entre outros</div>
          </div>
          <div style={{position: 'absolute', top: 50, left: 20, color: '#3FAFE4', fontSize: 20}}>
            <div style={{display: 'flex'}}>
              <div><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
            </div>
          </div>
          <div style={{position: 'absolute', bottom: 20, left: 20, color: '#3FAFE4', fontSize: 20, fontWeight: 500}}>Grátis</div>
          <div style={{position: 'absolute', bottom: 20, right: 20}}>
            <a href='https://web.tria.health' css={css`border: 2px solid #df1c4a; border-radius: 30px; color: #df1c4a; cursor: pointer; display: inline-block; font-weight: 500; padding: 8px 20px; text-align: center; font-family: inherit; font-size: 11px; text-transform: uppercase; background: white; text-decoration: none; &:hover { background-color: #df1c4a; color: #fff;}`}>Escolher</a>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 16, right: 16, width: 70, height: 70, border: '5px solid #3FAFE4', borderRadius: '50%'}}>
            <span style={{color: '#3FAFE4', fontSize: 16, fontWeight: 400}}>100MB</span>
          </div>
        </div>

        <div style={{margin: '10px auto 40px auto', boxShadow: '0 0px 1px 0 rgba(0,0,0,0.1), 0 2px 1px -1px rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1)', color: '#A5A5A5', position: 'relative', backgroundColor: 'white', padding: 20, minWidth: 240, maxWidth: 320, height: 280, fontWeight: 300}}>
        <div style={{fontSize: 20, marginBottom: 50, fontWeight: 500}}>Plano Individual</div>
        <div style={{display: 'block'}}>
          <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
          <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Guarde exames, laudos, prescrições, entre outros</div>
        </div>
        <div style={{display: 'block'}}>
          <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
          <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Assinatura anual</div>
        </div>
        <div style={{position: 'absolute', top: 50, left: 20, color: '#3FAFE4', fontSize: 20}}>
          <div style={{display: 'flex'}}>
            <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
            <div><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
          </div>
        </div>
        <div style={{position: 'absolute', bottom: 20, left: 20, color: '#3FAFE4', fontSize: 20, fontWeight: 500}}>R$ 9,15/mês</div>
        <div style={{position: 'absolute', bottom: 20, right: 20}}>
          <a href='/planos/individual' css={css`border: 2px solid #df1c4a; border-radius: 30px; color: #df1c4a; cursor: pointer; display: inline-block; font-weight: 500; padding: 8px 20px; text-align: center; font-family: inherit; font-size: 11px; text-transform: uppercase; background: white; text-decoration: none; &:hover { background-color: #df1c4a; color: #fff;}`}>Escolher</a>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 16, right: 16, width: 70, height: 70, border: '5px solid #3FAFE4', borderRadius: '50%'}}>
          <span style={{color: '#3FAFE4', fontSize: 16, fontWeight: 400}}>1GB</span>
        </div>
      </div>

        <div style={{margin: '10px auto 40px auto', boxShadow: '0 0px 1px 0 rgba(0,0,0,0.1), 0 2px 1px -1px rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1)', color: '#A5A5A5', position: 'relative', backgroundColor: 'white', padding: 20, minWidth: 240, maxWidth: 320, height: 280, fontWeight: 300}}>
          <div style={{fontSize: 20, marginBottom: 50, fontWeight: 500}}>Plano Adicional</div>
          <div style={{display: 'block'}}>
            <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
            <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Guarde exames, laudos, prescrições, entre outros</div>
          </div>
          <div style={{display: 'block'}}>
            <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
            <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Assinatura anual</div>
          </div>
          <div style={{display: 'block'}}>
            <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
            <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Gerencie até 2 perfis</div>
          </div>
          <div style={{position: 'absolute', top: 50, left: 20, color: '#3FAFE4', fontSize: 20}}>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
              <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
              <div><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
            </div>
          </div>
          <div style={{position: 'absolute', bottom: 20, left: 20, color: '#3FAFE4', fontSize: 20, fontWeight: 500}}>R$ 13,75/mês</div>
          <div style={{position: 'absolute', bottom: 20, right: 20}}>
            <button css={css`border: 2px solid #df1c4a; border-radius: 30px; color: #df1c4a; cursor: pointer; display: inline-block; font-weight: 500; padding: 8px 20px; text-align: center; font-family: inherit; font-size: 11px; text-transform: uppercase; background: white; &:hover { background-color: #df1c4a; color: #fff;}`}>Em Breve</button>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 16, right: 16, width: 70, height: 70, border: '5px solid #3FAFE4', borderRadius: '50%'}}>
            <span style={{color: '#3FAFE4', fontSize: 16, fontWeight: 400}}>1,5GB</span>
          </div>
        </div>

      </div>

    </div>

    <div>

      <div style={{display: 'flex', justifyContent: 'space-evenly'}}>

        <div style={{margin: 10, boxShadow: '0 0px 1px 0 rgba(0,0,0,0.1), 0 2px 1px -1px rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1)', color: '#A5A5A5', position: 'relative', backgroundColor: 'white', padding: 20, minWidth: 240, maxWidth: 320, height: 280, fontWeight: 300}}>
          <div style={{fontSize: 20, marginBottom: 50, fontWeight: 500}}>Plano Família</div>
          <div style={{display: 'block'}}>
            <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
            <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Guarde exames, laudos, prescrições, entre outros</div>
          </div>
          <div style={{display: 'block'}}>
            <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
            <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Assinatura anual</div>
          </div>
          <div style={{display: 'block'}}>
            <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
            <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Gerencie até 3 perfis</div>
          </div>
          <div style={{position: 'absolute', top: 50, left: 20, color: '#3FAFE4', fontSize: 20}}>
            <div style={{display: 'flex'}}>
              <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
              <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
              <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
              <div><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
            </div>
          </div>
          <div style={{position: 'absolute', bottom: 20, left: 20, color: '#3FAFE4', fontSize: 20, fontWeight: 500}}>R$ 14,65/mês</div>
          <div style={{position: 'absolute', bottom: 20, right: 20}}>
            <button css={css`border: 2px solid #df1c4a; border-radius: 30px; color: #df1c4a; cursor: pointer; display: inline-block; font-weight: 500; padding: 8px 20px; text-align: center; font-family: inherit; font-size: 11px; text-transform: uppercase; background: white; &:hover { background-color: #df1c4a; color: #fff;}`}>Em Breve</button>
          </div>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 16, right: 16, width: 70, height: 70, border: '5px solid #3FAFE4', borderRadius: '50%'}}>
            <span style={{color: '#3FAFE4', fontSize: 16, fontWeight: 400}}>1,6GB</span>
          </div>
        </div>

        <div style={{margin: 10, boxShadow: '0 0px 1px 0 rgba(0,0,0,0.1), 0 2px 1px -1px rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.1)', color: '#A5A5A5', position: 'relative', backgroundColor: 'white', padding: 20, minWidth: 240, maxWidth: 320, height: 280, fontWeight: 300}}>
        <div style={{fontSize: 20, marginBottom: 50, fontWeight: 500}}>Plano Família Plus</div>
        <div style={{display: 'block'}}>
          <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
          <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Guarde exames, laudos, prescrições, entre outros</div>
        </div>
        <div style={{display: 'block'}}>
          <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
          <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Assinatura anual</div>
        </div>
        <div style={{display: 'block'}}>
          <div style={{display: 'inline-block', verticalAlign: 'baseline', width: 10, height: 10, backgroundColor: 'hsla(199, 75%, 57%, 1)', marginRight: 10, borderRadius: '50%'}} />
          <div style={{display: 'inline', fontSize: 15, marginBottom: 4, lineHeight: 1.5}}>Gerencie até 5 perfis</div>
        </div>
        <div style={{position: 'absolute', top: 50, left: 20, color: '#3FAFE4', fontSize: 20}}>
          <div style={{display: 'flex'}}>
            <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
            <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
            <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
            <div style={{marginRight: 8}}><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
            <div><img style={{width: 30}} alt='' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQBAMAAAB8P++eAAAAMFBMVEUAAAD03uPtytPw0NXy1dvjprHcjJzipbHowMnYdorLHUPMM03RSGbVYXnu2d/08vUIAhiEAAAACnRSTlMALleMsML9/f3+kt793AAABKlJREFUSMedl01sG0UUx21TRE8RgiL1aLmCXkACIdoDHAJSRblRBEWI9lKEE5OPtlBt6hYnfCm2SWJLCJxs0uQY5F3nhOQqs/YJ1W78cUKK89FUnOyN1O5KHOI97fBm195946wtxBxsef3zm/fxnzfPPt//Wy+EziTiwlTip1Do+UFc4KIgHjyq1Q92xdH3BpAXLk9UJElSCLwUM58M9+PeTFZlxVnS1jdveHOnYpKCF5FvefsXxfbYkuvTQS97lR4Odq+PHovI/+lxDsj8pV7uraritciDnoBOfCt5g4VfePD9faXPejDMG5T7gfIkBs+TfpxCsq+jUK4oA9ZHbopeqgzgyLZj0n8VPwdBSJzHRSeXJ8ZQigu7mbD4CCdL/rW79zmU7PqIRqkZv4f3WP+7o4Z5d6fCNNW0lEbVezgP12xwaMUFd3RTiITDKdraQyYf2+BplyumDUEMh8VMyoy5T8l9W23n3EdLZnI7BT4K23obOb7xPeOe+Vx2Daq70xSWNjFpjrtg7gtLscvOFg/1hUlqLWM3nUTR/MHAV9yyrBmiTqnK4OSa4T4nvzMJvebuMNNsAHN4H15aDRMpL/sh1O8dx8UNPcmM3S2CWTOsIycLUMXAnANmzYk0IMsyCyiWPkQJgpQH3EKvMxdVoUIyUymanGkiEModcF0ptzM0vkqYMhq0eb2Nwt4J+k66wTXa23SB6YaQMm1zYCnoO+uCO+1F2rY2IJO0PYnB9aCvSZDFRWrGGLel0yPOYjboO1Q4H6lBmFwo+HiEwI3vMJg3ADQZuAZpv34kc+BTZN+M6Db4uCePDJxHn7ToDGwtVaUdSiN6DG99A4NQ6yWq1m6r42W9tYhrrRRu+ObQxzUjoxsjOm3dZurBp6ZwDYOgx2hHj2omPavwINpa2Uy3GroFRpd0rm8VOB/hzNCFLSagaEk3uM4KwTzFR31TNyKZeEKAk5Pk2gqfcNhhUVcFUcxM0/iKMgjcEqepqbJOERE5MDuMRQENpSWOQu/R4qujKtesQRTnUW8sps3ZVegUkYORlDkm8+BzCMxDqYUwW9AuuHTkg77AHkq4lUMtZb01MfgwiA8XUxerypLdLHAJ2eG6K7uisE3Zhk2UcXZc/W+jBmARc5v2O5YZa+OvOmDetnRHSduW3bA3LnG9p2H7tidZrY+iDsl6j9vNut/L3V8s893sxa+6dYF0AwtqyIIDUzr92bH4p9War8gd6aTiK3BYQIcQVbM2qjkpz33N9fC8UFFK1KxYeXqiyNtO2Fn7ojnddbpKwJZhn+t5WVIKXRfL9q0w1C0/vOXSrHJkh0mCdNND/upccU9kdI1b11hRrfLnwF7PoluqYZsX8K1Z7k4LASS9mm3qwPN29X9ABk0AxZvuFDU2aAL4zR0V/FcHmMzhYerifn+wjAe5wEJfkxv8bHhhRf5Pk5Qv8Fkfk8Xece/UuNcUR3Jf9g6Q/pc958LSu8dH0kNyfCLN/ugx4w7N9s6kUikV9JyaL+/jWYvIpY/7zOwwNytSJ3r4Sf1WsN/EHjoTrXVkXdiaCgUH/FkInYUJrlY7EEd+CA3++xHQNC2R0LR/er/4F5kFD7Ci7hIuAAAAAElFTkSuQmCC' /></div>
          </div>
        </div>
        <div style={{position: 'absolute', bottom: 20, left: 20, color: '#3FAFE4', fontSize: 20, fontWeight: 500}}>R$ 18,30/mês</div>
        <div style={{position: 'absolute', bottom: 20, right: 20}}>
          <button css={css`border: 2px solid #df1c4a; border-radius: 30px; color: #df1c4a; cursor: pointer; display: inline-block; font-weight: 500; padding: 8px 20px; text-align: center; font-family: inherit; font-size: 11px; text-transform: uppercase; background: white; &:hover { background-color: #df1c4a; color: #fff;}`}>Em Breve</button>
        </div>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', top: 16, right: 16, width: 70, height: 70, border: '5px solid #3FAFE4', borderRadius: '50%'}}>
          <span style={{color: '#3FAFE4', fontSize: 16, fontWeight: 400}}>2GB</span>
        </div>
      </div>

      </div>

    </div>


</div>

</div>
    </Box>

    
  </Box>
))
