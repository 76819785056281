import React, { memo } from 'react'

import { Box } from '../../components/FlexBox'
import H1 from '../../components/H1'

import List from './List'

export default memo(({ navigate }) => (
  <Box p={4}>
    <Box mb={4}>
      <H1>Pacientes</H1>
    </Box>
    
    <List navigate={navigate} />
  </Box>
))
