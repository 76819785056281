import React, { memo, useState } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import { CloseIcon } from '../../components/Icons'
import Input from '../../components/Input'
import Button from '../../components/Button'

import { useMutation } from '../../hooks/useApollo'
import { INVITE } from './Gqls'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [email, setEmail] = useState('')
  const [name, setName] = useState('')

  const invite = useMutation(INVITE)

  const handleInvite = () => {

    invite({ variables: { input: { email, name } } })
      .then(data => {
        onUpdated()
      })
      .catch(error => {
        console.error('error', error)
      })


    // invite()
    // setQ(query)
  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Convidar Médico</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Box mb={2}>
          <Box>Email do médico</Box>
          <Box mb={2}><Input value={email} onChange={e => setEmail(e.target.value)} /></Box>
          <Box>Nome do médico</Box>
          <Box><Input value={name} onChange={e => setName(e.target.value)} /></Box>
        </Box>
        <Box><Button onClick={handleInvite} small>Convidar</Button></Box>
      </Box>
    </Flex>
  )
})
