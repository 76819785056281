import React, { memo, useState, useMemo, useEffect } from 'react'
import Cascader from 'antd/lib/cascader'
import Table from 'antd/lib/table';
import 'antd/dist/antd.css';

import "./style.css";
import traverse from 'traverse'

import MenuOver from '../../components/MenuOver'
import { MoreIcon, IconPdf, DocIcon, ArrowLeftIcon } from '../../components/Icons'

import styled, { css } from '../../utils/styled'

import { Flex, Box } from '../../components/FlexBox'
import Lightbox from '../../components/Lightbox'
import Text from '../../components/Text'
import Input from '../../components/Input'
import InputDate from '../../components/InputDate'
import Select from '../../components/Select'
import Fab from '../../components/Fab'
import Button from '../../components/Button'
import Modal from '../../components/StyledModal'
import Modal2 from '../../components/StyledModal2'
import ImageModal from '../../components/ImageModal'

import { FolderIcon, ImageIcon, CloseIcon, PdfIcon, DeleteIcon } from '../../components/Icons'
// import ReactFileBase64 from '../../components/ReactFileBase64'
import ReactUpload from '../../components/ReactUpload'
import CircularProgress from '../../components/CircularProgress'

import { useQuery, useMutation } from '../../hooks/useApollo'
import { LIST, UPLOAD, CREATE_EXAM, CREATE } from './Gqls'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

import Paginator from '../../components/Paginator'
import { serialize } from '../../utils/utils'
import useMyFetch from '../../hooks/useMyFetch'

import Switch from 'react-ios-switch'
import ReactApexChart from 'react-apexcharts'

const formDataFromObject = object => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())

const asyncReduce = async (arr, fn, val, pure) => {
  for (let i = 0; i < arr.length; i++) {
    let v = await fn(val, arr[i], i, arr)
    if (pure !== false) val = v
  }
  return val
}

const FolderItem = styled(Flex)`
  justify-content: space-between;
  border-bottom: 1px solid hsla(0, 0%, 93%, 1);
  padding: 20px;
  font-size: 18px;
`

const Badge = styled(Flex)`
  background: #8E8D92;
  width: 24px;
  height: 24px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: white;
  font-size: 14px;
  ${props => props.value && css`background-color: #548D4B;`}
`

const filter = (files, search, sortBy) => {
  let filtered = files
  if (files.length <= 0) {
    console.log('filter inside folder')
  }
  else {
    filtered = filtered.filter(item => {
      let condition = true
      if (condition) condition = search ? item.name.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(search.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) : condition
      return condition
    })
  }

  filtered = filtered.sort((a, b) => {
    if (sortBy == 'id')
      return (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)
    else
      return (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0)
  });
  return filtered
}

const Charts = memo(({ data, id, navigate, message }) => {
  const dataList = [
    {
      key: '1',
      id: "duh034d848chc",
      doctorNameOne: "Dr. Carlos Eduardo Silveira",
      dataOne: "12/02/2022",
      doctorNameTwo: "Dra. Kátia Santos",
      dataTwo: "12/03/2022"
    }, {
      key: '2',
      id: "duh034d848chc",
      doctorNameOne: "Dr. Carlos Eduardo Silveira",
      dataOne: "12/02/2022",
      doctorNameTwo: "Dra. Kátia Santos",
      dataTwo: "12/03/2022"
    }, {
      key: '3',
      id: "duh034d848chc",
      doctorNameOne: "Dr. Carlos Eduardo Silveira",
      dataOne: "12/02/2022",
      doctorNameTwo: "Dra. Kátia Santos",
      dataTwo: "12/03/2022"
    }, {
      key: '4',
      id: "duh034d848chc",
      doctorNameOne: "Dr. Carlos Eduardo Silveira",
      dataOne: "12/02/2022",
      doctorNameTwo: "Dra. Kátia Santos",
      dataTwo: "12/03/2022"
    }, {
      key: '5',
      id: "duh034d848chc",
      doctorNameOne: "Dr. Carlos Eduardo Silveira",
      dataOne: "12/02/2022",
      doctorNameTwo: "Dra. Kátia Santos",
      dataTwo: "12/03/2022"
    }, {
      key: '6',
      id: "duh034d848chc",
      doctorNameOne: "Dr. Carlos Eduardo Silveira",
      dataOne: "12/02/2022",
      doctorNameTwo: "Dra. Kátia Santos",
      dataTwo: "12/03/2022"
    }, {
      key: '7',
      id: "duh034d848chc",
      doctorNameOne: "Dr. Carlos Eduardo Silveira",
      dataOne: "12/02/2022",
      doctorNameTwo: "Dra. Kátia Santos",
      dataTwo: "12/03/2022"
    }, {
      key: '8',
      id: "duh034d848chc",
      doctorNameOne: "Dr. Carlos Eduardo Silveira",
      dataOne: "12/02/2022",
      doctorNameTwo: "Dra. Kátia Santos",
      dataTwo: "12/03/2022"
    },
  ]
  const dataColumns = [
    {
      title: 'Código da Prescrição',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Nome do médico 1',
      dataIndex: 'doctorNameOne',
      key: 'doctorNameOne',
    },
    {
      title: 'Data 1',
      dataIndex: 'dataOne',
      key: 'dataOne',
    },
    {
      title: 'Nome do médico 2',
      dataIndex: 'doctorNameTwo',
      key: 'doctorNameTwo',
    },
    {
      title: 'Data 2',
      dataIndex: 'dataTwo',
      key: 'dataTwo',
    },
  ];

  const chartDonutsOptions = {
    chart: {
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      formatter: function (seriesName, opts) {
        return [`${opts.w.globals.series[opts.seriesIndex]}%`, " - ", seriesName]
      },
      markers: {
        radius: 0,
      },
    },
  }

  const comorbiditiesDonuts = {
    series: [40, 30, 30],
    options: {
      labels: ['Diabetes', 'Insuficiência cardíaca', 'Anemia Falciforme'],
      ...chartDonutsOptions,
    },
  };

  const monthRequests = {

    series: [{
      name: 'Blue',
      data: [44, 55, 57, 56, 61, 58, 63, 60, 66, 44, 55, 57]
    }, {
      name: 'Green',
      data: [76, 85, 101, 98, 87, 105, 91, 114, 94, 76, 85, 101]
    }],
    options: {
      legend: {
        show: false,
      },
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '60%',
          endingShape: 'rounded'
        },
      },
      dataLabels: {
        enabled: true,
        offsetY: 0
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val + "%";
          }
        },
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      xaxis: {
        categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
      },
      fill: {
        opacity: 1
      },
      // tooltip: {
      //   y: {
      //     formatter: function (val) {
      //       return "$ " + val + " thousands"
      //     }
      //   }
      // }
    },


  };

  const comorbiditiesBar = {
    series: [{
      data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200]
    }],
    chart: {
      type: 'bar',
      height: 350
    },
    plotOptions: {
      bar: {
        horizontal: true,
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      categories: ['Diabetes', 'Insuficiência cardíaca', 'Anemia Falciforme', 'Insuficiência cardíaca', 'Anemia Falciforme', 'Insuficiência cardíaca', 'Anemia Falciforme', 'Insuficiência cardíaca', 'Biabetes'],
      labels: {
        show: false,
      },
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
    },
  };

  const duplicateQuantityLineChart = {

    series: [{
      name: "Quantitativo em duplicidade",
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148, 51, 49, 62]
    }],
    options: {
      chart: {
        height: 350,
        type: 'line',
        zoom: {
          enabled: false
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return " "
        },
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        },
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
        },
      },
    },

  };

  const chartDonutsOptionsBottomLegend = {
    ...chartDonutsOptions,
    legend: {
      position: 'bottom',
      formatter: function (seriesName, opts) {
        return [`${opts.w.globals.series[opts.seriesIndex]}%`, " - ", seriesName]
      },
      floating: false,
      markers: {
        radius: 0,
      },
    },
  }

  const oldInformationDonuts = {
    series: [40, 30, 30],
    options: {
      labels: ['20-25 anos', '25-30 anos', '30-35 anos'],
      ...chartDonutsOptionsBottomLegend,
    },
  };
  const genderInformationDonuts = {
    series: [70, 30],
    options: {
      labels: ['Feminino', 'Masculino'],
      ...chartDonutsOptionsBottomLegend,
    },
  };
  const comorbidityInformationDonuts = {
    series: [40, 30, 30],
    options: {
      labels: ['Diabetes', 'Insuficiência cardíaca', 'Anemia Falciforme'],
      ...chartDonutsOptionsBottomLegend,
    },
  };
  const TUSSInformationDonuts = {
    series: [40, 30, 30],
    options: {
      labels: ['12314121', '12312312', '2314452'],
      ...chartDonutsOptionsBottomLegend,
    },
  };


  return (
    <Box id="charts" className="lists-charts" mb={4} >
      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} jc="space-between" ai="center"><h2>Solicitações por mês</h2> <Flex gap={"10px"}><Select id='FilterMonthRequests'><option>Filter</option></Select><Select id='ComparativeYearOne'><option>Comparativo ano 1</option></Select><Select id='ComparativeYearTwo'><option>Comparativo ano 2</option></Select></Flex></Flex>
        <Box className='main-section'>
          <ReactApexChart options={monthRequests.options} series={monthRequests.series} height="300px" type="bar" />
        </Box>
      </Box>

      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} jc="space-between" ai="center"><h2>Pedidos por comorbidade (indicação do exame)</h2> <Select id='FilterComorbidity'><option>Filter</option></Select></Flex>
        <Box className='main-section'>
          <Box className="doubleCharts">
            <ReactApexChart options={comorbiditiesBar} series={comorbiditiesBar.series} height="200px" type="bar" />
            <ReactApexChart options={comorbiditiesDonuts.options} series={comorbiditiesDonuts.series} height="200px" type="donut" />
          </Box>
        </Box>
      </Box>

      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} jc="space-between" ai="center"><h2>Prescrições em duplicidade</h2> <Select id='duplicatePrescription'><option>Filter</option></Select></Flex>
        <Box className='main-section'>
          <Table dataSource={dataList} columns={dataColumns}></Table>
        </Box>
      </Box>

      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} jc="space-between" ai="center"><h2>Quantitativo em duplicidade</h2> <Select id='duplicateQuantity'><option>Filter</option></Select></Flex>
        <Box className='main-section'>
          <ReactApexChart options={duplicateQuantityLineChart.options} series={duplicateQuantityLineChart.series} type="line" height={350} />
        </Box>
      </Box>

      <Box className='section'>
        <Flex mb={4} className="header-section" gap={"10px"} jc="space-between" ai="center"><h2>Informações dos pacientes</h2><Select id='PatientInformation'><option>Filter</option></Select></Flex>
        <Box className='main-section'>
          <Box className="quadrupleCharts">
            <Box>
              <p>Idade</p>
              <ReactApexChart options={oldInformationDonuts.options} series={oldInformationDonuts.series} height="200px" type="donut" />
            </Box>
            <Box>
              <p>Sexo</p>
              <ReactApexChart options={genderInformationDonuts.options} series={genderInformationDonuts.series} height="200px" type="donut" />
            </Box>
            <Box>
              <p>Comorbidade</p>
              <ReactApexChart options={comorbidityInformationDonuts.options} series={comorbidityInformationDonuts.series} height="200px" type="donut" />
            </Box>
            <Box>
              <p>TUSS</p>
              <ReactApexChart options={TUSSInformationDonuts.options} series={TUSSInformationDonuts.series} height="200px" type="donut" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
})

export default memo(props => {
  const params = { pageSize: 10 }
  if (props.id)
    params.id = props.id
  // const { data, error, loading } = useMyFetch(`${BASE_API}/folder/?${serialize(params)}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
  // const { data, error, loading } = useQuery(LIST, { variables: { id: props.id } })
  //console.log(loading)
  // if (error) return `Erro! ${error.message}`
  // if (!data || loading) return <CircularProgress />
  //console.log(data)
  // return <Charts {...props} data={data}/>
  return <Charts {...props} />
})
