import React, { memo, useState } from 'react';

import { FaSearch } from "react-icons/fa";
import { Box } from '../../components/FlexBox';
import {
  CardDate,
  CardOption,
  CardSearch,
  Filters,
  Main,
  Navigation,
  Option,
  Title
} from "./styles";

import CardDoctor from './CardDoctor';
import CardPrescrition from './CardPrescrition';

import ViewDoctor from './ViewDoctor';
import ViewPrescription from './ViewPrescription';

export default memo(({ id, navigate, message }) => {
  const [page, setPage] = useState(1);
  const [viewPrescriptionData, setViewPrescriptionData] = useState(false);
  const [viewDoctorData, setViewDoctorData] = useState(false);

  const backHome = () => {
    setViewPrescriptionData(false);
    setViewDoctorData(false);
  }

  const doctorsDuplicity = [
    {
      doctorName: "Dr. Carlos Eduardo Silveira",
      doctorPicProfile: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
      specialization: "Ortopedista",
      exam: "Radiografia da mão direita",
      crm: "12345-67",
      email: "silveiracarlosed@gmail.com",
      phone: "21 98765 4321",
      prescritionOne: {
        date: "20/01/2022 10:45:36",
        exam: "Radiografia da mão direita",
        patient: "Julio Cesar Carvalho de Castro Almeida",
        examType: "Imagem",
        status: true
      },
      prescritionTwo: {
        date: "20/01/2022 10:45:36",
        exam: "Radiografia da mão direita",
        patient: "Julio Cesar Carvalho de Castro Almeida",
        examType: "Imagem",
        status: true
      },
      action: "Ver"
    },
    {
      doctorName: "Dr. Carlos Eduardo Silveira",
      doctorPicProfile: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
      specialization: "Ortopedista",
      exam: "Radiografia da mão direita",
      crm: "12345-67",
      email: "silveiracarlosed@gmail.com",
      phone: "21 98765 4321",
      prescritionOne: {
        date: "20/01/2022 10:45:36",
        exam: "Radiografia da mão direita",
        patient: "Julio Cesar Carvalho de Castro Almeida",
        examType: "Imagem",
        status: true
      },
      prescritionTwo: {
        date: "20/01/2022 10:45:36",
        exam: "Radiografia da mão direita",
        patient: "Julio Cesar Carvalho de Castro Almeida",
        examType: "Imagem",
        status: true
      },
      action: "Ver"
    },
    {
      doctorName: "Dr. Carlos Eduardo Silveira",
      doctorPicProfile: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
      specialization: "Ortopedista",
      exam: "Radiografia da mão direita",
      crm: "12345-67",
      email: "silveiracarlosed@gmail.com",
      phone: "21 98765 4321",
      prescritionOne: {
        date: "20/01/2022 10:45:36",
        exam: "Radiografia da mão direita",
        patient: "Julio Cesar Carvalho de Castro Almeida",
        examType: "Imagem",
        status: true
      },
      prescritionTwo: {
        date: "20/01/2022 10:45:36",
        exam: "Radiografia da mão direita",
        patient: "Julio Cesar Carvalho de Castro Almeida",
        examType: "Imagem",
        status: true
      },
      action: "Ver"
    }
  ]

  const prescritionsDuplicity = [
    {
      doctorOne: {
        name: "Dr. Carlos Eduardo Silveira",
        picProfile: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
        specialization: "Ortopedista",
        crm: "12345-67",
        email: "silveiracarlosed@gmail.com",
        phone: "21 98765 4321",
      },
      doctorTwo: {
        name: "Dr. Carlos Eduardo Silveira",
        picProfile: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
        specialization: "Ortopedista",
        crm: "12345-67",
        email: "silveiracarlosed@gmail.com",
        phone: "21 98765 4321",
      },
      exam: "Radiografia da mão direita",
      examType: "Imagem",
      patient: "Julio Cesar Carvalho de Castro Almeida",
      prescritionDateOne: "20/01/2022",
      prescritionDateTwo: "20/01/2022",
      statusOne: true,
      statusTwo: true
    },
    {
      doctorOne: {
        name: "Dr. Carlos Eduardo Silveira",
        picProfile: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
        specialization: "Ortopedista",
        crm: "12345-67",
        email: "silveiracarlosed@gmail.com",
        phone: "21 98765 4321",
      },
      doctorTwo: {
        name: "Dr. Carlos Eduardo Silveira",
        picProfile: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
        specialization: "Ortopedista",
        crm: "12345-67",
        email: "silveiracarlosed@gmail.com",
        phone: "21 98765 4321",
      },
      exam: "Radiografia da mão direita",
      examType: "Imagem",
      patient: "Julio Cesar Carvalho de Castro Almeida",
      prescritionDateOne: "20/01/2022",
      prescritionDateTwo: "20/01/2022",
      statusOne: true,
      statusTwo: true
    },
    {
      doctorOne: {
        name: "Dr. Carlos Eduardo Silveira",
        picProfile: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
        specialization: "Ortopedista",
        crm: "12345-67",
        email: "silveiracarlosed@gmail.com",
        phone: "21 98765 4321",
      },
      doctorTwo: {
        name: "Dr. Carlos Eduardo Silveira",
        picProfile: "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=200",
        specialization: "Ortopedista",
        crm: "12345-67",
        email: "silveiracarlosed@gmail.com",
        phone: "21 98765 4321",
      },
      exam: "Radiografia da mão direita",
      examType: "Imagem",
      patient: "Julio Cesar Carvalho de Castro Almeida",
      prescritionDateOne: "20/01/2022",
      prescritionDateTwo: "20/01/2022",
      statusOne: true,
      statusTwo: true
    }
  ]

  return (
    <Main>
      <Box p={4}>
        <Box mb={3}>
          {(!viewPrescriptionData && !viewDoctorData) && <Title>Alertas</Title>}

          {(viewPrescriptionData || viewDoctorData) && <Title onClick={backHome}>
            &lt; {viewPrescriptionData && "Prescrições"} {viewDoctorData && "Médicos"} em duplicidade
          </Title>}

        </Box>
        {viewPrescriptionData || viewDoctorData ? (
          <>
            {viewPrescriptionData && <ViewPrescription viewPrescription={viewPrescriptionData} />}
            {viewDoctorData && <ViewDoctor viewDoctor={viewDoctorData} />}
          </>
        ) : (
          <>
            <Navigation>
              <CardOption>
                <Option onClick={() => setPage(1)} select={page === 1 ? "true" : "false"} >Médicos em duplicidade</Option>
                <Option onClick={() => setPage(2)} select={page === 2 ? "true" : "false"} >Prescrições em duplicidade</Option>
              </CardOption>

              <Filters>
                <CardDate>
                  <input type="date" placeholder="De 12/08/2021" />
                </CardDate>
                <CardDate>
                  <input type="date" placeholder="Até 12/08/2021" />
                </CardDate>
                <CardSearch>
                  <input type="text" placeholder="Buscar" />
                  <FaSearch></FaSearch>
                </CardSearch>
              </Filters>
            </Navigation>
            {page === 1 && <CardDoctor content={doctorsDuplicity} viewDoctor={setViewDoctorData} filters={null} />}
            {page === 2 && <CardPrescrition content={prescritionsDuplicity} viewPrescription={setViewPrescriptionData} filters={null} />}
          </>
        )}
      </Box>
    </Main>
  )
})
