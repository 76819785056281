import * as Yup from 'yup'

const cpfIsValid = value => {
  if (!value) value = ''
  let soma = 0
  if (value === '00000000000') return false
  if (value === '11111111111') return false
  if (value === '22222222222') return false
  if (value === '33333333333') return false
  if (value === '44444444444') return false
  if (value === '55555555555') return false
  if (value === '66666666666') return false
  if (value === '77777777777') return false
  if (value === '88888888888') return false
  if (value === '99999999999') return false

  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(value.substring(i - 1, i), 10) * (11 - i)
  let resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(value.substring(9, 10), 10)) return false

  soma = 0
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(value.substring(i - 1, i), 10) * (12 - i)
  resto = (soma * 10) % 11

  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(value.substring(10, 11), 10)) return false
  return true
}

Yup.setLocale({
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório',
  },
  string: {
    min: params => `Deve ter pelo menos ${params.min} caracteres`,
  },
})


Yup.addMethod(Yup.string, 'cpf', function(message) {
  return this.test('cpf', (message || 'CPF inválido'), function (value) {
    if (!value) return true
    return cpfIsValid(value.replace(/-/g, '').replace(/\./g, ''))
  })

})
