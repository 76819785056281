import React, { memo, useEffect, useState } from "react";
import styled from "../utils/styled";

import Button from "../components/Button";
import CircularProgress from "../components/CircularProgress";
import { Box, Flex } from "../components/FlexBox";
import { CloseIcon, MenuIcon } from "../components/Icons";
import NavLink, { BlankNavLink } from "../components/NavLink";
import Modal from "../components/StyledModalMenu";
import Text from "../components/Text";

import Notifications from "../components/StyledModalNotifications";

import { useQuery } from "../hooks/useApollo";
import { ME_DOCTOR, ME_ESTABLISHMENT, ME_OPERATOR, ME_PATIENT } from "./Gqls";

import * as camelCase from "camelcase";
import * as snake_case from "snake-case";
import AUTH_TOKEN from "../constants/authToken";
import BASE_API from "../constants/baseApi";

import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { RestLink } from "apollo-link-rest";
import ApolloLinkTimeout from "apollo-link-timeout";
import Footer from "../containers/Footer";
import SignatureModal from "./SignatureModal";

export const Container = styled(Box)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

export const Wrapper = styled(Flex)`
  height: 100%;
`;
export const Sidebar = styled(Box)`
  background-color: white;
  width: 240px;
  border-right: 1px solid hsla(0, 0%, 93%, 1);
  @media (max-width: 680px) {
    display: none;
  }
`;

export const ContentWrapper = styled(Box)`
  width: 100%;
`;
export const Header = styled(Flex)`
  justify-content: space-between;
  width: 100%;
  min-height: 50px;
  align-items: center;
  border-bottom: 1px solid hsla(0, 0%, 93%, 1);
`;
export const Logo = styled(Box)`
  text-align: center;
  & > img {
    width: 100px;
  }
`;

export const SearchBar = styled(Box)`
  background-color: #eee;
  width: 100px;
  height: 30px;
  margin-right: 20px;
  border-right: 1px solid gray;
`;

export const Content = styled(Box)`
  height: calc(100vh - 63px);
  width: 100%;
  overflow: auto;
`;

const formSerializer = (data, headers) => {
  const formData = new FormData();
  for (let key in data) {
    if (data.hasOwnProperty(key)) formData.append(key, data[key]);
  }
  return { body: formData, headers };
};

const logout = (client) => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem("has_study_hcg");
  localStorage.removeItem("has_polypus");
  localStorage.removeItem("has_loyalty_card");
  localStorage.removeItem("tempToken");
  client.resetStore();
  window.location.href = "/login";
};

const timeoutLink = new ApolloLinkTimeout(60000);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: { ...headers, authorization: token ? `token ${token}` : "" },
  };
});
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Not authenticated as user.") {
        logout(client);
      }
    });
  }
  if (networkError && networkError.statusCode === 401) {
    logout(client);
  }
});

const restLink = new RestLink({
  uri: BASE_API,
  bodySerializers: { form: formSerializer },
  fieldNameNormalizer: (key) => camelCase(key),
  fieldNameDenormalizer: (key) => snake_case(key),
});

const client = new ApolloClient({
  link: ApolloLink.from([timeoutLink, authLink, errorLink, restLink]),
  cache: new InMemoryCache(),
  defaultOptions: {},
});

const Main = memo(({ children, me, onLogout }) => {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [menuModalOpen, setMenuModalOpen] = useState(false);
  const [hiddenNotifications, setHiddenNotifications] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    fetch(`${BASE_API}/userNotification/`, {
      method: "GET",
      headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` },
    })
      .then(async (response) => {
        const data = await response.json();
        setNotifications(data);
      })
      .finally(() => setLoading(false));
  }, [hiddenNotifications]);

  const handleHideNotification = (id) => {
    notifications.filter((item) => item.id === id)[0].read = true;
    setHiddenNotifications((currentHiddenNotifications) => [
      ...currentHiddenNotifications,
      id,
    ]);
    const formData = new FormData();
    formData.append("read", true);
    fetch(`${BASE_API}/userNotification/${id}/`, {
      method: "PATCH",
      body: formData,
      headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` },
    });
    setNotifications((currentNotifications) => {
      return notifications.filter((item) => item.id !== id);
    });
  };

  const isBetaHCG =
    window.location.pathname === "/estabelecimento/betaHCG" ? true : false;
  const [expanded, setExpanded] = useState(isBetaHCG ? "panel1" : false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isBetaHCG ? panel : isExpanded ? panel : false);
  };

  return (
    <Container>
      <Wrapper>
        <Sidebar>
          <Logo mt={4}>
            <img
              alt="Logo - Tria"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAAqCAMAAACp1aRYAAAAwFBMVEUAAAAAAP//AAAAqv+/AACZZswas+bmAE3fAEDiAE4utuTdAEXqACA+rODfEkncNFvdAEg8ruPfHErgHEc7rOE8rN/fGkg8reHhGUk/reE9rOLhF0c+rOHhFEk7ruE+reLfE0nhEkg9reE9reHgEEk7reHgF0g+rOHgF0g9reHgF0g9rOHgFkk9ruE+reHgFkhCrOA9reHfFUk9reHgFUk9reE9r+Q+sOY/sec/s+hCrODgFUnhFUniFUnkFUvmFUsMp7FVAAAANXRSTlMAAQIDBAUKChAaHCUwMTg7PEhIU1ZZWV1lZmlveH6Hj5CgorGzwcXP19vk5u/x+Pj6+/z9/iqSIZkAAARcSURBVFjDtZjrQuo6EIUntAcEdW9RUUAR5WK5ltN7ewrt+7/Vzq20aRK2cHT9EZrA50wnM6sAGABDaxvo5cRzmMeOvOBt1/O3WwADAZjw6+CnqZ+619CAilAD2qPPje+uZv0G3ibKQB0rjoJL6FhRHMy7+EtO0KG/ybI0TQ9ZtriT8B07drxL6Z4TxPHEAKOhoZvwnu9dnyy5bpbd1/FWbAfBpXSy7CRWE/5R0014yF18nck97NpiZp7131ulRyd22clUQ0eAFpmbHrXLP8Tg56HtVHQMqJQdEbqwq7bdSYagpJtwz+A47fTPfnOF/6VSXlJVUX5xRVFigZVEsUJJSPleuG5q6B+5S6l5znKfPwnBD4YVPU8iUoDhdtB7rqgL3d6jQr3xlt0RJ+7Bb5mO4Gqz92nIL+9kOXWzmVT2pbqJQ0OpXUa6/R2L4p14oqKb8ERDd/M+wIzcAz8V686oqMlqkCQSVa8bwq6qUHMb4mx50VKVeZMjD5tWUQFu/qIL3oBeQdeHW00JasIryRa+V80biY673A5fwURS6a0V2eBmn+KZu5xOPnEb0jL9r3OT1ekm9FniSZMhbYedvWsN/iJ6oKU34JMmPluYgEy4y2jd5SNN6i+hd1nV/yvTG3DNT9k749HG42YL+F+ZR0fhepzyT8hVZ8KIHfbDL0Kvvb2ATk6CuDCgDYI0Y4mOymARTcXN3q+m4kw6jrR2SDvjkLfaV+m885lb3mhUlMHKVMZ2mo7Zze7b1FqWcpJi0txKsfMi91OfXylS78pz9q90ZEBnvI6TOIrCowKHhz6RpgwCc5EJBxxXoV8e/7PoCMHAxkMFyyvFLUa07Uj0srn1C1ZxAg+1Qfd3ugHjJNB5ILsrz3c+3mhjR/Xu86AKXk83SEf1dO5jKjsrVHTWvBxqZeednUU/NlTRdzhF5uXYsaXKpDjNyqBD59Cn3IB5ccWLxIHHgrckT4tBuXSPqxPX/DIdlztznl4QzV9LTcPQY97mtlZ1CNrUV4j1XbgN9aDT04/esyeaEZviSbMR6cJ4g7rT8n1XMej09HHCLMxQ9Bl4xni87ur0Ge9rrYZZqkUMts5j6Ol8lizJua/0AINZbSeyRDpuLC6r7lGdcWxB6Ot0+jRBWpohH0RqrMSqM+GFz7PRw5Oge9pwsM+8kVJ/gk5DTN4k+pDZ0KV44vhAIanPa6JzTukxLqAPVHQ8TI8PT3Wxxyo8ds/J/Fn0YpqdkJ9Jz7PfRecm6pQUHuOb6IWBxOdaKbp0WLWkWfJNdGae/fSgFL/zdY/xTXQ+3vAD80opnvqPH6IXvmIEVy1ZJvMY5GH6R+jcV5CXshq8E5GH6Z+gl4PMRLKqHuPrdM+zVfQYLzgi/dQQr3qMTbtOT+ivBzI9IQ42GSs6LfG28RrTM9ZGrpmvSPdKA1P8kkQbzqj2ZY+RjeVJ9MnSwlq/SvTHNVlYTjE93WG5u/bVxiWvtL9SYI+x8smO9PMPynz9GhqHbnMAAAAASUVORK5CYII="
            />
          </Logo>

          {localStorage.getItem("type") === "doctor" && <SignatureModal />}

          {localStorage.getItem("type") === "doctor" && (
            <Box>
              <NavLink to="/pacientes">Pacientes</NavLink>
              <NavLink to="/operadoras">Operadoras</NavLink>
              <NavLink to="/prescricoes">Prescrições</NavLink>
              <NavLink to="/meuperfil">Perfil</NavLink>
            </Box>
          )}

          {localStorage.getItem("type") === "establishment" && (
            <Box>
              <NavLink to="/estabelecimento/dashboard">Dashboard</NavLink>
              <NavLink to="/estabelecimento/pacientes">Pacientes</NavLink>
              <NavLink to="/upload-exames">Upload de exames</NavLink>
              <NavLink to="/listar-exames">Listagem de exames</NavLink>

              {localStorage.getItem("has_loyalty_card") === "true" && (
                <NavLink to="/estabelecimento/cartaoFidelidade">
                  Cartão Fidelidade
                </NavLink>
              )}
              <NavLink to="/estabelecimento/medicos">Médicos</NavLink>

              {localStorage.getItem("has_study_hcg") === "true" ||
                (localStorage.getItem("has_polypus") === "true" && (
                  <Accordion
                    style={{
                      boxShadow: "none",
                      position: "unset",
                      color: "#707070",
                      fontWeight: "300",
                    }}
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary style={{ marginLeft: "6px" }}>
                      Dados Clínicos
                    </AccordionSummary>
                    {localStorage.getItem("has_study_hcg") === "true" && (
                      <AccordionDetails
                        style={{ padding: "0 16px", maxHeight: "50px" }}
                      >
                        <NavLink to="/estabelecimento/betaHCG">
                          Beta HCG
                        </NavLink>
                      </AccordionDetails>
                    )}
                    {localStorage.getItem("has_polypus") === "true" && (
                      <AccordionDetails
                        style={{ padding: "0 16px", maxHeight: "50px" }}
                      >
                        <NavLink to="/estabelecimento/polypus">Pólipos</NavLink>
                      </AccordionDetails>
                    )}
                  </Accordion>
                ))}
              <BlankNavLink to="/politica-de-privacidade">
                Termos de Uso
              </BlankNavLink>
            </Box>
          )}

          {/* Associados: */}
          {/* {localStorage.getItem("type") === "patient" && (
            <Box>
              <NavLink to="/arquivos">Arquivos</NavLink>
              <NavLink to="/medicos">Médicos</NavLink>
              <NavLink to="/instituicoes">Instituições</NavLink>
              <NavLink to="/perfil">Perfil</NavLink>
              <BlankNavLink to="/politica-de-privacidade">
                Termos de Uso
              </BlankNavLink>
            </Box>
          )} */}

          {/* Operador: */}
          {localStorage.getItem("type") === "patient" && (
            <Box>
              <NavLink to="/arquivos">Arquivos</NavLink>
              <NavLink to="/medicos">Médicos</NavLink>
              <NavLink to="/instituicoes">Instituições</NavLink>
              <NavLink to="/perfil">Perfil</NavLink>
              <BlankNavLink to="/politica-de-privacidade">
                Termos de Uso
              </BlankNavLink>
            </Box>
          )}

          {localStorage.getItem("type") === "operator" && (
            <Box>
              <NavLink to="/pacientes">Pacientes</NavLink>
              <NavLink to="/medicos">Médicos</NavLink>
              <NavLink to="/relatorios">Relatórios</NavLink>
              <NavLink to="/alertas">Alertas</NavLink>
              <NavLink to="/usuarios">Usuários</NavLink>
              <NavLink to="/perfil">Perfil</NavLink>
              <BlankNavLink to="/termos">Termos de Uso</BlankNavLink>
            </Box>
          )}
        </Sidebar>

        <ContentWrapper>
          <Header>
            <Flex width="100%" jc="space-between" ai="center">
              <Box display={["block", "none"]} p={2}>
                <Flex ai="center">
                  <Box cursor="pointer">
                    <MenuIcon onClick={() => setMenuModalOpen(true)} />
                  </Box>
                  <Box ml={3}>
                    <img
                      style={{ height: 30 }}
                      alt="Logo - Tria"
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAAqCAMAAACp1aRYAAAAwFBMVEUAAAAAAP//AAAAqv+/AACZZswas+bmAE3fAEDiAE4utuTdAEXqACA+rODfEkncNFvdAEg8ruPfHErgHEc7rOE8rN/fGkg8reHhGUk/reE9rOLhF0c+rOHhFEk7ruE+reLfE0nhEkg9reE9reHgEEk7reHgF0g+rOHgF0g9reHgF0g9rOHgFkk9ruE+reHgFkhCrOA9reHfFUk9reHgFUk9reE9r+Q+sOY/sec/s+hCrODgFUnhFUniFUnkFUvmFUsMp7FVAAAANXRSTlMAAQIDBAUKChAaHCUwMTg7PEhIU1ZZWV1lZmlveH6Hj5CgorGzwcXP19vk5u/x+Pj6+/z9/iqSIZkAAARcSURBVFjDtZjrQuo6EIUntAcEdW9RUUAR5WK5ltN7ewrt+7/Vzq20aRK2cHT9EZrA50wnM6sAGABDaxvo5cRzmMeOvOBt1/O3WwADAZjw6+CnqZ+619CAilAD2qPPje+uZv0G3ibKQB0rjoJL6FhRHMy7+EtO0KG/ybI0TQ9ZtriT8B07drxL6Z4TxPHEAKOhoZvwnu9dnyy5bpbd1/FWbAfBpXSy7CRWE/5R0014yF18nck97NpiZp7131ulRyd22clUQ0eAFpmbHrXLP8Tg56HtVHQMqJQdEbqwq7bdSYagpJtwz+A47fTPfnOF/6VSXlJVUX5xRVFigZVEsUJJSPleuG5q6B+5S6l5znKfPwnBD4YVPU8iUoDhdtB7rqgL3d6jQr3xlt0RJ+7Bb5mO4Gqz92nIL+9kOXWzmVT2pbqJQ0OpXUa6/R2L4p14oqKb8ERDd/M+wIzcAz8V686oqMlqkCQSVa8bwq6qUHMb4mx50VKVeZMjD5tWUQFu/qIL3oBeQdeHW00JasIryRa+V80biY673A5fwURS6a0V2eBmn+KZu5xOPnEb0jL9r3OT1ekm9FniSZMhbYedvWsN/iJ6oKU34JMmPluYgEy4y2jd5SNN6i+hd1nV/yvTG3DNT9k749HG42YL+F+ZR0fhepzyT8hVZ8KIHfbDL0Kvvb2ATk6CuDCgDYI0Y4mOymARTcXN3q+m4kw6jrR2SDvjkLfaV+m885lb3mhUlMHKVMZ2mo7Zze7b1FqWcpJi0txKsfMi91OfXylS78pz9q90ZEBnvI6TOIrCowKHhz6RpgwCc5EJBxxXoV8e/7PoCMHAxkMFyyvFLUa07Uj0srn1C1ZxAg+1Qfd3ugHjJNB5ILsrz3c+3mhjR/Xu86AKXk83SEf1dO5jKjsrVHTWvBxqZeednUU/NlTRdzhF5uXYsaXKpDjNyqBD59Cn3IB5ccWLxIHHgrckT4tBuXSPqxPX/DIdlztznl4QzV9LTcPQY97mtlZ1CNrUV4j1XbgN9aDT04/esyeaEZviSbMR6cJ4g7rT8n1XMej09HHCLMxQ9Bl4xni87ur0Ge9rrYZZqkUMts5j6Ol8lizJua/0AINZbSeyRDpuLC6r7lGdcWxB6Ot0+jRBWpohH0RqrMSqM+GFz7PRw5Oge9pwsM+8kVJ/gk5DTN4k+pDZ0KV44vhAIanPa6JzTukxLqAPVHQ8TI8PT3Wxxyo8ds/J/Fn0YpqdkJ9Jz7PfRecm6pQUHuOb6IWBxOdaKbp0WLWkWfJNdGae/fSgFL/zdY/xTXQ+3vAD80opnvqPH6IXvmIEVy1ZJvMY5GH6R+jcV5CXshq8E5GH6Z+gl4PMRLKqHuPrdM+zVfQYLzgi/dQQr3qMTbtOT+ivBzI9IQ42GSs6LfG28RrTM9ZGrpmvSPdKA1P8kkQbzqj2ZY+RjeVJ9MnSwlq/SvTHNVlYTjE93WG5u/bVxiWvtL9SYI+x8smO9PMPynz9GhqHbnMAAAAASUVORK5CYII="
                    />
                  </Box>
                </Flex>
              </Box>
              <Box flex="1" ta="right" w={1} p={3} cursor="pointer">
                <Flex jc="flex-end" ai="center">
                  <Box mr={3}>
                    <Text>
                      {(localStorage.getItem("type") === "doctor" &&
                        "médico") ||
                        (localStorage.getItem("type") === "patient" &&
                          "paciente")}
                    </Text>
                  </Box>

                  <Flex
                    mr={2}
                    width={30}
                    height={30}
                    overflow="hidden"
                    w
                    borderRadius="50%"
                    jc={"center"}
                    border={"3px solid #eee"}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={me ? me.thumbnail : ""}
                      alt=""
                    />
                  </Flex>
                  <Box mr={3}>
                    <Text>{me ? me.login : "w"}</Text>
                  </Box>
                  <Box mr={3}>
                    <Text
                      onClick={() => {
                        if (
                          !notifications.filter((item) => !item.read).length
                        ) {
                          return alert(
                            "Você não possui notificação no momento."
                          );
                        }

                        setNotificationOpen(true);
                      }}
                    >
                      Notificações
                      {notifications &&
                        `(${
                          notifications.filter((item) => !item.read).length
                        })`}
                    </Text>
                  </Box>
                  <Box>
                    <Text underline onClick={() => logout(client)}>
                      Sair
                    </Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Header>

          <Content>
            <Box px={[2, 5, 5, 4]} py={3}></Box>
            {children}
            <Footer />
          </Content>
        </ContentWrapper>
      </Wrapper>

      {menuModalOpen && (
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setMenuModalOpen(false)}
          render={({ onRequestClose }) => (
            <Flex fd="column" h="100%">
              <Box position="relative" h={64}>
                <Box
                  position="absolute"
                  cursor="pointer"
                  lh="0"
                  top={16}
                  right={16}
                  onClick={onRequestClose}
                >
                  <CloseIcon />
                </Box>
              </Box>
              <Box p={4} overflow="auto">
                <Box p={4}>
                  {localStorage.getItem("type") === "doctor" && (
                    <Box>
                      <NavLink to="/pacientes">Pacientes</NavLink>
                      <NavLink to="/operadoras">Operadoras</NavLink>
                      <NavLink to="/prescricoes">Prescrições</NavLink>
                      <NavLink to="/meuperfil">Perfil</NavLink>
                      <NavLink to="/termos">Termos de Uso</NavLink>
                    </Box>
                  )}

                  {/* Operador: */}
                  {localStorage.getItem("type") === "patient" && (
                    <Box>
                      <NavLink to="/arquivos">Arquivos</NavLink>
                      <NavLink to="/medicos">Médicos</NavLink>
                      <NavLink to="/instituicoes">Instituições</NavLink>
                      <NavLink to="/perfil">Perfil</NavLink>
                      <BlankNavLink to="/politica-de-privacidade">
                        Termos de Uso
                      </BlankNavLink>
                    </Box>
                  )}

                  {/* {localStorage.getItem("type") === "patient" && (
                    <Box>
                      <NavLink to="/associados">Associados</NavLink>
                      <NavLink to="/relatorios">Relatórios</NavLink>
                      <NavLink to="/alertas">Alertas</NavLink>
                      <NavLink to="/usuarios">Usuários</NavLink>
                      <NavLink to="/perfil">Perfil</NavLink>
                      <NavLink to="/termos">Termos de Uso</NavLink>
                    </Box>
                  )} */}

                  {localStorage.getItem("type") === "operator" && (
                    <Box>
                      <NavLink to="/pacientes">Pacientes</NavLink>
                      <NavLink to="/medicos">Médicos</NavLink>
                      <NavLink to="/relatorios">Relatórios</NavLink>
                      <NavLink to="/alertas">Alertas</NavLink>
                      <NavLink to="/usuarios">Usuários</NavLink>
                      <NavLink to="/perfil">Perfil</NavLink>
                      <NavLink to="/termos">Termos de Uso</NavLink>
                    </Box>
                  )}

                  {localStorage.getItem("type") === "establishment" && (
                    <Box>
                      <NavLink to="/estabelecimento/dashboard">
                        Dashboard
                      </NavLink>
                      <NavLink to="/estabelecimento/pacientes">
                        Pacientes
                      </NavLink>
                      <NavLink to="/estabelecimento/medicos">Médicos</NavLink>
                    </Box>
                  )}
                </Box>
              </Box>
            </Flex>
          )}
        />
      )}

      {notificationOpen && (
        <Notifications
          hasBackdrop={false}
          onCloseCompleted={() => setNotificationOpen(false)}
          render={({ onRequestClose }) => {
            return (
              <Box>
                {/* {error && <div>Erro! ${error.message}</div>} */}
                {loading && <CircularProgress color="hsla(199, 75%, 43%, 1)" />}
                <Box
                  display="inline-block"
                  onClick={() => onRequestClose()}
                  p={2}
                  cursor="pointer"
                >
                  <CloseIcon />
                </Box>
                <Box p={2}>
                  {notifications &&
                    notifications
                      .filter((item) => !item.read)
                      .map((item) => (
                        <Box
                          p={2}
                          my={2}
                          key={item.id}
                          borderRadius={8}
                          backgroundColor="hsla(201, 20%, 93%, 1)"
                        >
                          {/* <Box>{item.created_at}</Box> */}
                          <Box mb={2}>
                            <Text fontSize="13px" fontWeight={600}>
                              {item.title}
                            </Text>
                          </Box>
                          <Box mb={2}>
                            <Text fontSize="13px">{item.message}</Text>
                          </Box>
                          <Box mb={2}>
                            <Button
                              small
                              onClick={() => handleHideNotification(item.id)}
                            >
                              Ok
                            </Button>
                          </Box>
                        </Box>
                      ))}
                </Box>
              </Box>
            );
          }}
        />
      )}
    </Container>
  );
});

export default memo((props) => {
  const loginType = localStorage.getItem("type");
  let query = ME_PATIENT;
  if (loginType === "doctor") query = ME_DOCTOR;
  if (loginType === "operator") query = ME_OPERATOR;
  if (loginType === "establishment") query = ME_ESTABLISHMENT;
  const { data, error, loading } = useQuery(query);
  if (error) {
    return logout(client);
    // return props.onLogout();
    // return `Erro! ${error.message}`
  }
  if (!data || loading) return <CircularProgress />;
  return <Main {...props} me={data[loginType]} />;
});
