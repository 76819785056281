import gql from 'graphql-tag'

export const GET = gql`
  query {
    doctor @rest(type: "Doctor", path: "/doctor") {
      id
      createdAt
      name
      phone
      birthDate
      login
      description
      price
      gender
      crm
      crmType
      crmUf
      thumbnail
      specialtyPrimary
      specialtyPrimaryObj
      specialtySecundary
      specialtySecundaryObj
      healthInsurances
      establishments
      addresses
    }
  }
`

export const UPDATE = gql`
  mutation ($input: Doctor!) {
    media(input: $input) @rest(type: "Doctor", path: "/doctor/", method: "PUT", bodySerializer: "form") {
      NoResponse
    }
  }
`

export const CREATE_ADDRESS = gql`
  mutation ($input: Address!) {
    media(input: $input) @rest(type: "Address", path: "/address/", method: "POST", bodySerializer: "form") {
      NoResponse
    }
  }
`