import React, { memo, useState, useEffect } from "react";
import { css } from "styled-components/macro";
import { ButtonRaw } from "@alobato/button";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import { Formik } from "formik";
import { FacebookIcon, GoogleIcon } from "../../components/Icons";
import md5 from "md5";
import { Flex, Box } from "../../components/FlexBox";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Select from "../../components/Select";
import { CloseIcon } from "../../components/Icons";
import Modal from "../../components/StyledModal";
import {
  SignupFormPatientStep1,
  SignupFormPatientStep2,
} from "./SignupFormPatient";
import {
  SignupFormDoctorStep1,
  SignupFormDoctorStep2,
} from "./SignupFormDoctor";
import * as Yup from "yup";
import "../../validators";
import BASE_API from "../../constants/baseApi";
import formDataFromObject from "../../utils/formDataFromObject";

const validationSchemas = {
  patient: [
    Yup.object().shape({
      login: Yup.string().required("O email é obrigatório"),
      name: Yup.string().required("O nome é obrigatório"),
      password: Yup.string()
        .required("A senha é obrigatória")
        .matches(
          /[a-z]/,
          "Pelo menos uma letra maiúscula, um número e um caractere especial"
        )
        .matches(
          /[A-Z]/,
          "Pelo menos uma letra maiúscula, um número e um caractere especial"
        )
        .matches(
          /\d/,
          "Pelo menos uma letra maiúscula, um número e um caractere especial"
        )
        .matches(
          /[@$!%*#?&]/,
          "Pelo menos uma letra maiúscula, um número e um caractere especial"
        ),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Confirmação de senha é diferente"
      ),
      terms: Yup.boolean().oneOf(
        [true],
        "Você deve concordar com os termos para continuar"
      ),
    }),
    Yup.object().shape({
      birth_date: Yup.string().required("A data de nascimento é obrigatória"),
      cpf: Yup.string().cpf("CPF Inválido").required("O CPF é obrigatório"),
      gender: Yup.string().required("O gênero é obrigatório"),
      cellphone: Yup.string().required("O telefone é obrigatório"),
      health_insurance: Yup.string().required("O plano de saúde é obrigatório"),
    }),
  ],
  doctor: [
    Yup.object().shape({
      login: Yup.string().required("O email é obrigatório"),
      name: Yup.string().required("O nome é obrigatório"),
      password: Yup.string()
        .required("A senha é obrigatória")
        .matches(
          /[a-z]/,
          "Pelo menos uma letra maiúscula, um número e um caractere especial"
        )
        .matches(
          /[A-Z]/,
          "Pelo menos uma letra maiúscula, um número e um caractere especial"
        )
        .matches(
          /\d/,
          "Pelo menos uma letra maiúscula, um número e um caractere especial"
        )
        .matches(
          /[@$!%*#?&]/,
          "Pelo menos uma letra maiúscula, um número e um caractere especial"
        ),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Confirmação de senha é diferente"
      ),
      terms: Yup.boolean().oneOf(
        [true],
        "Você deve concordar com os termos para continuar"
      ),
    }),
    Yup.object().shape({
      birth_date: Yup.string().required("A data de nascimento é obrigatória"),
      crm_type: Yup.string().required(),
      crm: Yup.string().required(),
      crm_uf: Yup.string().required(),
      gender: Yup.string().required("O gênero é obrigatório"),
      phone: Yup.string().required("O telefone é obrigatório"),
      specialty_primary: Yup.string().required(),
      specialty_secundary: Yup.string().required(),
    }),
  ],
  establishment: [
    Yup.object().shape({
      login: Yup.string().required("O email é obrigatório"),
      name: Yup.string().required("O nome é obrigatório"),
      password: Yup.string().required("A senha é obrigatória"),
      password_confirmation: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Confirmação de senha é diferente"
      ),
      terms: Yup.boolean().oneOf(
        [true],
        "Você deve concordar com os termos para continuar"
      ),
    }),
    Yup.object().shape({}),
  ],
};

const Signup = memo(({ navigate, message }) => {
  const [loginType, setLoginType] = useState("patient");
  const [step, setStep] = useState(1);

  const [tempToken, setTempToken] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");

  const [alertModal, setAlertModal] = useState(false);

  useEffect(() => {
    setStep(1);
  }, [setStep, loginType]);

  const commomInitialValues = {
    terms: false,
    name: userName,
    login: email,
    password: "",
    password_confirmation: "",
  };
  const initialValues = {
    patient: {
      ...commomInitialValues,
      birth_date: "",
      cpf: "",
      gender: "",
      cellphone: "",
      health_insurance: "",
      other_health_insurance: "",
    },
    doctor: {
      ...commomInitialValues,
      birth_date: "",
      crm_type: "",
      crm_uf: "",
      crm: "",
      gender: "",
      phone: "",
      specialty_primary: "",
      specialty_secundary: "",
    },
    establishment: {
      ...commomInitialValues,
    },
  };

  const sendEmailVerification = async (token) => {
    await (
      await fetch(`${BASE_API}/sendVerificationEmail/`, {
        method: "POST",
        headers: { Authorization: `token ${token}` },
      })
    ).json();
  };

  const handleSubmit = async (variables, actions) => {
    let baseVariables = {};

    if (step === 1) {
      baseVariables = {
        ...(variables.cpf.length && { cpf: variables.cpf }),
        login: variables.login,
        password: md5(variables.password).toUpperCase(),
        name: variables.name,
      };
      const body = formDataFromObject(baseVariables);
      try {
        if (tempToken) {
          const putData = await (
            await fetch(`${BASE_API}/${loginType}/`, {
              method: "PUT",
              body,
              headers: { Authorization: `token ${tempToken}` },
            })
          ).json();
          if (!putData || putData.message) {
            actions.setSubmitting(false);
            return message("Ocorreu um erro.");
          }
          if (putData.message && putData.message.login) {
            actions.setSubmitting(false);
            return message(putData.message.login[0]);
          }
          actions.setSubmitting(false);
          setStep(2);

          setEmail(variables.login);
          setUserName(variables.name);
          setPassword(variables.password);
        } else {
          const postData = await (
            await fetch(`${BASE_API}/${loginType}/`, { method: "POST", body })
          ).json();
          if (!postData) {
            actions.setSubmitting(false);
            return message("Ocorreu um erro.");
          }
          if (postData.message && postData.message.login) {
            actions.setSubmitting(false);
            return message(postData.message.login[0]);
          }
          actions.setSubmitting(false);
          setStep(2);

          setEmail(variables.login);
          setUserName(variables.name);
          setPassword(variables.password);
        }
      } catch (error) {
        actions.setSubmitting(false);
        console.error(error);
        return message("Ocorreu um erro.");
      }
    }

    if (step === 2) {
      if (loginType === "patient")
        baseVariables = {
          birth_date: variables.birth_date,
          cpf: variables.cpf,
          gender: variables.gender,
          cellphone: variables.cellphone,
          health_insurance: variables.health_insurance,
        };
      if (loginType === "doctor")
        baseVariables = {
          birth_date: variables.birth_date,
          crm_type: variables.crm_type,
          crm_uf: variables.crm_uf,
          crm: variables.crm,
          gender: variables.gender,
          phone: variables.phone,
          specialty_primary: variables.specialty_primary,
          specialty_secundary: variables.specialty_secundary,
        };

      if (loginType === "patient" && variables.health_insurance === "7")
        baseVariables = {
          ...baseVariables,
          other_health_insurance: variables.other_health_insurance,
        };

      try {
        let token = tempToken;

        if (!token) {
          const authData = await (
            await fetch(`${BASE_API}/auth/`, {
              method: "POST",
              body: formDataFromObject({
                username: email,
                password: md5(password).toUpperCase(),
                type: loginType,
              }),
            })
          ).json();
          token = authData.token;
        }

        if (token) {
          try {
            const step2PutData = await (
              await fetch(`${BASE_API}/${loginType}/`, {
                method: "PUT",
                body: formDataFromObject(baseVariables),
                headers: { Authorization: `token ${token}` },
              })
            ).json();
            if (!step2PutData || step2PutData.message) {
              actions.setSubmitting(false);
              if (
                step2PutData.message &&
                step2PutData.message.cpf &&
                step2PutData.message.cpf[0] ===
                  "patient com este cpf já existe."
              ) {
                return message("Já existe um usuário cadastrado com esse CPF");
              }
              return message("Ocorreu um erro.");
            } else {
              await sendEmailVerification(token);
              actions.setSubmitting(false);
              setAlertModal(true);
              localStorage.setItem("tempToken", token);
              return navigate("/login?successSignup=1");
            }
          } catch (error) {
            actions.setSubmitting(false);
            console.error(error);
            return message("Ocorreu um erro.");
          }
        } else {
          actions.setSubmitting(false);
          return message("Ocorreu um erro.");
        }
      } catch (error) {
        console.error(error);
        return message("Ocorreu um erro.");
      }
    }
  };

  const renderForm = (props, email) => {
    switch (loginType) {
      case "patient":
        if (step === 1)
          return (
            <SignupFormPatientStep1 {...props} email={email} name={userName} />
          );
        if (step === 2)
          return (
            <SignupFormPatientStep2 {...props} email={email} name={userName} />
          );
        break;
      case "doctor":
        if (step === 1)
          return (
            <SignupFormDoctorStep1 {...props} email={email} name={userName} />
          );
        if (step === 2)
          return (
            <SignupFormDoctorStep2 {...props} email={email} name={userName} />
          );
        break;
      default:
        return false;
    }
  };

  const responseFacebook = async (response) => {
    const { accessToken, name } = response;
    setUserName(name);
    try {
      const loginFacebookData = await (
        await fetch(`${BASE_API}/loginFacebook/`, {
          method: "POST",
          body: formDataFromObject({ token: accessToken, mode: loginType }),
        })
      ).json();
      const { token } = loginFacebookData;
      setTempToken(token);
      const data = await (
        await fetch(`${BASE_API}/${loginType}/`, {
          headers: { Authorization: `token ${token}` },
        })
      ).json();
      setEmail(data.login);
    } catch (error) {
      console.error(error);
    }
  };

  const responseGoogle = async (response) => {
    const { tokenId, profileObj } = response;
    try {
      const loginGoogleData = await (
        await fetch(`${BASE_API}/loginGoogle/`, {
          method: "POST",
          body: formDataFromObject({ token: tokenId, mode: loginType }),
        })
      ).json();
      const { token } = loginGoogleData;
      setTempToken(token);
      const data = await (
        await fetch(`${BASE_API}/${loginType}/`, {
          headers: { Authorization: `token ${token}` },
        })
      ).json();
      setEmail(data.login);
      setUserName(data.name);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Flex jc="center" maxWidth={420} mx="auto">
      <Box width={[1]}>
        <Box ta="center">
          <Text extralarge primary fw={600}>
            Criar conta
          </Text>
        </Box>

        <Box p={[3, 4]} mx="auto">
          <Flex
            css={css`
              align-items: center;
              margin: 30px 0;
            `}
          >
            <Box
              css={css`
                flex-grow: 1;
                border-top: 1px solid #a5a5a5;
              `}
            />
            <Box
              css={css`
                font-size: 13px;
                padding: 0 10px;
                color: #a5a5a5;
              `}
            >
              Criar conta
            </Box>
            <Box
              css={css`
                flex-grow: 1;
                border-top: 1px solid #a5a5a5;
              `}
            />
          </Flex>

          <Box mb={4}>
            <Select
              name="loginType"
              value={loginType}
              onChange={(e) => setLoginType(e.target.value)}
            >
              <option value="patient">Paciente</option>
              <option value="doctor">Médico</option>
              {/* <option value='establishment'>Instituição</option> */}
            </Select>
          </Box>

          {["patient", "doctor"].includes(loginType) && (
            <Box mb={4}>
              <Box mb={3} ta="center">
                <FacebookLogin
                  appId="187089541949496"
                  callback={responseFacebook}
                  render={(renderProps) => (
                    <ButtonRaw
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        maxWidth: 240,
                        padding: 8,
                        background: "#4267b2",
                        color: "white",
                        borderRadius: 20,
                      }}
                      onClick={renderProps.onClick}
                    >
                      <Flex ai="center" jc="center">
                        <Box mr={2}>
                          <FacebookIcon height={16} />
                        </Box>
                        <Box
                          css={css`
                            font-size: 14px;
                          `}
                        >
                          Conectar com Facebook
                        </Box>
                      </Flex>
                    </ButtonRaw>
                  )}
                />
              </Box>

              <Box ta="center">
                <GoogleLogin
                  clientId="1080521404778-dc1i91l4n34kgrncvp8si6ji2og980rh.apps.googleusercontent.com"
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  cookiePolicy={"single_host_origin"}
                  render={(renderProps) => (
                    <ButtonRaw
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        maxWidth: 240,
                        padding: 8,
                        background: "#d93025",
                        color: "white",
                        borderRadius: 20,
                      }}
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      <Flex ai="center" jc="center">
                        <Box mr={2}>
                          <GoogleIcon height={16} />
                        </Box>
                        <Box
                          css={css`
                            font-size: 14px;
                          `}
                        >
                          Conectar com Google
                        </Box>
                      </Flex>
                    </ButtonRaw>
                  )}
                />
              </Box>
            </Box>
          )}

          <Box mb={4}>
            <Formik
              initialValues={initialValues[loginType]}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validationSchema={validationSchemas[loginType][step - 1]}
              render={(props) => renderForm(props, email)}
            />
          </Box>

          <Box ta="center">
            <Text pointer underline onClick={() => navigate("/login")}>
              Fazer login
            </Text>
          </Box>
        </Box>
      </Box>

      {alertModal && (
        <Modal
          onCloseCompleted={() => {
            setAlertModal(false);
          }}
          css={css`
            & > div {
              max-height: 300px;
            }
          `}
          render={({ onRequestClose }) => (
            <Flex fd="column" h="100%">
              <Box position="relative" h={64}>
                <Box
                  position="absolute"
                  cursor="pointer"
                  lh="0"
                  top={16}
                  right={16}
                  onClick={onRequestClose}
                >
                  <CloseIcon />
                </Box>
              </Box>
              <Box p={4} overflow="auto">
                <Box ta="center">
                  Conta criada com sucesso! Verifique seu email para validar sua
                  conta.
                  <br />
                  <br />
                  <Button onClick={sendEmailVerification}>
                    Enviar email novamente
                  </Button>
                </Box>
              </Box>
            </Flex>
          )}
        />
      )}
    </Flex>
  );
});

export default Signup;
