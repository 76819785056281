import React, { memo, useState, useEffect } from 'react'

import { Flex, Box } from '../../components/FlexBox'
import { Table, TableContainer, TableRow, TableCell, TableHeaderRow, TableHeaderCell } from '@alobato/table'
import Text from '../../components/Text'
import CircularProgress from '../../components/CircularProgress'
import Button from '../../components/Button'
import Modal from '../../components/StyledModal'
import { CloseIcon } from '../../components/Icons'
import LoyaltyCardForm from './LoyaltyCardForm'

import useFetch from '@alobato/use-fetch'
import { Formik } from 'formik'
import * as Yup from 'yup'

import { serialize } from '../../utils/utils'
import Paginator from "../../components/Paginator"

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

import '../../utils/validators'
import formDataFromObject from '../../utils/formDataFromObject'

const formatDateTime = text => {
  if (!text) return ''
  const year = text.slice(0, 4)
  const month = text.slice(5, 7)
  const day = text.slice(8, 10)
  return `${day}/${month}/${year}`
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  cpf: Yup.string().required('O CPF é obrigatório'),
  birth_date: Yup.string().required('A data de nascimento é obrigatória'),
  expiration_date: Yup.string().required('A data de expiração é obrigatória'),
})

const List = memo(({ patients, navigate }) => {

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [results, setResults] = useState(patients.results)
  const [count, setCount] = useState(patients.count)
  const [errorAdd, setErrorAdd] = useState(false)
  const [update, setUpdate] = useState(1)
  const [message, setMessage] = useState("Erro ao criar cartão fidelidade, por favor verifique as informações inseridas.")
  const pageSize = 10

  useEffect(() => {
    async function fetchData() {
      const params = {
        'page': activePage,
        'pageSize': pageSize,
        'establishment': 15
      }

      const data = await (await fetch(`${BASE_API}/patient-loyalty-card/?${serialize(params)}`, {
        method: 'GET',
        headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` }
      })).json()

      setResults(data.results)
      setCount(data.count)
    }

    fetchData()
  }, [activePage, update])

  const handlePageChange = async (pageNumber) => {
    setActivePage(pageNumber)
  }

  const handleSubmit = async (variables, actions) => {
    console.log(variables, actions)
    setErrorAdd(false)

    // 11/05/2021
    const body_object = {
      establishment: 15,
      name: variables.name,
      cpf: variables.cpf,
      birth_date: `${variables.birth_date.substring(6, 10)}-${variables.birth_date.substring(3, 5)}-${variables.birth_date.substring(0, 2)}`,
      expiration_date: `${variables.expiration_date.substring(6, 10)}-${variables.expiration_date.substring(3, 5)}-${variables.expiration_date.substring(0, 2)}`,
    }
    
    try {
      const data = await fetch(`${BASE_API}/create-patient-loyalty-card/`, { 
        method: 'POST', 
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body_object) 
      })

      if(data.status == 200){
        setAddModalOpen(false)
        setUpdate(update+1)
      }
      else{
        const response = await (data).json()
        
        if(response.message){
          setMessage(response.message)
        }
        else if(response.detail){
          setMessage(response.detail)
        }
        else{
          setMessage('Erro ao criar cartão fidelidade, por favor verifique as informações inseridas.')
        }

        actions.setSubmitting(false)
        setErrorAdd(true)
      }

    } catch (e) {
      console.log(e)
      actions.setSubmitting(false)
      setErrorAdd(true)
    }
  };

  return (
    <Box>
      <Box>
        <Button small onClick={() => setAddModalOpen(true)}>
          Adicionar Cartão Fidelidade
        </Button>
      </Box>

      <TableContainer style={{ border: 'none', marginTop: '40px' }}>
        <Table>
          <TableHeaderRow style={{ boxShadow: 'inset 0 -1px 0 0 lightgrey' }}>
            <TableHeaderCell style={{ padding: '16px' }}>
              Nome
            </TableHeaderCell>
            <TableHeaderCell style={{ padding: '16px' }}>
              CPF
            </TableHeaderCell>
            <TableHeaderCell style={{ padding: '16px' }}>
              Data de Expiração
            </TableHeaderCell>
          </TableHeaderRow>
          {results.map(item => (
            <TableRow key={item.id} style={{ boxShadow: 'inset 0 -1px 0 0 lightgrey' }}>
              <TableCell style={{ padding: '16px' }} data-title='Nome'>
                {item.name}
              </TableCell>
              <TableCell style={{ padding: '16px' }} data-title='CPF'>
                {item.cpf}
              </TableCell>
              <TableCell style={{ padding: '16px' }} data-title='Data de expiração'>
                {formatDateTime(item.loyalty_card.expiration_date)}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>

      <Flex ai={'center'}>
        <Paginator 
          activePage={activePage}
          itemsCountPerPage={pageSize}
          totalItemsCount={count}
          onChange={handlePageChange}
        />
      </Flex>

      {addModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setAddModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Flex fd='column' h='100%'>
                <Box position='relative' h={64}>
                  <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}>
                    <CloseIcon />
                  </Box>
                  <Box p={3} ta='center'><Text secondary medium> Adicionar Cartão Fidelidade </Text></Box>
                </Box>
                <Box w='80%' style={{margin: '20px auto'}}>
                  <Formik
                    initialValues={{}}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    render={props => (<LoyaltyCardForm {...props} />)}
                  />
                </Box>
                {errorAdd && <Box w='80%' style={{margin: '20px auto', color: 'hsla(6,66%,47%,1)'}}>
                  {message}
                </Box>}
              </Flex>
            )
          }
        />
      }
    </Box>
  )
})

export default memo(props => {
  //http://api.tria.health/patient-loyalty-card/?establishment=15
  const { data, error, loading } = useFetch(`${BASE_API}/patient-loyalty-card/?establishment=15`)
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  console.log(data)
  return <List {...props} patients={data} />
})
