import React from "react";
import ReactDOM from "react-dom";

import * as camelCase from "camelcase";
import * as snake_case from "snake-case";

import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { RestLink } from "apollo-link-rest";
import ApolloLinkTimeout from "apollo-link-timeout";
import { ApolloProvider } from "react-apollo";
import { ApolloProvider as ApolloHooksProvider } from "react-apollo-hooks";
import CookieConsent from "react-cookie-consent";
import { Flex } from "./components/FlexBox";

import AUTH_TOKEN from "./constants/authToken";
import BASE_API from "./constants/baseApi";

import App from "./App";
import theme from "./layouts/theme";

const logout = (client) => {
  localStorage.removeItem(AUTH_TOKEN);
  localStorage.removeItem("has_study_hcg");
  localStorage.removeItem("has_polypus");
  localStorage.removeItem("has_loyalty_card");
  localStorage.removeItem("type");
  client.resetStore();
  window.location.href = "/login";
};

const formSerializer = (data, headers) => {
  const formData = new FormData();
  for (let key in data) {
    if (data.hasOwnProperty(key)) formData.append(key, data[key]);
  }
  return { body: formData, headers };
};

const timeoutLink = new ApolloLinkTimeout(60000);

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: { ...headers, authorization: token ? `token ${token}` : "" },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
    graphQLErrors.forEach(({ message, locations, path }) => {
      if (message === "Not authenticated as user.") logout(client);
    });
  }
  if (networkError && networkError.statusCode === 401) {
    console.log(`[Network error]: ${networkError}`);
    logout(client);
  }
});

const restLink = new RestLink({
  uri: BASE_API,
  bodySerializers: { form: formSerializer },
  fieldNameNormalizer: (key) => camelCase(key),
  fieldNameDenormalizer: (key) => snake_case(key),
});

const client = new ApolloClient({
  link: ApolloLink.from([timeoutLink, authLink, errorLink, restLink]),
  cache: new InMemoryCache(),
  defaultOptions: {},
});

const onAccept = async () => {
  const data = await fetch(`${BASE_API}/cookie/acceptCookie/`, {
    method: "POST",
    mode: "no-cors",
  });
};

ReactDOM.render(
  <ApolloProvider client={client}>
    <ApolloHooksProvider client={client}>
      <Flex>
        <App client={client} logout={logout} />
        <CookieConsent
          location="bottom"
          buttonText="Entendi"
          cookieName="cookiePermission"
          style={{
            background: theme.colors.primary,
            display: "flex",
            flex: 1,
            alignItems: "center",
          }}
          buttonStyle={{
            backgroundColor: `rgb(223, 28, 74)`,
            borderRadius: "22px",
            color: "#ffffff",
            fontSize: "16px",
            fontWeight: 700,
            padding: "12px 32px",
          }}
          onAccept={onAccept}
          expires={50}
        >
          <span style={{ fontSize: "14px" }}>
            Este website usa cookies essenciais para oferecer-lhe uma boa
            experiência de navegação e acesso a todas as funcionalidades.
            <br />
          </span>
          <span style={{ fontSize: "12px" }}>
            Ao clicar em "Entendi" ou ao continuar usando nosso site, você
            concorda com o uso que fazemos deles.
          </span>
        </CookieConsent>
      </Flex>
    </ApolloHooksProvider>
  </ApolloProvider>,
  document.getElementById("root")
);
