
import React, { memo, useCallback, useState } from "react";
import { FaSearch } from "react-icons/fa";
import { Box, Flex } from "../../components/FlexBox";
import {
  BtnBlue,
  BtnPink,
  BtnsMenu,
  CardOption,
  CardSearch,
  Main,
  Option,
  TableContent,
  Title
} from "./styles";

import ButtonLoading from "../../components/ButtonLoading";
import { TableUsers } from "./TableUsers";
import ViewLevel from "./ViewLevel";
import ViewUser from "./ViewUser";

export default memo(({ id, navigate, message, path }) => {
  const [page, setPage] = useState(1);
  const [viewUser, setViewUser] = useState(false);
  const [viewLevel, setViewLevel] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [searchInput, setSearchInput] = useState("");


 

  const backHome = useCallback((method) => {
    if (method === "save") {
      setLoadingSave(true);
    } else if (method === "delete") {
      setLoadingDelete(true);
    }
    setTimeout(() => {
      setViewUser(false);
      setViewLevel(false);
      setLoadingSave(false);
      setLoadingDelete(false);
    }, 1000);
  }, [])

  return (
    <Main>
      <Box p={4}>
        <Flex mb={3} jc={"space-between"} flexWrap="wrap" ai={"center"}>
          {(!viewUser && !viewLevel) && <Title>Usuários</Title>}
          {viewUser && <Title onClick={backHome}>&lt; {viewUser.name}</Title>}
          {viewLevel && <Title onClick={backHome}>&lt; {viewLevel.name}</Title>}
          {(viewUser || viewLevel) && <>
            <BtnsMenu>
              <BtnBlue disabled={loadingDelete || loadingSave} onClick={() => backHome("delete")}>Excluir {loadingDelete && <ButtonLoading />}</BtnBlue>
              <BtnPink disabled={loadingDelete || loadingSave} onClick={() => backHome("save")}>Salvar {loadingSave && <ButtonLoading />}</BtnPink>
            </BtnsMenu>
          </>}
        </Flex>
        <CardOption>
          {(!viewUser && !viewLevel) && <>
            <Option onClick={() => setPage(1)} select={page === 1 ? "true" : "false"}>Usuários</Option>
            <Option onClick={() => setPage(2)} select={page === 2 ? "true" : "false"}>Níveis</Option>
            <CardSearch>
              <input type="text" placeholder="Buscar" value={searchInput} onChange={(e) => setSearchInput(e.target.value)} />
              <FaSearch></FaSearch>
            </CardSearch>
          </>}
        </CardOption>

        {(!viewUser && !viewLevel) && <TableContent>
          <TableUsers searchInput={searchInput} setViewUser={setViewUser} setViewLevel={setViewLevel} page={page} />
        </TableContent>}
        {viewUser && <ViewUser user={viewUser} />}
        {viewLevel && <ViewLevel level={viewLevel} />}
      </Box>
    </Main>
  )
});
