import React, { memo, useState } from 'react'

import Button from '../../components/Button'
import { Box, Flex } from '../../components/FlexBox'
import { CloseIcon } from '../../components/Icons'
import Input from '../../components/Input'
import InputDate from '../../components/InputDate'
import InputPhone from '../../components/InputPhone'
import Select from '../../components/Select'
import Text from '../../components/Text'

import { useMutation } from '../../hooks/useApollo'
import { UPDATE } from './Gqls'

export default memo(({ onUpdated, onRequestClose, initialData }) => {

  const [name, setName] = useState(initialData.name || '')
  const [gender, setGender] = useState(initialData.gender || '')
  const [phone, setPhone] = useState(initialData.phone || '')
  const [birthDate, setBirthDate] = useState(initialData.birthDate || '')
  const [price, setPrice] = useState(initialData.price || '')
  const [description, setDescription] = useState(initialData.description || '')

  const updateItem = useMutation(UPDATE)

  const handleUpdate = () => {
    updateItem({ variables: { input: { name, gender, phone, price, description, birthDate } } })
      .then(data => {
        onUpdated()
      })
      .catch(error => {
        console.error('error', error)
      })
  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Informações</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>

        <Box mb={3}>
          <Box>Nome</Box>
          <Box><Input value={name} onChange={e => setName(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Email</Box>
          <Box><Input value={initialData.login} disabled style={{backgroundColor: '#e8e8e8'}} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Data de Nascimento</Box>
          <Box><InputDate value={birthDate} onChange={(e, value) => setBirthDate(value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Gênero</Box>
          <Select placeholder='Selecione...' name='gender' value={gender} onChange={e => setGender(e.target.value)}>
            <option>Masculino</option>
            <option>Feminino</option>
            <option>Outro</option>
          </Select>
        </Box>

        <Box mb={3}>
          <Box>Telefone</Box>
          <Box><InputPhone value={phone} onChange={e => setPhone(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Descrição</Box>
          <Box><Input value={description} onChange={e => setDescription(e.target.value)} /></Box>
        </Box>

        <Box mb={3}>
          <Box>Valor da Consulta</Box>
          <Box><Input value={price} onChange={e => setPrice(e.target.value)} /></Box>
        </Box>

        <Box><Button onClick={handleUpdate}>Salvar</Button></Box>
      </Box>
    </Flex>
  )
})
