import React, { useRef } from "react";
import styled from "styled-components";

const Radio = ({
  checked,
  label,
  labelStyle,
  containerStyle,
  checkAnimation,
  uncheckAnimation,
  ...rest
}) => {
  const checkboxRef = useRef();
  const checkRef = useRef();

  const handleClick = () => {
    if (checked) {
      if (uncheckAnimation) {
        uncheckAnimation(checkRef, () => rest.onChange(false));
      } else {
        rest.onChange(false);
      }
    } else {
      if (checkAnimation) {
        checkAnimation(checkRef, () => rest.onChange(true));
      } else {
        rest.onChange(true);
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: checked ? "black" : "white",
        color: checked ? "white" : "black",
      }}
      onKeyPress={(e) => ["Enter", " "].includes(e.key) && handleClick()}
      ref={checkboxRef}
      onClick={handleClick}
      {...rest}
    >
      {label}
    </div>
  );
};

const StyledRadio = styled(Radio)`
  border-radius: 24px;
  padding: 8px;
  text-align: center;
  max-width: 260px;
  margin-bottom: 16px;
  border: 3px solid black;
  cursor: pointer;
`;

const StyledRadioWithAnimations = (props) => <StyledRadio {...props} />;

StyledRadioWithAnimations.defaultProps = {
  tabIndex: 0,
  onChange: () => {},
  containerStyle: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  labelStyle: { marginLeft: 8 },
};

export default StyledRadioWithAnimations;
