import React, { memo } from 'react'

import Button from '../../components/Button'
import { Box } from '../../components/FlexBox'
import FormErrorBox from '../../components/FormErrorBox'
import Input from '../../components/Input'

import useScrollToError from '../../hooks/useScrollToError'

export default memo(({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, dirty }) => {
  useScrollToError({errors, isValid, isSubmitting})

  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Input autoComplete='nope' placeholder='Email' name='username' value={values.username} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='username' errors={errors} touched={touched} />
      </Box>
      <Box mb={1}>
        <Input type='password' autoComplete='current-password' placeholder='Senha' name='password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='password' errors={errors} touched={touched} />
      </Box>
      {/* <Box mb={4}>
        <Link to='/esqueci-a-senha'>Esqueci a senha</Link>
      </Box> */}
      <Box ta='center'>
        <Button type='submit' loading={isSubmitting} disabled={!dirty || isSubmitting}>Login</Button>
      </Box>
    </form>
  )
})
