import * as Yup from 'yup'

import '../../utils/validators'

const validationSchema = Yup.object().shape({
  username: Yup.string().required('O login é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
})

export default validationSchema
