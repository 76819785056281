import React from 'react'

export default class FormatPhone extends React.Component {
  static defaultProps = {
    currentDDD: null
  }

  render() {
    let formatedPhone = this.props.children
    if (!formatedPhone) return ''

    if (formatedPhone.length === 8) {
      formatedPhone = `${formatedPhone.substr(0, 4)}-${formatedPhone.substr(4, 4)}`
    }
    else if (formatedPhone.length === 9) {
      formatedPhone = `${formatedPhone.substr(0, 5)}-${formatedPhone.substr(5, 4)}`
    }
    else if (formatedPhone.length === 10) {
      if (this.props.currentDDD && this.props.currentDDD === formatedPhone.substr(0, 2)) {
        formatedPhone = `${formatedPhone.substr(2, 4)}-${formatedPhone.substr(6, 4)}`
      } else {
        formatedPhone = `(${formatedPhone.substr(0, 2)}) ${formatedPhone.substr(2, 4)}-${formatedPhone.substr(6, 4)}`
      }
    }
    else if (formatedPhone.length === 11) {
      if (this.props.currentDDD && this.props.currentDDD === formatedPhone.substr(0, 2)) {
        formatedPhone = `${formatedPhone.substr(2, 5)}-${formatedPhone.substr(7, 4)}`
      } else {
        formatedPhone = `(${formatedPhone.substr(0, 2)}) ${formatedPhone.substr(2, 5)}-${formatedPhone.substr(7, 4)}`        
      }
    }

    return formatedPhone
  }
}
