import React, { useState } from 'react';

import { Modal } from 'antd';
import ButtonLoading from '../../components/ButtonLoading';
import { Box } from "../../components/FlexBox";
import { CloseIcon } from '../../components/Icons';
import './style.css';
import {
    BtnPink,
    Btns,
    FormGroup,
    FormModal,
    ModalBox,
    ModalHeader
} from "./styles";

export default function NewPresctiptionModal({ addModal, handleOk, handleCancel, content }) {
    const [formValues, setFormValues] = useState({type: "1", category: "Radiografia", exam: "Radiografia simples", protocol: "Protocolo 1"});
    const [loading, setLoading] = useState(false);
    function formSubmitHandler(e) {
        e.preventDefault();
        setLoading(true);
        setTimeout(() => {
            const object = {
                name: formValues.type === "1" ? formValues.exam : formValues.protocol,
                category: formValues.category
            }
            content.set([object,...content.get])
            handleOk();
            setLoading(false);
        } , 500);
    }

    function formValueHandler(e) {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        });
    }

    return (
        <Modal open={addModal} onOk={handleOk} onCancel={handleCancel}>
            <ModalBox>
                <ModalHeader>
                    <h1>Gerar prescrição</h1>
                    <Box cursor='pointer' onClick={handleCancel}><CloseIcon /></Box>
                </ModalHeader>
                <Box w="100%">
                    <FormModal onSubmit={formSubmitHandler}>
                        <FormGroup>
                            <label htmlFor="type">Tipo</label>
                            <select value={formValues.type} onChange={formValueHandler} id="type" name="type">
                                <option value="1">Exame</option>
                                <option value="2">Protocolo</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            <label htmlFor="category">Categoria</label>
                            <select value={formValues.category} onChange={formValueHandler} id="category" name="category">
                                <option value="Radiografia">Radiografia</option>
                            </select>
                        </FormGroup>
                        <FormGroup>
                            {formValues.type === "1" && <>
                                <label htmlFor="exam">Exame</label>
                                <select value={formValues.exam} onChange={formValueHandler} id="exam" name="exam">
                                    <option value="Radiografia simples">Radiografia simples</option>
                                </select>
                            </>}

                            {formValues.type === "2" && <>
                                <label htmlFor="protocol">Protocolo</label>
                                <select value={formValues.protocol} onChange={formValueHandler} id="protocol" name="protocol">
                                    <option value="Protocolo 1">Protocolo 1</option>
                                </select>
                            </>}
                        </FormGroup>
                        <Btns>
                            <BtnPink width="100%" type="submit" >Gerar {loading && <ButtonLoading/>}</BtnPink>
                        </Btns>
                    </FormModal>
                </Box>
            </ModalBox>
        </Modal>
    )
}