import React, { memo } from 'react'
import { css } from 'styled-components/macro'
import { Formik } from 'formik'
import md5 from 'md5'
// import { useQueryParam, StringParam } from 'use-query-params'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'


import ForgotPasswordForm from './ForgotPasswordForm'

import BASE_API from '../../constants/baseApi'

import formDataFromObject from '../../utils/formDataFromObject'


import * as Yup from 'yup'

import '../../utils/validators'

const validationSchema = Yup.object().shape({
  email: Yup.string().required('O email é obrigatório')
})


export default memo(({ navigate, message }) => {
  
  const initialValues = { email: '' }

  const handleSubmit = async (variables, actions) => {
    // setLastEmail(variables.username)
    try {
      const body = formDataFromObject({ email: variables.email })
      const data = await (await fetch(`${BASE_API}/forgot_password/`, { method: 'POST', body})).json()
      console.log('data', data)

      if (data.worked) {
        actions.setSubmitting(false)
        actions.setValues(initialValues)
        return message('Enviado com sucesso!')
      }

    //   navigate('/')
      
      actions.setSubmitting(false)
    } catch(error) {
      console.error(error)
      actions.setSubmitting(false)
      message('Erro')
    }
  }

  return (
    <Flex jc='center' maxWidth={420} mx='auto'>

      <Box width={1}>

        <Box ta='center' mb={2}><Text extralarge primary fw={600}>Recuperar senha</Text></Box>

        <Box p={[3, 4]} mx='auto'>

          <Flex css={css`align-items:center;margin:20px 0;`}>
            <Box css={css`flex-grow:1;border-top:1px solid #A5A5A5;`} />
            <Box css={css`font-size:13px;padding:0 10px;color:#A5A5A5;`}>Digite seu email</Box>
            <Box css={css`flex-grow:1;border-top:1px solid #A5A5A5;`} />
          </Flex>

          <Box mb={4}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
              validateOnChange={false}
              validateOnBlur={false}
              render={props => (<ForgotPasswordForm {...props} />)}
            />
          </Box>

          <Box ta='center'><Text pointer underline onClick={() => navigate('/login')}>Fazer login</Text></Box>

        </Box>

      </Box>

    </Flex>
  )
})
