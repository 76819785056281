import React, { useEffect } from "react";
import Modal from "./StyledModalAlert";

export default ({ autoHideDuration = 3000, open, message, onClose }) => {
  if (open)
    return (
      <Modal
        hasBackdrop={false}
        onCloseCompleted={() => onClose()}
        render={({ onRequestClose }) => {
          let timeout;
          useEffect(() => {
            if (open) {
              timeout = setTimeout(() => {
                onRequestClose();
              }, autoHideDuration);
              return () => clearTimeout(timeout);
            }
          }, [open]);
          return <div onClick={() => onRequestClose()}>{message}</div>;
        }}
      />
    );

  return null;
};
