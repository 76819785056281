const space = [0, 4, 8, 16, 32, 64, 128, 256, 512]
const fontSizes = [12, 14, 16, 20, 24, 32, 48, 64, 96]

export default {
  space,
  fontSizes,
  colors: {
    white: 'white',
    black: 'black',
    primary100: 'hsla(201, 100%, 96%, 1)',
    primary200: 'hsla(202,  81%, 86%, 1)',
    primary300: 'hsla(203,  82%, 76%, 1)',
    primary400: 'hsla(205,  79%, 66%, 1)',
    primary:    'hsla(207,  73%, 57%, 1)',
    primary600: 'hsla(209,  62%, 50%, 1)',
    primary700: 'hsla(211,  61%, 43%, 1)',
    primary800: 'hsla(213,  49%, 34%, 1)',
    primary900: 'hsla(215,  41%, 28%, 1)',
    grey100: 'hsla(201,  20%, 96%, 1)',
    grey200: 'hsla(202,  20%, 86%, 1)',
    grey300: 'hsla(203,  20%, 76%, 1)',
    grey400: 'hsla(205,  20%, 66%, 1)',
    grey:    'hsla(207,  20%, 57%, 1)',
    grey600: 'hsla(209,  20%, 50%, 1)',
    grey700: 'hsla(211,  20%, 43%, 1)',
    grey800: 'hsla(213,  20%, 34%, 1)',
    grey900: 'hsla(215,  20%, 28%, 1)',
    error:      'hsla(6, 66%, 47%, 1)',
  },
}
