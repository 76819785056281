import { Box, Flex, css } from '@alobato/flex-box'
import camelcaseKeys from 'camelcase-keys'
import { Formik } from 'formik'
import md5 from 'md5'
import React, { memo, useEffect, useState } from 'react'
import CircularProgress from '../../components/CircularProgress'
import { FormatDate } from '../../components/Format'
import FormatPhone from '../../components/FormatPhone'
import Modal from '../../components/StyledModal'
import Text from '../../components/Text'

import { useQuery } from '../../hooks/useApollo'
import { GET } from './Gqls'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

import Allergies from './Allergies'
import Avatar from './Avatar'
import History from './History'
import Info from './Info'
import LifeStyle from './LifeStyle'
import Medications from './Medications'
import Plan from './Plan'


import ChangePasswordForm from './ChangePasswordForm'


import * as Yup from 'yup'

import '../../utils/validators'

import Button from '../../components/Button'
import formDataFromObject from '../../utils/formDataFromObject'
import Operator from './Operator'
import { Main } from './styles'

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string().required('A senha atual é obrigatória'),
  password: Yup.string().required('A nova senha é obrigatória').matches(/[a-z]/, 'Pelo menos uma letra maiúscula, um número e um caractere especial').matches(/[A-Z]/, 'Pelo menos uma letra maiúscula, um número e um caractere especial').matches(/\d/, 'Pelo menos uma letra maiúscula, um número e um caractere especial').matches(/[@$!%*#?&]/, 'Pelo menos uma letra maiúscula, um número e um caractere especial'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Confirmação de senha é diferente'),
})

const Home = memo(({ me, message }) => {
  console.log('me', me)
  const initialValues = { currentPassword: '', password: '', passwordConfirmation: '' }

  const [avatarModalOpen, setAvatarModalOpen] = useState(false)
  const [allergiesModalOpen, setAllergiesModalOpen] = useState(false)
  const [historyModalOpen, setHistoryModalOpen] = useState(false)
  const [medicationsModalOpen, setMedicationsModalOpen] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [planModalOpen, setPlanModalOpen] = useState(false)
  const [lifeStyleModalOpen, setLifeStyleModalOpen] = useState(false)
  const [operatorModalOpen, setOperatorModalOpen] = useState(false)

  // const [currentPassword, setCurrentPassword] = useState('')
  // const [newPassword, setNewPassword] = useState('')
  // const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')

  const [patient, setPatient] = useState(me);
  const [operator, setOperator] = useState(me);

  const fetchPatient = async () => {
    const response = await fetch(`${BASE_API}/patient`, { method: 'GET', headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' } })
    const data = await response.json()
    setPatient(camelcaseKeys(data))
  }

  const fetchPatientNoStorage = async () => {
    const response = await fetch(`${BASE_API}/patient/?storage=false`, { method: 'GET', headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' } })
    const data = await response.json()
    if (patient) {
      data['subscription'] = patient.subscription
      data['docsSize'] = patient.docsSize
      data['imageSize'] = patient.imageSize
    }
    setPatient(camelcaseKeys(data))
  }

  const fetchOperator = async () => {
    fetch(`${BASE_API}/get-health-operator/`, { method: 'GET', headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded' } })
        .then(response => response.json())
        .then(data => {
          setOperator(data);
        })
  }

  const handleChangePasswordSubmit = async (variables, actions) => {

    const body = formDataFromObject({ username: me.login, password: md5(variables.currentPassword).toUpperCase(), type: 'patient' })
    const data = await (await fetch(`${BASE_API}/auth/`, { method: 'POST', body })).json()

    if (!data.token) {
      actions.setSubmitting(false)
      return message('Senha atual inválida')
    }

    actions.setSubmitting(false)
    actions.resetForm(initialValues)
    return message('Senha alterada com sucesso!')
  }

  const multiplier = {
    'GB': 1024 * 1024 * 1024,
    'MB': 1024 * 1024
  }

  useEffect(() => {
    if (localStorage.getItem('type') === 'operator') {
      fetchOperator();
    }
  }, []);

  console.log('patient.subscription', patient.subscription)
  const plan = (patient.subscription && patient.subscription.plan) || {
    plan: {
      active: true,
      color: null,
      createdAt: "",
      days: 0,
      description: "",
      id: 1,
      isDefault: true,
      name: "Plano Grátis",
      planId: null,
      price: 0,
      profileManagement: 1,
      size: 100,
      sizeType: "MB"
    }
  }

  const total = plan.size * multiplier[plan.sizeType]

  const docsSize = patient.docsSize
  const imageSize = patient.imageSize
  const totalSize = patient.imageSize + patient.docsSize

  const imageSizePercent = (imageSize * 100) / total
  const docsSizePercent = (docsSize * 100) / total

  if (localStorage.getItem('type') !== 'operator') return (
    <Main>
      <Flex flexDirection={'column'} p={[2, 3]} ai={'center'}>

        <Flex maxWidth='1000px' p={5} jc={'space-evenly'} ai={'center'} flexWrap={'wrap'} width={[1]}>
          <Flex flexDirection='column' ai='center'>
            <Flex width={100} height={100} overflow='hidden' css={css`border-radius: 50%;`} jc={'center'} border={'3px solid #eee'}>
              <img style={{ width: '100%' }} src={patient.thumbnail} alt='' />
            </Flex>
            <Box onClick={() => setAvatarModalOpen(true)}><Text cursor='pointer' fs={12}>Alterar imagem</Text></Box>

            <Box mt={2} fontSize={3} textAlign='center' maxWidth='200px'>
              {patient.name}
            </Box>
          </Flex>
        </Flex>

        <Box mb={4}>
          <Box mb={2}>
            <strong>Armazenamento:</strong> {totalSize.toFixed(2)} {plan.sizeType} de {plan.size} {plan.sizeType} usados
          </Box>
          <Flex mb={2} css={css`height: 20px; width: 400px; border-radius: 10px; background-color: hsl(0, 0%, 90%); overflow: hidden;`}>
            <Box css={css`background-color: #4EAE7E; height: 100%; width: ${docsSizePercent}%;`}></Box>
            <Box css={css`background-color: #C36B29; height: 100%; width: ${imageSizePercent}%;`}></Box>
          </Flex>
          <Flex>
            <Flex ai='center' mr={3}>
              <Box mr={2} css={css`background-color: #4EAE7E; width: 14px; height: 14px; border-radius: 50%;`}></Box>
              <Box>Documentos</Box>
            </Flex>
            <Flex ai='center'>
              <Box mr={2} css={css`background-color: #C36B29; width: 14px; height: 14px; border-radius: 50%;`} />
              <Box>Mídias</Box>
            </Flex>
          </Flex>
        </Box>

        <Flex jc='space-between' p={3} maxWidth='800px' width={1} flexWrap='wrap'>

          <Flex p={3} width={[1, 1, 1, '45%']} flexDirection='column' mt={[3, 3, 3, 0]} css={css`border: 1px solid #e1e1e1; border-radius: 8px; box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.15);`}>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Estilo de Vida</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setLifeStyleModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box fontSize={2}>
              <Box mt={2}><Text>Alimentação:</Text> {patient.alimentation && patient.alimentation.name}</Box>
              <Box mt={1}><Text>Alcool:</Text> {patient.alcohol && patient.alcohol.name}</Box>
              <Box mt={1}><Text>Cigarro:</Text> {patient.smoke && patient.smoke.name}</Box>
              <Box mt={1}><Text>Drogas:</Text> {patient.drug && patient.drug.name}</Box>
              <Box mt={1}><Text>Atividades Físicas:</Text> {patient.physicalActivity && patient.physicalActivity.name}</Box>
            </Box>
          </Flex>

          <Flex p={3} fontSize={2} width={[1, 1, 1, '45%']} flexDirection='column' mt={[3, 3, 3, 0]} height={'auto'} css={css`border: 1px solid #e1e1e1; border-radius: 8px; box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.15);`}>
            <Flex jc='space-between'>
              <Box><Text fw={700}>Alergias</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setAllergiesModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box mb={3}>
              {patient.allergies ? patient.allergies.map(item => item.name).join(', ') : 'Nenhuma'}
            </Box>

            <Flex jc='space-between'>
              <Box><Text fw={700}>Histórico Familiar</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setHistoryModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box mb={3}>
              {patient.familyHistory ? patient.familyHistory.map(item => item.name).join(', ') : 'Nenhum'}
            </Box>

            <Flex jc='space-between'>
              <Box><Text fw={700}>Medicamentos contínuos</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setMedicationsModalOpen(true)}>Editar</Text></Box>
            </Flex>
            <Box mb={3}>
              {patient.continuousMedications ? patient.continuousMedications.map(item => item.name).join(', ') : "Nenhum"}
            </Box>

          </Flex>
        </Flex>

        <Flex jc='space-between' p={3} maxWidth='800px' width={1} fw='wrap'>
          <Flex flexDirection='column' p={3} fontSize={2} width={[1, 1, 1, '45%']} mt={[3, 3, 3, 0]} css={css`border: 1px solid #e1e1e1; border-radius: 8px; box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.15);`}>

            <Flex jc='space-between'>
              <Box><Text fw={700}>Informações</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setInfoModalOpen(true)}>Editar</Text></Box>
            </Flex>

            <Box mt={1}><Text strong>Nome:</Text> {patient.name}</Box>
            <Box mt={1}><Text strong>Data de Nascimento: </Text><FormatDate>{patient.birthDate}</FormatDate></Box>
            <Box mt={1}><Text strong>CPF: </Text>{patient.cpf}</Box>
            <Box mt={1}><Text strong>Gênero: </Text>{patient.gender}</Box>
            <Box mt={1}><Text strong>Número de celular: </Text><FormatPhone>{patient.cellphone && patient.cellphone.replace(/\D/g, '')}</FormatPhone></Box>
            <Box mt={1}><Text strong>Email: </Text>{patient.login}</Box>
          </Flex>
          <Flex flexDirection='column' p={3} fontSize={2} width={[1, 1, 1, '45%']} mt={[3, 3, 3, 0]} css={css`border: 1px solid #e1e1e1; border-radius: 8px; box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.15);`}>

            <Flex jc='space-between'>
              <Box><Text fw={700}>Plano de Saúde</Text></Box>
              <Box><Text cursor='pointer' onClick={() => setPlanModalOpen(true)}>Editar</Text></Box>
            </Flex>

            <Box>{patient.healthInsurance ? (<><Text strong>Plano de Saúde: </Text>{patient.healthInsurance ? patient.healthInsurance.name : ''}</>) : "Nenhum"}</Box>
            {patient.healthInsuranceCardPhoto &&
              <Box><img src={patient.healthInsuranceCardPhoto} style={{ width: '100%' }} alt='Plano' /></Box>
            }

          </Flex>
        </Flex>

        <Flex flexDirection='column' p={3} fontSize={2} width={[1, 1, 1, '45%']} mt={[3, 3, 3, 0]} css={css`border: 1px solid #e1e1e1; border-radius: 8px; box-shadow: 1px 1px 5px rgba(50, 50, 50, 0.15);`}>
          <Box><Text fw={700}>Alterar senha</Text></Box>

          <Box mb={4}>
            <Formik
              initialValues={initialValues}
              onSubmit={handleChangePasswordSubmit}
              validationSchema={validationSchema}
              render={props => (<ChangePasswordForm {...props} />)}
            />
          </Box>



          {/* <Box>
            <Box><Text fw={700}>Trocar senha</Text></Box>
            <Box>
              <Box>Senha autal:</Box>
              <Box><Input type='password' value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} style={{width: 300}} /></Box>
              <Box>Nova senha:</Box>
              <Box><Input type='password' value={newPassword} onChange={e => setNewPassword(e.target.value)} style={{width: 300}} /></Box>
              <Box>Repita a nova senha:</Box>
              <Box mb={2}><Input type='password' value={newPasswordConfirmation} onChange={e => setNewPasswordConfirmation(e.target.value)} style={{width: 300}} /></Box>
              <Box><Button onClick={savePassword}>Salvar</Button></Box>
            </Box>
          </Box> */}
        </Flex>
      </Flex>

      {/* <Box>
        <Box>
          <Text large primary fw={600}>Perfil</Text>
        </Box>
        <Box>
          {patient.name}
        </Box>
        <Box>
          {patient.thumbnail &&
            <img style={{width: '100%'}} src={`${BASE_API}/${patient.thumbnail}?token=token%20${localStorage.getItem(AUTH_TOKEN)}`} alt='' />
          }
        </Box>

        <Box>
          <Flex jc='space-between'>
            <Box><Text>Estilo de Vida</Text></Box>
            <Box><Text onClick={() => setLifeStyleModalOpen(true)}>Editar</Text></Box>
          </Flex>
          <Box>
            <Box mb={1}><Text strong>Alimentação:</Text> {patient.alimentation.name}</Box>
            <Box mb={1}><Text strong>Alcool:</Text> {patient.alcohol.name}</Box>
            <Box mb={1}><Text strong>Cigarro:</Text> {patient.smoke.name}</Box>
            <Box mb={1}><Text strong>Drogas:</Text> {patient.drug.name}</Box>
            <Box mb={1}><Text strong>Atividades Físicas:</Text> {patient.physicalActivity.name}</Box>
          </Box>
        </Box>

        <Box>
          <Flex jc='space-between'>
            <Box><Text>Alergias</Text></Box>
            <Box><Text onClick={() => setAllergiesModalOpen(true)}>Editar</Text></Box>
          </Flex>
          <Box>
            {patient.allergies.map(item => item.name).join(', ')}
          </Box>
        </Box>

        <Box>
          <Flex jc='space-between'>
            <Box><Text>Histórico Familiar</Text></Box>
            <Box><Text onClick={() => setHistoryModalOpen(true)}>Editar</Text></Box>
          </Flex>
          <Box>
            {patient.familyHistory.map(item => item.name).join(', ')}
          </Box>
        </Box>

        <Box>
          <Flex jc='space-between'>
            <Box><Text>Medicamentos contínuos</Text></Box>
            <Box><Text onClick={() => setMedicationsModalOpen(true)}>Editar</Text></Box>
          </Flex>
          <Box>
            {patient.continuousMedications.map(item => item.name).join(', ')}
          </Box>
        </Box>

        <Box>
          <Flex jc='space-between'>
            <Box><Text>Informações</Text></Box>
            <Box><Text onClick={() => setInfoModalOpen(true)}>Editar</Text></Box>
          </Flex>
          <Box>
            <Box mb={1}><Text strong>Data de Nascimento:</Text> {patient.birthDate}</Box>
            <Box mb={1}><Text strong>CPF:</Text> {patient.cpf}</Box>
            <Box mb={1}><Text strong>Gênero:</Text> {patient.gender}</Box>
            <Box mb={1}><Text strong>Número de celular:</Text> {patient.cellphone}</Box>
            <Box mb={1}><Text strong>Email:</Text> {patient.login}</Box>
          </Box>
        </Box>

        <Box>
          <Flex jc='space-between'>
            <Box><Text>Plano de Saúde</Text></Box>
            <Box><Text onClick={() => setPlanModalOpen(true)}>Editar</Text></Box>
          </Flex>
          <Box>
            <Box mb={1}><Text strong>Plano de Saúde:</Text> {patient.healthInsurance}</Box>
            <Box mb={1}><Text strong>Número do cartão do plano de saúde:</Text> {patient.healthInsuranceNumber}</Box>
            <Box mb={1}><Text strong>Tipo:</Text> {patient.healthInsuranceSubPlan}</Box>
          </Box>
        </Box>

      </Box> */}

      {avatarModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setAvatarModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Avatar
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={patient}
                onUpdated={() => { fetchPatientNoStorage(); onRequestClose() }}
              />
            )
          }
        />
      }

      {lifeStyleModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setLifeStyleModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <LifeStyle
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={patient}
                onUpdated={() => fetchPatientNoStorage()}
              />
            )
          }
        />
      }

      {allergiesModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setAllergiesModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Allergies
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={patient.allergies}
                onUpdated={() => fetchPatientNoStorage()}
              />
            )
          }
        />
      }

      {historyModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setHistoryModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <History
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={patient.familyHistory}
                onUpdated={() => fetchPatientNoStorage()}
              />
            )
          }
        />
      }

      {medicationsModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setMedicationsModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Medications
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={patient.continuousMedications}
                onUpdated={() => fetchPatientNoStorage()}
              />
            )
          }
        />
      }

      {infoModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setInfoModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Info
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={patient}
                onUpdated={() => fetchPatientNoStorage()}
              />
            )
          }
        />
      }

      {planModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setPlanModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Plan
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={patient}
                onUpdated={() => { fetchPatient(); message('Salvo com sucesso!') }}
              />
            )
          }
        />
      }
    </Main>
  )

  if (localStorage.getItem('type') === 'operator') return (
    <Main>
      <Flex flexDirection={'column'} p={[2, 3]} ai={'center'}>

        <Flex maxWidth='1000px' p={5} jc={'space-evenly'} ai={'center'} flexWrap={'wrap'} width={[1]}>
          <Flex flexDirection='column' ai='center'>
            <Flex width={100} height={100} overflow='hidden' css={css`border-radius: 50%;`} jc={'center'} border={'3px solid #eee'}>
              <img style={{ width: '100%' }} src={operator.thumbnail} alt='' />
            </Flex>
            <Box onClick={() => setAvatarModalOpen(true)}><Text cursor='pointer' fs={12}>Alterar imagem</Text></Box>

            <Box mt={2} fontSize={3} textAlign='center' maxWidth='200px'>
              {operator.name || "Não informado"}
            </Box>

            <Box mt={1} fontSize={2} textAlign='center' maxWidth='200px'>
              CNPJ: {operator.cnpj || 'Não informado'}
            </Box>
            <Box mt={4} fontSize={2} textAlign='center' maxWidth='200px'>
              <Button onClick={() => setOperatorModalOpen(true)}>Editar</Button>
            </Box>
          </Flex>
        </Flex>
      </Flex>
      {avatarModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setAvatarModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Avatar
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={patient}
                onUpdated={() => { fetchOperator(); onRequestClose() }}
              />
            )
          }
        />
      }

      {operatorModalOpen &&
        <Modal
          clickOutsideDisabled={true}
          onCloseCompleted={() => setOperatorModalOpen(false)}
          render={
            ({ onRequestClose }) => (
              <Operator
                onRequestClose={onRequestClose}
                onCompleted={() => message('Salvo com sucesso!')}
                initialData={operator}
                onUpdated={() => { fetchOperator(); onRequestClose();}}
              />
            )
          }
        />
        }
    </Main>
  )
})


export default memo(props => {
  const { data, error, loading } = useQuery(GET)
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress />
  return <Home {...props} me={data.patient} />
})
