import React, { memo } from 'react'

import Button from '../../components/Button'
import ButtonRaw from '../../components/ButtonRaw'
import { Box, Flex } from '../../components/FlexBox'
import { CloseIcon, DeleteIcon } from '../../components/Icons'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Text from '../../components/Text'


import useInput from '../../hooks/useInput'

export default memo(({ onUpdated, onRequestClose, initialData }) => {


  // const [loading, setLoading] = useState(false)
  // const [addresses, setAddresses] = useState(initialData)

  // const [uf, setUf] = useState('')

  const ufInput = useInput('')
  const nameInput = useInput('')
  const cityInput = useInput('')
  const postalCodeInput = useInput('')
  const districtInput = useInput('')
  const complementInput = useInput('')
  const streetInput = useInput('')
  const numberInput = useInput('')

  // const createAddress = useMutation(CREATE_ADDRESS)

  const addresses = initialData.addresses

  // const [specialtyId, setSpecialtyId] = useState(undefined)

  // const linkSpecialty = useMutation(LINK_SPECIALITY)

  // const refetch = async () => await client.query({ query: LIST, fetchPolicy: 'network-only' })

  const handleDelete = async id => {
    // const response = await fetch(`${BASE_API}/establishmentSpecialty/?establishment_id=${initialData.id}&specialty_id=${id}`, { method: 'DELETE', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})
    // const data = await response.json()
    // console.log('data', data)
    // // if (data && data.worked) onUpdated()    
  }

  const handleAdd = () => {
    // linkSpecialty({ variables: { input: { establishmentId: initialData.id, specialtyId } } })
    //   .then(data => {
    //     console.log({data})
    //   })
    //   .catch(error => {
    //     console.error('error', error)
    //   })

  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Endereços</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Box mb={3}>
          <Box>Endereço</Box>
          <Box>

            <Box mb={3}>
              <Box flex={1} mb={2}><Input placeholder='Nome' {...nameInput} /></Box>
              <Box flex={1} mb={2}><Input placeholder='Endereço' {...streetInput} /></Box>
              <Box flex={1} mb={2}><Input placeholder='Número' {...numberInput} /></Box>
              <Box flex={1} mb={2}><Input placeholder='Complemento' {...complementInput} /></Box>
              <Box flex={1} mb={2}><Input placeholder='Bairro' {...districtInput} /></Box>
              <Box flex={1} mb={2}>
                <Select placeholder='Selecione o Estado...' {...ufInput}>
                  <option value='RJ'>RJ</option>
                </Select>
              </Box>
              <Box flex={1} mb={2}><Input placeholder='Cidade' {...cityInput} /></Box>
              <Box flex={1} mb={2}><Input placeholder='CEP' {...postalCodeInput} /></Box>
              <Box><Button onClick={handleAdd}>Adicionar</Button></Box>
            </Box>

            {/* <Box>
              <Button onClick={handleAdd} small>Adicionar</Button>
            </Box> */}
          </Box>
        </Box>
        
        <Box mb={3}>
          {addresses.map(item =>
            <Flex jc='space-between' ai='center' key={item.id} p={3} style={{borderBottom: '1px solid lightgrey'}}>
              <Box py={3}>
                <Box>{item.street} {item.number} {item.complement}</Box>
                <Box>{item.district}</Box>
                <Box>{item.postal_code}</Box>
                <Box>{item.city.name} - {item.city.uf.abbreviation}</Box>
              </Box>
              <Box ml={3}>
                <Box><ButtonRaw onClick={() => handleDelete(item.id)}><DeleteIcon /></ButtonRaw></Box>
              </Box>
            </Flex>
          )}
        </Box>

      </Box>
    </Flex>
  )
})
