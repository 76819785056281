import React, { memo, useState } from "react";
import { QueryParamProvider } from "use-query-params";
import { Router, globalHistory, useNavigate } from "./utils/router";
import { ThemeProvider } from "./utils/styled";

import GlobalStyle from "./layouts/GlobalStyle";
import theme from "./layouts/theme";

import Alert from "./components/Alert";
import { OnlyAuthenticated, OnlyUnauthenticated } from "./utils/authorization";

import LayoutLogin from "./layouts/Login";

import ForgotPassword from "./containers/Sessions/ForgotPassword";
import Login from "./containers/Sessions/Login";
import Signup from "./containers/Sessions/Signup";

import Alerts from "./containers/Alerts";
import Conditions from "./containers/Conditions";
import Doctors from "./containers/Doctors";
import Exams from "./containers/Exams";
import Files from "./containers/Files";
import Institutions from "./containers/Institutions";
import Invite from "./containers/Invite";
import ListExams from "./containers/ListExams";
import MyPlan from "./containers/MyPlan";
import Plans from "./containers/Plans";
import Prescription from "./containers/Prescription";
import Profile from "./containers/Profile";
import Report from "./containers/Report";
import Terms from "./containers/Terms";
import UploadExams from "./containers/UploadExams";
import Users from "./containers/Users";

import DoctorProfile from "./containers/DoctorProfile";

import EstablishmentBetaHCG from "./containers/EstablishmentBetaHCG";
import EstablishmentDashboard from "./containers/EstablishmentDashboard";
import EstablishmentDoctors from "./containers/EstablishmentDoctors";
import EstablishmentLoyaltyCard from "./containers/EstablishmentLoyaltyCard";
import EstablishmentPatients from "./containers/EstablishmentPatients";
import EstablishmentPolypus from "./containers/EstablishmentPolypus";

import Operators from "./containers/Operators";
import Patients from "./containers/Patients";
import useMobileDetect from "./hooks/useMobileDetect";
import DicomViewer from "./containers/DicomViewer";

export default memo(({ client, logout }) => {

  const [alertMessage, setAlertMessage] = useState("");
  useMobileDetect();

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />

        <QueryParamProvider reachHistory={globalHistory}>
          <Router>
            <Invite path="/convite-medico" />

            <Exams path="/exame" />

            <Terms path="/politica-de-privacidade" />

            <Conditions path="/termos" />

            <OnlyUnauthenticated path="/login">
              {(props) => (
                <LayoutLogin>
                  <Login {...props} message={setAlertMessage} redirectTo="/" />
                </LayoutLogin>
              )}
            </OnlyUnauthenticated>

            <OnlyUnauthenticated path="/cadastro">
              {(props) => (
                <LayoutLogin>
                  <Signup {...props} message={setAlertMessage} redirectTo="/" />
                </LayoutLogin>
              )}
            </OnlyUnauthenticated>

            <OnlyUnauthenticated path="/recuperar-senha">
              {(props) => (
                <LayoutLogin>
                  <ForgotPassword
                    {...props}
                    message={setAlertMessage}
                    redirectTo="/"
                  />
                </LayoutLogin>
              )}
            </OnlyUnauthenticated>

            <OnlyAuthenticated path="/perfil">
              {(me, props) => (
                <Profile
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/arquivos">
              {(me, props) => (
                <Files
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            {/* <OnlyAuthenticated path='/associados'>
              {(me, props) => <Associates {...props} message={setAlertMessage} me={me} onLogout={() => logout(client)} /> }
            </OnlyAuthenticated> */}

            <OnlyAuthenticated path="/pacientes">
              {(me, props) => (
                <Patients
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/operadoras">
              {(me, props) => (
                <Operators
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/medicos">
              {(me, props) => (
                <Doctors
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/relatorios">
              {(me, props) => (
                <Report
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/alertas">
              {(me, props) => (
                <Alerts
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/usuarios">
              {(me, props) => (
                <Users
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/meu-plano">
              {(me, props) => (
                <MyPlan
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/planos">
              {(me, props) => (
                <Plans
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/instituicoes">
              {(me, props) => (
                <Institutions
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            {/* <OnlyAuthenticated path='/pacientes'>
              {(me, props) => <DoctorPatients {...props} message={setAlertMessage} me={me} onLogout={() => logout(client)} client={client} /> }
            </OnlyAuthenticated> */}

            <OnlyAuthenticated path="/meuperfil">
              {(me, props) => (
                <DoctorProfile
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                  client={client}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/">
              {(me, props) => {
                const navigate = useNavigate();
                const type = localStorage.getItem("type");
                if (type === "operator") {
                  navigate("/relatorios");
                  return (
                    <Report
                      {...props}
                      message={setAlertMessage}
                      me={me}
                      onLogout={() => logout(client)}
                    />
                  );
                } else if (type === "doctor") {
                  navigate("/prescricoes");
                  return (
                    <Prescription
                      {...props}
                      message={setAlertMessage}
                      me={me}
                      onLogout={() => logout(client)}
                    />
                  );
                } else {
                  return (
                    <Report
                      {...props}
                      message={setAlertMessage}
                      me={me}
                      onLogout={() => logout(client)}
                    />
                  );
                }
              }}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/prescricoes">
              {(me, props) => (
                <Prescription
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>
            <OnlyAuthenticated path="/upload-exames">
              {(me, props) => (
                <UploadExams
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>
            <OnlyAuthenticated path="/listar-exames">
              {(me, props) => (
                <ListExams
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>
            <OnlyAuthenticated path="/dicom-view">
              {(me, props) => (
                <DicomViewer
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/estabelecimento/pacientes">
              {(me, props) => (
                <EstablishmentPatients
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                  client={client}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/estabelecimento/medicos">
              {(me, props) => (
                <EstablishmentDoctors
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                  client={client}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/estabelecimento/dashboard">
              {(me, props) => (
                <EstablishmentDashboard
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                  client={client}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/estabelecimento/betaHCG">
              {(me, props) => (
                <EstablishmentBetaHCG
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                  client={client}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/estabelecimento/cartaoFidelidade">
              {(me, props) => (
                <EstablishmentLoyaltyCard
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                  client={client}
                />
              )}
            </OnlyAuthenticated>

            <OnlyAuthenticated path="/estabelecimento/polypus">
              {(me, props) => (
                <EstablishmentPolypus
                  {...props}
                  message={setAlertMessage}
                  me={me}
                  onLogout={() => logout(client)}
                  client={client}
                />
              )}
            </OnlyAuthenticated>
          </Router>
        </QueryParamProvider>

        <Alert
          open={!!alertMessage}
          onClose={() => setAlertMessage("")}
          message={alertMessage}
        />
      </>
    </ThemeProvider>
  );
});
