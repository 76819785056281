import React, { memo, useState } from 'react'

import { useAsync } from 'react-async-hook'

import { Flex, Box } from '../../components/FlexBox'
import Text from '../../components/Text'
import { CloseIcon } from '../../components/Icons'
import ButtonRaw from '../../components/ButtonRaw'
import { DeleteIcon } from '../../components/Icons'
import Select from '../../components/Select'
import Button from '../../components/Button'
import CircularProgress from '../../components/CircularProgress'

import { useMutation } from '../../hooks/useApollo'
import { LINK_SPECIALITY } from './Gqls'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const Specialties = memo(({ onDeleted, onAdd, onRequestClose, initialData, specialties }) => {

  const mySpecialties = initialData.specialties

  const [specialtyId, setSpecialtyId] = useState(undefined)

  const linkSpecialty = useMutation(LINK_SPECIALITY)

  const handleDelete = async id => {
    if (window.confirm('Você tem certeza que deseja excluir esta especialidade?')) {
      const data = new FormData()
      data.append('establishment_id', initialData.id)
      data.append('specialty_id', id)
      const response = await (await fetch(`${BASE_API}/establishmentSpecialty`, { method: 'DELETE', body: data, headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
      console.log('response', response)
      if (response && response.worked) onDeleted()
    }
  }

  const handleAdd = () => {
    linkSpecialty({ variables: { input: { establishmentId: initialData.id, specialtyId } } })
      .then(data => {
        onAdd()
      })
      .catch(error => {
        console.error('error', error)
      })

  }

  return (
    <Flex fd='column' h='100%'>
      <Box position='relative' h={64}>
        <Box position='absolute' cursor='pointer' lh='0' top={16} right={16} onClick={onRequestClose}><CloseIcon /></Box>
        <Box p={3} ta='center'><Text secondary large>Especialidades</Text></Box>
      </Box>
      <Box p={4} overflow='auto'>
        <Box mb={3}>
          <Box>Especialidade</Box>
          <Flex ai='center'>
            <Box mr={2} flex={1}>
              <Select placeholder='Selecione uma especialidade...' value={specialtyId} onChange={e => setSpecialtyId(e.target.value)}>
                {specialties.sort((a, b) => a.name.localeCompare(b.name)).map(item =>
                  <option value={item.id} key={item.id}>{item.name}</option>
                )}
              </Select>
            </Box>
            <Box>
              <Button onClick={handleAdd} small>Adicionar</Button>
            </Box>
          </Flex>
        </Box>
        
        <Box mb={3}>
          {mySpecialties.map(item =>
            <Flex jc='space-between' ai='center' key={item.id} p={3} style={{borderBottom: '1px solid lightgrey'}}>
              <Box>
                {item.name}
              </Box>
              <Box ml={3}>
                <Box><ButtonRaw onClick={() => handleDelete(item.specialty)}><DeleteIcon /></ButtonRaw></Box>
              </Box>
            </Flex>
          )}
        </Box>

      </Box>
    </Flex>
  )
})

export default memo(props => {
  const fetchSpecialties = async id => (await fetch(`${BASE_API}/getAllSpecialties`, { headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
  const { loading, error, result } = useAsync(fetchSpecialties, [])
  if (error) return `Erro! ${error.message}`
  if (!result || loading) return <CircularProgress />
  return <Specialties {...props} specialties={result} />
})
